import React, { Fragment } from "react";
import { Alert, AlertIcon, Button } from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/all";
import { useCustomerVerification } from "hooks";
import { useHistory } from "react-router-dom";

interface Props {
  successMessage?: string;
  redirectToTradeScreen?: boolean;
}

export const ContinueNextStep = ({ successMessage, redirectToTradeScreen }: Props) => {
  const history = useHistory();
  const { autoSelectCustomerStage } = useCustomerVerification();

  function handleNextStep() {
    if (redirectToTradeScreen) {
      history.push('/customer/trade');
    } else {
      autoSelectCustomerStage();
    }
  }

  return (
    <Fragment>
      {successMessage && (
        <Alert status="success" my={5} id="alert-success">
          <AlertIcon />
          {successMessage}
        </Alert>
      )}

      <Button
        onClick={handleNextStep}
        colorScheme="brand"
        size="md"
        id="next-step"
        rightIcon={<FaChevronRight size={14} color="#c1c1c1" />}
        type="button">
        Continue
      </Button>
    </Fragment>
  );
};
