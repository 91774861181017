import React, { ChangeEvent } from "react";
import {
  Box, Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  ListItem,
  Select, Switch,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import { Field } from "formik";
import { FormDisclaimer } from "components";
import { SourceOfFundsFormValues } from "../SourceOfFundsForm";

interface Props {
  values: SourceOfFundsFormValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const SOFStep4 = ({ values, setFieldValue }: Props) => {
  return (
    <Grid templateColumns={{ base: '1fr', lg: 'repeat(1, 1fr)' }} gap={5} my={5}>
      <Field name="individualNetWorthSwitch">
        {({ field, form }: any) => {
          const isInvalid = (form.errors.individualNetWorthSwitch && form.touched.individualNetWorthSwitch);
          return (
            <FormControl isInvalid={isInvalid}>
              <FormLabel as="legend">
                Would you like to disclose your overall net worth?
                <Box as="span" color="gray.500" fontSize="xs" ml={1}>(optional)</Box>
              </FormLabel>
              <FormHelperText fontSize="xs" mb={2}>
                Although this is entirely optional higher net worth individuals are entitled to higher trading limits.
              </FormHelperText>
              <Flex mt={3}>
                <Switch
                  {...field}
                  isChecked={values.individualNetWorthSwitch}
                  size="lg"
                  colorScheme="teal"
                  id="individualNetWorthSwitch"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('individualNetWorthSwitch', event.target.checked);
                  }}
                />
                <Text as="samp" fontSize="sm" ml={3} alignSelf="center">
                  {values.individualNetWorthSwitch ? 'Yes' : 'No'}
                </Text>
              </Flex>
              <FormErrorMessage>{form.errors.individualNetWorthSwitch}</FormErrorMessage>
            </FormControl>
          );
        }}
      </Field>

      {values.individualNetWorthSwitch && (
        <Field name="individualNetWorth">
          {({ field, form }: any) => {
            const isInvalid = (form.errors.individualNetWorth && form.touched.individualNetWorth);
            return (
              <FormControl isInvalid={isInvalid}>
                <FormLabel as="legend">What is your overall net-worth?</FormLabel>
                <FormHelperText fontSize="xs" mb={2}>
                  <Box fontSize="sm" mb={4} color="gray.500">
                    <Text mb={2}>
                      Your net worth is the value of the assets you own, minus the liabilities you owe.
                    </Text>
                    <Text>
                      Assets could include:
                    </Text>
                    <UnorderedList mt={1} mb={1} spacing={1}>
                      <ListItem>
                        Your home
                      </ListItem>
                      <ListItem>
                        Financial assets (cryptos, stocks, bonds etc)
                      </ListItem>
                      <ListItem>
                        Other properties
                      </ListItem>
                      <ListItem>
                        Vehicles
                      </ListItem>
                      <ListItem>
                        Jewelery
                      </ListItem>
                      <ListItem>
                        Retirement accounts
                      </ListItem>
                      <ListItem>
                        Savings
                      </ListItem>
                    </UnorderedList>
                    <Text>
                      Liabilities could include:
                    </Text>
                    <UnorderedList mt={1} mb={1} spacing={1}>
                      <ListItem>
                        Mortgages on your properties
                      </ListItem>
                      <ListItem>
                        Vehicle finance
                      </ListItem>
                      <ListItem>
                        Student loans
                      </ListItem>
                      <ListItem>
                        Credit card debts
                      </ListItem>
                      <ListItem>
                        Other loans
                      </ListItem>
                    </UnorderedList>
                  </Box>
                </FormHelperText>
                <Select
                  {...field}
                  maxWidth={485}
                  placeholder="Select option"
                  name="individualNetWorth"
                  id="individualNetWorth"
                  size="sm">
                  <option value={`{ "min": 0, "max": 12000 }`}>£0.00 - £12,000</option>
                  <option value={`{ "min": 12001, "max": 25000 }`}>£12,001 - £25,000</option>
                  <option value={`{ "min": 25001, "max": 40000 }`}>£25,001 - £40,000</option>
                  <option value={`{ "min": 40001, "max": 70000 }`}>£40,001 - £70,000</option>
                  <option value={`{ "min": 70001, "max": 100000 }`}>£70,001 - £100,000</option>
                  <option value={`{ "min": 100001, "max": 150000 }`}>£100,001 - £150,000</option>
                  <option value={`{ "min": 150001, "max": 250000 }`}>£150,001 - £250,000</option>
                  <option value={`{ "min": 250001, "max": 500000 }`}>£250,001 - £500,000</option>
                  <option value={`{ "min": 500001, "max": 1000000 }`}>£500,001 - £1,000,000</option>
                  <option value={`{ "min": 1000001, "max": 2500000 }`}>£1,000,001 - £2,500,000</option>
                  <option value={`{ "min": 2500001, "max": 5000000 }`}>£2,500,001 - £5,000,000</option>
                  <option value={`{ "min": 5001000, "max": 9999999 }`}>£5,001,000 - or above</option>
                </Select>
                <FormErrorMessage>{form.errors.individualNetWorth}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
      )}

      <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
        <FormDisclaimer name="Level 2: T0 Form" />
      </Grid>
    </Grid>
  );
};
