class KTimer {
  constructor(listener) {
    if (listener.id) {
      this.id = listener.id;
    } else {
      KTimer.uniqueId++;
      this.id = KTimer.uniqueId;
    }
    this.listener        = listener;
    this.nextCallTime    = 0;
    this.delay           = 0;
    this.timerHandle     = null;
    this.callOnceMode    = false;
  }

  _killTimer() {
    this.nextCallTime = 0;

    /* clear timer if there is one already */
    if (this.timerHandle) {
      clearTimeout(this.timerHandle);
    }
  }

  _castTimer() {
    this._killTimer();
    this.nextCallTime = this._now() + this.delay;
    this.timerHandle = setTimeout(
      () => {
        this._onTryCalling();
      },
      this.delay
    );

    /* the unref() is needed in node.js code.
     If the unref() wasn't called, then this timer would prevent
     the node.js process from exiting when finished.
    */
    if (this.timerHandle.unref) {
      this.timerHandle.unref();
    }
  }

  _now() {
    return (new Date()).getTime();
  }

  destroy() {
    this._killTimer();
  }

  callEvery(delay) {
    this.callOnceMode = false;
    this.delay        = delay;

    this._castTimer(this.delay);
  }

  _onTryCalling() {
    const now = this._now();
    if (this.callOnceMode) {
      this.nextCallTime = 0;
    } else {
      this.nextCallTime = now + this.delay;
    }

    // Pass control to caller-defined handler.
    this.listener.onTimer(now);

    // if it's repeating timer, cast it again
    if ((!this.callOnceMode) && (this.nextCallTime)) {
      this._castTimer();
    }
  }

  callOnce(delayMinMs, delayMaxMs) {
    this.callOnceMode = true;

    if (this.nextCallTime === 0) {
      // Timer is stopped right now, cast the new one.
      this.delay = delayMaxMs;
    } else {
      // Timer is already running. Modify next call time if needed.
      let delayCurrentMs = this.nextCallTime - this._now();

      if (delayCurrentMs < delayMinMs) {
        delayCurrentMs = delayMinMs;
      }

      if (delayCurrentMs > delayMaxMs) {
        delayCurrentMs = delayMaxMs;
      }

      this.delay = delayCurrentMs;
    }
    this._castTimer();
  }
}

KTimer.uniqueId = 0;

exports.KTimer = KTimer;
