import React, { ReactNode } from 'react';
import { Image, Box } from '@chakra-ui/react';

interface Props {
  icon: string;
  children: ReactNode;
  title: string;
}

export const ContactMethod = ({ icon, children, title }: Props) => {
  return (
    <Box display="flex">
      <Image mr={5} boxSize="40px" src={icon} alt={title} />
      {children}
    </Box>
  );
};
