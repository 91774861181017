import React, { Fragment, useEffect, useRef } from "react";
import { useComplianceState, useCustomerProfile, useCustomerSumSub } from "hooks";
import { Alert, AlertDescription, AlertIcon, Box, Spinner, Text } from "@chakra-ui/react";
import snsWebSdk from "@sumsub/websdk";
import { customerApiClient } from "../../../../../libs/CustomerLondonLinkApiClient";
import { envars } from "../../../../../config/envars";

export const SumSubIframe = () => {
  const { actions } = useComplianceState();
  const { sumsubState, getWebSDKToken } = useCustomerSumSub();
  const { profile } = useCustomerProfile();

  useEffect(() => {
    getWebSDKToken();
  }, []);

  const kycStatus = actions.kycSumsub.status;
  const kycState = actions.kycSumsub.state;

  const sumsubDone = kycStatus === 'pending' && kycState !== 'waitingForSumsub';
  const needsRetry = kycStatus === 'failed';

  if (sumsubDone && !needsRetry) {
    return (
      <Alert status="info">
        <Box mr={4}>
          <Spinner size="md" />
        </Box>
        <Text>
          We are reviewing your information, this process normally takes about 2 minutes. In case of difficulties,
          it may last for up to 24 hours. This screen will automatically update once we have finished reviewing
          your information.
        </Text>
      </Alert>
    );
  }

  if (sumsubState.getWebSDKTokenRequest.dataStatus === "loading" || !sumsubState.webSDKToken || !profile.contactDetails) {
    return <Spinner />;
  }


  return (
    <Fragment>
      {kycState === 'failedFinal' && (
        <Alert mt={4} status="info">
          <AlertIcon boxSize="18px" />
          <AlertDescription size="xs">
            Please note: SumSub is LondonLink&apos;s KYC provider
          </AlertDescription>
        </Alert>
      )}

      <Iframe
        token={sumsubState.webSDKToken}
        customerEmail={profile.contactDetails.email}
      />
    </Fragment>
  );
};

interface SumSubIframeProps {
  token: string;
  customerEmail: string;
}

const Iframe = ({ token, customerEmail }: SumSubIframeProps) => {
  const { fetchProfile } = useCustomerProfile();
  const iframeContainer = useRef(null);

  const iframeCSS = `
  section { 
    max-width: 100%;
  }
  
  button {
    border-radius: 4px;
  }
  
  button.submit, button[type=submit] {
    background: #004890;
    border-radius: 4px;
  }
  
  button.submit:active:not(:disabled),
  button.submit:hover:not(:disabled):not(.disabled):not(:active),
  button[type=submit]:active:not(:disabled),
  button[type=submit]:hover:not(:disabled):not(.disabled):not(:active) {
    background-image: none;
    background: #004182;
  }
  
  .step.pending .bullet,
  .step.pending .line {
    background-color: #2ab893;
    border-color: #2ab893;
  }
  
  .step.active.pending .bullet:before {
    background-color: #2ab893;
  }
`;

  useEffect(() => {
    function initSumsubSDKInstance(
      accessToken: string,
      applicantEmail: string,
      applicantPhone?: string,
      customI18nMessages?: any) {
      const getNewAccessToken = async () => {
        try {
          const res = await customerApiClient.getXhrClient().post("/sumsub/getWebSDKAccessToken", {
            levelName: "Tier 0 Individual"
          });

          return Promise.resolve(res.data.token);
        } catch (error: any) {
          // TODO: Log error to service
          throw new Error(error);
        }
      };

      let snsWebSdkInstance = snsWebSdk.init(
        accessToken,
        // Token update callback, must return Promise
        // Access token expired - get a new one and pass it to the callback to re-initiate the WebSDK
        () => getNewAccessToken()
      );

      if (envars.ENVIRONMENT !== "production") {
        snsWebSdkInstance = snsWebSdkInstance.onTestEnv();
      }

      snsWebSdkInstance = snsWebSdkInstance
        .withConf({
          lang: 'en', // language of WebSDK texts and comments (ISO 639-1 format)
          email: applicantEmail,
          phone: applicantPhone,
          i18n: customI18nMessages, // JSON of custom SDK Translations
          uiConf: {
            customCssStr: iframeCSS
          },
        })
        .withOptions({ addViewportTag: false, adaptIframeHeight: true })
        .on('stepCompleted', (payload: any) => {
          fetchProfile();
        })
        .on('onError', (error: any) => {
          // Handle errors
          // TODO: Log error to service
        })
        .onMessage(async (type: any, payload: any) => {
          // Send message events to server
          if ((type === 'idCheck.onApplicantLoaded') || (type === 'idCheck.applicantStatus')) {
            try {
              await customerApiClient.getXhrClient().post('/customer/sumsubUpdated', { message: { type, payload } });
            } catch (error: any) {
              // TODO: Log error to service
              throw new Error(error);
            }
          }

          if (type === 'idCheck.onApplicantLoaded') {
            window.testPayload.sumsub.applicantId = payload.applicantId;
          }

          if (type === 'idCheck.applicantStatus') {
            const { reviewStatus, reviewResult } = payload;

            if (reviewStatus === 'completed' && reviewResult.reviewAnswer === 'GREEN') {
            // Handle sumsub completed & GREEN result
              await fetchProfile();
            }

            if (reviewStatus === 'completed' && reviewResult.reviewAnswer === 'RED') {
            // Handle sumsub completed & RED result
              await fetchProfile();
            }
          }
        })
        .build();

      snsWebSdkInstance.launch("#sumsub-websdk-container");
    }

    initSumsubSDKInstance(token, customerEmail);
  }, []);

  return (
    <div ref={iframeContainer} id="sumsub-websdk-container" />
  );
};
