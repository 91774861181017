export const colors = {
  brand: {
    50: '#80a4c8',
    100: '#6691bc',
    200: '#4d7fb1',
    300: '#336da6',
    400: '#1a5a9b',
    500: '#004890',
    600: '#004182',
    700: '#003a73',
    800: '#003265',
    900: '#002b56'
  }
};
