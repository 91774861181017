import React, { Fragment, useEffect } from "react";
import {
  AdditionalVerificationStatus,
  ContinueNextStep, SourceOfFunds,
  VerificationTemplate
} from "components";
import { useComplianceState, useCustomerVerification } from "hooks";
import { ComplianceActionStages } from "../../../../../shared/src/types/compliance";

export const Level2VerificationPage = () => {
  const { verificationState } = useCustomerVerification();
  const { actions, level2Completed } = useComplianceState();

  const currentStage = verificationState.currentStage;
  const { autoSelectCustomerStage } = useCustomerVerification();

  useEffect(() => {
    autoSelectCustomerStage();
  }, []);

  return (
    <VerificationTemplate
      pageName="Level 2 Verification"
      verificationStages={[
        { key: 1, title: 'Source of funds', status: actions.t1Form.status, item: ComplianceActionStages.t1Form },
        { key: 2, title: 'Additional verification', status: actions.level2AdditionalVerification.status, item: ComplianceActionStages.level2AdditionalVerification, disabled: true },
      ]}
      breadcrumb={[
        { title: 'Verification', current: false, to: '/customer/verification' },
        { title: 'Level 2', current: true, to: '/customer/verification/level-2' },
      ]}>
      {!level2Completed ? (
        <Fragment>
          {currentStage === ComplianceActionStages.t1Form && <SourceOfFunds />}
          {currentStage === ComplianceActionStages.level2AdditionalVerification && <AdditionalVerificationStatus />}
        </Fragment>
      ) : (
        <ContinueNextStep successMessage="Level 2 verification completed!" redirectToTradeScreen />
      )}
    </VerificationTemplate>
  );
};
