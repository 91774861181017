import React, { Fragment, useEffect } from "react";
import { Field, Form, Formik } from "formik";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Select,
  Textarea
} from "@chakra-ui/react";
import { useCustomerForms, useCustomerVerification } from "hooks";
import { FormDisclaimer, SpinnerToTickIcon } from "components";
import * as yup from "yup";
import { ComplianceActionStages } from "../../../../../../../shared/src/types/compliance";
import { DataStatuses } from "../../../../../../../shared/src/types/common";

const initialValues = {
  accountPurpose: '',
  employmentStatus: '',
  sourceOfFunds: '',
  sourceOfFundsOther: undefined
};

export type T0FormValues = typeof initialValues;

const validationSchema = yup.object().shape({
  accountPurpose: yup.string().required('Field is required'),
  employmentStatus: yup.string().required('Field is required'),
  sourceOfFunds: yup.string().required('Field is required'),
  sourceOfFundsOther: yup.string().when('sourceOfFunds', {
    is: (val: string) => val === 'Other',
    then: yup.string().required('Field is required'),
    otherwise: yup.string().nullable()
  }),
});


export const AccountPurposeForm = () => {
  const { submitForm, formsState, resetFormsState } = useCustomerForms();
  const { customerStageSelect } = useCustomerVerification();

  const isLoading = formsState.formSubmitRequest.dataStatus === DataStatuses.Loading;
  const isSuccess = formsState.formSubmitRequest.dataStatus === DataStatuses.Ok;

  useEffect(() => {
    // Move to next stage after success
    if (isSuccess) {
      // Add a small delay before moving onto the next step to allow the SpinnerToTickIcon component animation to finish
      setTimeout(() => {
        resetFormsState();
        customerStageSelect(ComplianceActionStages.kycSumsub);
      }, 1250);
    }
  }, [isSuccess]);

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: T0FormValues) => {
        const formData = {
          accountPurpose: values.accountPurpose,
          employmentStatus: values.employmentStatus,
          sourceOfFunds: values.sourceOfFunds
        };

        // Add optional fields
        if (values.sourceOfFundsOther) {
          Object.assign(formData, { sourceOfFundsOther: values.sourceOfFundsOther });
        }

        submitForm(formData);
      }}>
      {({ values }) => {
        return (
          <Form id="t0" style={{ width: '100%' }}>
            <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
              <Field name="accountPurpose">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.accountPurpose && form.touched.accountPurpose);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <FormLabel fontSize="sm" as="legend">What is your primary reason for creating an account?</FormLabel>
                      <Select
                        {...field}
                        placeholder="Select option"
                        name="accountPurpose"
                        id="accountPurpose"
                        size="sm">
                        <option value="Investing (Buying to hold)">Investing (Buying to hold)</option>
                        <option value="Trading (Buying/selling often)">Trading (Buying/selling often)</option>
                        <option value="Divesting (selling Crypto obtained elsewhere)">Divesting (selling Crypto obtained elsewhere)</option>
                        <option value="Trading on other platforms">Trading on other platforms</option>
                        <option value="Online purchases">Online purchases</option>
                        <option value="Online payments">Online payments</option>
                      </Select>

                      <FormErrorMessage>{form.errors.accountPurpose}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
            </Grid>

            <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
              <Field name="employmentStatus">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.employmentStatus && form.touched.employmentStatus);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <FormLabel fontSize="sm" as="legend">What is your employment status?</FormLabel>
                      <Select
                        {...field}
                        placeholder="Select option"
                        name="employmentStatus"
                        id="employmentStatus"
                        size="sm">
                        <option value="Employed">Employed</option>
                        <option value="Self-Employed">Self-Employed</option>
                        <option value="Retired">Retired</option>
                        <option value="Student">Student</option>
                        <option value="Unemployed">Unemployed</option>
                      </Select>

                      <FormErrorMessage>{form.errors.employmentStatus}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
            </Grid>

            <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
              <Field name="sourceOfFunds">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.sourceOfFunds && form.touched.sourceOfFunds);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <FormLabel fontSize="sm" as="legend">What is your primary source of funds?</FormLabel>
                      <Select
                        {...field}
                        placeholder="Select option"
                        name="sourceOfFunds"
                        id="sourceOfFunds"
                        size="sm">
                        <option value="Income/ Salary">Income/ Salary</option>
                        <option value="Savings">Savings</option>
                        <option value="Property Sale">Property Sale</option>
                        <option value="Sale of investment">Sale of investment</option>
                        <option value="Business ownership">Business ownership</option>
                        <option value="Inheritance">Inheritance</option>
                        <option value="Credit/ Loan">Credit/ Loan</option>
                        <option value="Gift">Gift</option>
                        <option value="Pension">Pension</option>
                        <option value="Lottery/ windfall">Lottery/ windfall</option>
                        <option value="Other">Other</option>
                      </Select>
                      <FormErrorMessage>{form.errors.sourceOfFunds}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
            </Grid>

            {values.sourceOfFunds === 'Other' && (
              <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
                <Field name="sourceOfFundsOther">
                  {({ field, form }: any) => {
                    const isInvalid = (form.errors.sourceOfFundsOther && form.touched.sourceOfFundsOther);
                    return (
                      <FormControl isInvalid={isInvalid}>
                        <FormLabel fontSize="sm" as="legend">What is your primary source of funds?</FormLabel>
                        <Textarea
                          {...field}
                          name="sourceOfFundsOther"
                          id="sourceOfFundsOther"
                          size="sm"
                          rows={2}
                        />
                        <FormErrorMessage>{form.errors.sourceOfFundsOther}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>
              </Grid>
            )}

            <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
              <FormDisclaimer name="Level 1: Source of funds" />
            </Grid>

            {/* Handle Error */}
            {formsState.formSubmitRequest.dataStatus === "error" && (
              <Alert status="error" mt={5}>
                <AlertIcon />
                {formsState.formSubmitRequest.dataError?.message}
              </Alert>
            )}

            <Box my={5}>
              <Button
                type="submit"
                transition="all 0.5s linear"
                colorScheme={isSuccess ? "green" : "brand"}
                leftIcon={<Fragment />}
                spinnerPlacement="end"
                rightIcon={isSuccess ? <SpinnerToTickIcon size="sm" /> : <Fragment />}
                isLoading={isLoading}
                isDisabled={isLoading}
                loadingText="Submitting"
                id="submit">
                {isSuccess ? "Submitting" : "Submit"}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
