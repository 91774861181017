import React, { Fragment } from 'react';
import { envars } from '../../config/envars';

export const EnvCheck: React.FC = ({ children }) => {
  const requiredVars: string[] = [];
  const isProduction = process.env.NODE_ENV === 'production';

  // Add mandatory environment variables here
  if (envars.BUSINESS_ENTITY === undefined) requiredVars.push('REACT_APP_BUSINESS_ENTITY');
  if (envars.API_URL === undefined) requiredVars.push('REACT_APP_API_URL');
  if (envars.WS_URL === undefined) requiredVars.push('REACT_APP_WS_URL');
  if (envars.ENVIRONMENT === undefined) requiredVars.push('REACT_APP_ENVIRONMENT');
  if (isProduction && envars.GTM.ID === undefined) requiredVars.push('REACT_APP_GTM_ID');
  if (isProduction && envars.GTM.AUTH === undefined) requiredVars.push('REACT_APP_GTM_AUTH');
  if (isProduction && envars.GTM.PREVIEW === undefined) requiredVars.push('REACT_APP_GTM_PREVIEW');

  const Error = () => {
    console.log('%cMissing environment variables:',
      'color: orange;'
      + ' font-family:sans-serif;'
      + ' font-size: 14px',
      `\n ${requiredVars.join('\n')}`);
    return <div>Configuration error. Check console for details.</div>;
  };

  if (requiredVars.length > 0) {
    return <Error />;
  }

  return <Fragment>{children}</Fragment>;
};
