import React, { Fragment } from 'react';
import { Heading, Text, AlertIcon, Alert } from '@chakra-ui/react';
import { TermsOfServiceForm } from "components";
import { useComplianceState } from "hooks";

export const TermsOfService = () => {
  const complianceState = useComplianceState();

  return (
    <Fragment>
      <Heading size="md" as="h2" mb={3}>
        Terms of service
      </Heading>

      <Text mb={4}>These are the terms you agreed to when registering your account.</Text>

      {complianceState.newTermsNotAcceptedSinceRegistering && (
        <Alert status="warning" p={2} mb={4} fontSize="sm">
          <AlertIcon width="16px" />
          This section must be completed to be eligible to trade.
        </Alert>
      )}

      <TermsOfServiceForm allTermsAgreed={complianceState.actions.termsAccepted.status === "completed"} />
    </Fragment>
  );
};
