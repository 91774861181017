import React from "react";
import { useCustomerProfile } from "hooks";
import { Text } from "@chakra-ui/react";
import { ComplianceLevelBadge, LoggedInDefaultTemplate } from "components";
import { Customer } from "../../../../../shared/src/types/customer";

export const CustomerAccountPage = () => {
  const { profile } = useCustomerProfile();

  const contactDetails = profile.contactDetails as Customer['contactDetails'];
  const complianceState = profile.compliance?.state as Customer['compliance']['state'];

  return (
    <LoggedInDefaultTemplate pageName="Account">
      <Text fontSize="sm">
        {contactDetails.email}
      </Text>

      <ComplianceLevelBadge complianceState={complianceState} />
    </LoggedInDefaultTemplate>
  );
};
