import React from "react";
import { Link } from "react-router-dom";
import { Box, Container, Image, Text } from "@chakra-ui/react";
import logo from "../../assets/images/LondonLink_Icon.png";
import { FooterNav } from "./FooterNav/FooterNav";
import { constants } from "../../config/constants";

export const Footer = () => {
  return (
    <footer>
      <Box bg="brand.500" py={5} color="#fff">
        <Container>
          <Box
            display="flex"
            alignItems={{ base: 'flex-start', lg: 'center' }}
            flexDirection={{ base: 'column', lg: 'row' }}
            justifyContent="space-between">
            <Link to="/">
              <Image src={logo} alt="LondonLink Logo" width="40px" mb={{ base: 5, lg: 0 }} />
            </Link>
            <FooterNav />
            <Text as="span" fontSize="sm">
              &copy; {constants.businessEntityFullName()} {new Date().getFullYear()}
            </Text>
          </Box>
        </Container>
      </Box>
    </footer>
  );
};
