import { envars } from "./envars";

/**
 * These are different from envars as they can by dynamically computed
 */
export const constants = {
  /**
   * This can be used to conditionally render logic per site on the website or other site specific logic
   */
  businessEntity: envars.BUSINESS_ENTITY,

  /**
   * This is used to render the full business name, normally used in legal documents/pages
   */
  businessEntityFullName: () => {
    if (envars.BUSINESS_ENTITY === "gib") {
      return "LondonLink (GI) Limited";
    }

    if (envars.BUSINESS_ENTITY === "uk") {
      return "LondonLink OTC Limited";
    }

    return "LondonLink";
  },

  /**
   * List of sites using this application
   */
  sites: {
    uk: "https://www.londonlinkotc.co.uk",
    gib: "https://londonlink.io"
  },

  /**
   * Return the full web address of the current site
   */
  site: () => {
    if (envars.BUSINESS_ENTITY === "gib") {
      return "https://londonlink.io";
    }

    if (envars.BUSINESS_ENTITY === "uk") {
      return "https://www.londonlinkotc.co.uk";
    }

    // Default
    return undefined;
  },

  /**
   * This determins the current domain
   * Example usage: when displaying email addresses on the website: `hello@${domain}`
   */
  domain: () => {
    if (envars.BUSINESS_ENTITY === "gib") {
      return "londonlink.io";
    }

    if (envars.BUSINESS_ENTITY === "uk") {
      return "londonlinkotc.co.uk";
    }

    // Default
    return "londonlink.io";
  }
};
