import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { Requirement } from "../Requirement/Requirement";

interface Props {
  password: string;
}

export const PasswordRequirements = ({ password }: Props) => {
  return (
    <Box>
      <Text fontSize="md" color="gray.600" mb={2}>Password Requirements:</Text>
      <Requirement title="8 or more characters" isValid={password.length > 7} />
      <Requirement title="1 letter" isValid={!!password.match(/[a-zA-Z]/g)} />
      <Requirement title="1 number" isValid={!!password.match(/\d/g)} />
    </Box>
  );
};
