import { AbstractActionModel } from "../abstract/AbstractActionModel";
import { DataStatuses, ErrorResponse } from "../../types/common";
import { customerApiClient } from "../../../../fe-main/src/libs/CustomerLondonLinkApiClient";

export interface CustomerConfigModelState {
  sumsubUrlPrefix: string | null;
  dataStatus: DataStatuses;
  dataError: ErrorResponse | null;
}

const xhrClient = customerApiClient.getXhrClient();

export class CustomerConfigModel extends AbstractActionModel {
  private state: CustomerConfigModelState = {
    sumsubUrlPrefix: null,
    dataStatus: DataStatuses.Uninitialized,
    dataError: null
  }

  private updateState(newState: Partial<CustomerConfigModelState>) {
    this.state = { ...this.state, ...newState };
    this.notify();
  }

  /*
    Public methods
  */
  public async fetchConfig() {
    this.updateState({ dataError: null }); // Reset error on new fetch

    const genericErrorMessage = "There was an unexpected error fetching config";

    if (this.state.dataStatus !== "loading") {
      try {
        this.updateState({ dataStatus: DataStatuses.Loading });
        const res = await xhrClient.get("/config");

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({
            sumsubUrlPrefix: res.data.config.sumsubUrlPrefix
          });

          this.updateState({ dataStatus: DataStatuses.Ok });
        } else {
          this.updateState({ dataError: { message: genericErrorMessage } });
          this.updateState({ dataStatus: DataStatuses.Error });
        }
      } catch (error) {
        this.updateState({ dataError: { stack: error, message: genericErrorMessage } });
        this.updateState({ dataStatus: DataStatuses.Error });
      }
    }
  }

  public getConfigState() {
    return this.state;
  }
}
