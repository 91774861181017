import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import {
  Box,
  Text,
  HStack,
  Button,
  ModalOverlay,
  ModalBody,
  ModalHeader,
  ModalContent,
  ModalFooter,
  Modal,
  ModalCloseButton,
  useDisclosure,
  useToast
} from '@chakra-ui/react';

export const CookieBanner = () => {
  const isReactSnap = navigator && navigator.userAgent === 'ReactSnap';
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [cookies, setCookie] = useCookies(['cookiesConsent']);
  const toast = useToast();
  const toastIdRef = React.useRef<any>(null);

  useEffect(() => {
    if (!isReactSnap) {
      if (cookies.cookiesConsent === '1') {
        if (toastIdRef.current) {
          toast.close(toastIdRef.current);
        }
      } else {
        toastIdRef.current = toast({
          position: 'bottom-left',
          duration: null,
          isClosable: false,
          render: () => (
            <Box color="gray" p={3} bg="white" borderRadius={4} boxShadow="rgb(0 0 0 / 25%) 0px 6px 16px">
              <Text fontSize="sm" mb={4}>
                LondonLink uses cookies to ensure you have the best possible experience on our website. If you continue on
                our site you are agreeing to our use of cookies and our general website terms.
              </Text>
              <HStack spacing={2} my={2} flexWrap="wrap" display="flex">
                <Button minW={100} size="sm" variant="outline" onClick={onOpen}>More Info</Button>
                <Button minW={100} size="sm" colorScheme="brand" onClick={handleConsent}>OK</Button>
              </HStack>
            </Box>
          ),
        });
      }
    }
  }, [cookies.cookiesConsent]);


  function handleConsent() {
    setCookie('cookiesConsent', '1', {
      expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)) // 1 year from now
    });
  }

  if (isReactSnap) {
    return null;
  }

  // Load "More info" modal
  return (
    <Modal size="xs" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Terms</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text fontSize="sm">
            <Link onClick={onClose} to="/general-terms-and-conditions">View our General Terms & Conditions</Link>
          </Text>
          <Text fontSize="sm">
            <Link onClick={onClose} to="/cookie-policy">View our Cookie Policy</Link>
          </Text>
        </ModalBody>

        <ModalFooter />
      </ModalContent>
    </Modal>
  );
};
