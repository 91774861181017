export const legalPage = {
  '.legal-page': {
    paddingTop: 5,
    paddingBottom: 10,
    '*': {
      fontSize: '14px'
    },
    p: {
      marginTop: '8px'
    },
    h1: {
      fontSize: '28px',
      margin: '15px 0 5px 0'
    },
    h2: {
      fontSize: '22px',
      margin: '15px 0 5px 0'
    },
    h3: {
      fontSize: '20px',
      margin: '15px 0 5px 0'
    },
    h4: {
      fontSize: '18px',
      margin: '15px 0 5px 0'
    },
    h5: {
      fontSize: '16px',
      margin: '15px 0 5px 0'
    },
    h6: {
      fontSize: '14px',
      margin: '15px 0 5px 0'
    },
    section: {
      margin: '20px 0'
    },
    a: {
      color: 'blue.500',
    },
    ul: {
      paddingLeft: '20px',
      marginTop: 3
    },
    ol: {
      paddingLeft: '20px',
      marginTop: 3
    },
    '.box': {
      border: '1px solid grey',
      padding: '15px',
      margin: '15px 0',
      background: '#fafafa',
      '.title': {
        fontWeight: 'bold',
        marginBottom: '15px'
      }
    },
    '.important': {
      fontStyle: 'italic',
      textAlign: 'center',
      fontWeight: 700
    },
    '.underline': {
      textDecoration: 'underline',
      fontSize: 'inherit'
    },
    '.table-container': {
      marginTop: 6,
      marginBottom: 6,
      overflowX: 'auto',
      th: {
        background: 'gray.200',
        padding: '2px 8px'
      },
      td: {
        borderTop: '1px solid',
        borderColor: 'gray.200',
        padding: 2
      }
    },
    hr: {
      margin: '25px 0'
    }
  }
};
