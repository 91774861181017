import React from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Box,
  Image,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useMediaQuery,
  useDisclosure
} from '@chakra-ui/react';
import { CustomerAccountSidebar, CustomerProfile } from "components";
import { FaBars } from 'react-icons/fa';
import logo from '../../assets/images/LondonLink_Logo.svg';

export const LoggedInHeader = () => {
  const [isLargerThan767] = useMediaQuery('(min-width: 768px)');
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeMobileDrawer = () => onClose();

  return (
    <Box
      as="header"
      padding="20px 0"
      boxShadow="0 2px 5px rgba(0,0,0, 0.4)">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between">
          {!isLargerThan767 && (
            <IconButton
              bg="white"
              onClick={onOpen}
              aria-label="Toggle menu"
              icon={<FaBars color="#222155" size={28} />}
            />
          )}

          <Link to="/customer">
            <Image
              src={logo}
              alt="LondonLink Logo"
              width={{ base: 150, md: 200 }}
            />
          </Link>

          <CustomerProfile />
        </Box>

        <Drawer
          size="xs"
          isOpen={isOpen}
          placement="left"
          onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />

            <DrawerBody paddingTop={5}>
              <CustomerAccountSidebar closeMobileDrawer={closeMobileDrawer} />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      </Container>
    </Box>
  );
};
