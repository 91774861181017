import React from "react";
import { Button, Container, Heading, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { PublicPageTemplate } from "../PublicPageTemplate/PublicPageTemplate";

export const NotFoundPage = () => {
  return (
    <PublicPageTemplate>
      <Container>
        <Helmet>
          <title>Page not found</title>
          <meta name="robots" content="noindex, follow" />
        </Helmet>

        <Heading mb={5}>404 - Page Not Found</Heading>
        <Text fontSize="lg" mb={5}>The page you were looking for could not be found.</Text>
        <Link to="/">
          <Button variant="outline" colorScheme="brand">Return home</Button>
        </Link>
      </Container>
    </PublicPageTemplate>
  );
};
