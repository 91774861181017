import React, { useEffect, useRef } from "react";
import { Alert, AlertIcon, Box, Button, Heading, HStack, Stack } from "@chakra-ui/react";
import { FastField, Form, Formik } from "formik";
import { useCustomerForms } from "hooks";
import { FormDisclaimer } from "components";
import * as yup from "yup";

import { FormFieldSimple } from "./FormFields/FormFieldSimple";
import { FormFieldStartUpCapital } from "./FormFields/FormFieldStartUpCapital";
import { FormFieldRadio } from "./FormFields/FormFieldRadio";
import { FormFieldDirectors } from "./FormFields/FormFieldDirectors";
import { FormFieldShareholders } from "./FormFields/FormFieldShareholders";
import { constants } from "../../../config/constants";
import { DataStatuses } from "../../../../../shared/src/types/common";

export interface NonIndividualFormValuesDirector {
  name: string;
  dob: string;
  nationality: string;
  residentialAddress: string;
}

export interface NonIndividualFormValuesShareholder {
  name: string;
  dob: string;
  nationality: string;
  residentialAddress: string;
  sharesPercentage: string;
}

export interface NonIndividualFormValues {
  entityType: string;
  legalEntityName: string;
  legalEntityNumber: string;
  tradingName: string;
  entityRegisteredAddress: string;
  regulatedOrNot: string;
  tradingAddresses: string;
  outlineOfActivities: string;
  turnover: string;
  profit: string;
  monthlyTransactionValueBuyingBTC: string;
  monthlyTransactionValueSellingBTC: string;
  monthlyTransactionsCountBuyingBTC: string;
  monthlyTransactionsCountSellingBTC: string;
  purposeForTrading: string;
  startUpCompanyCapital: {
    directorsLoan: string;
    companyLoan: string;
    equityRaise: string;
    other: string;
  };
  directorsAndOwners: NonIndividualFormValuesDirector[];
  shareholders: NonIndividualFormValuesShareholder[];
  antiMoneyLaunderingPolicy: boolean | null;
  antiBriberyAndCorruptionPolicy: boolean | null;
  countriesSupplyingServices: string;
  website: string;
  bankCountryAndName: string;
}

const validationSchema = yup.object().shape({
  entityType: yup.string().required('Required field'),
  legalEntityName: yup.string().required('Required field'),
  legalEntityNumber: yup.string().required('Required field'),
  tradingName: yup.string().required('Required field'),
  entityRegisteredAddress: yup.string().required('Required field'),
  regulatedOrNot: yup.string().required('Required field'),
  tradingAddresses: yup.string().required('Required field'),
  outlineOfActivities: yup.string().required('Required field'),
  turnover: yup.string().required('Required field'),
  profit: yup.string().required('Required field'),
  monthlyTransactionValueBuyingBTC: yup.string().required('Required field'),
  monthlyTransactionValueSellingBTC: yup.string().required('Required field'),
  monthlyTransactionsCountBuyingBTC: yup.string().required('Required field'),
  monthlyTransactionsCountSellingBTC: yup.string().required('Required field'),
  purposeForTrading: yup.string().required('Required field'),
  startUpCompanyCapital: yup.object().required('Required field').shape({
    directorsLoan: yup.string(),
    companyLoan: yup.string(),
    equityRaise: yup.string(),
    other: yup.string()
  }).test('at-least-one-property', "You must provide at least one", (value: any) => {
    return !!(value.companyLoan || value.directorsLoan || value.equityRaise || value.other);
  }),
  antiMoneyLaunderingPolicy: yup.boolean().required('Required field').nullable(),
  antiBriberyAndCorruptionPolicy: yup.boolean().required('Required field').nullable(),
  countriesSupplyingServices: yup.string().required('Required field'),
  website: yup.string().required('Required field'),
  bankCountryAndName: yup.string().required('Required field'),
});

const initialValues: NonIndividualFormValues = {
  entityType: "",
  legalEntityName: "",
  legalEntityNumber: "",
  tradingName: "",
  entityRegisteredAddress: "",
  regulatedOrNot: "",
  tradingAddresses: "",
  outlineOfActivities: "",
  turnover: "",
  profit: "",
  monthlyTransactionValueBuyingBTC: "",
  monthlyTransactionValueSellingBTC: "",
  monthlyTransactionsCountBuyingBTC: "",
  monthlyTransactionsCountSellingBTC: "",
  purposeForTrading: "",
  startUpCompanyCapital: {
    directorsLoan: "",
    companyLoan: "",
    equityRaise: "",
    other: ""
  },
  directorsAndOwners: [],
  shareholders: [],
  antiMoneyLaunderingPolicy: null,
  antiBriberyAndCorruptionPolicy: null,
  countriesSupplyingServices: "",
  website: "",
  bankCountryAndName: "",
};

export const NonIndividualForm = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { submitCorpoForm, formsState } = useCustomerForms();

  const handleSubmit = (values: NonIndividualFormValues) => {
    submitCorpoForm(values);
  };

  const isSuccess = formsState.corpoFormSubmitRequest.dataStatus === DataStatuses.Ok;
  const isLoading = formsState.corpoFormSubmitRequest.dataStatus === DataStatuses.Loading;
  const isError = formsState.corpoFormSubmitRequest.dataStatus === DataStatuses.Error;

  // Handle form success
  useEffect(() => {
    if (isSuccess && formRef) {
      formRef.current?.reset();
    }
  }, [isSuccess]);

  return (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: NonIndividualFormValues) => handleSubmit(values)}>
      {({ values, isValid }) => (
        <Form
          id="non-individual"
          style={{ marginTop: 25, maxWidth: 992 }}
          ref={formRef}>
          <Stack spacing={10}>
            <FastField
              name="entityType"
              label="Entity type (Corporate(Ltd), Sole trader, fund, trust, partnership etc)"
              isRequired
              component={FormFieldSimple}
            />

            <HStack spacing={4}>
              <FastField
                name="legalEntityName"
                label="Legal Entity Name"
                isRequired
                component={FormFieldSimple}
              />

              <FastField
                name="legalEntityNumber"
                label="Legal Entity Number"
                isRequired
                component={FormFieldSimple}
              />
            </HStack>

            <FastField
              name="tradingName"
              label="Trading name if different from the entity name) or N/A, if not applicable"
              component={FormFieldSimple}
            />

            <FastField
              name="entityRegisteredAddress"
              label="Entity Registered address (address listed on incorporation documents, a registered address could be where your customers, banks or suppliers send you correspondence)"
              type="textarea"
              isRequired
              component={FormFieldSimple}
            />

            <FastField
              name="regulatedOrNot"
              label="Are you regulated or not regulated? (If regulated then please provide name of regulator and unique identifying number)"
              isRequired
              component={FormFieldSimple}
            />

            <FastField
              name="tradingAddresses"
              label="Trading addresses if different from business/registered address (A trading address may be where you carry out business and can be different to the company's registered address, therefore it doesn't have to be public information.)"
              type="textarea"
              component={FormFieldSimple}
            />

            <FastField
              name="outlineOfActivities"
              label="Please provide an outline of your activities / Nature of business:"
              type="textarea"
              isRequired
              component={FormFieldSimple}
            />

            <Box as="section">
              <Heading mb={1} fontSize="14px" as="h2" fontWeight="normal">
                Business/Corporates Annual Turnover (GBP) and Contribution/profit (GBP)
              </Heading>
              <Stack direction={{ base: "column", lg: "row" }} spacing={4} style={{ marginTop: 10 }}>
                <FastField
                  name="turnover"
                  label="Turnover (yearly) GBP"
                  isRequired
                  component={FormFieldSimple}
                />

                <FastField
                  name="profit"
                  label="Profit (Annual) GBP"
                  isRequired
                  component={FormFieldSimple}
                />
              </Stack>
            </Box>

            <Box as="section">
              <Heading mb={1} fontSize="14px" as="h2" fontWeight="normal">
                Average expected transaction value (monthly) with LondonLink
              </Heading>
              <Stack direction={{ base: "column", lg: "row" }} spacing={4}>
                <FastField
                  name="monthlyTransactionValueBuyingBTC"
                  label="Buying BTC with GBP (monthly)"
                  isRequired
                  component={FormFieldSimple}
                />

                <FastField
                  name="monthlyTransactionValueSellingBTC"
                  label="Selling BTC (monthly)"
                  isRequired
                  component={FormFieldSimple}
                />
              </Stack>
            </Box>

            <Box as="section">
              <Heading mb={1} fontSize="14px" as="h2" fontWeight="normal">
                Estimated number of transactions per month
              </Heading>
              <Stack direction={{ base: "column", lg: "row" }} spacing={4}>
                <FastField
                  name="monthlyTransactionsCountBuyingBTC"
                  label="Buying BTC with GBP (monthly)"
                  isRequired
                  component={FormFieldSimple}
                />

                <FastField
                  name="monthlyTransactionsCountSellingBTC"
                  label="Selling BTC (monthly)"
                  isRequired
                  component={FormFieldSimple}
                />
              </Stack>
            </Box>

            <FastField
              name="purposeForTrading"
              label="Describe the purpose of transaction/trading with LondonLink"
              type="textarea"
              isRequired
              component={FormFieldSimple}
            />

            <FastField
              name="startUpCompanyCapital"
              label="Where did the company's original startup capital come from?, generally (Inheritance/Property Sold etc in fiat i.e. GBP, earnings in cryptoassets, brokering/re-selling, etc"
              isRequired
              component={FormFieldStartUpCapital}
            />

            <Box as="section">
              <FastField
                name="directorsAndOwners"
                label="Directors"
                helpText="Full Legal Names as they appear in Government Documents (including any other previous known names)"
                isRequired
                component={FormFieldDirectors}
              />
            </Box>

            <Box as="section">
              <FastField
                name="shareholders"
                label="Beneficial Owners"
                helpText="Name, nationality, % shares, country of residence and date of birth of directors and beneficial owners with more than 25% of shares (if an incorporated company), as the case may be"
                isRequired
                component={FormFieldShareholders}
              />
            </Box>

            <FastField
              name="antiMoneyLaunderingPolicy"
              label="If not regulated do you have an Anti-money laundering policy?"
              type="radio"
              isRequired
              options={[{ label: "Yes", value: "true" }, { label: "No", value: "false" }]}
              component={FormFieldRadio}
            />

            <FastField
              name="antiBriberyAndCorruptionPolicy"
              label="If not regulated do you have an Anti bribery and corruption policy?"
              type="radio"
              isRequired
              options={[{ label: "Yes", value: "true" }, { label: "No", value: "false" }]}
              component={FormFieldRadio}
            />

            <FastField
              name="countriesSupplyingServices"
              label="What Countries are you supplying your services to (e.g. what country/countries are you selling BTC to if its buying from LondonLink for re-sale?"
              type="textarea"
              isRequired
              component={FormFieldSimple}
            />

            <FastField
              name="website"
              label="Web address"
              type="text"
              isRequired
              component={FormFieldSimple}
            />

            <FastField
              name="bankCountryAndName"
              label="What country do you bank in and what is the name of your bank?"
              type="textarea"
              isRequired
              component={FormFieldSimple}
            />
          </Stack>

          {/* Handle Feedback */}
          <Box mt={5}>
            {/* Success */}
            {isSuccess && (
              <Alert status="success" mt={5}>
                <AlertIcon />
                Thank you. We have received your information. You may now close this page.
              </Alert>
            )}

            {/* Error */}
            {isError && (
              <Alert status="error" mt={5}>
                <AlertIcon />
                There was an unexpected error.
                Please try again, if the issue persists contact us at verify@{constants.domain()}
              </Alert>
            )}

            {/* Error */}
            {!isValid && (
              <Alert status="error" mt={5}>
                <AlertIcon />
                There are incomplete fields above, please check and try again.
              </Alert>
            )}
          </Box>

          <Stack spacing={2} mt={5}>
            <FormDisclaimer name="Non-individual" />
            <Button
              type="submit"
              colorScheme="brand"
              isLoading={isLoading}
              loadingText="Submitting"
              isDisabled={isLoading}>
              Submit
            </Button>
          </Stack>

          {/* Debug */}
          {/*<Box mt={6}>*/}
          {/*  <pre>*/}
          {/*    {JSON.stringify(values, null, 2)}*/}
          {/*  </pre>*/}
          {/*</Box>*/}
        </Form>
      )}
    </Formik>
  );
};
