import React, { Fragment, ReactNode } from "react";
import { Header, Footer, MainBodyContent } from "components";

interface Props {
  children: ReactNode;
}

export const LegalPageTemplate = ({ children }: Props) => {
  return (
    <Fragment>
      <Header />

      <MainBodyContent className="legal-page">
        {children}
      </MainBodyContent>

      <Footer />
    </Fragment>
  );
};
