import { AbstractActionModel } from "../abstract/AbstractActionModel";
import { DataStatuses, ErrorResponse } from "../../types/common";
import { customerApiClient } from "../../../../fe-main/src/libs/CustomerLondonLinkApiClient";
import { Customer } from "../../types/customer";

export interface CustomerProfileModelState {
  compliance: Customer['compliance'] | null;
  contactDetails: Customer['contactDetails'] | null;
  agreements: {
    marketingMessagesAgreementGIB?: boolean;
  };
  dataStatus: DataStatuses;
  dataError: ErrorResponse | null;
}

const defaultState: CustomerProfileModelState = {
  compliance: null,
  contactDetails: null,
  agreements: {},
  dataStatus: DataStatuses.Uninitialized,
  dataError: null
}

const xhrClient = customerApiClient.getXhrClient();

export class CustomerProfileModel extends AbstractActionModel {
  private state = defaultState;

  private updateState(newState: Partial<CustomerProfileModelState>) {
    this.state = { ...this.state, ...newState };
    this.notify();
  }

  /*
    Public methods
  */
  public async fetchProfile() {
    this.updateState({ dataError: null }); // Reset error on new fetch

    const genericErrorMessage = "There was an unexpected error fetching your profile";

    if (this.state.dataStatus !== DataStatuses.Loading) {
      try {
        this.updateState({ dataStatus: DataStatuses.Loading });
        const res = await xhrClient.get("/customer/getProfile");

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({
            compliance: res.data.profile.compliance,
            contactDetails: res.data.profile.contactDetails
          });

          this.updateState({ dataStatus: DataStatuses.Ok });
        } else {
          this.updateState({ dataError: { message: genericErrorMessage } });
          this.updateState({ dataStatus: DataStatuses.Error });
        }
      } catch (error) {
        this.updateState({ dataError: { stack: error, message: genericErrorMessage } });
        this.updateState({ dataStatus: DataStatuses.Error });
      }
    }
  }

  public getProfileState() {
    return this.state;
  }

  public resetProfileState() {
    this.updateState(defaultState);
  }
}
