import React, { ErrorInfo, ReactNode, Component } from "react";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { VscDebug } from "react-icons/all";

interface Props {
  children: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // TODO: Log errors here to service
  }

  render() {
    if (this.state.hasError) {
      return (
        <Box padding={5}>
          <VscDebug size={50} color="orange" />
          <Heading as="h1" fontWeight={500}>
            Oops!<br />
            <span style={{ fontWeight: "bold" }}>We encountered an error.</span>
          </Heading>
          <Text>This error has been automatically reported to us.</Text>
          <Button mt={5} size="md" variant="outline" onClick={() => window.location.reload()}>
            Reload this page and try again
          </Button>
        </Box>
      );
    }

    return this.props.children;
  }
}
