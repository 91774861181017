import React from "react";
import { LegalPageTemplate, LegalDisclaimer, NotFoundPage } from "components";
import { Container } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { constants } from "../../../config/constants";

export const TradingTermsPage = () => {
  /**
   * GIB version
   */
  if (constants.businessEntity === "gib") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Trading Terms and Conditions</title>
        </Helmet>

        <Container>
          <article>
            <h1>Trading Terms and Conditions</h1>
            <p><strong>*PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE TRADING. BY TRADING OR CONTINUING TO TRADE YOU ARE AGREEING TO BE BOUND BY THE TERMS AND CONDITIONS HEREIN*</strong></p>

            <section>
              <h2>1. LONDONLINK (GI) TRADING TERMS AND CONDITIONS</h2>

              <h3>1.1 WHAT&apos;S IN THESE TERMS?</h3>
              <p>
              LondonLink (GI) Limited (<strong>hereinafter “LondonLink”, &quot;We,&quot; &quot;we&quot;, &quot;Us&quot;, &quot;us&quot;, &quot;Our&quot; or &quot;our&quot;</strong>) are the provider of Cryptoasset brokerage services (execution only) allowing you to buy and sell Cryptoassets (<strong>hereinafter &quot;Services&quot;</strong>).
              </p>
              <p>
                Cryptoassets (or <strong>&quot;Cryptocurrency&quot;</strong> or <strong>&quot;Virtual Assets&quot;</strong> as they are also referred), in relation to the provision of Our Services means any cryptographically secured digital representations of value or contractual rights that can be transferred, stored or traded electronically.
              </p>
              <p>
                LondonLink offers Services in relation to Bitcoin (<strong>hereinafter “BTC”</strong>), which is officially an exchange token (as opposed to a security, or other type of token or asset) and other Cryptoassets, including Ethereum and certain stablecoins.
              </p>
              <p>
                This document and the terms and conditions herein are our Trading Terms and Conditions (<strong>hereinafter “Trading Terms”</strong>).
              </p>
              <p>
                A reference to <strong>“You”, “you”, “your” or “yours”</strong> in the Trading Terms means, as appropriate, you as an individual customer or you as or on behalf of a Corporate customer as the situation may be.
              </p>
              <p>
                A reference to a <strong>“Corporate”</strong> in the Trading Terms means any other entity/person or entities/persons including but not limited to a corporate or non-corporate entity, a trust, an SPV, a group, a consortium or other vehicle or entity.
              </p>

              <h3>1.2 ACCEPTANCE OF THESE TERMS</h3>
              <p>These Trading Terms contain the terms and conditions of the provision of our Services to you. When you are using our Services you agree that these Trading Terms will be binding on you (and will continue to be binding on you until such time as it is terminated in accordance with clause 19 below) when you are provided with a copy of these Trading Terms and then do any one of the following, or any combination of, the following:</p>
              <ul>
                <li>create an account on our site (<a href="https://londonlink.io">www.londonlink.io</a>) or have an account created on our site by any other method; or</li>
                <li>ask to, signify or otherwise express or imply your desire to trade with us through our site; or</li>
                <li>ask to, signify or otherwise express or imply your desire to trade with us using any other method including (but not limited to) third party websites such as Paxful.com, LocalBitcoins.com, instant messaging apps or any other means of verbal or written communication (<strong>hereinafter “Third Party Platforms”</strong>).</li>
              </ul>
              <p>Failing to adhere to and comply with these Trading Terms (and where applicable any supplementary terms or policies mentioned within) will result in you being in breach of contract, for which we could bring legal claims against you.</p>
              <p>If you do not agree to these Trading Terms, you must not use our Services.</p>
              <p>Before you use our Services you also must meet our verification requirements which are explained in clause 2 below.</p>
              <p>We recommend that you print a copy of these Trading Terms for future reference.</p>

              <h3>*RISK DISCLAIMER*</h3>
              <p>Cryptoassets are not backed or guaranteed by any government or financial institution.</p>
              <p>When purchasing Cryptoassets from LondonLink, you must satisfy yourself that you understand all the associated investment risks in light of the above, and generally. Before you decide to invest, do not invest in anything you cannot afford to lose.</p>
              <p>You must acknowledge when buying and/or selling Cryptoassets from LondonLink that 100% of your capital invested is at risk. We cannot, and do not make any guarantee about the future value of your Cryptoassets and we will not be held liable for any losses you incur. Equally, we will not request a benefit from any gains you might make.</p>
              <p>Furthermore, we are not qualified or authorised to give any advice. We very strongly suggest you obtain financial advice from your professional advisor on any investments you make in Cryptoassets.</p>
              <p>For the avoidance of doubt, LondonLink are a brokerage offering an ‘execution only’ service at this time. It is solely your responsibility to store any Cryptoassets received in a safe manner. This includes but is not limited to providing LondonLink with the correct and appropriate wallet address to receive Cryptoassets purchased through LondonLink.</p>
              <p>All Cryptoasset transactions are immutable. This means that they cannot be reversed should you lose access to your Cryptoasset wallet. If you decide to store or invest your Cryptoassets with a third-party, you should do your own due-diligence on that third party before sending your Cryptoassets to them. LondonLink will not be held responsible for losses incurred as a result of the acts or omissions of third parties.</p>
              <p>You should be particularly cautious with any third parties that promise guaranteed or unrealistic returns.</p>

              <h3>1.3 WHO WE ARE AND HOW TO CONTACT US</h3>
              <p>We are a limited company registered in Gibraltar under company number 121194 and have our registered office at Madison Building, Midtown, Queensway, Gibraltar, GX11 1AA.</p>
              <p>To contact us, please email <a href="mailto:hello@londonlink.io">hello@londonlink.io</a>.</p>
              <p>Our website, amongst other things, is a resource of information about us for your benefit. Our website is available to you at https://www.londonlink.io (<strong>hereinafter “our site”</strong>).</p>

              <h3>1.4 THE FOLLOWING TERMS AND POLICIES ALSO APPLY TO YOU IF YOU USE OUR SERVICES</h3>
              <p>The terms and policies below also apply (in addition to these Trading Terms) when using our Services:</p>
              <ul>
                <li>Our <Link to="/privacy-policy">Privacy Policy</Link></li>
                <li>Our <Link to="/acceptable-use-policy">Acceptable Use Policy</Link></li>
                <li>Our <Link to="/cookie-policy">Cookie Policy</Link></li>
                <li>Our <Link to="/general-terms-and-conditions">General Terms and Conditions</Link></li>
              </ul>
              <p>(<strong>together the “Other Policies”</strong>)</p>

              <h3>1.5 WE MAY MAKE CHANGES TO THESE TERMS</h3>
              <p>We may amend our Trading Terms or Other Policies from time to time. You will be provided with any new versions, if required. Otherwise, they will be made permanently available on our site from the date they apply.</p>
              <p>By continuing to trade with us, positively affirming your agreement in any other way, or by us placing relevant notices on our site, you are agreeing to be bound by any new versions of our Trading Terms and Other Policies as the case may be.</p>
            </section>

            <section>
              <h2>2. HOW TO USE OUR SERVICES</h2>

              <p>Our Trading Terms apply at all times when we provide Services to you. </p>
              <p>Before you can use our Services you will be asked to, and you must, complete our verification requirements. We will then use the information that you provide (<strong>hereinafter “Verification Information”</strong>) to conduct credit checks, sanction list searches, PEP register searches, blockchain analysis and other related due diligence checks in compliance with our anti-money laundering (<strong>hereinafter “AML”</strong>) obligations.</p>
              <p>Any personal or other information received is held strictly in accordance with our <Link to="/privacy-policy">Privacy Policy</Link>. By using our Services you are giving us permission, in the relevant circumstances, to disclose your personal information to third parties to discharge our legal, regulatory, operational and/or contractual responsibilities.</p>

              <h3>2.1 ONGOING USE OF OUR SERVICES</h3>
              <p>For the ongoing use of our Services after providing the Verification Information we may, before and/or during trading, perform some initial or further due diligence on you and/or on any one or more entities you represent (<strong>hereinafter “Ongoing Due Diligence”</strong>).</p>
              <p>It is a condition of these Trading Terms, and you agree, that you will provide the required information as part of the Ongoing Due Diligence that we may request from you.</p>
              <p>This should be provided in a timely manner and in any event you agree to do so before we are able to provide ongoing use of our Services to you. If you fail to provide this, it is likely that we will stop providing our Services to you with immediate effect. </p>
              <p>If, following the collection of Verification Information and/or after completing Ongoing Due Diligence, the information does not comply with our legal or regulatory obligations and/or with our internal policies, you agree that we can no longer provide our Services to you. Therefore, we reserve the right to cease offering our Services to you with immediate effect.</p>
              <p>If we are satisfied that the Verification Information and the Ongoing Due Diligence are complete and accurately provided, you will be verified (<strong>hereinafter “Verified”</strong>) and we will be in a position to offer our Services to you , unless and up to the point in which we communicate to you the need to do any Ongoing Due Diligence at a later time.</p>
            </section>

            <section>
              <h2>3. OUR TRADING PROCESS</h2>
              <p>Our trading process for each trade with you involves the following three stages:</p>
              <p>(1) requesting and receiving a quote;</p>
              <p>(2) locking the quoted price; and</p>
              <p>(3) settling the trade.</p>
              <p>The corresponding contractual relationship between us and you is unique to the individual trade.</p>

              <h3>3.1 Stage 1 - Requesting and receiving a quote</h3>
              <p>To receive a quote you will need to contact us through your account on our site or via any one of the Third Party Platforms to request a quote (<strong>hereinafter “an RFQ”</strong>). The trading process through your account on our site may not take the form outlined below if a Third Party Platform is used. However, even if the process may be slightly different than what is outlined below, it is still governed by the same terms and conditions.</p>
              <p>All quotes in response to an RFQ will be provided in Great British Pounds (GBP) unless otherwise agreed by you and us when requesting a quote.</p>
              <p>Please note that we reserve the right to pass on any bank (or other) charges or costs when a fiat transfer is not in GBP, EUR or other fiat currencies in which we offer Services.</p>
              <p>Additionally, if you use a payment method which causes us to incur an additional cost to receive your funds, such as CHAPS, BACS or SWIFT, we reserve the right to pass on the cost to you.</p>
              <p>If you are <strong>buying</strong> a Cryptoasset you must request an RFQ in a clear and unambiguous way similar to the example phrases below:</p>
              <ul>
                <li>“I want to buy 1 BTC. Please can you give me a quote?”; or</li>
                <li>“Please quote me to buy 1.2536 BTC”; or</li>
                <li>“I want to buy £1000 worth of BTC. Please can I have a quote?”.</li>
              </ul>
              <p>If you are <strong>selling</strong> a Cryptoasset you must request an RFQ in a clear and unambiguous way similar to the example phrases below:</p>
              <ul>
                <li>“I want to sell 1 BTC. Please can you give me a quote?”; or</li>
                <li>“Please quote me to sell 1.2536 BTC”; or</li>
                <li>“I want to receive £1000 worth of BTC in GBP. Please can I have a quote?”.</li>
              </ul>
              <p>Please note that in our examples above, BTC refers specifically to Bitcoin. In relation to any other Cryptoassets, you must use the full name (in place of BTC in the examples above) to clearly identify the Cryptoasset for which you are asking the RFQ.</p>
              <p>We will then respond to your RFQ in an unambiguous way, informing you of the best priced quote available at the time. Our quote will look similar to the contents of the table below unless we have, by your and our established previous conduct, and formal written agreement, other methods of quoting in response to your RFQ:</p>

              <div className="table-container">
                <table cellSpacing="0">
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Price per BTC</th>
                    <th>Amount of BTC</th>
                    <th>GBP amount for trade</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>16/01/19</td>
                    <td>John Smith</td>
                    <td>£3,333.33</td>
                    <td>3.0</td>
                    <td>£10,000.00</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <p>The second column is your name or the name of the entity you represent as the case may be.</p>
              <p>The third column is the quoted price (in fiat currency, denominated in GBP/£ in this example) per Cryptoasset (e.g. the price per BTC), sometimes referred to as the “rate”.</p>
              <p>The fourth column is the quoted number of Cryptoassets required to be sent or received if the quote is locked.</p>
              <p>The fifth column is the quoted amount of fiat currency (denominated in GBP/£ in this example) required to be sent or received if the quote is accepted.</p>

              <h3>3.2 Stage 2 - Locking a trade at the quoted price</h3>
              <p>When you receive a quote from us in response to an RFQ you have two choices:</p>
              <ol>
                <li><strong>Decline</strong> the quoted rate by a non-response or any negative affirmation, for example by writing or saying “no”, “don’t lock” or similar;<br />or<br /></li>
                <li><strong>Accept</strong> the quoted rate by any positive affirmation, for example by writing or saying “lock”, “yes”, “take”, “fine”, “ok”, “done” or similar.
                <br />
                <br />
                  <strong>Please note: Accepting the quote means you are committing to complete the trade with us at the agreed price without regard to any other variable factors including (but not limited to) the changing market (or ‘spot’) price of the Cryptoasset or fiat currency and/or banking limitations such as daily transfer limits. Our completing a trade at a quoted rate is subject to clause 3.5.</strong>
                  <br />
                  <strong>You agree that if you accept a quoted rate you will send, pay or transfer the agreed amount of Cryptoasset or fiat in the denomination you had agreed when asking for a RFQ and receiving our quote within 10 minutes of acceptance.</strong>
                </li>
              </ol>

              <p>In order for us to keep our Services competitively priced and manage our operational risks, if you do not respond to a quote from us within 60 seconds, we are not obliged to honour that quote. We may, however, decide to honour the quote in our absolute discretion. We may also retract a quote if you have not already accepted it within the aforementioned 60-second window. If the quote is retracted but you would like an updated quote then we will happily provide this.
              </p>

              <p className="important">*For the avoidance of doubt, our quotes are available for a maximum of 60 seconds but may be retracted if we communicate this to you (for example by saying “gone”, “retracted”, “expired” or “no”) before you positively affirm your acceptance. If no response is received within 60 seconds, the quote is deemed retracted in any event.*
              </p>

              <h3>3.3 Stage 3 - Settlement of the trade</h3>
              <p>Trade settlement is the process of transferring Cryptoassets into the wallet of the buyer and fiat into the bank account of the seller, following acceptance of an RFQ.</p>
              <p>Once the quote has been provided and you have accepted the quoted price of the trade, we will settle the trade as soon as possible, subject to any issues that affect the completion of a trade, which will be dealt with in accordance with clause 3.5 below.</p>

              <h3>3.3.1 If you are <span className="underline">buying</span> Cryptoassets <span className="underline">from</span> LondonLink:</h3>
              <p><strong>(if you are selling Cryptoassets to us please skip to 3.3.2)</strong></p>
              <p><strong>Individuals:</strong> If you are a Verified individual:</p>
              <p>You must send fiat from a personal account in your name. We will return any monies in accordance with clause 3.5, if, following our checks, we have reason to believe that the fiat has not come from an account in your name.</p>

              <p><strong>Not an individual:</strong> If you are a Verified Corporate:</p>
              <p>You must send fiat from a business account in your Corporate’s name for which either: (1) you are a Director or (2) you have documentary evidence (to our satisfaction) to prove a Director (or a Trustee) has given you specific authority to make <span className="underline">this</span> payment, <span className="underline">before you make the payment or agree a rate.</span></p>
              <p>There may be delays to the clearing of the fiat into our account that are outside of our control. You accept that such delays may arise and agree not to blame or hold us liable for any losses you suffer that arise from those delays. Where there are delays, whether in or outside of our control, we aim to keep you updated as and when appropriate.</p>
              <p>Once the fiat has cleared into our account, we will ask you to provide your wallet address for us to send the Cryptoassets. We will only be obliged to send you Cryptoassets in the event there is not an issue in the trading process in accordance with clause 3.5 or otherwise. If there is an issue in the trading process, return of fiat and/or Cryptoassets will be dealt with in accordance with clause 3.5.</p>


              <p><strong>If you are a verified individual or a Verified Corporate</strong></p>

              <p>You agree that it is your sole responsibility to ensure the wallet address provided to us for receiving any Cryptoassets from us is accurate, up-to-date and not missing any digits. You will also ensure that the wallet address is capable of receiving the type of Cryptoasset to be sent to you. We are happy to assist you with trying to identify this by providing some <span className="underline"><strong>general information</strong></span> but we accept no liability for any losses you suffer as a result of providing us with an incorrect wallet address or a wallet address that is not capable of receiving the Cryptoasset.  You acknowledge that providing the correct wallet address to us and ensuring that the wallet address can receive the Cryptoasset to be sent to you is your sole responsibility.</p>

              <h3>3.3.2 If you are <span className="underline">selling</span> Cryptoassets <span className="underline">to</span> us:</h3>
              <p>We will provide you with a wallet address for the type of Cryptoasset you have identified when a rate is locked following your RFQ. You must not send Cryptoassets to any wallet address other than the one we provide you, for the specific trade in question.</p>
              <p>If you have incorrectly identified the Cryptoasset when you ask for an RFQ, we will not be liable for any losses caused by you in error, including but not limited to the Cryptoassets themselves or their value depreciation incurred by your incorrect identification. You agree to be contractually obliged to complete the original trade with us regardless of the aforementioned errors and/or losses. </p>
              <p>You, the individual or the Corporate sending the Cryptoassets must, at all times, be Verified. If, part way through a trade, we discover that you are not Verified (including if you have not provided any Ongoing Due Diligence information whether previously requested or not), you agree to provide the requested verification information before we settle the trade.</p>
              <p>Once we are satisfied that we have received or will imminently receive the Cryptoassets, we will set up a transfer of fiat in the pre-agreed fiat currency denomination to the bank details you provide so long as we are satisfied you are Verified and the bank owner information matches the Verified account.</p>
              <p>To be clear, we are only obliged to set up the agreed fiat transfer to you if the bank details you provide us are evidently (to our satisfaction) yours and the Cryptoassets have been received safely (or are going to be imminently received) by us. If we cannot set up the agreed fiat transfer, the trade may be dealt with using one of the mechanisms in clause 3.5.</p>
              <p>For the avoidance of doubt, we acquire ownership of the Cryptoassets as soon as they are received into our wallet. At no point during the trading process do we hold Cryptoassets belonging to you on your behalf.</p>

              <h3>3.4 Receipts</h3>
              <p>For our accountancy and other purposes, we will produce a receipt of each and every trade we conduct with you. At your request, we can provide you with a copy.</p>
              <p>If for some reason it is appropriate to issue you with an invoice for any unsettled trade(s) and/or debts owed by you, we will do so. Invoices are immediately payable unless otherwise stated on the invoice itself.</p>

              <h3>3.5 Issues during the trading process</h3>
              <h3>3.5.1 If you send fiat from a bank account not in your Verified name</h3>
              <p>If possible, we will always verify whether a fiat transfer came from you by checking the sender’s name on our bank statement against the previously collected Verification Information. </p>
              <p>If necessary, we may also ask you to provide us with evidence that the fiat came from your account. For example, we may ask you for a copy of a bank issued document and/or card showing the name and full account details of the sender, which we will then check against our bank feed and/or other information available to us.</p>
              <p>If we do not believe that the fiat transfer came from you, or if you or the third party are not Verified, we reserve the right to return the funds to the same account from which the fiat came. We shall not, and you agree that you will not ask us to, send the fiat to any other account. </p>

              <h3>3.5.2 Overpayment or underpayment of fiat or Cryptoassets</h3>
              <p>If Verified, and you send us an overpayment or underpayment of fiat or Cryptoassets relating to a locked quote, we may, at our sole discretion return the full amount of the fiat or Cryptoassets to you and cancel the trade. Alternatively, we may either:</p>
              <ol>
                <li>apply the pre-agreed rate to the full amount of fiat of Cryptoassets received by us; or</li>
                <li>return the difference in fiat or Cryptoassets received by us to the wallet address or bank account from which they were sent to us and then apply the agreed rate to the balance (i.e. the agreed amount); or</li>
                <li>in the case of being unable to return Cryptoassets to the same address as they were sent from (for example in the case of this sending address being an exchange hot-wallet) we may, in our sole discretion verify your ownership of another Cryptoasset address and return the difference to that verified address.</li>
              </ol>

              <p>We reserve the right to pass on any charges to you that we may incur as a direct result of your overpayment or underpayment of fiat or Cryptoassets, in accordance with this clause.</p>
              <p>For the avoidance of doubt, any overpayment of fiat or Cryptoassets will be owned by LondonLink until such time as it is returned to you. At no point do we hold assets on behalf of customers.</p>

              <h3>3.5.3 Input errors by us</h3>
              <p>If a quote in response to an RFQ has been given in error by us, you acknowledge that we are not obliged to trade at that rate.</p>
              <p>For example, we might miss or add a decimal number by mistake in our quote as follows “15 BTC” as opposed to the intended “1.5 BTC”.</p>
              <p>If we make any other errors when quoting, sending fiat, Cryptoassets or otherwise, you agree to assist us expeditiously in correcting this error. This may include you sending Cryptoassets or fiat back to us. If you do not assist us expeditiously in correcting this error we may take whatever lawful action necessary, including but not limited to, holding you in breach of contract and pursuing legal action against you, contacting relevant authorities including the police to investigate criminal liability, and immediately terminating the provision of our Services to you and to any of your associates or referrals.</p>

              <h3>3.5.4 If fiat or Cryptoassets are sent to the incorrect account or address</h3>
              <p><strong>If we are receiving Cryptoassets</strong> in relation to a trade, we are not liable to you or anyone else for any losses caused by you sending Cryptoassets to an invalid address or any address other than the one provided to you by us at the time of the specific trade.</p>
              <p><strong>If we are receiving fiat</strong> in relation to a trade, we are not liable to you or anyone else for any losses caused by sending fiat to any account other than the one with the account details provided to you by us at the time of the specific trade.</p>
              <p><strong>If we are sending Cryptoassets or fiat</strong> in relation to a trade, we are not liable to you or anyone else for any losses caused by sending those Cryptoassets or fiat to the wallet address or bank account provided to us by you at the time of the specific trade.</p>

              <h3>3.5.5 Return of fiat or Cryptoassets for other reasons</h3>
              <p>If you send us fiat and (1) you are not Verified at that time or (2) we reasonably decide, having conducted blockchain analysis and/or any other related due diligence, not to complete the trade with you, the trade will not be executed and we shall return the fiat to the bank account from which it was sent to us.</p>
              <p>If you send us Cryptoassets and (1) you are not Verified at that time or (2) we reasonably decide, having conducted blockchain analysis and/or any other related due diligence, not to complete the trade, the trade will not be executed and we reserve the right, for compliance or other reasons, to either (1) return the Cryptoassets to the wallet address from which they were sent to us, having first deducted any mining fees or other fees or costs we incur from receiving, dealing with and returning those Cryptoassets, or (2) return fiat of equivalent value to that amount of Cryptoassets (applying a reasonable rate) to your bank account. Again, before we do so, you or the owner will be required to provide us evidence of the ownership of the bank account before any fiat is sent.</p>

              <h3>3.5.6 Maximum limits when sending fiat</h3>
              <p>Sometimes we or you may reach a daily limit (or other limit) when setting up a fiat transfer. It is your responsibility to make sure you know the limit applied by your bank and you agree not to ask for an RFQ or lock a trade if it will mean you cannot send the fiat instantly, or at worst, within one hour. You will be in breach of this contract if you do not complete a trade once you lock in a price and we reserve the right to hold you to account if it is not resolved expeditiously. <strong>If you reach a transfer limit with your bank</strong>, you agree to provide evidence of this to us, to our satisfaction. You agree that in our absolute discretion we may decide to act as follows: (1) adjust the quote down to the maximum amount you can send at the same rate; (2) require you to send the fiat over multiple transactions from your Verified account and across multiple days as the case may be; or (3) cancel the trade and return any fiat to your Verified account (if some fiat was sent) in relation to this trade (subject to the restrictions in clause 3.5.1 relating to Verified accounts in your name).</p>
              <p><strong>If we reach a transfer limit with our bank</strong>, we agree that we will complete the trade at the agreed rate as soon as it is possible for us to do so which will, in usual circumstances, be the following working day.</p>

              <h3>THE TERMS IN THIS BOX APPLY IN ADDITION IF YOU CREATE AN ACCOUNT ON OUR SITE</h3>
              <div id="accounts" className="box">
                <h3 className="title">THE TERMS IN THIS BOX APPLY IN ADDITION IF YOU CREATE AN ACCOUNT ON OUR SITE</h3>
                <h4>CREATING AND CLOSING A WEBSITE ACCOUNT</h4>
                <p>In order for us to provide you with Services, we may need you to create an account or otherwise interact with our site.
                </p>
                <p>When you create an account on our site you will become a website customer (<strong>hereinafter
                  “Website Customer”</strong>). Once you become a Website Customer you will have, as a result, entered
                  into the Trading Terms contract (and any Other Policies you agreed to at the time) with Us.
                </p>
                <p>You may cease to be a Website Customer at any time by clearly notifying Us via <a
                  href="mailto:support@londonlink.io">support@londonlink.io</a>. Notifying us of your wish to cease to be a Website Customer will be effective when you receive email confirmation in reply that we have actioned your request. Please note that you will cease to be a Website Customer but not cease to be bound by the Trading Terms or Other Policies which remain in full force and effect.
                </p>
                <p>You may also cease to be a Website Customer by instructing us to ‘close your account’ via a button or icon on the website itself or via any other self-service method we may implement in future that explicitly indicates you wish to close your account or cease to be a Website Customer. </p>

                <h4>YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</h4>
                <p>If you choose, or you are provided with, a user identification ID (or email address or similar), password or any other piece of information as part of our account sign up or security procedures, you must treat such information as confidential. You must not disclose it to any third party.
                </p>
                <p>We may require the use of SMS/MFA/TOTP codes (2 Factor Authentication) in the future and reserve the right to require you to use this to log in and for other important high security functions once logged in.
                </p>
                <p>We have the right to disable any user identification ID (or email address or similar) or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with the Trading Terms or any of the Other Policies.
                </p>
                <p>If you know or suspect that anyone other than you knows your user identification code or password, you must promptly notify us by email at <a
                    href="mailto:support@londonlink.io">support@londonlink.io</a>.
                </p>

                <h4>WE MAY SUSPEND OR WITHDRAW YOUR ACCOUNT</h4>
                <p>Your account on our site is made available to you free of charge.</p>
                <p>We do not guarantee that your account, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of your account for business, legal and/or operational reasons.
                </p>
                <p>You are also responsible for ensuring that no one other than you accesses your account. If in the event someone other than you accesses your account, or we reasonably believe someone other than you has access to your account, we may suspend or withdraw or restrict the availability of all or any part of your account without notice.
                </p>
                <p>We will not be held liable for any losses suffered by you if you provide a third-party access to your account either deliberately or by not adequately securing your account details.
                </p>
                <p>We are not liable for any losses suffered by you as a result of suspending or withdrawing your free account.</p>
              </div>
            </section>

            <section>
              <h2>4 REMEDIES</h2>
              <h3>4.1 Available remedies</h3>
              <p>Whether specifically reserved or referred to in a clause of these Trading Terms or not, we hereby reserve the right to hold you in breach of contract, in tort or otherwise if we believe you to be in breach of any legal, tortious or equitable obligations. We will communicate the failure of your legal obligation and the circumstances to you and if it is not satisfactorily resolved in a reasonable time taking into account the severity of the breach, we will stop making Services available to you, may issue legal proceedings against you and/or take any other action deemed necessary.</p>

              <h3>4.2 Reversed payments</h3>
              <p>If you believe you have made a payment to us in error, you agree to inform us immediately.</p>
              <p>We will then investigate the situation as soon as possible.</p>
              <p>You agree that you will not reverse any fiat payments sent to us <span className="underline">in any circumstances</span>. If a payment sent to us is reversed we will hold you in breach of the Trading Terms and you will be liable to us for direct and consequential losses arising from that breach suffered by us including but not limited to:</p>
              <ul>
                <li>if appropriate, the fiat value of the payment concerned; and/or</li>
                <li>the loss of banking services (regardless of the duration for which banking services are lost); and/or</li>
                <li>the loss of profit; and/or</li>
                <li>any other liabilities or losses arising as a result of reversed payments, including management or consultant time explaining the situation to banking providers and subsequent resolution of the same.</li>
              </ul>
            </section>

            <section>
              <h2>5 OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</h2>
              <h3>5.1 Our liability</h3>
              <p>Our liability to you under these Trading Terms or otherwise is limited to your direct losses as a result of our clear breach of the Trading Terms. For the avoidance of doubt, we will not be in breach of the Trading Terms if we cannot complete a trade because there is an issue in the trading process in accordance with clause 3.5 or another issue which is not as a direct result of our contractual or tortious actions or omissions as the case may be.</p>

              <h3>5.1.1 Whether you are a consumer or a business user:</h3>
              <ul>
                <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors, or for fraud or fraudulent misrepresentation.</li>
              </ul>
              
              <h3>5.1.2 If you are a business user:</h3>
              <ul>
                <li>We exclude all implied conditions, warranties, representations or other terms that may apply to our site and any content on it, these Trading Terms and any Other Policies.</li>
                <li>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</li>
                <ul>
                  <li>your inability to use our site or Services; or</li>
                  <li>reliance on any content displayed on our site.</li>
                </ul>
                <br />
                In particular, we will not be liable for:
                <ul>
                  <li>loss of profits, sales, business, or revenue;</li>
                  <li>business interruption;</li>
                  <li>loss of anticipated savings;</li>
                  <li>loss of business opportunity, goodwill or reputation; or</li>
                  <li>any indirect or consequential loss or damage.</li>
                </ul>
              </ul>

              <h3>5.1.3 If you are a consumer user:</h3>
              <ul>
                <li>Please note that we only provide our site and/or Services to you for private use. You agree not to use our site or Services for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</li>
              </ul>

              <h3>5.2 Our limit on liability owed to you</h3>
              <p>In any event, any liability we owe to you is strictly limited to 100% of the fiat value of the disputed trade (or the fiat equivalent value of the trade disputed, at the rate that it was quoted, which is based upon the ‘spot’ price at the time). You agree to and will take reasonably appropriate steps to minimise and mitigate any losses you incur as a result of our act or omission relating to any purported liability of LondonLink or any of its representatives to you.</p>
            </section>

            <section>
              <h2>6 HOW WE MAY USE YOUR PERSONAL INFORMATION</h2>
              <p>We will only use your personal information as set out in our <a href="/privacy-policy">Privacy Policy</a>.</p>
            </section>

            <section>
              <h2>7 ANTI-MONEY LAUNDERING</h2>
              <p>It is absolutely prohibited for you to try to use our Services for the purpose of cleaning money, hiding money, in any way attempting to obscure the source of funds, or attempting to circumvent financial regulations or restrictions.</p>
              <p>If we suspect you have attempted to deceive in this way or to engage in money laundering activities, terrorist financing or any fraud, LondonLink will cease to make any Services available to you and to any associated individuals or entities we think may be involved or related. You and they will also be blacklisted from the use of LondonLink’s Services.</p>
              <p>In all circumstances, we will take a zero-tolerance approach to any illegal activity you are, or may become, involved in. We are fully committed to our responsibilities in this regard and reserve the right to report any activity we consider suspicious.</p>
            </section>

            <section>
              <h2>8 CASH AS A METHOD OF PAYMENT</h2>
              <p>Under no circumstances whatsoever will LondonLink provide Services or otherwise buy and/or sell any Cryptoassets from you using cash as a method of payment.</p>
            </section>

            <section>
              <h2>9 AVAILABILITY OF SERVICES TO YOU</h2>
              <p>We reserve the right to cease (either temporarily or permanently) making Services available to you if: (1) at any point in time you become not Verified (which can automatically occur in accordance with the Trading Terms, even if you were previously Verified); or (2) you owe us any fiat or Cryptoasset for any length of time; or (3) we have reason to believe that you are in breach of the Trading Terms or Other Policies.</p>
              <p>If we temporarily cease to make Services available to you, you are still contractually bound by the Trading Terms until such time as it those terms are is terminated in accordance with clause 19.</p>
            </section>
            
            <section>
              <h2>10 MERCHANTS</h2>
              <p>We cannot be held responsible, and accept no liability, for losses resulting from delayed payments or any other acts or omissions of third-party merchants and/or payment providers.</p>
              <p>In the case of delayed payments of fiat or Cryptoassets out of our control, if possible, we will contact you as soon as practicable if we are made aware of any delays. We will also take reasonable steps to minimise the effects of the delay to you. However, in usual circumstances we are not likely to be made aware of the reason for a delay.</p>
            </section>

            <section>
              <h2>11 REFUNDS</h2>
              <p>We cannot and will not under any circumstances refund any fiat or Cryptoassets to you once a trade has been completed. You understand refunds are not possible due to the 24/7 nature of the market conditions, mixing of fiat and Cryptoassets (and therefore the inability to identify specific transferred fiat funds or Cryptoassets) and potential volatility of swings which could lead to considerable, unrecoverable, losses for us.</p>
              <p>Please see our Risk Disclaimer at the top of this document about the risks associated with you purchasing Cryptoassets. The Disclaimer is repeated and made available in various other prominent locations to you.</p>
              <p>Any reference to returning or transferring fiat or Cryptoassets to/from us and/or to/from you in clause 3.5 shall not constitute a refund. Clause 3.5 refers to <strong className="underline">issues during a trade</strong> (i.e. before the trade is concluded) meaning that a trade cannot be completed. If a trade is not completed, it cannot be refunded or claimed by you that it has been refunded.</p>
            </section>

            <section>
              <h2>12 AFFILIATE COMMISSIONS</h2>
              <p>We may enter into a written agreement to pay you a commission for introducing clients to us at a rate to be agreed at the time of the introduced client’s gross trading value. We reserve the right to change this commission rate at a later date or cancel it entirely without notice to you.</p>
              <p>This written agreement could be on any written medium such as email, instant messenger or live chat.</p>
              <p>We may also seek your agreement via technical means such as a ‘tick box’ on our site or similar.</p>
              <p>Any commissions accrued will be considered a liability of LondonLink to you. These monies only become your property after they have been transferred to you and at no time is LondonLink holding value on your behalf.</p>
              <p>Commissions may accrue in GBP, EUR, or BTC. In some circumstances we may add further assets that we may allow at our sole discretion.</p>
              <p>You may not purport to be a LondonLink staff member or otherwise insinuate you are more than an affiliate of the company.</p>
              <p>You understand and agree that if you are introduced to us and become our client, we may pay affiliate commissions to the affiliate, if any,  who has introduced you to us, in accordance with the provisions of this clause.</p>
            </section>

            <section>
              <h2>13 TAX AND VAT</h2>
              <p>It is your sole responsibility to determine and report your tax obligations and information from your use of our Services to the appropriate tax authorities in a timely and full manner.</p>
            </section>

            <section>
              <h2>14 COMPLAINTS</h2>
              <p>If you wish to make a complaint against us, you should inform us in writing via email immediately at <a href="mailto:complaints@londonlink.io">complaints@londonlink.io</a>. In order to allow us to investigate your complaint promptly and effectively you should provide the full details of the circumstances giving rise to your complaint.</p>
              <p>We reserve the right to not commence investigations until sufficient details are provided. We will inform you if this is the case. Once provided with sufficient details, we will investigate the complaint promptly and fully, and then provide a written response to your complaint.</p>
              <p>Please note we are not covered by the Gibraltar Investor Compensation Scheme, nor does its concept or protections offered bear any relation to the Services we offer. We are an ‘execution service’ only and do not hold or store any fiat or Cryptoassets on your behalf.</p>
            </section>

            <section>
              <h2>15 SEVERABILITY</h2>
              <p>If any court or relevant authority decides that any paragraph (separated by a blank line and not by clause number) is unlawful, all remaining paragraphs will remain in full force and effect.</p>
            </section>

            <section>
              <h2>16 DELAY</h2>
              <p>If we do not insist that you do anything immediately as required to do under these Trading Terms, or if we delay in taking steps against you in respect of you breaching the Trading Terms or Other Policies, that will not mean that you do not have to do those things and it will not prevent us taking steps against you at a later date subject to any statutory limitation. For example, if you underpay us or we overpay you when we provide Services and we do not chase you for repayment initially, we can still require you to make the payment later.</p>
            </section>

            <section>
              <h2>17 RIGHTS OF THIRD PARTIES</h2>
              <p>This contract is between you and LondonLink. Subject to the Novation clause below, no other third-party person or third-party entity shall have any rights to enforce any of this contract’s terms.</p>
            </section>

            <section>
              <h2>18 NOVATION</h2>
              <p>We may in the future novate (transfer) our rights and obligations under the Trading Terms to another organisation as part of a company sale, reorganisation or other reason. We will inform you in writing if this happens and we will ensure that the novation will not affect your rights or benefits under this agreement. However, if you are unhappy with a novation for any reason you may contact us (or the novated entity, whose identity will be clearly communicated to you if novated) to terminate the Trading Terms within 14 days of us informing you of a novation situation. Any termination is strictly subject to the operation of clause 19.</p>
              <p>If you are unhappy with the novation and terminate the Trading Terms but later contact us or the organisation receiving the benefit of the novation to access the Services as described in this agreement, you will, in accordance with clause 1.2 either become bound to the novated entity (1) by these Trading Terms; or (2) by any updated trading terms if you are provided with a copy before trading.</p>
            </section>

            <section>
              <h2>19 TERMINATING THE TRADING TERMS</h2>
              <p><strong>Your rights to terminate the Trading Terms</strong></p>
              <p>You can terminate the Trading Terms at any time other than when you and we are engaged in a part of the trading process in clause 3 of the Trading Terms, or otherwise have any liability or owe any fiat or Cryptoasset to us. For the avoidance of doubt, you are not able to terminate the Trading Terms if there is an issue during the trading process referred to in clause 3.5, or otherwise.</p>
              <p>To terminate the Trading Terms, please contact us by email on <a href="mailto:hello@londonlink.io">hello@londonlink.io</a> communicating your clear intention to terminate these Trading Terms and cease using our Services. Your termination of the Trading Terms with us will be effective on receipt of an email from us confirming the Trading Terms are able to be terminated.</p>
              <p>Please note that once you have terminated the Trading Terms (in accordance with the process and restrictions above) you will at a later date become bound by the Trading Terms (or an update of the Trading Terms as the case may be) if you do any of the things which constitute acceptance of these or the updated Trading Terms in accordance with clause 1.2.</p>
              <p><strong>Our rights to terminate the Trading Terms</strong></p>
              <p>We reserve the right to terminate the Trading Terms immediately if we consider you to be in breach of contract or if we believe that you may be, or try to, circumvent these expressed or any implied terms and conditions to our detriment. If you are in breach of a contractual duty owed to us which causes us loss, we have the right to claim against you for damages and take any other action deemed necessary.</p>
              <p>We also reserve the right to terminate the Trading Terms if we believe you are breaking the law, or are about to break the law, in any other way including in relation to clause 7.</p>
              <p>Our terminating the Trading Terms in accordance with this clause does not mean you are not liable to us in relation to any breach of contract, tort or any other liability concerned.</p>
            </section>

            <section>
              <h2>20 ANY STORAGE OF CRYPTOASSETS</h2>
              <p>LondonLink does not store any Cryptoassets on behalf of any customers.</p>
            </section>

            <section>
              <h2>21 LAW AND JURISDICTION</h2>
              <p>If you are a consumer, please note that these Trading Terms, their subject matter and their formation, are governed by Gibraltar law and the courts of Gibraltar. You and we both agree that the courts of Gibraltar will have exclusive jurisdiction.</p>
              <p>If you are a business, these Trading Terms, their subject matter and their formation (and any non-contractual disputes or claims) are governed by Gibraltar law. You and we both agree to the exclusive jurisdiction of the courts of Gibraltar.</p>
              <p>This document and content is copyright of LondonLink (GI) Limited. All rights are strictly reserved. Theft of intellectual property is a serious matter and LondonLink will pursue claims against any perpetrators.</p>
            </section>

            <LegalDisclaimer version="2.0.0" name="Trading Terms and Conditions" dateLastUpdated="January 2022" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  /**
   * UK version
   */
  if (constants.businessEntity === "uk") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Trading Terms and Conditions</title>
        </Helmet>

        <Container>
          <article>
            <h1>Trading Terms and Conditions</h1>
            <p><strong>*PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE TRADING. BY
              TRADING OR CONTINUING TO TRADE YOU ARE AGREEING TO BE BOUND BY THE TERMS AND CONDITIONS HEREIN*</strong>
            </p>

            <section>
              <h2>1. LONDONLINK OTC TRADING TERMS AND CONDITIONS</h2>
            </section>

            <section>
              <h3>1.1 WHAT&apos;S IN THESE TERMS?</h3>
              <p>LondonLink OTC Limited
                (<strong>hereinafter &quot;We,&quot; &quot;we&quot;, &quot;Us&quot;, &quot;us&quot;, &quot;Our&quot; or
                  &quot;our&quot;</strong>)
                are the provider of Cryptoasset brokerage services allowing you to buy and sell Cryptoassets
                (<strong>hereinafter &quot;Services&quot;</strong>).
              </p>
              <p>Cryptoassets (or <strong>&quot;Cryptocurrency&quot;</strong> as they are also referred), in relation to
                the provision of Our Services, means any cryptographically secured digital representations of value or
                contractual rights that can be transferred, stored or traded electronically.
              </p>
              <p>This document and terms and conditions herein are our Trading Terms and Conditions (<strong>hereinafter
                “Trading Terms”</strong>).
              </p>
              <p>A reference to <strong>“You”, “you”, “your” or “yours”</strong> in the Trading Terms means, as
                appropriate, you as an individual customer or you as, or for a Corporate customer.
              </p>
              <p>A reference to a <strong>“Corporate”</strong> in the Trading Terms means, any other entity/person or
                entities/persons including but not limited to a corporate or non-corporate entity, a trust, an SPV, a
                group, a consortium or other vehicle.
              </p>
            </section>

            <section>
              <h3>1.2 ACCEPTANCE OF THESE TERMS</h3>
              <p>These Trading Terms contain the terms and conditions of the provision of Our Services to you. You are
                using our Services and agree that the Trading Terms will be binding on you (and will continue to be
                binding on you until such time as it is terminated in accordance with clause 19) when you are provided
                with a copy of the Trading Terms and then do any one of the following, or any combination of, the
                following:
              </p>
              <ul>
                <li>Create an account on our site or have an account created on our site by any other method; or</li>
                <li>ask to, signify or otherwise express or imply your desire to trade with Us through our site; or</li>
                <li>ask to, signify or otherwise express or imply your desire to trade with Us using any other method
                  including (but not limited to) third party websites such as LocalBitcoins.com, instant messaging apps
                  or any other means of verbal or written communication (<strong>hereinafter “Third Party
                    Platforms”</strong>).
                </li>
              </ul>
              <p>Failing to adhere to the Trading Terms will result in you being in breach of contract making remedies
                available to Us.
              </p>
              <p>If you do not agree to these Trading Terms, you must not use our Services.</p>
              <p>Before you use Our Services you also must meet our verification requirements which are explained in
                clause 2, below.
              </p>
              <p>We recommend that you print a copy of these Trading Terms for future reference.</p>

              <div className="box">
                <h4 className="title"><strong>RISK DISCLAIMER</strong></h4>
                <p>Cryptoassets are not backed or guaranteed by any government or financial institution.</p>
                <p>When purchasing Cryptoassets from LondonLink, you must satisfy yourself that you understand all the
                  associated investment risks in light of the above, and generally. Before you decide to invest, do not
                  invest in anything you cannot afford to lose.
                </p>
                <p>You must acknowledge when buying and/or selling Cryptoassets from LondonLink that 100% of your
                  capital invested is at risk. We cannot, and do not make any guarantee about the future value and we
                  will not be held liable for any losses you incur, or request a benefit from any gains you make.
                </p>
                <p>Furthermore, we are not qualified or authorised to give any advice. We very strongly suggest
                  obtaining financial advice from your professional advisor on any investments you make in Cryptoassets.
                </p>
                <p>For the avoidance of doubt, LondonLink are a brokerage offering an ‘execution only’ service at this
                  time. It is solely your responsibility to store any Cryptoasset received in a safe manner. This
                  includes but is not limited to providing LondonLink with the correct and appropriate wallet address to
                  receive Cryptoassets purchased through LondonLink
                </p>
              </div>
            </section>

            <section>
              <h3>1.3 WHO WE ARE AND HOW TO CONTACT US</h3>
              <p>We are a limited company registered in England and Wales under company number 11668544 and have our
                registered office at 71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ.
              </p>
              <p>To contact Us, please email <a href="mailto:hello@londonlinkotc.co.uk">hello@londonlinkotc.co.uk</a> or
                telephone our customer service line on 0207 971 1013.
              </p>
              <p>Our website is also available at https://www.londonlinkotc.co.uk (<strong>hereinafter “our
                site”</strong>).
              </p>
            </section>

            <section>
              <h3>1.4 THE FOLLOWING TERMS AND POLICIES ALSO APPLY TO YOU IF YOU USE OUR SERVICES</h3>
              <p>The terms and policies below also apply (in addition to these Trading Terms) when using our Services:
              </p>
              <ul>
                <li>Our <Link to="/privacy-policy">Privacy Policy</Link></li>
                <li>Our <Link to="/acceptable-use-policy">Acceptable Use Policy</Link></li>
                <li>Our <Link to="/cookie-policy">Cookie Policy</Link></li>
                <li>Our <Link to="/general-terms-and-conditions">General Terms and Conditions</Link></li>
              </ul>
              <p>(<strong>together the “Other Policies”</strong>)</p>
            </section>

            <section>
              <h3>1.5 WE MAY MAKE CHANGES TO THESE TERMS</h3>
              <p>We may amend our Trading Terms or Other Policies from time to time. You will be provided with any new
                versions, if required, otherwise they will be made permanently available on our site from the date they
                apply.
              </p>
              <p>By continuing to trade with Us, positively affirming your agreement in any other way, or by notices on
                our site, you are agreeing to be bound by any new versions of the Trading Terms and Other Policies as
                the case may be.
              </p>
            </section>

            <h2>2. HOW TO USE OUR SERVICES</h2>
            <p>The Trading Terms apply at all times when we provide Services to you.</p>
            <p>Before you can use our Services you will be asked to and you must complete our verification requirements.
              Verification information will be required as part of our AML policies such as conducting credit checks,
              sanction list searches, PEP register searches, blockchain analysis and other related due diligence checks.
              (hereinafter “Verification Information”)
            </p>
            <p>Any information gathered is held strictly in accordance with our <Link to="/privacy-policy">Privacy
              Policy</Link> which may, in certain circumstances, include disclosing information to selected third
              party credit or search providers to which you consent you agree herein.
            </p>

            <section>
              <h3>2.1 ONGOING USE OF OUR SERVICES</h3>
              <p>For the ongoing use of our Services after providing the Verification Information we may, before and/or
                during the trading perform some initial or further due diligence on you and/or on an entity you
                represent (<strong>hereinafter “Ongoing Due Diligence”</strong>).
              </p>
              <p>It is a condition of the Trading Terms and, you agree that you will provide the required information as
                part of the Ongoing Due Diligence that we may request from you.
              </p>
              <p>This should be provided in a timely manner and in any event you agree to do so before we are able to
                provide our Services to you. If you fail to provide this, it is likely that we will stop providing our
                Services to you with immediate effect.
              </p>
              <p>If, following the collection of Verification Information and/or after completing Ongoing Due Diligence,
                the information does not meet our legal, regulatory and/or internal policies, you agree that we can no
                longer provide our Services to you. Therefore, we reserve the right to cease offering our Services to
                you with immediate effect.
              </p>
              <p>If we become satisfied that the Verification Information and the Ongoing Due Diligence are complete,
                accurately provided and sufficiently processed by LondonLink, you will be verified and we will be in a
                position to offer our Services to you(<strong>hereinafter “Verified”</strong>), unless and up to the
                point in which we communicate to you the need to do any Ongoing Due Diligence at a later time.
              </p>
            </section>

            <h2>3. OUR TRADING PROCESS</h2>
            <p>Our trading process involves the following three stages: (1) Requesting and receiving a quote, (2)
              locking the quoted price and (3) settling the trade.
            </p>

            <section>
              <h3>3.1 Stage 1 - Receiving a Quote</h3>
              <p>To receive a quote you will need to contact us through your account on our site or via any one of the
                Third Party Platforms to request a quote (<strong>hereinafter “an RFQ”</strong>). The trading process
                through your account on our site may not take the form outlined below if a Third Party Platform is used.
                However, even if the process may be slightly different than what is outlined below, it is still governed
                by the same trading terms and conditions.
              </p>
              <p>All quotes in response to an RFQ will be provided in Great British Pounds (GBP) unless otherwise agreed
                by you and us when requesting a quote.
              </p>
              <p>NB - please note we reserve the right to pass on any bank (or other) charges or costs when the fiat
                transfer is not in GBP or not to/from a UK bank account.
              </p>
              <p>If you are <strong>buying</strong> a Cryptoasset you must request an RFQ in a clear and unambiguous way
                similar to the below:
              </p>
              <ul>
                <li>“I want to buy 1 BTC, please can you give me a quote”; or</li>
                <li>“Please quote me to buy 1.2536 BTC”; or</li>
                <li>“I want to buy £1000 worth of BTC. Please can I have a quote”.</li>
              </ul>

              <p>If you are selling a Cryptoasset you must request an RFQ in a clear and unambiguous way similar to the
                below:
              </p>
              <ul>
                <li>“I want to sell 1 BTC, please can you give me a quote”; or</li>
                <li>“Please quote me to sell 1.2536 BTC”; or</li>
                <li>“I want to receive £1000 worth of BTC in my account. Please can I have a quote”.</li>
              </ul>

              <p>Please note that in our examples above, BTC refers specifically to Bitcoin. We may provide Services in
                relation to other types of Cryptoassets. If we confirm to you we provide Services in relation to any
                other Cryptoassets, you must use the full name (in place of BTC in the examples above) to clearly
                identify which Cryptoasset you are asking for an RFQ in relation to.
              </p>
              <p>We will then respond to your RFQ in an unambiguous way informing you of the best priced quote available
                at the time. Our quote will look similar to the below unless we have, by your and our previous conduct,
                agreed other methods of quoting in response to your RFQ:
              </p>

              <div className="table-container">
                <table>
                  <thead>
                  <tr>
                    <th>Date</th>
                    <th>Name</th>
                    <th>Price per BTC</th>
                    <th>Amount of BTC</th>
                    <th>GBP amount for trade</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>16/01/19</td>
                    <td>John Smith</td>
                    <td>£3,333.33</td>
                    <td>3.0</td>
                    <td>£10,000.00</td>
                  </tr>
                  </tbody>
                </table>
              </div>

              <p>The first column is the date we are providing the quote.</p>
              <p>The second column is your name.</p>
              <p>The third column is the quoted price (in fiat currency, denominated in GBP/£ in this example) per
                Cryptoasset (e.g. price per Bitcoin). Sometimes referred to as the “rate”.
              </p>
              <p>The fourth column is the quoted number of Cryptoassets required to be sent or received if the quote is
                accepted.
              </p>
              <p>The fifth column is the quoted amount of fiat currency (denominated in GBP/£ in this example) required
                to be sent or received if the quote is accepted.
              </p>
            </section>

            <section>
              <h3>3.2 Stage 2 - Locking a trade at the quoted price</h3>
              <p>When you receive a quote from Us in response to an RFQ you have two choices:</p>
              <ol>
                <li><strong>Decline</strong> the quoted rate by a non-response or any negative affirmation, including
                  but not limited to, by writing or saying “no”, “don’t lock” or similar;
                </li>
                <p>or</p>
                <li>
                  <p><strong>Accept</strong> the quoted rate by any positive affirmation, including but not limited to,
                    by writing or saying “lock”, “yes”, “take”, “fine”, “ok”, “done” or similar.
                  </p>
                  <p><strong>Please note: Accepting the quote means you are committing to complete the trade with Us at
                    the agreed price without regard to any other variable factors including (but not limited to) the
                    changing market (or ‘spot’) price of the Cryptoasset or fiat currency and /or banking limitations
                    such as daily transfer limits. Us completing a trade at a quoted rate is subject to clause
                    3.5.</strong></p>
                  <p><strong>You agree that if you accept a quoted rate you will send, pay or transfer the agreed amount
                    of Cryptoasset or fiat in the denomination you had agreed when asking for a RFQ.</strong></p>
                </li>
              </ol>

              <p>In order for Us to keep our Services competitively priced, if you do not respond to a quote from Us
                within 60 seconds, we are not obliged to honour a quote. We may, however, decide to honour the quote in
                our absolute discretion. We may also retract a quote if you have not already accepted it within the 60
                second window aforementioned. If the quote is retracted but you would like another quote then we will
                happily provide this.
              </p>
              <p className="important">*For the avoidance of doubt, our quotes are available for a maximum of 60
                seconds but may be retracted if we communicate this to you (including, but not limited to, by saying
                “gone”, “retracted”, “expired” or “no”) before you positively affirm your acceptance. If no response is
                received in 60 seconds, the quote is deemed retracted in any event*
              </p>
            </section>

            <section>
              <h3>3.3 Stage 3 - Settlement of the trade</h3>
              <p>Trade settlement is the process of transferring Cryptoassets into the wallet of a buyer and fiat into
                the bank account of the seller, following acceptance of an RFQ.
              </p>
              <p>Once the quote has been provided and you have accepted the quoted price of the trade, we will settle
                the trade as soon as possible, subject to any issues meaning a trade cannot be completed which will be
                dealt with in accordance with clause 3.5.
              </p>

              <h3>3.3.1 If you are <strong className="underline">buying</strong> Cryptoassets <strong
                className="underline">from</strong> Us:</h3>
              <p><strong>(if you are selling Cryptoassets to Us please skip to 3.3.2)</strong></p>
              <p><strong>Individuals:</strong> If you are a Verified individual:</p>
              <p>You must send fiat from a personal account in your name. We must return any monies in accordance with
                clause 3.5, if , following our checks, we have reason to believe that the fiat has not come from an
                account in your name.
              </p>

              <p><strong>Not an individual:</strong> If you are a Verified Corporate:</p>
              <p>You must send fiat from a business account in your Corporate’s name for which either: (1) you are a
                Director or (2) you have documentary evidence (to our satisfaction) to prove a Director (or a Trustee)
                has given you specific authority to make <span className="underline">this</span> payment, <span
                  className="underline">before you make the payment or agree a rate.</span></p>

              <p>Once the fiat has cleared into our account (for which you accept there may be delays out of our
                control, you <span className="underline">agree to wait through the delay</span> and agree not to
                blame or hold Us liable in the interim, we will ask you to provide your wallet address for us to send
                the Cryptoassets to. We will only be obliged to send you Cryptoassets in the event there is not an issue
                in the trading process in accordance with clause 3.5 or otherwise. If there is an issue in the trading
                process, return of fiat and/or Cryptoassets will be dealt with in accordance with clause 3.5.
              </p>

              <p>Where there are delays, whether in our control or out of our control, we aim to keep you updated as and
                when appropriate.
              </p>

              <p>You agree that it is your sole responsibility to ensure the wallet address provided to us for receiving
                any Cryptoassets from us is accurate, up to date and not missing any digits. If relevant you will also
                ensure that the wallet address is specifically for the type of Cryptoasset you are receiving. We are
                happy to assist you with trying to identify this by providing <strong><span
                  className="underline">general information</span></strong>, but we accept no liability and you
                acknowledge that providing the wallet address to us is your sole responsibility.
              </p>
            </section>

            <section>
              <h3>3.3.2 If you are <span className="underline">selling</span> Cryptoassets <span
                className="underline">to</span> Us:</h3>
              <p>We will provide you with a wallet address for the type of Cryptoasset you have identified when asking
                for an RFQ. You must not send Cryptoassets to any wallet address other than the one we provide you
                with, <span className="underline">immediately prior</span> to requesting sending of the specific
                Cryptoasset quoted for.
              </p>
              <p>If you have incorrectly identified the Cryptoasset when you ask for an RFQ, we will not be liable for
                any losses caused by you in error, including but not limited to the Cryptoassets themselves or their
                value depreciation incurred by your incorrect identification. You agree to be contractually obliged to
                complete the original trade with us regardless of aforementioned errors and/or losses.
              </p>
              <p>You, the individual or the Corporate sending the Cryptoassets must, at all times, be Verified. If for
                any erroneous reason it is discovered that you are not Verified (including if you have not provided any
                Ongoing Due Diligence information whether previously requested or not) when part way through a trade,
                you agree to provide the requested verifications before we are required to settle the trade.
              </p>
              <p>Once we are satisfied that the Cryptoassets have been received by Us (or otherwise satisfied that they
                will be received) we will set up a transfer of fiat in the pre-agreed fiat currency denomination to the
                bank details you provide so long as we are satisfied you are Verified and the bank owner information
                matches the Verified account.
              </p>
              <p>To be clear, we are only obliged to set up the agreed fiat transfer if the bank details you provide Us
                with are evidently (to our satisfaction) yours and the Cryptoassets have been received safely by Us. If
                we cannot set up the agreed fiat transfer, the trade may be dealt with using one of the mechanisms in
                clause 3.5.
              </p>
            </section>

            <section>
              <h3>3.4 Receipts</h3>
              <p>For our accountancy and other purposes, we will produce a receipt of each and every trade we conduct
                with you. At your request, we can provide you with a copy.
              </p>
              <p>If for some reason it is appropriate to issue you with an invoice for any unsettled trade(s) and/or
                debts owed by you, we will do so. Invoices are expressed to be due immediately unless otherwise provided
                on the invoice.
              </p>
            </section>

            <section>
              <h3>3.5 Issues during the trading process</h3>
              <h3>3.5.1 If you send fiat from a bank account not in your Verified name</h3>

              <p>If possible, we will always verify whether a fiat transfer came from you by checking the sender’s name
                on our bank statement against the previously collected Verification Information. If we do not believe it
                came from you, or you or the third party are not Verified, we reserve the right to return the funds to
                the same account that the fiat came from.
              </p>

              <p>If necessary, we may ask you to provide us with evidence satisfying us that the fiat came from your
                account. This may be asked to be evidenced by you sending us a copy of a bank issued document and/or
                card showing the name and full account details of the sender being the same as on our bank feed or other
                information sources of ours. We may require more evidence to support evidence already provided if we are
                in doubt as to the validity of the same.
              </p>

              <p>At times we may be unable to identify the payment sender’s bank details, therefore you agree to provide
                us with evidence of the payment sender’s bank details.
              </p>

              <p>We will not send the fiat to anyone else and you agree to not pressure us to do so in light of what you
                have agreed to in the Trading Terms.
              </p>
            </section>

            <section>
              <h3>3.5.2 Overpayment or underpayment of fiat or Cryptoassets</h3>
              <p>If Verified, and you send us an overpayment or underpayment of fiat or Cryptoassets relating to a
                locked quote, we may, at our sole discretion have to return the fiat or Cryptoassets to you.
              </p>
              <p>However, if we decide we do not have to return the fiat or Cryptoassets we will decide to either A or
                B.
              </p>

              <p><strong>A.</strong> Round up or down the Cryptoassets or fiat at the agreed rate to the amount of fiat
                or Cryptoassets received by Us; or
              </p>
              <p><strong>B.</strong> Return the difference in fiat or Cryptoassets received by us to your bank account
                or wallet address you provide (so long as the bank account is evidenced yours and Verified). This can be
                evidenced by sending us a copy of a bank issued document or card showing the name and full account
                details of the sender being the same as on our bank statement. In addition, further evidence may be
                required if we are in doubt as to the validity of the already provided evidence.
              </p>

              <p>We reserve the right to pass on any charges to you that we may incur as a direct result of your
                overpayment or underpayment of fiat or Cryptoassets.
              </p>
            </section>

            <section>
              <h3>3.5.3 Input errors by us</h3>
              <p>If a quote in response to an RFQ has been given in error by us, you acknowledge that we are not obliged
                to trade at that rate.
              </p>
              <p>For example, if we miss or add a decimal number by mistake in our quote as follows “15 BTC” opposed to
                the intended “1.5 BTC”.
              </p>
              <p>If we make any other errors when quoting, sending fiat, Cryptoassets or otherwise, you agree to assist
                us expeditiously in correcting this error. This may include you sending Cryptoassets or fiat back to us.
                If you do not assist us expeditiously in correcting this error we may take whatever lawful action
                necessary, including but not limited to, holding you in breach of contract resulting in civil legal
                action against you, contacting relevant authorities including the police to explore if you have any
                criminal liability, immediately terminating the provision of our Services to you and terminating the
                provision of our Services to any of your associates or referrals immediately.
              </p>
            </section>

            <section>
              <h3>3.5.4 If fiat or Cryptoassets are sent to incorrect details or addresses</h3>
              <p>If you are Verified and you or we are sending fiat or Cryptoassets, the receiving party will not be
                liable if the sending party does not send fiat or Cryptoassets to the bank details or the Cryptoasset
                address respectively.
              </p>
              <p><strong>If we are receiving Cryptoassets</strong> in relation to a trade, we are not liable to you or
                anyone else for any losses caused by sending Cryptoassets to an invalid address or any addresses other
                than the one provided to you by us at the time of the specific trade.
              </p>
              <p><strong>If we are receiving fiat </strong> in relation to a trade, we are not liable to you or anyone
                else for any losses caused by sending fiat to any account other than the account details provided to you
                by us at the time of the specific trade.
              </p>
              <p>If we send Cryptoassets or fiat to an address or bank details provided by you in the agreed amount ,
                you agree we have fully discharged our obligations relating to that specific trade under these Trading
                Terms.
              </p>
            </section>

            <section>
              <h3>3.5.5 Return of fiat or Cryptoassets for other reasons</h3>
              <p>If (1) you are not Verified and you send fiat or Cryptoassets; or (2) when conducting blockchain
                analysis and/or any other related due diligence on receipt of fiat or Cryptoassets in relation to a
                trade or otherwise, we reserve the right to return fiat of equal value for compliance or other reasons..
                Whether this occurs during a trade or not, you agree that we will not be obliged to complete the
                specific trade.
              </p>
              <p><strong>If we have received fiat</strong>, you or the owner must evidence ownership of the account the
                fiat has been transferred from. This can be evidenced by sending us a copy of a bank issued document or
                card showing the name and full account details of the sender being the same as on our bank feed. We may
                require more evidence to support evidence already provided if we are in doubt as to the validity of the
                same.
              </p>
              <p><strong>If we have received Cryptoassets</strong>, we shall return the same number of Cryptoassets to
                the source they were sent from, minus any mining fees or other fees or costs incurred by us relating to
                your unsolicited sending of fiat or Cryptoasset to us.
              </p>
            </section>

            <section>
              <h3>3.5.6 Maximum limits when sending fiat</h3>
              <p>Sometimes we or you may reach a daily limit (or other limit) when setting up a fiat transfer. It is
                your responsibility to make sure you know your bank’s limits and not to ask for an RFQ or lock a trade
                if it will mean you cannot send the fiat instantly, or at worst, the same working day. You will be in
                breach of this contract if you do not complete a trade once you lock in a price and we reserve the right
                to hold you to that breach if it is not resolved expeditiously. You acknowledge that at times, by not
                holding you responsible for breach of the contract, does not mean that you will never be held in breach.
              </p>
              <p><strong>If you reach a limit with your bank</strong>, you will provide evidence of reaching the limit
                to us, to our satisfaction. You agree that in our absolute discretion we may decide to act as follows:
                (1) adjust the quote down to the maximum amount you can send at the same rate; (2) require you to send
                the fiat over multiple transactions from your Verified account and across multiple days if the case may
                be; or (3) cancel the trade and we return any fiat to your Verified account (if some fiat was sent) in
                relation to this trade (subject to the restrictions in clause 3.5.1 relating to Verified accounts in
                your name).
              </p>
              <p><strong>If we reach a limit with our bank</strong>, we agree that we will complete the trade at the
                agreed rate as soon as it is possible for us to do so which will, in usual circumstances, be the
                following working day.
              </p>
            </section>

            <section>
              <div id="accounts" className="box">
                <h3 className="title">THE TERMS IN THIS BOX APPLY IN ADDITION IF YOU CREATE AN ACCOUNT ON OUR SITE</h3>
                <h4>CREATING AND CLOSING A WEBSITE ACCOUNT</h4>
                <p>In order for Us to provide you with Services, we may need you to create an account or otherwise
                  interact with our site.
                </p>
                <p>When you create an account on our site you will become a website customer (<strong>hereinafter
                  “Website Customer”</strong>). Once you become a Website Customer you will have, as a result, entered
                  into the Trading Terms contract (and any Other Policies you agreed to at the time) with Us.
                </p>
                <p>You may cease to be a Website Customer at any time by clearly notifying Us via <a
                  href="mailto:support@londonlinkotc.com">support@londonlinkotc.com</a>. Notifying Us of your wish to
                  cease to be a Website Customer will be effective when you receive email confirmation in reply that we
                  have actioned your request. Please note that you will cease to be a Website Customer but not cease to
                  be bound by the Trading Terms or Other Policies which remain in full force and effect.
                </p>

                <h4>YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</h4>
                <p>If you choose, or you are provided with, a user identification ID (or email address or similar),
                  password or any other piece of information as part of our account sign up or security procedures, you
                  must treat such information as confidential. You must not disclose it to any third party.
                </p>
                <p>We may require the use of SMS/ MFA/ TOTP codes (2 Factor Authentication) in the future and reserve
                  the right to require you to use this to login and for other important high security functions once
                  logged in.
                </p>
                <p>We have the right to disable any user identification ID (or email address or similar) or password,
                  whether chosen by you or allocated by Us, at any time, if in our reasonable opinion you have failed to
                  comply with the Trading Terms or any of the Other Policies.
                </p>
                <p>If you know or suspect that anyone other than you knows your user identification code or password,
                  you must promptly notify Us by email at <a
                    href="mailto:support@londonlinkotc.com">support@londonlinkotc.com</a>.
                </p>

                <h4>WE MAY SUSPEND OR WITHDRAW YOUR ACCOUNT</h4>
                <p>Your account on our site is made available to you free of charge.</p>
                <p>We do not guarantee that your account, or any content on it, will always be available or be
                  uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of your
                  account for business, legal and/or operational reasons.
                </p>
                <p>You are also responsible for ensuring that no one other than you accesses your account. If in the
                  event someone other than you accesses your account, or we reasonably believe someone other than you is
                  accessing your account, we may suspend or withdraw or restrict the availability of all or any part of
                  your account without notice.
                </p>
                <p>We are not liable for any losses suffered by you as a result of suspending or withdrawing your free
                  account.
                </p>
              </div>
            </section>

            <section>
              <h3>4 REMEDIES</h3>
              <h4>4.1 Available remedies</h4>
              <p>Whether specifically reserved or referred to in a clause of these Trading Terms or not, we hereby
                reserve the right to hold you in breach of contract, in tort or otherwise if we believe you to be in
                breach of any legal, tortious or equitable obligations. We will communicate the failure of your legal
                obligation and the circumstances to you and if it is not satisfactorily resolved in a reasonable time we
                will stop making Services available to you, may issue legal proceedings against you. or take any other
                action deemed necessary.
              </p>

              <h4>4.2 Reversed payments</h4>
              <p>If you believe you have made a payment to us in error, you agree to inform us immediately. We will then
                look into the situation as soon as possible.
              </p>
              <p>
                You agree that you will not reverse any fiat payments sent to us <span className="underline">in any
              circumstances</span>.
                If a payment sent to us is reversed, we will hold you in breach of the Trading Terms and you are
                therefore, liable to us for direct and consequential losses arising from that breach suffered by us,
                including, but not limited to, issuing a claim against you (to the maximum extent permitted in law) for:
              </p>
              <ul>
                <li>if appropriate the fiat value of the payment concerned; and/or</li>
                <li>the loss of banking services (regardless of the duration banking services are lost for); and/or</li>
                <li>the loss of profit; and/or any other liabilities or losses arising as a result of reversed payments,
                  including management or consultant time explaining the situation to banking providers and subsequent
                  resolution of the same.
                </li>
              </ul>
            </section>

            <section>
              <h3>5 OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</h3>
              <h4>5.1 Our liability</h4>
              <p>Our liability to you under these Trading Terms or otherwise is limited to your direct losses as a
                result of our clear breach of the Trading Terms. For the avoidance of doubt, we will not be in breach of
                the Trading Terms if we cannot complete a trade because there is an issue in the trading process in
                accordance with clause 3.5, or another issue which is not as a direct result of our contractual or
                tortious actions or omissions as the case may be.
              </p>

              <h4>5.1.1 Whether you are a consumer or a business user:</h4>
              <ul>
                <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This
                  includes liability for death or personal injury caused by our negligence or the negligence of our
                  employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
                </li>
              </ul>

              <h4>5.1.2 If you are a business user:</h4>
              <ul>
                <li>We exclude all implied conditions, warranties, representations or other terms that may apply to our
                  site and any content on it, these Trading Terms and any Other Policies.
                </li>
                <li>We will not be liable to you for any loss or damage, whether in contract, tort (including
                  negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in
                  connection with:
                </li>
                <ul>
                  <li>your inability to use, our site or Services; or</li>
                  <li>use of or site or reliance on any content displayed on our site.</li>
                </ul>
                <p>In particular, we will not be liable for:</p>
                <ul>
                  <li>loss of profits, sales, business, or revenue;</li>
                  <li>business interruption;</li>
                  <li>loss of anticipated savings;</li>
                  <li>loss of business opportunity, goodwill or reputation; or</li>
                  <li>any indirect or consequential loss or damage.</li>
                </ul>
              </ul>

              <h4>5.1.3 If you are a consumer user:</h4>
              <ul>
                <li>Please note that we only provide our site for private use. You agree not to use our site for any
                  commercial or business purposes, and we have no liability to you for any loss of profit, loss of
                  business, business interruption, or loss of business opportunity.
                </li>
              </ul>
            </section>

            <section>
              <h4>5.2 Our limit on liability owed to you</h4>
              <p>In any event, any liability we owe to you is strictly limited to 100% of the fiat value of the
                particular disputed trade (or the fiat equivalent value of the particular trade disputed, at the rate
                that it was quoted, which is based upon ‘spot’ price at the time).
              </p>
            </section>

            <section>
              <h3>6 HOW WE MAY USE YOUR PERSONAL INFORMATION</h3>
              <p>We will only use your personal information as set out in our<Link to="/privacy-policy">Privacy
                Policy</Link>.
              </p>
            </section>

            <section>
              <h3>7 ANTI-MONEY LAUNDERING</h3>
              <p>It is absolutely prohibited for you to try and use Our Services for the purpose of; cleaning money,
                hiding money, in any way attempting to obscure the source of funds, or attempting to circumvent
                financial regulations or restrictions.
              </p>
              <p>If we suspect you have attempted to deceive in this way or engage in money laundering activities,
                terrorist financing or any fraud then, LondonLink will cease to make any services available to you, and
                any associated individuals or entities we think may be involved or related. You and they will also be
                blacklisted from the use of LondonLink’s services.
              </p>
              <p>We will take a zero tolerance approach in all circumstances to any illegal activity you are, or may
                become, involved in. We are fully committed to our responsibilities in this regard and reserve the right
                to report any activity we consider suspicious.
              </p>
            </section>

            <section>
              <h3>8 CASH AS A METHOD OF PAYMENT</h3>
              <p>Under no circumstances whatsoever will LondonLink provide services or otherwise, buy and/or sell any
                Cryptoassets from you using cash as a method of payment.
              </p>
            </section>

            <section>
              <h3>9 AVAILABILITY OF SERVICES TO YOU</h3>
              <p>We reserve the right to cease (either temporarily or permanently) making Services available to you
                should you (1) at any point in time become not Verified (which can automatically occur in accordance
                with the Trading Terms, even if you were previously Verified); or (2) if you owe us any fiat or
                Cryptoasset for any length of time; or (3) we have reasons to believe that you are in breach of the
                Trading Terms or Other Policies.
              </p>
              <p>If we temporarily cease to make Services available to you, you are still contractually bound by the
                Trading Terms until such time as it is terminated in accordance with clause 19.
              </p>
            </section>

            <section>
              <h3>10 MERCHANTS</h3>
              <p>We cannot be held responsible and accept no liability for losses resulting from delayed payments or any
                other acts or omissions of third party merchants and/or payment providers.
              </p>
              <p>In the case of delayed payments of fiat or Cryptoassets out of our control, if possible, we will
                contact you as soon as practicable if we are made aware of any delays. We will also take reasonable
                steps to minimise the effects of the delay to you. However, in usual circumstances we are not likely to
                be made aware of the reason for a delay.
              </p>
            </section>

            <section>
              <h3>11 REFUNDS</h3>
              <p>We cannot and will not under any circumstances refund any fiat or Cryptoassets to you once a trade has
                been completed. You understand refunds are not possible due to the 24/7 nature of the market conditions,
                mixing of fiat and Cryptoassets (and therefore lack an ability to identify) and potential volatility of
                swings which could lead to considerable, unrecoverable, losses for us.
              </p>
              <p>Please see our Risk Disclaimer at the top of this document about the risks associated with you
                purchasing Bitcoin or other Cryptocurrencies. The Disclaimer is repeated and made available in various
                other prominent locations to you.
              </p>
              <p>Any reference to returning or transferring fiat or Cryptoassets to/from us and/or to/from you in clause
                3.5 shall not constitute a refund. Clause 3.5 refers to issues during a trade (i.e. before the trade is
                concluded) meaning a trade cannot be completed. And if a trade is not completed, it cannot be refunded
                or claimed by you that it has been refunded.
              </p>
            </section>

            <section>
              <h3>12 TAX AND VAT</h3>
              <p>It is your sole responsibility to determine and report any tax information from your use of the
                Services to the appropriate tax authorities.
              </p>
            </section>

            <section>
              <h3>13 COMPLAINTS</h3>
              <p>If you wish to make a complaint against Us, you should inform Us via email immediately at <a
                href="mailto:support@londonlinkotc.com">support@londonlinkotc.com</a>. In order to allow Us to
                investigate your complaint promptly and effectively you should provide the full details of the
                circumstances giving rise to your complaint.
              </p>
              <p>We reserve the right to not commence investigations until sufficient details are provided. We will
                investigate the complaint promptly, fully and provide a written response to your complaint. Please note
                we are not covered by the Financial Services Compensation Scheme.
              </p>
            </section>

            <section>
              <h3>14 SEVERABILITY</h3>
              <p>Each of the paragraphs of these Trading Terms operates separately. If any court or relevant authority
                decides that any of them are unlawful, the remaining paragraphs will remain in full force and effect.
              </p>
            </section>

            <section>
              <h3>15 DELAY</h3>
              <p>If we do not insist that you do anything immediately as required to do under these Trading Terms, or if
                we delay in taking steps against you in respect of you breaching the Trading Terms or Other Policies,
                that will not mean that you do not have to do those things and it will not prevent Us taking steps
                against you at a later date. For example, if you underpay Us or we overpay you when we provide Services
                and we do not chase you for repayment initially, we can still require you to make the payment at a later
                date.
              </p>
            </section>

            <section>
              <h3>16 RIGHTS OF THIRD PARTIES</h3>
              <p>This contract is between you and Us. No other person or entity shall have any rights to enforce any of
                its terms subject to the Novation clause below. Neither you nor Us will need to get the agreement of any
                other person in order to end the contract or make any changes to these terms.
              </p>
            </section>

            <section>
              <h3>17 NOVATION</h3>
              <p>We may novate (transfer) our rights and obligations under the Trading Terms to another organisation. We
                will tell you in writing if this happens and we will ensure that the novation will not affect your
                rights under the contract. If you are unhappy with the novation you may contact Us to terminate the
                Trading Terms within 14 days of Us telling you about it subject to not being in the middle of a trade
                with Us (or the and in accordance with clause 19.
              </p>
              <p>If you are unhappy with the novation and terminate the Trading Terms but later contact Us or the
                organisation receiving the benefit of the novation to access the Services as described in this
                agreement, you will, in accordance with clause 1.2 either become bound (1) by these Trading Terms; or
                (2) by any updated trading terms if you are provided with a copy before trading.
              </p>
            </section>

            <section>
              <h3>18 TERMINATING THE TRADING TERMS</h3>
              <h4>Your rights to terminate the Trading Terms</h4>
              <p>You can terminate the Trading Terms at any time other than when you and we are engaged in a part of the
                trading process in clause 3 of the Trading Terms, or otherwise have any liability or owe any fiat or
                Cryptoasset to us. For the avoidance of doubt, you are not able to terminate the Trading Terms if there
                is an issue during the trading process referred to in clause 3.5, or otherwise.
              </p>
              <p>To terminate the Trading Terms, please contact Us by email on <a
                href="mailto:support@londonlinkotc.com">support@londonlinkotc.com</a> communicating your clear intention
                to terminate and cease using our Services. Your termination of the Trading Terms with us will be
                effective on receipt of an email from us confirming the Trading Terms can be terminated.
              </p>
              <p>Please note that once you have terminated the Trading Terms (in accordance with the process and
                restrictions above) you will at a later date become bound by the Trading Terms (or an update of the
                Trading Terms if the case may be) if you do any of the things which constitute acceptance of these or
                the updated Trading Terms in accordance with clause 1.2.
              </p>

              <h4>Our rights to terminate the Trading Terms</h4>
              <p>We reserve the right to terminate the Trading Terms immediately if we believe you to be in breach of
                contract or if we believe that you may be, or try to, circumvent terms and conditions to our detriment.
                If you are in breach of a contractual duty owed to Us which causes us loss we have the right to claim
                against you for damages and take any other action deemed necessary.
              </p>
              <p>We also reserve the right to terminate the Trading terms if we believe you are breaking the law, or are
                about to break the law, in any other way including in relation to clause 7.
              </p>
              <p>Us terminating the Trading Terms in accordance with this clause does not mean you are not liable to Us
                in relation to any breach of contract, tort or any other liability concerned.
              </p>
            </section>

            <section>
              <h3>19 ANY STORAGE OF CRYPTOASSETS</h3>
              <p>LondonLink does not store any Cryptoassets on behalf of any customers.</p>
            </section>

            <section>
              <h3>20 LAW AND JURISDICTION </h3>
              <p>If you are a consumer, please note that these Trading Terms, their subject matter and their formation,
                are governed by English law and the courts of England and Wales. You and we both agree that the courts
                of England and Wales will have exclusive jurisdiction.
              </p>
              <p>If you are a business, these Trading Terms, their subject matter and their formation (and any
                non-contractual disputes or claims) are governed by English law. You and we both agree to the exclusive
                jurisdiction of the courts of England and Wales.
              </p>
            </section>

            <LegalDisclaimer version="1.2.3" name="Trading Terms and Conditions" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  return <NotFoundPage />;
};
