import React from "react";
import { Box, Stack } from "@chakra-ui/react";
import { FooterNavItem } from "./FooterNavItem/FooterNavItem";

export const FooterNav = () => {
  return (
    <Box as="nav">
      <Stack
        as="ul"
        spacing={{ base: 1, md: 5 }}
        direction={{ base: 'column', md: 'row' }}
        mb={{ base: 3, xl: 0 }}>
        <FooterNavItem link="/privacy-policy">Privacy Policy</FooterNavItem>
        <FooterNavItem link="/general-terms-and-conditions">Terms and Conditions</FooterNavItem>
        <FooterNavItem link="/acceptable-use-policy">Acceptable Use Policy</FooterNavItem>
        <FooterNavItem link="/risk-disclaimer">Risk Disclaimer</FooterNavItem>
        <FooterNavItem link="/cookie-policy">Cookie Policy</FooterNavItem>
        <FooterNavItem link="/gdpr-faq">GDPR FAQ</FooterNavItem>
      </Stack>
    </Box>
  );
};
