import React, { Fragment, ReactNode } from "react";
import { Badge, Box, Button, Divider, Heading, HStack, Text } from "@chakra-ui/react";
import { BiShield, HiOutlineClipboardCheck, HiOutlineClock } from "react-icons/all";
import { BiCheckShield } from "react-icons/bi";
import { Link } from "react-router-dom";
import { VerificationLevelStatusIndicator } from "components";
import { ComplianceItemStatus } from "../../../../../shared/src/types/compliance";

interface Props {
  name: "Level 1" | "Level 2";
  path: "level-1" | "level-2";
  requirements: { key: number, title: string; status: ComplianceItemStatus }[];
  completionTime: string;
  reviewTime: string;
  active: boolean;
  completed: boolean;
  optional: boolean;
  mandatory: boolean;
}

interface InfoItemProps {
  icon: ReactNode;
  text: string;
}

const InfoItem = ({ icon, text }: InfoItemProps) => {
  return (
    <Box color="gray.500" fontSize="13px" d="flex" alignItems="flex-start">
      <Box mr={2} mt="3px">{icon}</Box> <Text>{text}</Text>
    </Box>
  );
};

export const VerificationTier = ({ name, path, reviewTime, completionTime, active, completed, optional, mandatory, requirements }: Props) => {
  // Set Button text
  let buttonText = 'Complete Verification';

  if (completed) {
    buttonText = 'Verification Completed';
  }

  if (path === 'level-2' && (!active && !completed)) {
    buttonText = 'After Level 1';
  }

  const disabled = completed || !active;

  return (
    <Box
      p={5}
      bg="#f9f9f9"
      boxShadow="1px 2px 2px 2px #e4e4e4"
      borderTop="3px solid #004890"
      borderRadius={4}>
      <Heading
        as="h2"
        size="md"
        mb={3}
        d="flex"
        alignItems="center">
        {completed ? <BiCheckShield color="#38a169" /> : <BiShield color="#737373" />}
        <Box as="span" ml={1}>
          {name}
          {!completed && (
            <Fragment>
              {optional && <Badge ml={3} fontSize="10.5px" colorScheme="blue">Optional</Badge>}
              {mandatory && <Badge ml={3} fontSize="10.5px" colorScheme="red">Mandatory</Badge>}
            </Fragment>
          )}
        </Box>
      </Heading>

      <Box minHeight="57px">
        <InfoItem text={`Completion time: ${completionTime}`} icon={<HiOutlineClock />} />
        <InfoItem text={`Review time: ${reviewTime}`} icon={<HiOutlineClipboardCheck />} />
      </Box>

      <Divider my={4} />

      <Box minHeight="135px">
        {requirements.map((req) => {
          return (
            <HStack spacing={2} key={req.key}>
              <VerificationLevelStatusIndicator status={req.status} />
              <Text>{req.title}</Text>
            </HStack>
          );
        })}
      </Box>

      <Divider my={4} />

      <Button
        id={`start-verification-${path}`}
        as={disabled ? Button : Link}
        isFullWidth
        to={`/customer/verification/${path}`}
        colorScheme={disabled ? 'gray' : 'brand'}
        isDisabled={disabled}>
        {buttonText}
      </Button>
    </Box>
  );
};
