import { legalPage } from './legal-page';

export const styles = {
  global: {
    a: {
      color: 'blue.500',
    },
    '::selection': {
      background: 'rgb(0 54 177 / 10%)'
    },
    ...legalPage
  },
};
