import { AbstractActionModel } from "../abstract/AbstractActionModel";
import { Compliance, ComplianceActionStages, ComplianceItem } from "../../types/compliance";
import { CustomerProfileModel, CustomerProfileModelState } from "./CustomerProfileModel";
import { DataStatuses, ErrorResponse } from "../../types/common";
import { customerApiClient } from "../../../../fe-main/src/libs/CustomerLondonLinkApiClient";

export interface CustomerVerificationModelState {
  currentStage: ComplianceActionStages | null;
  complianceState: Compliance | null;
  verifyEmailRequest: {
    dataStatus: DataStatuses;
    dataError: ErrorResponse | null;
  },
  resendEmailVerificationRequest: {
    dataStatus: DataStatuses;
    dataError: ErrorResponse | null;
  },
}

export type CustomerVerificationRequestNames = "verifyEmailRequest";

export interface VerifyEmailRequest {
  email: string;
  code: string;
}

const defaultState: CustomerVerificationModelState = {
  currentStage: null,
  complianceState: null,
  verifyEmailRequest: { dataStatus: DataStatuses.Uninitialized, dataError: null },
  resendEmailVerificationRequest: { dataStatus: DataStatuses.Uninitialized, dataError: null }
}

const xhrClient = customerApiClient.getXhrClient();

export class CustomerVerificationModel extends AbstractActionModel {
  private state = defaultState;
  private customerProfileModel: CustomerProfileModel;
  private customerProfileState: CustomerProfileModelState | null = null;
  private customerSelectedStage: ComplianceActionStages | null = null;

  constructor(customerProfileModel: CustomerProfileModel) {
    super();
    this.customerProfileModel = customerProfileModel;
    this.customerProfileModel.addListener(() => this.getCustomerProfile() );
  }

  private updateState(newState: Partial<CustomerVerificationModelState>) {
    this.state = { ...this.state, ...newState };
    this.notify();
  }

  private calculateCurrentVerificationStage() {
    let currentStage;
    if (this.customerProfileState?.compliance) {
      if (this.customerSelectedStage) {
        currentStage = this.customerSelectedStage;
      } else {
        const actionsStages = Object.values(this.customerProfileState.compliance.actions);
        const actionsList = Object.keys(this.customerProfileState.compliance.actions);

        const current = actionsStages.findIndex((stage: ComplianceItem) => {
          const status = stage.status;
          return status === "todo" || status === "pending" || status === "failed";
        });

        currentStage = actionsList[current] as ComplianceActionStages;
      }
    } else {
      currentStage = defaultState.currentStage;
    }

    return currentStage;
  }

  private setVerificationStatus() {
    if (this.customerProfileState?.compliance) {
      this.updateState({ complianceState: this.customerProfileState.compliance });
    }
  }

  private getCustomerProfile() {
    this.customerProfileState = this.customerProfileModel.getProfileState();
    this.setVerificationStatus();
  }

  /*
    Public methods
  */
  public autoSelectCustomerStage() {
    this.customerSelectedStage = null;
    this.updateState({ currentStage: this.calculateCurrentVerificationStage() });
  }

  public customerStageSelect(stage: ComplianceActionStages) {
    this.customerSelectedStage = stage;
    this.updateState({ currentStage: this.calculateCurrentVerificationStage() });
  }

  // Verify Email
  public async verifyEmail(params: VerifyEmailRequest) {
    this.updateState({ verifyEmailRequest: { dataError: null } as CustomerVerificationModelState['verifyEmailRequest'] }); // Reset error on new fetch

    const genericErrorMessage = "There was an unexpected error verifying your email. Please try again.";

    if (this.state.verifyEmailRequest.dataStatus !== "loading") {
      try {
        this.updateState({ verifyEmailRequest: { dataStatus: DataStatuses.Loading, dataError: null } });

        const res = await xhrClient.post("/customer/verifyEmail", {
          email: params.email,
          code: params.code
        });

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({
            verifyEmailRequest: {
              dataStatus: DataStatuses.Ok,
              dataError: null
            }
          });
        } else {
          this.updateState({ verifyEmailRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
        }
      } catch (error: any) {
        let errorMessage = genericErrorMessage;

        // If server gave us error response, handle it here
        if (error.response?.data?.status) {
          if (error.response.data.status === "invalidCode") {
            errorMessage = "Incorrect verification code, please check and try again."
          }

          if (error.response.data.status === "customerNotFound") {
            errorMessage = "The account attempting to be verified could not be found."
          }

          if (error.response.data.status === "alreadyVerified") {
            errorMessage = "This email address has already been verified."
          }
        }

        this.updateState({ verifyEmailRequest: { dataStatus: DataStatuses.Error, dataError: { stack: error, message: errorMessage } } });
      }
    }
  }

  // Resend email verification
  public async resendEmailVerification() {
    const genericErrorMessage = "There was an unexpected error resending verification email. Please try again.";

    if (this.state.resendEmailVerificationRequest.dataStatus !== "loading") {
      try {
        this.updateState({ resendEmailVerificationRequest: { dataStatus: DataStatuses.Loading, dataError: null } });

        const res = await xhrClient.post("/customer/requestVerificationEmail");

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({
            resendEmailVerificationRequest: {
              dataStatus: DataStatuses.Ok,
              dataError: null
            }
          });
        } else {
          this.updateState({ resendEmailVerificationRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
        }
      } catch (error: any) {
        let errorMessage = genericErrorMessage;
        this.updateState({ resendEmailVerificationRequest: { dataStatus: DataStatuses.Error, dataError: { stack: error, message: errorMessage } } });
      }
    }
  }

  public getVerificationState() {
    return this.state;
  }

  public resetRequestState(request: CustomerVerificationRequestNames) {
    this.updateState({ [request]: { dataStatus: DataStatuses.Uninitialized, dataError: null } });
  }

  public resetCustomerVerificationState() {
    this.updateState(defaultState);
  }
}
