import React from "react";
import { Helmet } from "react-helmet";
import { Box, Container, Heading, Text } from "@chakra-ui/react";
import { RequestPasswordForm } from "components";
import { PublicPageTemplate } from "../../PublicPageTemplate/PublicPageTemplate";

export const RequestPasswordPage = () => {
  return (
    <PublicPageTemplate>
      <Container>
        <Helmet>
          <title>Request a new password</title>
        </Helmet>

        <Box maxW="450px">
          <Heading size="lg">Request a new password</Heading>
          <Text my={5}>
            Enter the email address associated with your account,
            and we&apos;ll send you a link to reset your password.
          </Text>

          <RequestPasswordForm />
        </Box>
      </Container>
    </PublicPageTemplate>
  );
};
