import React from "react";
import { Alert, AlertIcon } from "@chakra-ui/react";

export const AdditionalVerificationStatus = () => {
  return (
    <Alert>
      <AlertIcon />
      We are reviewing your information, this process normally up to 2 hours (during business hours).
      In case of difficulties, it may last for up to 24 hours.
      This screen will automatically update once we have finished reviewing your information.
    </Alert>
  );
};
