import React, { Fragment, useEffect } from "react";
import {
  AccountPurpose, ContinueNextStep,
  EmailVerification,
  KYC,
  SelfCertification,
  TermsOfService,
  VerificationTemplate
} from "components";
import { useComplianceState, useCustomerVerification } from "hooks";
import { ComplianceActionStages } from "../../../../../shared/src/types/compliance";

export const Level1VerificationPage = () => {
  const { verificationState } = useCustomerVerification();
  const { actions, level1Completed } = useComplianceState();

  const currentStage = verificationState.currentStage;
  const { autoSelectCustomerStage } = useCustomerVerification();

  useEffect(() => {
    autoSelectCustomerStage();
  }, []);

  return (
    <VerificationTemplate
      pageName="Level 1 Verification"
      verificationStages={[
        { key: 1, title: 'Terms of service', status: actions.termsAccepted.status, item: ComplianceActionStages.termsAccepted },
        { key: 2, title: 'Email verification', status: actions.emailVerification.status, item: ComplianceActionStages.emailVerification },
        { key: 3, title: 'Self certification', status: actions.selfCertification.status, item: ComplianceActionStages.selfCertification },
        { key: 4, title: 'Account purpose', status: actions.t0Form.status, item: ComplianceActionStages.t0Form },
        { key: 5, title: 'KYC verification', status: actions.kycSumsub.status, item: ComplianceActionStages.kycSumsub }
      ]}
      breadcrumb={[
        { title: 'Verification', current: false, to: '/customer/verification' },
        { title: 'Level 1', current: true, to: '/customer/verification/level-1' },
      ]}>

      {!level1Completed ? (
        <Fragment>
          {currentStage === ComplianceActionStages.termsAccepted && <TermsOfService />}
          {currentStage === ComplianceActionStages.emailVerification && <EmailVerification />}
          {currentStage === ComplianceActionStages.selfCertification && <SelfCertification />}
          {currentStage === ComplianceActionStages.t0Form && <AccountPurpose />}
          {currentStage === ComplianceActionStages.kycSumsub && <KYC />}
        </Fragment>
      ) : (
        <ContinueNextStep successMessage="Level 1 verification completed!" redirectToTradeScreen />
      )}
    </VerificationTemplate>
  );
};
