import React from "react";
import { Helmet } from "react-helmet";
import {
  Box,
  Container,
  Heading,
  Stack,
  Image,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";

import { PublicPageTemplate } from "../PublicPageTemplate/PublicPageTemplate";
import { constants } from "../../config/constants";
import bannerImage from "../../assets/images/why-gib-banner.png";

export const GibraltarMigrationPage = () => {
  return (
    <PublicPageTemplate>
      <Helmet>
        <title>LondonLink is moving all trading activity to Gibraltar</title>
        <meta name="description" content="" />
      </Helmet>

      <Container maxWidth="container.xl">
        <Box as="article">
          <Stack spacing={10}>
            <Box as="section">
              <Stack spacing={8}>
                <Box textAlign="center">
                  <Stack spacing={4}>
                    <Heading as="h1" size="xl" mb={3}>
                      LondonLink has moved all trading<Box as="br" display={{ base: "none", md: "block" }} /> activity to Gibraltar
                    </Heading>
                    <Image src={bannerImage} alt="" role="presentation" margin="0 auto" />
                  </Stack>
                </Box>

                <Stack spacing={4}>
                  <p>On Monday the 24th of January we announced  that LondonLink will be moving our trading products & services from the United Kingdom to Gibraltar <span role="img" aria-label="Party emoji">🥳</span>. This means that you, as a LondonLink customer can continue on your crypto journey with us while also having access to an exciting range of new features, with more on the way.</p>
                  <p>In case you didn&apos;t know, the LondonLink team has been brokering Bitcoin from as far back as November 2013 making us one of the most highly experienced Crypto teams on planet earth. <span role="img" aria-label="Earth emoji">🌎</span></p>
                  <p>Our journey has now brought us to Gibraltar where the senior management have big plans for the future of the business and most importantly, our customers!</p>
                </Stack>
              </Stack>
            </Box>

            <Box as="section">
              <Stack spacing={4}>
                <Heading as="h2" size="md">Why are we moving trading to Gibraltar?</Heading>
                <p>In no particular order, here are the primary reasons:</p>
                <UnorderedList pl="40px">
                  <ListItem mb={4}>
                    <strong>Supportive Government:</strong> The Government of Gibraltar is supportive of the Crypto / Virtual Asset Industry as a whole and would like to attract the best Crypto businesses to the jurisdiction.
                  </ListItem>
                  <ListItem mb={4}>
                    <strong>Innovative Financial Services Regulator:</strong> The GFSC (Gibraltar Financial Services Commission) was one of the first in the world to develop a world leading Crypto compliance framework and now has a proven track record of ensuring great outcomes for consumers and businesses alike.
                  </ListItem>
                  <ListItem mb={4}>
                    <strong>Familiarity:</strong> LondonLink originated in the United Kingdom and Gibraltar (a British Overseas Territory) has a very similar legal framework as well as English being the primary language and of course uses Great British Pounds as its currency!
                  </ListItem>
                  <ListItem mb={4}>
                    <strong>Market Access:</strong> Gibraltar based businesses are able to serve customers in almost any market including the UK and EU where most of LondonLink’s current customers are based.
                  </ListItem>
                  <ListItem mb={4}>
                    <strong>Pool of Talent:</strong> Gibraltar is a well established financial services hub which attracts the best talent for companies like ours.
                  </ListItem>
                </UnorderedList>
              </Stack>
            </Box>

            <Box as="section">
              <Stack spacing={4}>
                <Heading as="h2" size="md">Key Dates</Heading>
                <Box>
                  <Heading fontWeight={600} as="h3" size="sm">Monday the 24th of January</Heading>
                  <p>Registration was disabled for new customers</p>
                  <p>Existing customers could continue trading with LondonLink OTC Limited</p>
                  <p>Existing customers could access and onboard with LondonLink (GI) Limited</p>
                </Box>

                <Box>
                  <Heading fontWeight={600} as="h3" size="sm">Friday the 4th of February</Heading>
                  <p>LondonLink OTC Limited stopped all trading activities</p>
                </Box>

                <Box>
                  <Heading fontWeight={600} as="h3" size="sm">Monday the 7th of February</Heading>
                  <p>LondonLink (GI) Limited has enabled trading for new customers and customers previously registered with LondonLink OTC Limited.</p>
                </Box>
              </Stack>
            </Box>

            <Box as="section">
              <Stack spacing={6}>
                <Heading as="h2" size="md">Regulatory Permissions</Heading>
                <Box>
                  <Heading mb={2} fontWeight={600} as="h3" size="sm">Do you have regulatory permission to carry out the same type of trading in Gibraltar?</Heading>
                  <p>Yes! LondonLink (GI) Limited has permission to carry out non-custodial exchange services.</p>
                  <p>You can see this on the GFSC’s website <a target="_blank" rel="nofollow noreferrer" href="https://www.fsc.gi/regulated-entity/londonlink-gi-limited-27980">here</a>.</p>
                </Box>

                <Box>
                  <Heading mb={2} fontWeight={600} as="h3" size="sm">Do you have permission to carry out any additional services as compared to the UK?</Heading>
                  <p>Yes! In addition to Bitcoin  and Pound Sterling we now have permission to trade in Euro, Ethereum, Ripple, Litecoin, Tether, USDCircle and more!</p>
                </Box>
              </Stack>
            </Box>

            <Box as="section">
              <Stack spacing={6}>
                <Heading as="h2" size="md">The Legal Bit</Heading>
                <p>If you are a LondonLink OTC Limited customer (registered with LondonLink prior to Monday 24th January 2022) your account has been transferred to LondonLink (GI) Limited.</p>

                <Box>
                  <Heading mb={2} fontWeight={600} as="h3" size="sm">When did this transfer occur?</Heading>
                  <p>This transfer happened on the 5th of February 2022.</p>
                </Box>

                <Box>
                  <Heading mb={2} fontWeight={600} as="h3" size="sm">What does this mean for me as a LondonLink OTC Limited customer?</Heading>
                  <p>It means that any future contracts and policies will be with LondonLink (GI) Limited.</p>
                </Box>

                <Box>
                  <Heading mb={2} fontWeight={600} as="h3" size="sm">Do I need to do anything?</Heading>
                  <p>Not much! If you’d like to continue to access our LondonLink’s services remotely as you always have. All you need to do is <a href={`${constants.sites.gib}/login`}>login</a> to your account, agree to the terms and conditions and acknowledge you have read and understood the policies.</p>
                </Box>

                <Box>
                  <Heading mb={2} fontWeight={600} as="h3" size="sm">Will my trading experience change?</Heading>
                  <p>Not really, Gibraltar&apos;s regulatory framework is very similar to the UK and the process will feel very familiar for existing LondonLink customers.</p>
                </Box>
              </Stack>
            </Box>
          </Stack>

          <Text mt={6} fontSize="sm" fontStyle="italic">This page was last updated on 4th February 2022</Text>
        </Box>
      </Container>
    </PublicPageTemplate>
  );
};
