import { useEffect, useState } from "react";
import {
  CustomerFormsModel,
  CustomerFormsModelState
} from "../../../shared/src/models/customer/CustomerFormsModel";

const customerFormsModel = new CustomerFormsModel();

export function useCustomerForms() {
  const [formsState, setFormsState] = useState<CustomerFormsModelState>(customerFormsModel.getFormsState());

  // This fires when model state changes
  function onListen() {
    setFormsState(customerFormsModel.getFormsState());
  }

  useEffect(() => {
    customerFormsModel.addListener(onListen);

    return function cleanup() {
      customerFormsModel.removeListener(onListen);
    };
  }, []);

  function submitForm(params: Record<string, any>) {
    return customerFormsModel.submitForm(params);
  }

  function submitCorpoForm(params: Record<string, any>) {
    return customerFormsModel.submitCorpoForm(params);
  }

  function resetFormsState() {
    customerFormsModel.resetFormsState();
  }

  return {
    formsState,
    submitForm,
    resetFormsState,
    submitCorpoForm
  };
}
