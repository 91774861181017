import React from "react";
import { Grid } from "@chakra-ui/react";
import {
  LoggedInDefaultTemplate,
  PollCustomerProfile,
  VerificationTier
} from "components";
import { useComplianceState } from "hooks";

export const VerificationPage = () => {
  const {
    level2,
    level1Completed,
    level2Completed,
    actions
  } = useComplianceState();

  return (
    <LoggedInDefaultTemplate pageName="Verification">
      <PollCustomerProfile interval={2500} />

      <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)' }} gap={6}>
        <VerificationTier
          name="Level 1"
          path="level-1"
          completionTime="5 mins"
          reviewTime="3 mins"
          completed={level1Completed}
          active={!level1Completed}
          optional={false}
          mandatory={true}
          requirements={[
            { key: 1, title: 'Terms of service', status: actions.termsAccepted.status },
            { key: 2, title: 'Email verification', status: actions.emailVerification.status },
            { key: 3, title: 'Self certification', status: actions.selfCertification.status },
            { key: 4, title: 'Account purpose', status: actions.t0Form.status },
            { key: 5, title: 'KYC verification', status: actions.kycSumsub.status }
          ]}
        />

        <VerificationTier
          name="Level 2"
          path="level-2"
          completionTime="10 mins"
          reviewTime="up to 2 hours (during business hours)"
          completed={level2Completed}
          active={!level2Completed && level1Completed}
          optional={level2 === 'optional'}
          mandatory={level2 === 'todo'}
          requirements={[
            { key: 1, title: 'Source of funds', status: actions.t1Form.status },
            { key: 2, title: 'Additional verification', status: actions.level2AdditionalVerification.status },
          ]}
        />
      </Grid>
    </LoggedInDefaultTemplate>
  );
};
