import React, { FormEvent, useState } from "react";
import { nanoid } from 'nanoid';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Stack,
  Text,
  Input,
  Textarea,
  HStack,
  useMediaQuery, Select
} from "@chakra-ui/react";
import { FieldProps } from "formik";
import { RiDeleteBin2Line } from "react-icons/all";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { NonIndividualFormValuesDirector } from "../NonIndividualForm";
import countries from "../../../../../../shared/src/data/countries.json";

interface Props {
  field: FieldProps['field'];
  form: FieldProps['form'];
  label: string;
  isRequired?: boolean;
  helpText?: string;
}

export const FormFieldDirectors = ({ field, form, label, isRequired, helpText }: Props) => {
  const [isLargerThan992] = useMediaQuery("(min-width: 992px)");

  const template = () => {
    return {
      id: nanoid(),
      name: "",
      dob: "",
      nationality: "",
      residentialAddress: ""
    };
  };

  interface DirectorsFieldsWithId extends NonIndividualFormValuesDirector {
    id: string
  }

  const [values, setValues] = useState<DirectorsFieldsWithId[]>([template()]);

  const handleChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>, id: string) => {
    const selectedItem = values[values.findIndex((item) => item.id === id)];
    const formField = event.currentTarget.name;
    const value = event.currentTarget.value;
    const newValue = { ...selectedItem, [formField]: value };
    const updatedItems = values.map((item) => (item.id === id ? newValue : item)) as any;
    setValues(updatedItems);
  };

  const setValuesInForm = (valuesForForm: DirectorsFieldsWithId[]) => {
    const valuesWithoutId = valuesForForm.map((val) => {
      return {
        name: val.name,
        dob: val.dob,
        nationality: val.nationality,
        residentialAddress: val.residentialAddress
      };
    });

    form.setFieldValue(field.name, valuesWithoutId);
  };

  const handleBlur = () => {
    setValuesInForm(values);
  };

  const addDirector = () => {
    const updatedItems = [...values, { ...template() }];
    setValues(updatedItems);
    setValuesInForm(updatedItems);
  };

  const removeDirector = (id: string) => {
    const updatedItems = values.filter((item) => item.id !== id);
    setValues(updatedItems);
    setValuesInForm(updatedItems);
  };

  const hasError = form.errors.hasOwnProperty(field.name);

  return (
    <FormControl isInvalid={hasError} isRequired={isRequired}>
      <FormLabel fontSize="sm" >
        {label}
      </FormLabel>

      <FormHelperText mb={2}>{helpText}</FormHelperText>

      {values.map((value, index) => {
        const id = value.id;

        return (
          <Stack direction={{ base: "column", lg: "row" }} key={id} spacing={4} mt={4} alignItems="flex-start">
            <FormControl as="fieldset" isInvalid={hasError} isRequired={isRequired}>
              <FormLabel fontSize="sm" as="legend">Name</FormLabel>
              <Input
                name="name"
                size="sm"
                type="text"
                onChange={(event) => handleChange(event, id)}
                onBlur={handleBlur}
              />
            </FormControl>

            <FormControl as="fieldset" isInvalid={hasError} isRequired={isRequired}>
              <FormLabel fontSize="sm" as="legend">Date of birth</FormLabel>
              <InputMask
                mask="99/99/9999"
                onChange={(event) => handleChange(event, id)}
                onBlur={handleBlur}>
                {() => <Input name="dob" size="sm" placeholder="dd/mm/yy" />}
              </InputMask>
            </FormControl>

            <FormControl as="fieldset" isInvalid={hasError} isRequired={isRequired}>
              <FormLabel fontSize="sm" as="legend">Nationality</FormLabel>
              <Select
                placeholder="Select nationality"
                name="nationality"
                size="sm"
                onChange={(event) => handleChange(event, id)}
                onBlur={handleBlur}>
                {countries.sort((a, b) => {
                  // Put British top of list, then sort alphabetically
                  if (a.nationality === 'British, UK') return -1;
                  return a.nationality.localeCompare(b.nationality);
                }).map((country) =>
                  <option key={country.num_code} value={country.nationality}>
                    {country.nationality}
                  </option>
                )}
              </Select>
            </FormControl>

            <FormControl as="fieldset" isInvalid={hasError} isRequired={isRequired}>
              <FormLabel fontSize="sm" as="legend">Residential address</FormLabel>
              <Textarea
                name="residentialAddress"
                size="sm"
                type="text"
                onChange={(event) => handleChange(event, id)}
                onBlur={handleBlur}
              />
            </FormControl>

            <Button
              disabled={index === 0}
              style={{ marginBottom: 15 }}
              title="Remove"
              alignSelf={{ base: "flex-start", lg: "center" }}
              onClick={() => removeDirector(id)}
              size="sm"
              type="button"
              variant="outline"
              colorScheme="red"
              aria-label="Remove">
              <HStack spacing={2}>
                <RiDeleteBin2Line size="18px" />
                {!isLargerThan992 && <Text>Remove</Text>}
              </HStack>
            </Button>
          </Stack>
        );
      })}

      <FormErrorMessage fontSize="xs">{hasError && "Please fill out all the fields"}</FormErrorMessage>

      <Button
        isFullWidth
        colorScheme="black"
        variant="outline"
        type="button"
        my={4}
        size="sm"
        onClick={addDirector}>
        Add additional director
      </Button>

      <Alert fontSize="sm">
        <AlertIcon />
        <AlertDescription>
          All directors and shareholders with more than 25% of the companies shares (UBO&#39;s) must <Link to="/register" target="_blank" rel="noopener noreferrer">create a LondonLink account</Link> and complete Level 1 Verification
        </AlertDescription>
      </Alert>

      <Input {...field} size="sm" type="hidden" />

    </FormControl>
  );
};
