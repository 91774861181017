import React, { Fragment } from "react";
import { Box, Button, Container, Heading, Link, SimpleGrid, Stack, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { ContactForm, ContactMethod, CoverBanner, PublicPageTemplate } from "components";

import contactBanner from '../../../assets/images/contact-banner.jpg';
import iconPhone from '../../../assets/icons/phone.svg';
import iconMail from '../../../assets/icons/email.svg';
import iconChat from '../../../assets/icons/chat.svg';
import iconTelegram from '../../../assets/icons/telegram_logo.svg';
import iconWhatsapp from '../../../assets/icons/whatsapp.svg';
import { constants } from "../../../config/constants";


export const ContactPage = () => {
  return (
    <PublicPageTemplate>
      <Helmet>
        <title>Contact Us</title>
        <meta name="description" content="To speak to one of our friendly team please use the enquiry form below,
        or contact us via live chat, email, on the telephone or by WhatsApp." />
      </Helmet>

      <CoverBanner backgroundImage={contactBanner}>
        <Fragment>
          <Heading as="h1" size="lg" margin="0 auto" maxWidth={800}>
            Contact Us
          </Heading>

          <Text as="h2" size="md" maxWidth={640} margin="15px auto" fontWeight={400}>
            To speak to one of our friendly team please use the enquiry form below, or contact us via live chat,
            email, on the telephone or by WhatsApp.
          </Text>
        </Fragment>
      </CoverBanner>

      <Container maxW="container.xl">
        <SimpleGrid templateColumns={{ base: '1fr', lg: '0.4fr 0.6fr' }} spacing="50px" mt={10}>
          <Box as="section">
            <Heading size="md" mb={3}>We are available</Heading>
            <Stack spacing={2}>
              <Text fontSize="lg">Monday - Thursday, 9am - 1am</Text>
              <Text fontSize="lg">Friday - Sunday, 10am - 6pm</Text>
              <Text fontSize="lg">Bank holidays: Open - usual hours</Text>
            </Stack>

            <Stack spacing={5} mt={10} alignItems="flex-start">
              {constants.businessEntity === "uk" && (
                <ContactMethod title="Phone" icon={iconPhone}>
                  <Link alignSelf="center" fontSize="lg" href="tel:+442079711013">
                    0207 9711013
                  </Link>
                </ContactMethod>
              )}

              <ContactMethod title="Email" icon={iconMail}>
                <Link alignSelf="center" fontSize="lg" href={`mailto:hello@${constants.domain()}`}>
                  hello@{constants.domain()}
                </Link>
              </ContactMethod>

              <ContactMethod title="Live Chat" icon={iconChat}>
                <Button
                  minWidth={210}
                  fontSize="lg"
                  colorScheme="blue"
                  href="https://tawk.to/chat/5c2cd7517a79fc1bddf2faeb/default"
                  variant="outline"
                  size="md">
                  Start Live Chat
                </Button>
              </ContactMethod>

              <ContactMethod title="Telegram" icon={iconTelegram}>
                <Button
                  minWidth={210}
                  fontSize="lg"
                  colorScheme="blue"
                  size="md"
                  variant="outline"
                  href="https://t.me/LondonLinkOTC">
                  Start Telegram Chat
                </Button>
              </ContactMethod>

              <ContactMethod title="WhatsApp" icon={iconWhatsapp}>
                <Button minWidth={210} fontSize="lg" isDisabled size="md" variant="outline">
                  Coming Soon
                </Button>
              </ContactMethod>
            </Stack>
          </Box>

          <Box as="section">
            <Text fontSize="md" mb={10}>
              Please use the form below outside of these hours, we will get back to you as soon as possible:
            </Text>

            <ContactForm />
          </Box>
        </SimpleGrid>
      </Container>
    </PublicPageTemplate>
  );
};
