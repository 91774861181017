import React, { Fragment } from "react";
import { Alert, AlertIcon, Heading, Text } from "@chakra-ui/react";
import { useComplianceState } from "hooks";
import { ContinueNextStep, SelfCertificationForm } from "components";

export const SelfCertification = () => {
  const complianceState = useComplianceState();

  return (
    <Fragment>
      <Heading size="md" as="h2" mb={3}>
        Self certification
      </Heading>

      {complianceState.actions.selfCertification.status === 'completed' ? (
        <ContinueNextStep successMessage="Your details have been successfully submitted." />
      ) : (
        <Fragment>
          <Alert status="info" mb={5} alignSelf="flex-start" width="auto" size="sm">
            <AlertIcon />
            <Text fontSize="sm">
              Please note, we will not provide services to citizens of the USA, Canada or Japan
            </Text>
          </Alert>

          <Text fontSize="md" mb={4}>Please review and confirm the certifications below</Text>
          <SelfCertificationForm />

        </Fragment>
      )}
    </Fragment>
  );
};
