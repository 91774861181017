import React from "react";
import { useCustomerProfile, useInterval } from "hooks";

interface Props {
  interval: number;
}

export const PollCustomerProfile = ({ interval }: Props) => {
  const { fetchProfile } = useCustomerProfile();

  useInterval(() => {
    fetchProfile();
  }, interval);

  return null;
};
