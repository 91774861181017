const { SmartWebSocketConnection } = require('./SmartWebsocketConnection');

const LOG_ENABLED = false;
const LOG = (...args) => {
  if (LOG_ENABLED) {
    console.log(...args);
  }
};

exports.BaseWebSocketClientApplication = class BaseWebSocketClientApplication {
  constructor(config) {
    // check config
    if (!config) {
      throw new Error('BaseWebSocketClientApplication - config must be present');
    }
    if (typeof config.websocketUrl !== 'string') {
      throw new Error('SmartWebSocketConnection - config.websocketUrl must be string');
    }
    if (typeof config.WebsocketClass !== 'function') {
      throw new Error('SmartWebSocketConnection - config.WebsocketClass must be given');
    }

    // get config
    this.websocketUrl = config.websocketUrl;
    this.WebsocketClass = config.WebsocketClass;

    // init internals
    this.connectionStatusChangedListeners = [];
    this.errors = {
      cnt: 0,
      lastError: null
    };

    // prepare action-packs structures
    this.actionPackId = 1;
    this.actionPacks = {};

    // dispatch server events to managers via channelId
    this.channels = {};

    // start websocket
    this.wsConnected = false;
    this.smartWebSocketConnection = new SmartWebSocketConnection({
      url: this.websocketUrl,
      WebsocketClass: this.WebsocketClass
    });
    this.smartWebSocketConnection.setOnDataCallback((socket, msg) => {
      this._onData(socket, msg);
    });

    this.smartWebSocketConnection.setOnConnectionStatusChanged((socket, msg) => {
      this.wsConnected = socket.isConnected();
      this.notifyConnectionStatusChanged();
    });

    this.smartWebSocketConnection.setOnErrorCallback((socket, err) => {
      this.errors.cnt++;
      this.errors.lastError = err;
    });
  }

  addConnectionStatusChangedListener(cb) {
    this.connectionStatusChangedListeners.push(cb);
    return () => {
      this.removeConnectionStatusChangedListener(cb);
    };
  }

  removeConnectionStatusChangedListener(listenerToRemove) {
    this.connectionStatusChangedListeners = this.connectionStatusChangedListeners.filter((listener) => listenerToRemove !== listener);
  }

  notifyConnectionStatusChanged() {
    this.connectionStatusChangedListeners.forEach((oneListener) => oneListener());
  }

  isSocketConnected() {
    return this.wsConnected;
  }

  startSocket() {
    this.smartWebSocketConnection.start();
  }

  stopSocket() {
    this.smartWebSocketConnection.stop();
  }

  sendActions(actions, callback = null) {
    if (this.smartWebSocketConnection.isConnected()) {
      this.actionPacks[this.actionPackId] = {callback};
      const actionPack = JSON.stringify({actionPackId:this.actionPackId, actions});
      this.actionPackId++;
      this.smartWebSocketConnection.send(actionPack);
    }
  }

  _onData(socket, msg) {
    // parse or fail
    let json;
    try {
      json = JSON.parse(msg.data);
    } catch (error) {
      json = null;
    }

    if (json) {
      // if msg is valid json, play with it
      if (json.actionPackId != null) {
        // check if we still wait for this msg
        if (this.actionPacks[json.actionPackId] != null) {
          // find the actionPack, then call the callback and delete actionPack from collection
          const actionPack = this.actionPacks[json.actionPackId];
          if (actionPack.callback) {
            actionPack.callback(null, json.actionsResults);
          }
          delete this.actionPacks[json.actionPackId];
        } else {
          LOG('received non-existent action-pack:', json.actionPackId);
        }

      } else {
        // We got data, which is not response for our action-pack sent before.
        // It's event sent by server itself.

        if ((json.channelId != null) && (this.channels[json.channelId] != null)) {
          // Channel recognized - pass to related local manager.
          this.channels[json.channelId].listener.onDataOnChannel(json.event);

        } else {
          // Unknown channel - there is no registered manager listening on it.
          LOG('ERROR! Unknown channel id for server event', json);
        }
      }

    } else {
      // We got message, but json decode failed.
      LOG('got non-json data:', msg.data);
    }
  }

  registerChannel(channelId, listener) {
    this.channels[channelId] = {listener};
  }
};
