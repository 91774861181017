import React, { ReactNode } from 'react';
import { RiCheckboxCircleFill, RiCheckboxBlankCircleLine } from 'react-icons/ri';
import { Box, Text } from '@chakra-ui/react';

interface Props {
  isValid?: boolean;
  title?: string;
  children?: ReactNode;
}

export const Requirement = ({ isValid = false, title, children }: Props) => {
  return (
    <Box display="flex" alignItems="center">
      {isValid ? (
        <RiCheckboxCircleFill color="#38a169" size={18} />
      ) : (
        <RiCheckboxBlankCircleLine color="#dadada" size={18} />
      )}
      <Text ml={3}>{title || children}</Text>
    </Box>
  );
};
