import jwtDecode, { JwtPayload } from "jwt-decode";
import { AbstractActionModel } from "../abstract/AbstractActionModel";
import { DataStatuses, ErrorResponse } from "../../types/common";
import { isJwtExpired } from "../../libs/isJwtExpired";
import { AxiosRequestConfig } from "axios";
import { customerApiClient } from "../../../../fe-main/src/libs/CustomerLondonLinkApiClient";
import { CustomerProfileModel } from "./CustomerProfileModel";
import { CustomerVerificationModel } from "./CustomerVerficationModel";

export interface LoginRequest {
  email: string;
  password: string;
}

export interface NewPasswordRequest {
  email: string;
}

export interface NewPasswordRequest {
  email: string;
}

export interface ResetPasswordRequest {
  email: string;
  code: string;
  newPassword: string;
}

export interface RegisterRequest {
  email: string;
  password: string;
  formData: {
    marketingMessagesAgreementGIB: boolean;
    generalTermsAndConditionsAgreementGIB: boolean;
    tradingTermsAndConditionsAgreementGIB: boolean;
    acceptableUsePolicyAgreementGIB: boolean;
    privacyPolicyAgreementGIB: boolean;
    riskDisclaimerAgreementGIB: boolean;
    // Obsolete UK fields
    marketingMessagesAgreement?: boolean;
    generalTermsAndConditionsAgreement?: boolean;
    tradingTermsAndConditionsAgreement?: boolean;
    acceptableUsePolicyAgreement?: boolean;
    privacyPolicyAgreement?: boolean;
    riskDisclaimerAgreement?: boolean;
  };
}

export type CustomerAuthRequestNames = "loginRequest" | "registerRequest" | "newPasswordRequest" | "resetPassword";

export interface CustomerAuthModelState {
  authToken: string | null;
  authenticated: boolean;
  authTokenExpiry: number | null;
  loginRequest: {
    dataStatus: DataStatuses;
    dataError: ErrorResponse | null;
  },
  registerRequest: {
    dataStatus: DataStatuses;
    dataError: ErrorResponse | null;
  },
  newPasswordRequest: {
    dataStatus: DataStatuses;
    dataError: ErrorResponse | null;
  },
  resetPassword: {
    dataStatus: DataStatuses;
    dataError: ErrorResponse | null;
  }
}

const xhrClient = customerApiClient.getXhrClient();

export class CustomerAuthModel extends AbstractActionModel {
  static localStorage = window.localStorage;

  static state: CustomerAuthModelState = {
    authToken: CustomerAuthModel.localStorage.getItem("authToken") || null,
    authenticated: CustomerAuthModel.isAuthenticated(),
    authTokenExpiry: CustomerAuthModel.getTokenExpiry(CustomerAuthModel.localStorage.getItem("authToken") || "") || null,
    loginRequest: { dataStatus: DataStatuses.Uninitialized, dataError: null },
    registerRequest: { dataStatus: DataStatuses.Uninitialized, dataError: null },
    newPasswordRequest: { dataStatus: DataStatuses.Uninitialized, dataError: null },
    resetPassword: { dataStatus: DataStatuses.Uninitialized, dataError: null },
  }

  constructor() {
    super();

    // Check every second for expired token
    setInterval(() => {
      const jwtExpiry = this.getCustomerAuthState().authTokenExpiry;
      if (jwtExpiry && jwtExpiry < Date.now() / 1000) {
        this.logout();
      }
    }, 1000);

    // Set up requests to use authToken if one exists
    xhrClient.axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
      const myConfig: AxiosRequestConfig = config;

      // Set Bearer header if auth token exists
      const authToken = CustomerAuthModel.state.authToken;

      if (authToken && myConfig.headers) {
        myConfig.headers.Authorization = `Bearer ${authToken}`;

        const jwtToken = myConfig.headers.Authorization.replace("Bearer ", "");

        // Check JWT token is still valid before making authenticated request
        if (isJwtExpired(jwtToken)) {
          this.logout();
        }
      }

      return myConfig;
    }, (error) => {
      // Do something with request error
      return Promise.reject(error);
    });
  }

  private updateState(newState: Partial<CustomerAuthModelState>) {
    CustomerAuthModel.state = { ...CustomerAuthModel.state, ...newState };
    this.notify();
  }

  static getTokenExpiry(token: string) {
    if (!token) {
      return null;
    }

    const decoded: JwtPayload = jwtDecode(token);
    return decoded.exp;
  }

  static isAuthenticated() {
    if (this.localStorage.getItem("authToken") !== undefined && this.localStorage.getItem("authToken") !== null) {
      if (!isJwtExpired(this.localStorage.getItem("authToken") as string)) {
        return true;
      }
    }

    return false;
  }

  /*
    Public methods
  */
  // Login
  public async sendLogin(params: LoginRequest) {
    this.updateState({ loginRequest: { dataError: null } as CustomerAuthModelState['loginRequest'] }); // Reset error on new fetch

    const genericErrorMessage = "There was an unexpected error logging in. Please try again.";

    if (CustomerAuthModel.state.loginRequest.dataStatus !== "loading") {
      try {
        this.updateState({ loginRequest: { dataStatus: DataStatuses.Loading, dataError: null } });

        const res = await xhrClient.post("/customer/login", {
          email: params.email,
          password: params.password
        });

        if (res.data && res.data.status.toLowerCase() === "ok") {
          CustomerAuthModel.localStorage.setItem("authToken", res.data.token);

          this.updateState({
            authToken: res.data.token,
            authenticated: true,
            authTokenExpiry: CustomerAuthModel.getTokenExpiry(res.data.token),
            loginRequest: {
              dataStatus: DataStatuses.Ok,
              dataError: null
            }
          });
        } else {
          this.updateState({ loginRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
        }
      } catch (error: any) {
        let errorMessage = genericErrorMessage;

        // If server gave us error response, handle it here
        if (error.response?.data?.status) {
          if (error.response.data.status === "customerAuthFailed") {
            errorMessage = "Incorrect username or password."
          }
        }

        this.updateState({ loginRequest: { dataStatus: DataStatuses.Error, dataError: { stack: error, message: errorMessage } } });
      }
    }
  }

  // Register
  public async register(params: RegisterRequest) {
    this.updateState({ registerRequest: { dataError: null } as CustomerAuthModelState['registerRequest'] }); // Reset error on new fetch

    const genericErrorMessage = "There was an unexpected error registering. Please try again.";

    if (CustomerAuthModel.state.registerRequest.dataStatus !== DataStatuses.Loading) {
      try {
        this.updateState({ registerRequest: { dataStatus: DataStatuses.Loading, dataError: null } });

        const res = await xhrClient.post("/customer/register", {
          email: params.email,
          password: params.password,
          formData: params.formData
        });

        if (res.data && res.data.status.toLowerCase() === "ok") {
          CustomerAuthModel.localStorage.setItem("authToken", res.data.token);

          this.updateState({
            authToken: res.data.token,
            authenticated: true,
            authTokenExpiry: CustomerAuthModel.getTokenExpiry(res.data.token),
            registerRequest: {
              dataStatus: DataStatuses.Ok,
              dataError: null
            }
          });
        } else {
          this.updateState({ registerRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
        }
      } catch (error: any) {
        let errorMessage = genericErrorMessage;

        // If server gave us error response, handle it here
        if (error.response?.data?.status === "userAlreadyExists") {
          errorMessage = "This email address is already in use.";
        }

        this.updateState({ registerRequest: { dataStatus: DataStatuses.Error, dataError: { stack: error, message: errorMessage } } });
      }
    }
  }

  // Request password reset
  public async requestNewPassword(params: NewPasswordRequest) {
    this.updateState({ newPasswordRequest: { dataError: null } as CustomerAuthModelState['newPasswordRequest'] }); // Reset error on new fetch

    const genericErrorMessage = "There was an unexpected error requesting a new password. Please try again.";

    if (CustomerAuthModel.state.newPasswordRequest.dataStatus !== DataStatuses.Loading) {
      try {
        this.updateState({ newPasswordRequest: { dataStatus: DataStatuses.Loading, dataError: null } });

        const res = await xhrClient.post("/customer/forgotPassword", {
          email: params.email,
        });

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({
            newPasswordRequest: {
              dataStatus: DataStatuses.Ok,
              dataError: null
            }
          });
        } else {
          this.updateState({ newPasswordRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
        }
      } catch (error: any) {
        let errorMessage = genericErrorMessage;

        // If server gave us error response, handle it here
        if (error.response?.data?.status) {
          if (error.response.data.status === "customerNotFound") {
            errorMessage = "There is not an account associated to this email address."
          }
        }

        this.updateState({ newPasswordRequest: { dataStatus: DataStatuses.Error, dataError: { stack: error, message: errorMessage } } });
      }
    }
  }

  // Request password reset
  public async resetPassword(params: ResetPasswordRequest) {
    this.updateState({ resetPassword: { dataError: null } as CustomerAuthModelState['resetPassword'] }); // Reset error on new fetch

    const genericErrorMessage = "There was an unexpected error requesting a new password. Please try again.";

    if (CustomerAuthModel.state.resetPassword.dataStatus !== "loading") {
      try {
        this.updateState({ resetPassword: { dataStatus: DataStatuses.Loading, dataError: null } });

        const res = await xhrClient.post("/customer/resetPassword", {
          email: params.email,
          code: params.code,
          newPassword: params.newPassword,
        });

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({
            resetPassword: {
              dataStatus: DataStatuses.Ok,
              dataError: null
            }
          });
        } else {
          this.updateState({ resetPassword: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
        }
      } catch (error: any) {
        let errorMessage = genericErrorMessage;

        // If server gave us error response, handle it here
        if (error.response?.data?.status) {
          if (error.response.data.status === "invalidCode") {
            errorMessage = "Password reset code invalid or expired."
          }
        }

        this.updateState({ resetPassword: { dataStatus: DataStatuses.Error, dataError: { stack: error, message: errorMessage } } });
      }
    }
  }

  public logout() {
    CustomerAuthModel.localStorage.removeItem("authToken");
    this.updateState({ authToken: null, authenticated: false });

    const customerProfileModel = new CustomerProfileModel();
    const customerVerificationModel = new CustomerVerificationModel(customerProfileModel);

    // Clear data on logout
    customerProfileModel.resetProfileState();
    customerVerificationModel.resetCustomerVerificationState();
  }

  public resetRequestState(request: CustomerAuthRequestNames) {
    this.updateState({ [request]: { dataStatus: DataStatuses.Uninitialized, dataError: null } });
  }

  public getCustomerAuthState() {
    return CustomerAuthModel.state;
  }
}
