import { useEffect, useState } from "react";
import { CustomerProfileModel, CustomerProfileModelState } from "../../../shared/src/models/customer/CustomerProfileModel";

export const customerProfileModel = new CustomerProfileModel();

export function useCustomerProfile() {
  const [profile, setProfile] = useState<CustomerProfileModelState>(customerProfileModel.getProfileState());

  // This fires when model state changes
  function onListen() {
    setProfile(customerProfileModel.getProfileState());
  }

  useEffect(() => {
    customerProfileModel.addListener(onListen);

    return function cleanup() {
      customerProfileModel.removeListener(onListen);
    };
  }, []);

  function fetchProfile() {
    return customerProfileModel.fetchProfile();
  }

  function resetProfileState() {
    return customerProfileModel.resetProfileState();
  }

  return {
    profile,
    fetchProfile,
    resetProfileState
  };
}
