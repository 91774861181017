import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
  Textarea
} from "@chakra-ui/react";
import { FieldProps } from "formik";

interface Props {
  field: FieldProps['field'];
  form: FieldProps['form'];
  label: string;
  isRequired?: boolean;
  type?: "text" | "textarea";
  helpText?: string;
}

export const FormFieldSimple = ({ field, form, label, isRequired, type = "text", helpText }: Props) => {
  const hasError = form.errors.hasOwnProperty(field.name);

  return (
    <FormControl isInvalid={hasError} isRequired={isRequired}>
      <FormLabel fontSize="sm" >
        {label}
      </FormLabel>

      <FormHelperText mb={2}>{helpText}</FormHelperText>

      {type === "text" && <Input {...field} size="sm" type="text" />}
      {type === "textarea" && <Textarea {...field} size="sm" />}

      <FormErrorMessage fontSize="xs">{form.errors[field.name]}</FormErrorMessage>
    </FormControl>
  );
};
