import React from "react";
import { Box, Container, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { PublicPageTemplate } from "../../PublicPageTemplate/PublicPageTemplate";
import { RegisterForm } from "../RegisterForm/RegisterForm";

export const RegisterPage = () => {
  return (
    <PublicPageTemplate>
      <Container>
        <Helmet>
          <title>Register</title>
        </Helmet>

        <Box maxW="600px">
          <Heading as="h1" size="lg" mb={5}>Create your LondonLink account</Heading>
          <RegisterForm />
          <Text color="gray.600">Already have an account? <Link to="/login">Login</Link></Text>
        </Box>
      </Container>
    </PublicPageTemplate>
  );
};
