import { useEffect, useState } from "react";
import { AbstractActionModel } from "../../../shared/src/models/abstract/AbstractActionModel";

/**
 * Data Store
 */
export class MigrationModalStore extends AbstractActionModel {
  private isOpen = false;

  public closeModal() {
    this.isOpen = false;
    this.notify("modalOpenClose");
  }

  public openModal() {
    this.isOpen = true;
    this.notify("modalOpenClose");
  }

  public isModalOpen() {
    return this.isOpen;
  }
}

const migrationModalStore = new MigrationModalStore();

/**
 * React Hook
 */
export const useMigrationModalStore = () => {
  const [isModalOpen, setIsModalOpen] = useState(migrationModalStore.isModalOpen());

  // This fires when model calls notify() function
  function onListen(stateTriggerId?: string) {
    if (stateTriggerId === "modalOpenClose") {
      setIsModalOpen(migrationModalStore.isModalOpen());
    }
  }

  useEffect(() => {
    migrationModalStore.addListener(onListen);

    return function cleanup() {
      migrationModalStore.removeListener(onListen);
    };
  }, []);

  function closeModal(): void {
    migrationModalStore.closeModal();
  }

  function openModal(): void {
    migrationModalStore.openModal();
  }

  return {
    isModalOpen: isModalOpen,
    closeModal: closeModal,
    openModal: openModal
  };
};
