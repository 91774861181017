import React, { Fragment } from "react";
import { Alert, AlertIcon, Heading, useMediaQuery } from "@chakra-ui/react";
import { ContinueNextStep, SourceOfFundsForm, Step, Steps } from "components";
import { useComplianceState, useSteps } from "hooks";

export const SourceOfFunds = () => {
  const { nextStep, prevStep, activeStep } = useSteps({ initialStep: 0 });
  const [isLargerThan767] = useMediaQuery('(min-width: 768px)');
  const complianceState = useComplianceState();

  return (
    <Fragment>
      <Heading size="md" as="h2" mb={3}>
        Source of funds
      </Heading>

      {complianceState.actions.t1Form.status !== 'completed' && (
        <Steps orientation={isLargerThan767 ? 'horizontal' : 'vertical'}>
          <Step active={activeStep === 0} done={activeStep > 0} number="1" title="Recurring income" />
          <Step active={activeStep === 1} done={activeStep > 1} number="2" title="Savings" />
          <Step active={activeStep === 2} done={activeStep > 2} number="3" title="One-off income/payments" />
          <Step active={activeStep === 3} done={activeStep > 3} number="4" title="Overall net worth" />
        </Steps>
      )}

      {complianceState.actions.t1Form.status === 'completed' ? (
        <ContinueNextStep successMessage="Your details have been successfully submitted." />
      ) : (
        <Fragment>
          <Alert status="info" my={{ base: 2, md: 6 }} fontSize="sm">
            <AlertIcon />
            Level 2 verification is required for higher trading limits.<br />
            We may also ask you to complete Level 2 verification to meet our regulatory obligations.
          </Alert>

          <SourceOfFundsForm steps={{ nextStep, prevStep, activeStep }} />
        </Fragment>
      )}
    </Fragment>
  );
};
