import React, { useEffect } from "react";
import * as yup from "yup";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useCustomerAuth } from "hooks";
import { useHistory } from "react-router-dom";

export interface LoginFormValues {
  email: string;
  password: string;
}

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Field is required'),
  password: yup.string()
    .required('Field is required')
});

const initialValues = {
  email: '',
  password: '',
};

export const LoginForm = () => {
  const history = useHistory();
  const { sendLogin, authState, resetRequestState } = useCustomerAuth();

  function handleSubmit(values: LoginFormValues) {
    sendLogin({ email: values.email, password: values.password });
  }

  const isLoading = authState.loginRequest.dataStatus === "loading";

  useEffect(() => {
    if (authState.authenticated) {
      history.push({ pathname: "/customer" });
    }
  }, [authState.authenticated]);

  // Reset request state when component is unmounted
  useEffect(() => {
    return function cleanup() {
      resetRequestState("loginRequest");
    };
  }, []);

  return (
    <Formik
      validateOnBlur={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: LoginFormValues) => handleSubmit(values)}>
      {() => (
        <Form id="login-form" style={{ maxWidth: 450 }}>
          <Stack spacing="6">
            <Field name="email">
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={!!(form.errors.email && form.touched.email)}>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input {...field} type="email" id="email" autoFocus />
                  <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            <Field name="password">
              {({ field, form }: FieldProps) => (
                <FormControl isInvalid={!!(form.errors.password && form.touched.password)}>
                  <FormLabel htmlFor="password">Password</FormLabel>
                  <Input {...field} type="password" id="password" />
                  <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                </FormControl>
              )}
            </Field>
          </Stack>

          {/* Handle Error */}
          {authState.loginRequest.dataStatus === "error" && (
            <Alert status="error" mt={5}>
              <AlertIcon />
              {authState.loginRequest.dataError?.message}
            </Alert>
          )}

          <Box textAlign="right">
            <Button
              mt={5}
              isLoading={isLoading}
              isDisabled={isLoading}
              loadingText="Submitting"
              colorScheme="brand"
              size="md"
              type="submit"
              id="submit">
              Sign in
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

