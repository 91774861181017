import React, { useEffect, useRef } from "react";
import { Field, FieldProps, Form, Formik } from "formik";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text,
  Textarea
} from "@chakra-ui/react";
import { Prompt } from "react-router-dom";
import * as yup from "yup";
import { useCustomerContactMessages } from "hooks";
import { constants } from "../../../config/constants";
import { DataStatuses } from "../../../../../shared/src/types/common";

type ContactFormValues = typeof initialValues;

const initialValues = {
  name: '',
  email: '',
  phone: '',
  message: ''
};

const validationSchema = yup.object().shape({
  name: yup.string().required('Field is required'),
  email: yup.string().email('Invalid email').required('Field is required'),
  message: yup.string().required('Field is required'),
});

export const ContactForm = () => {
  const formRef = useRef<HTMLFormElement>(null);
  const { message, sendMessage } = useCustomerContactMessages();

  const isLoading = message.dataStatus === DataStatuses.Loading;
  const isError = message.dataStatus === DataStatuses.Error;
  const isSuccess = message.dataStatus === DataStatuses.Ok;

  // Handle form request status changes
  useEffect(() => {
    if (isSuccess && formRef) {
      formRef.current?.reset();
    }
  }, [message.dataStatus]);

  function handleSubmit(values: ContactFormValues) {
    sendMessage(values);
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: ContactFormValues) => handleSubmit(values)}>
      {({ dirty }) => {
        return (
          <Form id="contact-form" ref={formRef}>
            <Prompt
              when={dirty}
              message="Leaving this page will discard unsaved changes."
            />

            {/* Form Fields */}
            <Stack spacing="6">
              <Field name="name">
                {({ field, form }: FieldProps) => (
                  <FormControl isRequired isInvalid={!!(form.errors.name && form.touched.name)}>
                    <FormLabel htmlFor="name">Name</FormLabel>
                    <Input {...field} type="text" id="name" />
                    <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="email">
                {({ field, form }: FieldProps) => (
                  <FormControl isRequired isInvalid={!!(form.errors.email && form.touched.email)}>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input {...field} type="email" id="email" />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="phone">
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={!!(form.errors.phone && form.touched.phone)}>
                    <FormLabel htmlFor="phone">
                      Phone
                      <Text ml={2} as="span" color="gray" fontSize="xs">(optional)</Text>
                    </FormLabel>
                    <Input {...field} type="text" id="phone" />
                    <FormErrorMessage>{form.errors.phone}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="message">
                {({ field, form }: FieldProps) => (
                  <FormControl isRequired isInvalid={!!(form.errors.message && form.touched.message)}>
                    <FormLabel htmlFor="message">Message</FormLabel>
                    <Textarea {...field} type="text" id="message" />
                    <FormErrorMessage>{form.errors.message}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            {/* Handle Feedback */}
            <Box mt={5}>
              {/* Success */}
              {isSuccess && (
                <Alert status="success" mt={5}>
                  <AlertIcon />
                  Thank you. We have received your enquiry and will be in touch soon.
                </Alert>
              )}

              {/* Error */}
              {isError && (
                <Alert status="error" mt={5}>
                  <AlertIcon />
                  There was an unexpected error.
                  Please try again, if the issue persists contact us at hello@{constants.domain()}
                </Alert>
              )}
            </Box>

            {/* Actions */}
            <Box textAlign="right">
              {/* Submit */}
              <Button
                mt={5}
                isLoading={isLoading}
                loadingText="Submitting"
                isDisabled={isLoading}
                colorScheme="brand"
                size="md"
                type="submit"
                id="submit">
                Submit
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
