import React, { Fragment } from "react";
import { Box, Container, Heading, SimpleGrid, Text, Image, Stack } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { CoverBanner, CTABar } from "components";
import { PublicPageTemplate } from "../PublicPageTemplate/PublicPageTemplate";

import aboutBanner from '../../assets/images/about-banner.jpg';
import imageTrading from '../../assets/images/trading.jpg';
import { constants } from "../../config/constants";

interface Content {
  [key: string]: {
    bannerHeading: string,
    intro: string,
    content: (() => JSX.Element)
  }
}

const content = {
  gib: {
    bannerHeading: "About us",
    intro: "LondonLink was founded to solve the demand for professional, reliable and high-quality crypto services for everyone. As a multi award-winning brokerage, we’re known for our expertise and unrivalled customer service.",
    content: () => {
      return (
        <Box as="article" my={12}>
          <SimpleGrid templateColumns={{ base: '1fr', lg: '0.25fr 0.75fr' }} spacing="50px">
            <Image width={350} alignSelf="flex-start" src={imageTrading} alt="Trading" />
            <Stack spacing={2}>
              <Heading as="h3" size="md" mb={3}>Our mission</Heading>
              <Text>
                Our goal is simple - to provide you with the highest quality cryptocurrency services available.
              </Text>
              <Text>
                Our mission is to deliver a crypto trading environment and experience that is easy to use, fast and secure. Our long-standing reputation demonstrates our success and competence serving retail investors, professional traders and businesses with our signature level of customer service and professionalism. Bridging the gap between the traditional financial world and the new and exciting world of digital assets is all that we do - and what we do best.
              </Text>
              <Text>
                We’re continuing to build industry-leading applications and best in class services that work for you.  It’s an exciting journey and we hope you will join us.
              </Text>
            </Stack>
          </SimpleGrid>
        </Box>
      );
    }
  },
  uk: {
    bannerHeading: "About us",
    intro: "Being one of the longest-standing brokerages in this space we have become very well known and respected for our professionalism and personal touch.",
    content: () => {
      return (
        <Box as="article" my={12}>
          <SimpleGrid templateColumns={{ base: '1fr', lg: '0.25fr 0.75fr' }} spacing="50px">
            <Image width={350} alignSelf="flex-start" src={imageTrading} alt="Trading" />
            <Stack spacing={2}>
              <Heading as="h3" size="md" mb={3}>Our mission</Heading>
              <Text>
                Our goal is simple: to provide high quality brokerage services to our clients. Buying Bitcoin is
                harder that it should be and we aim to change that. FX fees, wiring money,
                lots of waiting and order books.
              </Text>
              <Text>
                At LondonLink we think buying Bitcoin should be as simple as purchasing anything else digital.
                We strive to meet that goal.
              </Text>
              <Text>
                We take the work out of trading Cryptoassets and will only ever quote you one all-inclusive price.
                No confusing prices or fee structures.
              </Text>
            </Stack>
          </SimpleGrid>
        </Box>
      );
    },
  }
};

export const AboutPage = () => {
  if (!constants.businessEntity) {
    throw new Error("No business entity");
  }

  const pageContent = content[constants.businessEntity];

  return (
    <PublicPageTemplate>
      <Helmet>
        <title>About Us</title>
        <meta name="description" content="LondonLink are experts in all things Crypto - from brokering,
        to mining and everything in-between. Being one of the longest-standing brokerages in this space we have
        become very well known and respected for our professionalism and personal touch." />
      </Helmet>

      <CoverBanner backgroundImage={aboutBanner}>
        <Fragment>
          <Heading as="h1" size="lg" margin="0 auto" maxWidth={800}>
            {pageContent.bannerHeading}
          </Heading>

          <Text as="h2" size="md" maxWidth={640} margin="15px auto" fontWeight={400}>
            {pageContent.intro}
          </Text>
        </Fragment>
      </CoverBanner>

      <Container maxWidth="container.xl">

        {pageContent.content()}

      </Container>

      <CTABar title="Ready to trade?" />
    </PublicPageTemplate>
  );
};
