import { AbstractActionModel } from "../abstract/AbstractActionModel";
import { DataStatuses, ErrorResponse } from "../../types/common";
import { customerApiClient } from "../../../../fe-main/src/libs/CustomerLondonLinkApiClient";

export interface CustomerContactMessagesModelState {
  dataStatus: DataStatuses;
  dataError: ErrorResponse | null;
}

export interface SendContactMessageRequest {
  email: string;
  message: string;
  name: string;
  phone?: string;
}

const defaultState: CustomerContactMessagesModelState = {
  dataStatus: DataStatuses.Uninitialized,
  dataError: null
}

const customerXhrClient = customerApiClient.getXhrClient();

export class CustomerContactMessagesModel extends AbstractActionModel {
  private readonly domain;

  constructor(domain: string) {
    super();
    this.domain = domain;
  }

  private state = defaultState;

  private updateState(newState: Partial<CustomerContactMessagesModelState>) {
    this.state = { ...this.state, ...newState };
    this.notify();
  }

  /*
    Public methods
  */
  public async sendMessage(params: SendContactMessageRequest) {
    const genericErrorMessage = `There was an unexpected error.\n Please try again, if the issue persists contact us at hello@${this.domain}`;

    if (this.state.dataStatus !== "loading") {
      try {
        this.updateState({ dataStatus: DataStatuses.Loading, dataError: null });
        const res = await customerXhrClient.post("/contactFormMessage", {
          msgContent: params
        });

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({ dataStatus: DataStatuses.Ok, dataError: null });
        } else {
          this.updateState({ dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } });
        }
      } catch (error) {
        this.updateState({ dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } });
      }
    }
  }

  public getMessagesState() {
    return this.state;
  }
}
