import React, { useEffect, useState } from "react";
import { PublicPageTemplate } from "components";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { useCustomerVerification } from "hooks";
import { Alert, AlertIcon, Button, Container, HStack, Spinner, Text } from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/all";
import { DataStatuses } from "../../../../../shared/src/types/common";

export const AutoEmailVerificationPage = () => {
  const [error, setError] = useState<null | string>(null);
  const { search } = useLocation();
  const { verifyEmail, verificationState } = useCustomerVerification();
  const urlParams = new URLSearchParams(search);
  const paramEmail = urlParams.get('email');
  const paramCode = urlParams.get('code');

  useEffect(() => {
    if (paramEmail && paramCode) {
      verifyEmail({ email: paramEmail, code: paramCode });
    } else {
      setError("There was an unexpected error verifying your email. Please login to verify your email.");
    }
  }, []);

  const isLoading = verificationState.verifyEmailRequest.dataStatus === DataStatuses.Loading;
  const isSuccess = verificationState.verifyEmailRequest.dataStatus === DataStatuses.Ok;

  return (
    <PublicPageTemplate>
      <Helmet>
        <title>Email Verification</title>
      </Helmet>

      <Container>
        {/* Handle Errors */}
        {(verificationState.verifyEmailRequest.dataStatus === "error") && (
          <Alert status="info" mt={5}>
            <AlertIcon />
            {verificationState.verifyEmailRequest.dataError?.message}
          </Alert>
        )}

        {error && (
          <Alert status="error" mt={5}>
            <AlertIcon />
            {error}
          </Alert>
        )}

        {isSuccess && (
          <Alert status="success">
            <AlertIcon />
            Your email address has been successfully verified.
          </Alert>
        )}

        {isLoading && (
          <HStack spacing={4}>
            <Spinner />
            <Text size="sm" color="gray.400">Verifying email address...</Text>
          </HStack>
        )}

        <Button
          as={Link}
          disabled={isLoading}
          to="/customer/verification/level-1"
          colorScheme="brand"
          size="md"
          mt={5}
          id="next-step"
          rightIcon={<FaChevronRight size={14} color="#c1c1c1" />}
          type="button">
          Continue
        </Button>
      </Container>
    </PublicPageTemplate>
  );
};

