import React from "react";
import { Container, Heading, SimpleGrid, Image, Text } from "@chakra-ui/react";
import { PublicPageTemplate } from "../../PublicPageTemplate/PublicPageTemplate";

// Images
import homeBanner from '../../../assets/images/home-banner.jpg';
import tradingIcon from '../../../assets/icons/trading.svg';
import ukIcon from '../../../assets/icons/uk.svg';
import medalIcon from '../../../assets/icons/medal.svg';
import allSizesIcon from '../../../assets/icons/trades-of-all-sizes.svg';
import bitcoinTradesIcon from '../../../assets/icons/bitcoin-trades.svg';
import { CoverBanner } from "../../CoverBanner/CoverBanner";
import { USP } from "../../USP/USP";
import { CTABar } from "../../CTABar/CTABar";
import { constants } from "../../../config/constants";

const content = {
  gib: {
    bannerHeading: "Buy Bitcoin with confidence from one of Europe's most established Crypto brokerage groups",
    intro: "LondonLink is a Bitcoin and Cryptoasset brokerage based in Europe. Our aim is to make buying and selling Cryptoassets as competitive, simple and secure as any other asset.",
    usp1: {
      title: "50,000+ trades",
      text: "Our team has completed over 50,000 trades on various platforms as well as directly with clients.",
      icon: tradingIcon
    },
    usp2: {
      title: "Multi-Award Winning",
      text: "We pride ourselves on our world-class service and are rated Excellent on Trustpilot. ⭐⭐⭐⭐⭐",
      icon: medalIcon
    },
    usp3: {
      title: "Crypto OTC Trading",
      text: "The simplest way for individuals and institutions looking to make over-the-counter (OTC) trades.",
      icon: bitcoinTradesIcon
    }
  },
  uk: {
    bannerHeading: "Buy Bitcoin with confidence with London’s most established bitcoin brokerage",
    intro: "LondonLink is a Bitcoin and Cryptoasset brokerage based in the UK. Our aim is to make buying and selling Cryptoassets as competitive, simple and secure as any other asset.",
    usp1: {
      title: "35,000+ trades",
      text: "Our team has completed over 35,000 trades on various platforms as well as directly with clients.",
      icon: tradingIcon
    },
    usp2: {
      title: "UK based team",
      text: "Our team is based in London, a truly global financial services centre.",
      icon: ukIcon
    },
    usp3: {
      title: "Trade any size",
      text: "From individuals buying their first Bitcoin, to institutions allocating capital; we cater for all at LondonLink.",
      icon: allSizesIcon
    }
  }
};


export const HomePage = () => {
  if (!constants.businessEntity) {
    throw new Error("No business entity");
  }

  const pageContent = content[constants.businessEntity];

  return (
    <PublicPageTemplate>
      <CoverBanner backgroundImage={homeBanner}>
        <Heading as="h1" size="lg" maxWidth={800} margin="0 auto">
          {pageContent.bannerHeading}
        </Heading>
      </CoverBanner>

      <Container maxW="container.xl">
        <Heading as="h2" size="md" textAlign="center" my={10}>
          {pageContent.intro}
        </Heading>

        <SimpleGrid
          templateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }}
          spacing={{ base: '15px', md: '75px' }}
          my={10}>
          <USP
            image={<Image boxSize="80px" m="0 auto" src={pageContent.usp1.icon} alt={pageContent.usp1.title} />}
            title={pageContent.usp1.title}
            align="center">
            <Text fontSize="18px">
              {pageContent.usp1.text}
            </Text>
          </USP>

          <USP
            image={<Image boxSize="80px" m="0 auto" src={pageContent.usp2.icon} alt={pageContent.usp2.title} />}
            title={pageContent.usp2.title}
            align="center">
            <Text fontSize="18px">
              {pageContent.usp2.text}
            </Text>
          </USP>

          <USP
            image={<Image boxSize="80px" m="0 auto" src={pageContent.usp3.icon} alt={pageContent.usp3.title} />}
            title={pageContent.usp3.title}
            align="center">
            <Text fontSize="18px">
              {pageContent.usp3.text}
            </Text>
          </USP>
        </SimpleGrid>
      </Container>

      {constants.businessEntity === "gib" && <CTABar title="Get started now" />}
    </PublicPageTemplate>
  );
};
