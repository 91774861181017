export const components = {
  List: {
    baseStyle: {
      container: {
        paddingLeft: '20px'
      }
    }
  },
  Input: {
    variants: {
      outline: {
        field: {
          borderColor: 'gray.300'
        }
      },
    },
  },
  Select: {
    variants: {
      outline: {
        field: {
          borderColor: 'gray.300'
        }
      },
    },
  },
  Textarea: {
    variants: {
      outline: {
        borderColor: 'gray.400'
      },
    },
  },
  Container: {
    baseStyle: {
      maxW: 'container.xl'
    }
  },
  Checkbox: {
    sizes: {
      'small-text': {
        control: { h: 4, w: 4 },
        label: { fontSize: 'sm' },
        icon: { fontSize: '0.625rem' },
      },
    }
  },
};
