import React, { useEffect } from 'react';
import { Avatar, MenuList, MenuButton, MenuItem, Menu, Text, Flex, useMediaQuery } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useCustomerAuth, useCustomerProfile } from "hooks";

export const CustomerProfile = () => {
  const [isLargerThan767] = useMediaQuery('(min-width: 768px)');
  const { logout, authState } = useCustomerAuth();
  const { fetchProfile, profile } = useCustomerProfile();

  useEffect(() => {
    if (authState.authenticated && !profile.contactDetails) {
      fetchProfile();
    }
  }, [authState.authenticated]);

  const handleLogout = async () => logout();

  return (
    <Menu placement="bottom-end" id="profile-menu">
      <MenuButton bg="none">
        <Flex>
          {isLargerThan767 && (
            <Text px={3} py={1} color="gray.500" fontSize="sm">
              {profile.contactDetails?.email}
            </Text>
          )}
          <Avatar width={{ base: 25, md: 30 }} height={{ base: 25, md: 30 }} />
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem fontWeight={600} fontSize="sm" as={RouterLink} to="/customer/account">
          Account
        </MenuItem>
        <MenuItem fontWeight={600} fontSize="sm" onClick={handleLogout} className="logout-button">
          <Text color="red.500">Sign out</Text>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

