import React from "react";
import { useComplianceState } from "hooks";
import { LoggedInDefaultTemplate, PollCustomerProfile, VerificationStatusResult } from "components";
import { AlertDescription, AlertIcon, AlertTitle, Button, Text } from "@chakra-ui/react";
import { FaChevronRight } from "react-icons/all";
import { Link } from "react-router-dom";

export const TradePage = () => {
  const {
    level1Required,
    level1Completed,
    level2Pending,
    level2Completed,
    level2Optional,
    level2Required,
    level2Failed,
    sumsubFinalFailed,
    dnt,
  } = useComplianceState();

  const Message = () => {
    if (level2Failed || dnt || sumsubFinalFailed) {
      return (
        <VerificationStatusResult status="error" id="result-final-fail">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Unfortunately, you are not eligible to trade
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <Text>
              Based on the information provided, we are unable to provide you services. If you believe that something is
              wrong on our side, please contact our support team. We apologize for any inconvenience this may caused and
              appreciate your understanding.
            </Text>
          </AlertDescription>
        </VerificationStatusResult>
      );
    }

    if (level2Pending) {
      return (
        <VerificationStatusResult status="info" id="result-final-fail">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            We are reviewing your information
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <Text>
              This process normally up to 2 hours (during business hours). In case of difficulties, it may last for up
              to 24 hours. This screen will automatically update once we have finished reviewing your information.
            </Text>
          </AlertDescription>
        </VerificationStatusResult>
      );
    }

    if (level1Completed && (level2Completed || level2Optional)) {
      return (
        <VerificationStatusResult status="success" id="result-ready-to-trade">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            You are ready to trade!
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <Text>
              Thank you for completing our verification process. You are now ready to trade with LondonLink.
              Please contact us to proceed with your trade.
            </Text>
          </AlertDescription>
        </VerificationStatusResult>
      );
    }

    if (level1Required) {
      return (
        <VerificationStatusResult status="warning" id="result-level1-required">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Verification required
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <Text>You must complete customer verification before you are able to trade.</Text>
            <Button
              id="start-verification"
              mt={2}
              as={Link}
              to="/customer/verification"
              colorScheme="brand"
              size="sm"
              rightIcon={<FaChevronRight size={14} color="#c1c1c1" />}>
              Go to verification
            </Button>
          </AlertDescription>
        </VerificationStatusResult>
      );
    }
    if (level2Required) {
      return (
        <VerificationStatusResult status="warning" id="result-level1-required">
          <AlertIcon boxSize="40px" mr={0} />
          <AlertTitle mt={4} mb={1} fontSize="lg">
            Verification required
          </AlertTitle>
          <AlertDescription maxWidth="sm">
            <Text>You must complete additional customer verification (Level 2) before you are able to trade.</Text>
            <Button
              id="start-verification"
              mt={2}
              as={Link}
              to="/customer/verification"
              colorScheme="brand"
              size="sm"
              rightIcon={<FaChevronRight size={14} color="#c1c1c1" />}>
              Go to verification
            </Button>
          </AlertDescription>
        </VerificationStatusResult>
      );
    }

    return (
      <VerificationStatusResult status="error" id="result-error">
        <AlertIcon boxSize="40px" mr={0} />
        <AlertTitle mt={4} mb={1} fontSize="lg">
          We could not determine your verification status
        </AlertTitle>
        <AlertDescription maxWidth="sm">
          <Text>
            Please contact customer support to resolve this issue.
          </Text>
        </AlertDescription>
      </VerificationStatusResult>
    );
  };

  return (
    <LoggedInDefaultTemplate pageName="Trade">
      <PollCustomerProfile interval={2500} />
      <Message />
    </LoggedInDefaultTemplate>
  );
};
