import React, { Fragment, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { MainBodyContent, LoggedInHeader, Footer, VerificationStatusSidebar, PollCustomerProfile } from "components";
import { Box, Breadcrumb, BreadcrumbItem, BreadcrumbLink, Container, Divider, Heading, HStack } from "@chakra-ui/react";
import { HiOutlineChevronRight } from "react-icons/all";
import { Link as RouterLink } from "react-router-dom";
import { ComplianceActionStages, ComplianceItemStatus } from "../../../../../shared/src/types/compliance";

export interface VerificationTemplateProps {
  children: ReactNode;
  pageName: string;
  breadcrumb?: { title: string, current: boolean, to: string }[];
  verificationStages: { key: number, title: string; status: ComplianceItemStatus, item: ComplianceActionStages, disabled?: boolean }[];
}

export const VerificationTemplate = ({ children, breadcrumb, pageName, verificationStages }: VerificationTemplateProps) => {
  return (
    <Fragment>
      <Helmet>
        <title>{pageName}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <PollCustomerProfile interval={2500} />

      <LoggedInHeader />

      <MainBodyContent>
        <Container>
          {breadcrumb?.length && (
            <Breadcrumb
              mb={5}
              spacing="8px"
              color="#6f6f6f"
              fontSize="sm"
              separator={<HiOutlineChevronRight color="#cacaca" size={14} />}>
              {breadcrumb.map((b) => {
                return (
                  <BreadcrumbItem key={b.title} isCurrentPage={b.current}>
                    <BreadcrumbLink as={RouterLink} to={b.to}>
                      {b.title}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                );
              })}
            </Breadcrumb>
          )}

          <Box>
            <Heading as="h1" size="lg" fontWeight={500} mb={{ base: 2, sm: 2 }}>
              {pageName}
            </Heading>

            <Divider my={{ base: 2, md: 4 }} />

            <HStack spacing={{ base: 0, md: 10 }} d="flex" alignItems="flex-start" flexDirection={{ base: 'column', md: 'row' }}>
              <VerificationStatusSidebar items={verificationStages} />

              <Box flex={1} width="100%">
                {children}
              </Box>
            </HStack>

          </Box>
        </Container>
      </MainBodyContent>

      <Footer />
    </Fragment>
  );
};
