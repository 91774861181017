import { Listener } from "../../types/common";

export class AbstractActionModel {
  private listeners: Listener[] = [];

  public addListener(listener: Listener) {
    this.listeners.push(listener);
  }

  public removeListener(listenerToRemove: Listener) {
    this.listeners = this.listeners.filter((listener) => listenerToRemove !== listener);
  }

  public notify(stateTriggerId?: string) {
    this.listeners.forEach((listener: (stateTriggerId?: string) => void | string) => listener(stateTriggerId));
  }
}
