import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { useCustomerConfig } from "hooks";
import { useCookies } from "react-cookie";

import {
  HomePage,
  RegisterPage,
  CustomerAccountPage,
  PrivateRoute,
  WhyLondonLinkPage,
  AboutPage,
  ContactPage,
  PrivacyPolicyPage,
  TermsAndConditionsPage,
  AcceptableUsePolicyPage,
  RiskDisclaimerPage,
  CookiePolicyPage,
  GDPRFAQPage,
  TradingTermsPage,
  NotFoundPage,
  NonIndividualCustomerPage,
  LoginPage,
  RequestPasswordPage,
  ResetPasswordPage,
  TradePage,
  VerificationPage,
  Level1VerificationPage,
  Level2VerificationPage,
  AutoEmailVerificationPage,
  ErrorBoundary,
  ConnectionStatus,
  CookieBanner,
  SEO,
  ScrollToTop,
  MigrationBanner,
  MigrationModal,
  MigratedToGibRoute,
  GibraltarMigrationPage
} from "components";

import { envars } from "./config/envars";

function App() {
  const [_, setCookie] = useCookies(['disableTawk']);
  const { fetchConfig } = useCustomerConfig();

  // Fetch the config when the app starts
  useEffect(() => {
    fetchConfig();
  }, []);

  // Drop a cookie for GTM to read if we want Tawk disabled
  useEffect(() => {
    if (envars.DISABLE_TAWK) {
      setCookie('disableTawk', '1');
    }
  }, []);

  return (
    <ErrorBoundary>
      <Router>
        <SEO />
        <ScrollToTop />
        <ConnectionStatus />
        <CookieBanner />
        <MigrationBanner />
        <MigrationModal />

        <Switch>
          {/*
            This is a blank "shell" page react-snap will generate in production
            When protected route pages are reloaded they will use this page instead of 404
            See Amplify Rewrites and redirects settings for more details
          */}
          <Route exact path="/blank" children={null} />

          {/* Force trailing slash */}
          <Route path="/:url*" exact strict render={(props) => <Redirect {...props} to={{ ...props.location, pathname: `${props.location.pathname}/` }} />} />

          {/* Public Pages */}
          {/*
            Pages where you can auth/login/register
            n.b: customer is NOT authed on these pages, component MigratedToGibRoute component is just to stop UK customers from
            being able to auth/login/register and will show them migration message.
          */}
          <MigratedToGibRoute exact path="/register/" children={<RegisterPage />} />
          <MigratedToGibRoute exact path="/login/" children={<LoginPage />} />
          <MigratedToGibRoute exact path="/request-password-reset/" children={<RequestPasswordPage />} />
          <MigratedToGibRoute exact path="/password-reset/" children={<ResetPasswordPage />} />
          <MigratedToGibRoute exact path="/verify-email/" children={<AutoEmailVerificationPage />} />

          <Route exact path="/" children={<HomePage />} />
          <MigratedToGibRoute exact path="/why-londonlink/" children={<WhyLondonLinkPage />} />
          <MigratedToGibRoute exact path="/about/" children={<AboutPage />} />
          <Route exact path="/contact/" children={<ContactPage />} />
          <Route exact path="/privacy-policy/" children={<PrivacyPolicyPage />} />
          <Route exact path="/general-terms-and-conditions/" children={<TermsAndConditionsPage />} />
          <Route exact path="/acceptable-use-policy/" children={<AcceptableUsePolicyPage />} />
          <Route exact path="/risk-disclaimer/" children={<RiskDisclaimerPage />} />
          <Route exact path="/cookie-policy/" children={<CookiePolicyPage />} />
          <Route exact path="/gdpr-faq/" children={<GDPRFAQPage />} />
          <Route exact path="/trading-terms-and-conditions/" children={<TradingTermsPage />} />
          <Route exact path="/non-individual/" children={<NonIndividualCustomerPage />} />
          <Route exact path="/why-gibraltar/" children={<GibraltarMigrationPage />} />

          {/* Authenticated Routes */}
          <Redirect exact path="/customer/" to="/customer/trade/" />
          <PrivateRoute exact path="/customer/trade/" children={<TradePage />} />
          <PrivateRoute exact path="/customer/account/" children={<CustomerAccountPage />} />
          <PrivateRoute exact path="/customer/verification/" children={<VerificationPage />} />
          <PrivateRoute exact path="/customer/verification/level-1/" children={<Level1VerificationPage />} />
          <PrivateRoute exact path="/customer/verification/level-2/" children={<Level2VerificationPage />} />

          <Route children={<NotFoundPage />} />
        </Switch>
      </Router>
    </ErrorBoundary>
  );
}

export default App;
