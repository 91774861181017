import React, { Fragment, useEffect, useState } from "react";
import {
  Alert,
  AlertIcon,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  HStack,
  PinInput,
  PinInputField,
  Text,
} from "@chakra-ui/react";
import { useComplianceState, useCustomerAuth, useCustomerProfile, useCustomerVerification } from "hooks";
import { ContinueNextStep, SpinnerToTickIcon } from "components";
import { ComplianceActionStages } from "../../../../../../shared/src/types/compliance";
import { Customer } from "../../../../../../shared/src/types/customer";
import { DataStatuses } from "../../../../../../shared/src/types/common";

export const EmailVerification = () => {
  const complianceState = useComplianceState();
  const { logout } = useCustomerAuth();
  const { profile } = useCustomerProfile();
  const { verifyEmail, verificationState, customerStageSelect, resetRequestState, resendEmailVerification } = useCustomerVerification();

  const isLoading = verificationState.verifyEmailRequest.dataStatus === DataStatuses.Loading;
  const isSuccess = verificationState.verifyEmailRequest.dataStatus === DataStatuses.Ok;

  const [code, setCode] = useState('');
  const codeValid = code.length === 6;

  const contactDetails = profile.contactDetails as Customer['contactDetails'];

  function handleSubmit() {
    verifyEmail({ email: contactDetails.email, code });
  }

  function handleResend() {
    resendEmailVerification();
  }

  useEffect(() => {
    // Move to next stage after success
    if (isSuccess) {
      // Add a small delay before moving onto the next step to allow the SpinnerToTickIcon component animation to finish
      setTimeout(() => {
        resetRequestState("verifyEmailRequest");
        customerStageSelect(ComplianceActionStages.selfCertification);
      }, 1250);
    }
  }, [isSuccess]);

  return (
    <Fragment>
      <Heading size="md" as="h2" mb={3}>
        Verify your email address
      </Heading>

      {complianceState.actions.emailVerification.status === 'completed' ? (
        <ContinueNextStep successMessage="Your email has been successfully verified." />
      ) : (
        <form onSubmit={handleSubmit}>
          <FormControl>
            <Text size="md" mb={2}>We’ve sent you a 6 digit code to <Text as="span" textDecoration="underline" fontWeight={600}>{contactDetails.email}</Text>.</Text>

            <FormLabel>Enter the code below to confirm your email address.</FormLabel>

            <HStack>
              <PinInput type="alphanumeric" size="lg" onChange={(value) => setCode(value)} id="otp">
                <PinInputField required />
                <PinInputField required />
                <PinInputField required />
                <PinInputField required />
                <PinInputField required />
                <PinInputField required />
              </PinInput>
            </HStack>

            <HStack mt={5} spacing={5}>
              <Button
                transition="all 0.5s linear"
                colorScheme={isSuccess ? "green" : "brand"}
                leftIcon={<Fragment />}
                spinnerPlacement="end"
                type="submit"
                rightIcon={isSuccess ? <SpinnerToTickIcon size="sm" /> : <Fragment />}
                onClick={handleSubmit}
                isLoading={isLoading}
                isDisabled={isLoading || !codeValid}
                loadingText="Submitting"
                id="submit">
                {isSuccess ? "Submitting" : "Submit"}
              </Button>
            </HStack>

            <Divider my={6} />

            <Heading size="sm" as="h3" mb={2}>
              I didn’t receive the code
            </Heading>
            <FormHelperText mt={1} mb={5}>
              {verificationState.resendEmailVerificationRequest.dataStatus === DataStatuses.Ok ? (
                <Alert status="success" color="black">
                  <AlertIcon />
                  Email verification sent. Please check your email.
                </Alert>
              ) : (
                <Fragment>
                  If you did not receive an email, please check your Spam/Junk folder or
                  <Button
                    textDecoration="underline"
                    onClick={handleResend}
                    ml={1}
                    loadingText="Resending"
                    isLoading={verificationState.resendEmailVerificationRequest.dataStatus === DataStatuses.Loading}
                    isDisabled={verificationState.resendEmailVerificationRequest.dataStatus === DataStatuses.Loading}
                    size="sm"
                    variant="link"
                    type="button">
                    resend email verification.
                  </Button>
                </Fragment>
              )}
            </FormHelperText>

            <Button
              onClick={handleResend}
              loadingText="Sending"
              isLoading={verificationState.resendEmailVerificationRequest.dataStatus === "loading"}
              isDisabled={verificationState.resendEmailVerificationRequest.dataStatus === "loading"}
              size="xs"
              colorScheme="brand"
              variant="outline"
              mt={3}
              type="button">
              Send email verification
            </Button>

            <Heading size="sm" as="h3" mb={2} mt={6}>
              The email address is incorrect
            </Heading>
            <FormHelperText mb={2}>
              If the email address above is incorrect, please <a href="/contact" target="_blank" rel="noopener noreferrer">contact us</a>.
            </FormHelperText>
          </FormControl>

          {/* Handle Error */}
          {verificationState.verifyEmailRequest.dataStatus === "error" && (
            <Alert status="error" mt={5}>
              <AlertIcon />
              {verificationState.verifyEmailRequest.dataError?.message}
            </Alert>
          )}
        </form>
      )}
    </Fragment>
  );
};
