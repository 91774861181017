import React, { AllHTMLAttributes, ReactNode } from "react";
import { Box, Link } from "@chakra-ui/react";
import { Link as RouterLink } from "react-router-dom";

interface Props {
  children: ReactNode;
  link: string;
  target?: AllHTMLAttributes<HTMLLinkElement>['target'];
}

export const FooterNavItem = ({ children, link, target }: Props) => {
  return (
    <Box as="li" display="inline-block">
      <Link color="#fff" fontSize="sm" as={RouterLink} to={{ pathname: link }} target={target}>
        {children}
      </Link>
    </Box>
  );
};
