import React, { Fragment } from 'react';
import { Circle, Divider, Text, Box, useMediaQuery } from '@chakra-ui/react';
import { FaCheck } from 'react-icons/fa';

export interface StepsProps {
  nextStep: () => void;
  prevStep: () => void;
  activeStep: number;
}

interface Props {
  children: any;
  orientation?: 'vertical' | 'horizontal';
}

interface StepProps {
  active: boolean;
  done: boolean;
  title: string;
  number: string;
  orientation?: Props['orientation'];
  key?: number;
  totalSteps?: number;
}

export const Step = ({ active, title, number, done, orientation, key, totalSteps }: StepProps) => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const isVertical = orientation === 'vertical';

  // Showing all steps on mobile takes up too much space so only return the active step
  const showOnlyOneStep = !isLargerThan1024 && !active;

  if (showOnlyOneStep) return null;

  const bg = () => {
    if (active) return 'green.300';
    if (done) return 'green.500';
    return 'gray.300';
  };

  return (
    <Fragment key={key}>
      <Box d="flex" alignItems="center" alignSelf="flex-start">
        <Circle size="25px" bg={bg()} color="white" fontSize="sm" alignSelf="flex-start">
          {done ? <FaCheck /> : number}
        </Circle>
        <Text ml={2} flex="1" fontSize="sm" color="gray.700" fontWeight={600}>
          {title}
          {!isLargerThan1024 && (
            <Fragment>
              <br />
              <Text
                position="relative"
                top="-3px"
                color="gray.400"
                fontWeight={400}
                fontSize="xs"
                as="span">
                Step {number} of {totalSteps}
              </Text>
            </Fragment>
          )}
        </Text>
      </Box>

      <Divider
        _last={{ display: 'none' }}
        alignSelf={isVertical ? 'flex-start' : 'center'}
        position="relative"
        left={isVertical ? 3 : 0}
        height={isVertical ? '40px' : 'auto'}
        width="auto"
        flex={isVertical ? 'initial' : 1}
        variant="dashed"
        orientation={orientation}
        borderColor="#c5c5c5"
        mx={isVertical ? 0 : 2}
      />
    </Fragment>
  );
};

export const Steps = ({ children, orientation = 'vertical' }: Props) => {
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)');
  const numberOfChildren = children.length;
  const isVertical = orientation === 'vertical';

  return (
    <Box
      px={isVertical && isLargerThan1024 ? 5 : 0}
      py={isLargerThan1024 ? 5 : 2}
      width={isVertical ? 250 : 'auto'}
      d="flex"
      justifyContent="space-between"
      flexDirection={isVertical ? 'column' : 'row'}>
      {Array.from({ length: numberOfChildren }, (_, i) => React.cloneElement(children[i], {
        orientation,
        key: i,
        totalSteps: numberOfChildren
      }))}
    </Box>
  );
};
