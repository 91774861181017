import React, { useEffect } from "react";
import * as yup from "yup";
import { Link as RouterLink, Redirect } from "react-router-dom";
import { Field, FieldProps, Form, Formik } from "formik";
import {
  Alert,
  AlertIcon,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Stack,
  Text
} from "@chakra-ui/react";
import { useCustomerAuth } from "hooks";
import { DataStatuses } from "../../../../../shared/src/types/common";

// Types
type RequestPasswordFormValues = typeof initialValues;

const initialValues = {
  email: '',
};

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Field is required')
});

export const RequestPasswordForm = () => {
  const { authState, requestNewPassword, resetRequestState } = useCustomerAuth();

  function handleSubmit(values: RequestPasswordFormValues) {
    requestNewPassword({ email: values.email });
  }

  const isLoading = authState.newPasswordRequest.dataStatus === DataStatuses.Loading;
  const isSuccess = authState.newPasswordRequest.dataStatus === DataStatuses.Ok;

  // Reset request state when component is unmounted
  useEffect(() => {
    return function cleanup() {
      resetRequestState("newPasswordRequest");
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={(values: RequestPasswordFormValues) => handleSubmit(values)}>
      {({ values }) => {
        return (
          <Form id="request-password-reset-form">
            <Stack spacing="6">
              <Field name="email">
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={!!(form.errors.email && form.touched.email)}>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input {...field} type="email" id="email" autoFocus />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            </Stack>

            {/* Handle Error */}
            {authState.newPasswordRequest.dataStatus === "error" && (
              <Alert status="error" mt={5}>
                <AlertIcon />
                {authState.newPasswordRequest.dataError?.message}
              </Alert>
            )}

            {/* Redirect on success */}
            {isSuccess && (
              <Redirect to={`/password-reset?email=${encodeURIComponent(values.email)}&originalTab=1`} />
            )}

            <Stack
              justifyContent="space-between"
              mt={5}
              flexDirection={{ base: 'column-reverse', md: 'row' }}>
              <Text color="gray.600" mt={3}>
                <RouterLink to="/login">Return to sign in</RouterLink>
              </Text>

              {/* Submit */}
              <Button
                width={{ base: '100%', md: 'auto' }}
                isLoading={isLoading}
                loadingText="Sending..."
                isDisabled={isLoading}
                colorScheme="brand"
                size="md"
                type="submit"
                id="submit">
                Send Password Reset
              </Button>
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};
