import React from 'react';
import { Text } from '@chakra-ui/react';
import { constants } from "../../config/constants";

interface Props {
  name: string;
}

export const FormDisclaimer = ({ name }: Props) => {
  return (
    <Text fontSize="xs" color="gray.400">
      By submitting this form you confirm that the information provided above is true and complete.
      If you have any questions please contact a member of the LondonLink team who will assist
      or email <a href={`mailto:verify@${constants.domain()}?subject=${name}`}>verify@{constants.domain()}</a>
    </Text>
  );
};
