import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Container, Heading, SimpleGrid, Text, Image } from "@chakra-ui/react";
import { PublicPageTemplate } from "../PublicPageTemplate/PublicPageTemplate";
import whyBanner from '../../assets/images/why-banner.jpg';

// Icons
import i1 from '../../assets/icons/competitive.svg';
import i2 from '../../assets/icons/fast.svg';
import i3 from '../../assets/icons/people.svg';
import i4 from '../../assets/icons/time.svg';
import i5 from '../../assets/icons/checklist.svg';
import i6 from '../../assets/icons/bank.svg';
import i7 from '../../assets/icons/telephone.svg';
import i8 from '../../assets/icons/support.svg';
import i9 from '../../assets/icons/trades.svg';
import { CoverBanner } from "../CoverBanner/CoverBanner";
import { USP } from "../USP/USP";
import { CTABar } from "../CTABar/CTABar";
import { constants } from "../../config/constants";

const content = {
  gib: {
    bannerHeading: "Why LondonLink?",
    intro: "Having 8 years in the Crypto OTC markets we know what our customers want. Great pricing, outstanding customer service, high availability and rapid trades.",
    usps: [
      {
        title: "Competitive Pricing",
        text: "Outstanding relationships with liquidity providers and market makers around the world ensure LondonLink can provide the best pricing for all our customers at any time.",
        icon: i1
      },
      {
        title: "Fast and Reliable",
        text: "Fast and easy sign up process. Open an account in minutes and start trading.",
        icon: i2
      },
      {
        title: "Unrivaled Experience",
        text: "We pride ourselves on our extensive knowledge and experience in the cryptocurrency space. With over 50 years of combined experience you can be sure you’re in safe hands with one of the most established companies in the industry.",
        icon: i3
      },
      {
        title: "High Availability",
        text: "Unlike traditional financial markets, Crypto never sleeps. Cryptocurrency markets can be very volatile and at LondonLink we understand this which is why we’re available 7 days a week.",
        icon: i4
      },
      {
        title: "Fully Compliant",
        text: "LondonLink is a Gibraltar registered VAAP (Virtual Asset Arrangement Provider) under POCA 2015. More information can be found <a target=\"_blank\" href=\"https://www.fsc.gi/regulated-entity/londonlink-gi-limited-27980\">here</a>.",
        icon: i5
      },
      {
        title: "GBP & EUR Banking",
        text: "We’ve partnered with the best payment service providers in the industry to provide instant and seamless GBP and EUR bank transfers with more to come!",
        icon: i6
      },
      {
        title: "Telephone Brokering",
        text: "Our aim is to provide the technology you need as well as the personal service you want. We’re experienced in providing personal service to our clients so if you prefer speaking to humans rather than robots please give us a call.",
        icon: i7
      },
      {
        title: "Expert Support",
        text: "Email, phone, WhatsApp or live chat. Get in touch with us on whatever method suits you. Want to discuss a trade ahead of time? Get an indicative price or anything else on the medium you prefer.",
        icon: i8
      },
      {
        title: "High Value Trades",
        text: "At LondonLink we’ll process any trade, any size.  Whether buying or selling Crypto, you can be assured that LondonLink will handle anything you can throw at us.",
        icon: i9
      },
    ],
  },
  uk: {
    bannerHeading: "Why trust LondonLink?",
    intro: "Having 5 years in the Bitcoin OTC markets we know what our customers want. Great pricing, outstanding customer service, high availability and rapid trades.",
    usps: [
      {
        title: "Competitive Pricing",
        text: "Due to our outstanding relationships with other buyers and sellers, professional partners and economies of scale, we have continued access to wholesale priced Bitcoins and we carry the cost savings over to you.",
        icon: i1
      },
      {
        title: "Fast and Reliable",
        text: "Once you have on-boarded with us, it can take as little as five minutes from the point of requesting a quote, to fully settling the trade.",
        icon: i2
      },
      {
        title: "Experienced Brokers",
        text: "Our brokers have over 12 years collective experience in the crypto space. Whether you are setting up your first wallet or signing a multisig transaction, we have seen it all before. You can rely on our expert knowledge.",
        icon: i3
      },
      {
        title: "High Availability",
        text: "Unlike traditional financial markets like the FTSE, Bitcoin never sleeps. Bitcoin is very volatile and you may decide that the best time to close your position is 11pm on a bank holiday Monday. LondonLink understands this, that's why we are available when you need us 9am - 1am Monday - Thursday and 10am - 6pm Friday - Sunday. Even on bank holidays!",
        icon: i4
      },
      {
        title: "Fully Compliant",
        text: "LondonLink has been temporarily registered under the Money Laundering, Terrorist Financing and Transfer of Funds (Information on the Payer) Regulations 2017 as a cryptoasset business until 31<sup>st</sup> March 2022, pending the determination of our application by the Financial Conduct Authority. The list of temporary registrations can be found <a target=\"_blank\" href=\"/docs/Cryptoasset_firms_with_Temporary_registration.pdf\">here</a>.",
        icon: i5
      },
      {
        title: "UK Banking",
        text: "LondonLink uses UK domestic bank transfers (Faster Payments). This allows us to settle trades in a matter of minutes.",
        icon: i6
      },
      {
        title: "Telephone Brokering",
        text: "At LondonLink you can have as much or as little interaction with us as you like. Whether you want a live quote on the phone, or want to discuss the latest gossip in the crypto world. Give us a call if you prefer it to messaging.",
        icon: i7
      },
      {
        title: "Expert Support",
        text: "Email, phone, WhatsApp or live chat. Get in touch with us on whatever method suits you. Want to discuss a trade ahead of time? Get an indicative price or anything else on the medium you prefer.",
        icon: i8
      },
      {
        title: "High Value Trades",
        text: "We can handle almost any size trade above £100 when you are buying or selling Bitcoin. We have also partnered with various institutions who provide deep liquidity should you need to trade OTC blocks of 100+ BTC.",
        icon: i9
      },
    ],
  }
};


export const WhyLondonLinkPage = () => {
  if (!constants.businessEntity) {
    throw new Error("No business entity");
  }

  const pageContent = content[constants.businessEntity];

  return (
    <PublicPageTemplate>
      <Helmet>
        <title>Why LondonLink</title>
        <meta name="description" content={pageContent.intro} />
      </Helmet>

      <CoverBanner backgroundImage={whyBanner}>
        <Fragment>
          <Heading as="h1" size="lg" margin="0 auto" maxWidth={800}>
            {pageContent.bannerHeading}
          </Heading>

          <Text as="h2" size="md" maxWidth={640} margin="15px auto" fontWeight={400}>
            {pageContent.intro}
          </Text>
        </Fragment>
      </CoverBanner>

      <Container>
        <SimpleGrid templateColumns={{ base: '1fr', lg: 'repeat(3, 1fr)' }} spacing="50px" my={{ base: 5, md: 20 }}>
          {pageContent.usps.map((usp) => {
            return (
              <USP
                key={usp.title}
                image={<Image boxSize="60px" m="0 auto" src={usp.icon} alt={usp.title} />}
                title={usp.title}
                align="left">
                <Text fontSize="sm" dangerouslySetInnerHTML={{ __html: usp.text }} />
              </USP>
            );
          })}
        </SimpleGrid>
      </Container>

      <CTABar title="Heard enough? Get in contact" />
    </PublicPageTemplate>
  );
};
