import React, { Fragment, JSXElementConstructor, ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Stack, Link } from '@chakra-ui/react';
import { useMigrationModalStore } from "../../../store/MigrationModalStore";
import { envars } from "../../../config/envars";

interface Props {
  closeMenu: () => void;
}

export const RegisterButtons = ({ closeMenu }: Props) => {
  return (
    <Stack
      spacing={{ base: 1, md: 4 }}
      direction={{ base: 'column', md: 'row' }}
      align="center"
      width={{ base: '100%', md: 'auto' }}>
      <Link width="100%" to="/login" as={envars.BUSINESS_ENTITY === "uk" ? OpenMigrationModalButton : RouterLink}>
        <Button isFullWidth size="sm" variant="ghost" onClick={closeMenu}>Sign In</Button>
      </Link>

      <Link width="100%" to="/register" as={envars.BUSINESS_ENTITY === "uk" ? OpenMigrationModalButton : RouterLink}>
        <Button isFullWidth size="sm" colorScheme="brand" onClick={closeMenu}>Register</Button>
      </Link>
    </Stack>
  );
};


/**
 * This component changes the onClick event on the register buttons to the migration popup message if we are on the "UK" version of the website
 */
interface OpenMigrationModalButtonProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>;
  to: string;
}

const OpenMigrationModalButton = ({ children, ...otherProps }: OpenMigrationModalButtonProps) => {
  const { openModal } = useMigrationModalStore();

  return (
    <Fragment>
      {React.cloneElement(children, { onClick: openModal })}
    </Fragment>
  );
};
