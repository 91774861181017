import React from "react";
import { LegalPageTemplate, LegalDisclaimer, NotFoundPage } from "components";
import { Container } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { constants } from "../../../config/constants";

export const RiskDisclaimerPage = () => {
  /**
   * GIB version
   */
  if (constants.businessEntity === "gib") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Risk Disclaimer</title>
        </Helmet>

        <Container>
          <article>
            <h1>Risk Disclaimer</h1>
            <section>
              <p>Cryptoassets are not backed or guaranteed by any government or financial institution.</p>
              <p>When purchasing Cryptoassets from LondonLink, you must satisfy yourself that you understand all the associated investment risks in light of the above, and generally. Before you decide to invest, do not invest in anything you cannot afford to lose.</p>
              <p>You must acknowledge when buying and/or selling Cryptoassets from LondonLink that 100% of your capital invested is at risk. We cannot, and do not make any guarantee about the future value of your Cryptoassets and we will not be held liable for any losses you incur. Equally, we will not request a benefit from any gains you might make.
              </p>
              <p>Furthermore, we are not qualified or authorised to give any advice. We very strongly suggest you obtain financial advice from your professional advisor on any investments you make in Cryptoassets.</p>
              <p>For the avoidance of doubt, LondonLink are a brokerage offering an ‘execution only’ service at this time. It is solely your responsibility to store any Cryptoassets received in a safe manner. This includes but is not limited to providing LondonLink with the correct and appropriate wallet address to receive Cryptoassets purchased through LondonLink.</p>
              <p>All Cryptoasset transactions are immutable. This means that they cannot be reversed should you lose access to your Cryptoasset wallet. If you decide to store or invest your Cryptoassets with a third-party you should do your own due-diligence on that third party before sending your Cryptoassets to them. LondonLink will not be held responsible for loss incurred as a result of the acts or omission of third parties.</p>
              <p>You should be particularly cautious with any third parties that promise guaranteed or unrealistic returns.</p>
            </section>

            <LegalDisclaimer version="2.0" name="Risk Disclaimer" dateLastUpdated="January 2022" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  /**
   * UK version
   */
  if (constants.businessEntity === "uk") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Risk Disclaimer</title>
        </Helmet>

        <Container>
          <article>
            <h1>Risk Disclaimer</h1>
            <section>
              <p>Cryptoassets are not backed or guaranteed by any government or financial institution.</p>
              <p>When purchasing Cryptoassets from LondonLink, you must satisfy yourself that you understand all the
                associated investment risks in light of the above, and generally. Before you decide to invest, do not
                invest in anything you cannot afford to lose.
              </p>
              <p>You must acknowledge when buying and/or selling Cryptoassets from LondonLink that 100% of your capital
                invested is at risk. We cannot, and do not make any guarantee about the future value and we will not be
                held liable for any losses you incur, or request a benefit from any gains you make.
              </p>
              <p>Furthermore, we are not qualified or authorised to give any advice. We very strongly suggest obtaining
                financial advice from your professional advisor on any investments you make in Cryptoassets.
              </p>
              <p>For the avoidance of doubt, LondonLink are a brokerage offering an ‘execution only’ service at this
                time. It is solely your responsibility to store any Cryptoasset received in a safe manner. This includes
                but is not limited to providing LondonLink with the correct and appropriate wallet address to receive
                Cryptoassets purchased through LondonLink.
              </p>
              <p>All Cryptoasset transactions are immutable. This means that they cannot be reversed should you lose
                access to your Cryptoasset wallet. If you decide to store or invest your Cryptoassets with a third-party
                you should do your own due-diligence on the third party before sending your Cryptoassets to them.
                LondonLink will not be held responsible for loss incurred due to third-parties.
              </p>
              <p>You should be particularly cautious in regards to third-parties promising guaranteed or unrealistic
                returns.
              </p>
              <p>LondonLink OTC Limited and the services we offer are not:</p>
              <ol>
                <li>within scope of the jurisdiction of the Financial Ombudsman Service, or</li>
                <li>subject to protection under the Financial Services Compensation Scheme.</li>
              </ol>
            </section>

            <LegalDisclaimer version="1.3" name="Risk Disclaimer" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  return <NotFoundPage />;
};
