import { Button, Box, Heading } from '@chakra-ui/react';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  title: string;
}

export const CTABar = ({ title }: Props) => {
  return (
    <Box bg="#fafafa" color="brand" textAlign="center" py={8} marginBottom="-25px">
      <Heading as="h5" size="md" mb={4}>{title}</Heading>
      <Link to="/contact" className="button-link">
        <Button colorScheme="brand" size="lg">Contact us</Button>
      </Link>
    </Box>
  );
};
