const LOG_ENABLED = false;
const LOG = (...args) => {
  if (LOG_ENABLED) {
    console.log(...args);
  }
};

exports.SmartWebSocketConnection = class SmartWebSocketConnection {
  constructor(config) {
    /* check config */
    if (!config) {
      throw new Error('SmartWebSocketConnection - config must be present');
    }
    if (typeof config.url !== 'string') {
      throw new Error('SmartWebSocketConnection - config.url must be string');
    }
    if (typeof config.WebsocketClass !== 'function') {
      throw new Error('SmartWebSocketConnection - config.WebsocketClass must be given');
    }

    /* get config */
    this.url = config.url;
    this.WebsocketClass = config.WebsocketClass;

    /* general setup */
    this.enabled   = false;
    this.connected = false;
    this.websocketConnectionsPossiblyFiltered = false;
    this.onDataCallback = null;
    this.onConnectionStatusChanged = null;
    this.onErrorCallback = null;
    this.failsInARow = 0;
    this.ws = null;
  }

  setOnDataCallback(cb) {
    this.onDataCallback = cb;
  }

  setOnConnectionStatusChanged(cb) {
    this.onConnectionStatusChanged = cb;
  }

  setOnErrorCallback(cb) {
    this.onErrorCallback = cb;
  }

  setWebsocketConnectionsPossiblyFiltered(yesNo) {
    this.websocketConnectionsPossiblyFiltered = yesNo;
  }

  isConnected() {
    return this.connected;
  }

  getFailsInARow() {
    return this.failsInARow;
  }

  _sleepAndReconnect() {
    // Note: We handle failed connection (closed with wasClean flag set to false)
    // using onclose event, but websocketConnectionsPossiblyFiltered is triggered
    // inside onerror event.
    //
    // It seems that FF sends extra onerror callback when connection closed on
    // server side (first dropdown before reconnect loop), but Chrome does not.
    // Due to that difference, FF fall into 20s delay after 3 tries, when Chrome
    // (and IE) after 4 tries.

    let reconnectTime;
    if (this.websocketConnectionsPossiblyFiltered) {
      // WS failed, but ajax works.
      // Probably something wrong with WS only.
      LOG('BrowserSmartWebSocket: ws filtered, going to reconnect after 20s');
      reconnectTime = 20000;

    } else if (this.getFailsInARow() === 1) {
      LOG('BrowserSmartWebSocket: going to reconnect in 100ms');
      // First reconnect. Maybe some minor connection drop - reconnect quickly.
      reconnectTime = 100;

    } else {
      // Default way - try reconnect every 500ms.
      LOG('BrowserSmartWebSocket: going to reconnect in 500ms after', this.getFailsInARow(), 'failed tries');
      reconnectTime = 500;
    }

    setTimeout(() => {
      this._reconnect();
    }
    , reconnectTime);
  }

  _reconnect() {
    LOG('BrowserSmartWebSocket: reconnect()');
    this.connect();
  }

  _setConnectionStatus(connected) {
    if (this.connected !== connected) {
      this.connected = connected;
      if (this.onConnectionStatusChanged) {
        this.onConnectionStatusChanged(this);
      }
    }
  }

  connect() {
    if (this.enabled && (!this.connected)) {
      this.ws = new this.WebsocketClass(this.url);

      this.ws.onopen = () => {
        this.setWebsocketConnectionsPossiblyFiltered(false);
        this._setConnectionStatus(true);
      };

      this.ws.onclose = closeEvent => {
        LOG('BrowserSmartWebSocket: onclose', closeEvent.wasClean);
        this._setConnectionStatus(false);
        if (!closeEvent.wasClean) {
          this.failsInARow++;
        }
        if (this.enabled) {
          this._sleepAndReconnect();
        }
      };

      this.ws.onerror = err => {
        LOG('BrowserSmartWebSocket: onerror');
        if (this.onErrorCallback) {
          this.onErrorCallback(this, err);
        }
        this._setConnectionStatus(false);
      };

      this.ws.onmessage = msg => {
        this.failsInARow = 0;

        if (this.onDataCallback) {
          this.onDataCallback(this, msg);
        } else {
          LOG('BrowserSmartWebSocket: got message (but there is no callback registered):', msg);
        }
      };
    }
  }

  start() {
    if (!this.enabled) {
      if (typeof this.WebsocketClass !== 'undefined' && this.WebsocketClass !== null) {
        this.enabled = true;
        this.connect();
      }
    }
  }

  stop() {
    if (this.enabled) {
      this.enabled = false;
      if (this.connected) {
        this.ws.close();
      }
    }
  }

  send(msg) {
    this.ws.send(msg);
  }
}
