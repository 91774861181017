import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';

interface Props {
  children: ReactNode;
  [key: string]: any;
}

export const MainBodyContent = ({ children, ...rest }: Props) => {
  return (
    <Box as="main" minHeight="calc(100vh - 170px)" py={{ base: 4, md: 8 }} {...rest}>
      {children}
    </Box>
  );
};
