import React from "react";
import { LegalPageTemplate, LegalDisclaimer, NotFoundPage } from "components";
import { Container } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { constants } from "../../../config/constants";

export const AcceptableUsePolicyPage = () => {
  /**
   * GIB version
   */
  if (constants.businessEntity === "gib") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Acceptable Use Policy</title>
        </Helmet>

        <Container>
          <article>
            <h1>Acceptable Use Policy</h1>
            <h2>PLEASE READ THE TERMS OF THIS POLICY CAREFULLY</h2>

            <section>
              <h3>WHAT’S IN THESE TERMS?</h3>
              <p>This Acceptable Use Policy sets out the content standards that apply when you upload content to our site, make contact with other users on our site, link to our site, or interact with our site in any other way.</p>
            </section>

            <section>
              <h3>WHO WE ARE AND HOW TO CONTACT US</h3>
              <p>https://www.londonlink.io is a site operated by LondonLink (GI) Limited (&quot;We&quot; or “LLGI”).</p>
              <p>We are registered in Gibraltar under company number 121194 and have our registered office at Madison Building, Midtown, Queensway, Gibraltar, GX11 1AA.</p>
              <p>We are a limited company.</p>
              <p>To contact us, please email <a href="mailto:hello@londonlink.io">hello@londonlink.io</a>.</p>
            </section>

            <section>
              <h3>BY USING OUR SITE YOU ACCEPT THESE TERMS</h3>
              <p>By using our site, you confirm that you accept the terms of this policy and that you agree to comply with them.</p>
              <p>If you do not agree to these terms, you must not use our site.</p>
              <p>We recommend that you print a copy of these terms for future reference.</p>
            </section>

            <section>
              <h3>THERE ARE OTHER TERMS THAT MAY APPLY TO YOU</h3>
              <p>This Acceptable Use Policy applies in addition to our <Link to="/privacy-policy">Privacy
                Policy</Link>, <Link to="/cookie-policy">Cookie Policy</Link>, <Link
                to="/general-terms-and-conditions">General Terms and Conditions</Link> and <Link
                to="/trading-terms-and-conditions">Trading Terms and
                Conditions</Link>.
              </p>
            </section>

            <section>
              <h3>WE MAY MAKE CHANGES TO THE TERMS OF THIS POLICY</h3>
              <p>We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time.</p>
            </section>

            <section>
              <h3>PROHIBITED USES</h3>
              <p>You may use our site only for lawful purposes. You may not use our site:</p>
              <ul>
                <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
                <li>For the purpose of harming or attempting to harm minors in any way.</li>
                <li>To send, knowingly receive, upload, download, use or re-use any material which is damaging or potentially damaging to the goodwill of LLGI or any individual or entity related to it.</li>
                <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of similar solicitation (spam).</li>
                <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses, worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar computer code designed to adversely affect the operation of any computer software or hardware.</li>
                <li>If you are under the age of 18 years old.</li>
              </ul>
              <br />
              <p>You also agree:</p>
              <br />
              <ul>
                <li>Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions of our General Terms and Conditions.</li>
                <li>Not to access without authority, interfere with, damage or disrupt:</li>
                <ul>
                  <li>any part of our site;</li>
                  <li>any equipment or network on which our site is stored;</li>
                  <li>any software used in the provision of our site; or</li>
                  <li>any equipment or network or software owned or used by any third party.</li>
                </ul>
              </ul>
            </section>

            <section>
              <h3>INTERACTIVE SERVICES</h3>
              <p>We may from time to time provide interactive services on or relating to our site, including, but not limited to:</p>
              <ul>
                <li>Chat rooms including ‘Tawk’ and/or live chat.</li>
                <li>Bulletin boards.</li>
                <li>Website customer enquiry forms.</li>
                <li>Blogs and the commenting features thereof.</li>
                <li>Know your client (KYC) forms for businesses (corporates), individuals or any other client due diligence.</li>
                <li>Email and other instant and non-instant messaging services.</li>
                <li>Telephone assistance.</li>
              </ul>

              <p>Where applicable, LLGI will do its best to advise users of any possible risks involved (and in particular for children, although this site is strictly not for anyone under the age of 18 years) from third parties when they use any interactive service provided on its site. LLGI will decide in each case whether it is appropriate to use moderation of the relevant service (including what kind of moderation to use) in the light of any risks. However, we are under no obligation to oversee, monitor or moderate any interactive service we provide on our site, and we expressly exclude our liability for any loss or damage arising from the use of any interactive service by a user, whether the service is moderated or not.</p>
            </section>

            <section>
              <h3>CONTENT STANDARDS</h3>
              <p>These content standards apply to any and all material which you contribute to our site (a “Contribution”), and to any interactive services associated with it.</p>
              <p>The Content Standards must be complied with in spirit as well as to the letter. The standards apply to each part of any Contribution as well as to its whole.</p>
              <p>LLGI will determine, in its sole discretion, whether a Contribution breaches the Content Standards.</p>
              <p>A Contribution must:</p>
              <ul>
                <li>Be accurate (where it states facts).</li>
                <li>Be genuinely held (where it states opinions).</li>
                <li>Comply with the law applicable in Gibraltar and in any country from which it is posted.</li>
              </ul>
              <p>A Contribution must not:</p>
              <ul>
                <li>Be defamatory of any person or entity.</li>
                <li>Be obscene, offensive, hateful or inflammatory.</li>
                <li>Promote sexually explicit material.</li>
                <li>Promote violence.</li>
                <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age.</li>
                <li>Infringe any copyright, database right or trademark of any other person or entity.</li>
                <li>Be likely to deceive any person or entity.</li>
                <li>Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.</li>
                <li>Promote any illegal activity.</li>
                <li>Be in contempt of court.</li>
                <li>Be threatening, abuse or invade an entity or a person&rsquo;s privacy, or cause annoyance, inconvenience or needless anxiety to that entity or person.</li>
                <li>Be likely to harass, upset, embarrass, alarm or annoy any other person or entity.</li>
                <li>Impersonate any person or entity, or misrepresent your identity or affiliation with any person or entity.</li>
                <li>Give the impression that the Contribution emanates from LLGI&nbsp; &nbsp; &nbsp; or any person representing it, if this is not the case.</li>
                <li>Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by way of example only) copyright infringement or computer misuse.</li>
                <li>Contain a statement which you know or believe, or have reasonable grounds for believing, that members of the public to whom the statement is, or is to be, published are likely to understand as a direct or indirect encouragement or other inducement to the commission, preparation or instigation of acts of terrorism.</li>
                <li>Contain any advertising or promote any services or web links to other sites.</li>
              </ul>
            </section>

            <section>
              <h3>BREACH OF THIS POLICY</h3>
              <p>When we consider that a breach of this Acceptable Use Policy has occurred, we may take such action as we deem appropriate.</p>
              <p>Failure to comply with this Acceptable Use Policy constitutes a material breach of the <Link
                to="/general-terms-and-conditions">General Terms and Conditions</Link> upon which you are permitted to use our site, and may result in our taking any one or more of the following actions:</p>
              <ul>
                <li>Immediate, temporary or permanent withdrawal of your right to use our site.</li>
                <li>Immediate, temporary or permanent removal of any Contribution uploaded by you to our site.</li>
                <li>Issue of a warning to you.</li>
                <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach.</li>
                <li>Further legal action against you.</li>
                <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or as required by law.</li>
              </ul>
              <p>We exclude our liability for all actions we may take in response to breaches of this Acceptable Use Policy. The actions we may take are not limited to those described above, and we may take any other action we reasonably deem appropriate.</p>
            </section>

            <section>
              <h3>WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES?</h3>
              <p>If you are a consumer or a business, please note that the terms of this policy, its subject matter and its formation (and any non-contractual disputes or claims) are governed by Gibraltar law. You and we both agree that the courts of Gibraltar will have exclusive jurisdiction.</p>
            </section>
            <LegalDisclaimer version="1.2.3" name="Acceptable Use Policy" dateLastUpdated="January 2022" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  /**
   * UK version
   */
  if (constants.businessEntity === "uk") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Acceptable Use Policy</title>
        </Helmet>

        <Container maxW="container.xl">
          <article>
            <h1>Acceptable Use Policy</h1>
            <h2>PLEASE READ THE TERMS OF THIS POLICY CAREFULLY</h2>

            <section>
              <h3>WHAT’S IN THESE TERMS?</h3>
              <p>This acceptable use policy sets out the content standards that apply when you upload content to our
                site, make contact with other users on our site, link to our site, or interact with our site in any
                other way.
              </p>
            </section>

            <section>
              <h3>WHO WE ARE AND HOW TO CONTACT US</h3>
              <p>https://www.londonlinkotc.co.uk/ is a site operated by LondonLink OTC Limited
                (<strong>&quot;We&quot;</strong>). We are registered in England and Wales under company number
                11668544 and have our registered office at 71-75 Shelton Street, Covent Garden, London,
                United Kingdom, WC2H 9JQ.
              </p>
              <p>We are a limited company.</p>
              <p>To contact us, please email <a href="mailto:hello@londonlinkotc.co.uk">hello@londonlinkotc.co.uk</a> or
                telephone our customer service line on 0207 971 1013.
              </p>
            </section>

            <section>
              <h3>BY USING OUR SITE YOU ACCEPT THESE TERMS</h3>
              <p>By using our site, you confirm that you accept the terms of this policy and that you agree to comply
                with them.
              </p>
              <p>If you do not agree to these terms, you must not use our site.</p>
              <p>We recommend that you print a copy of these terms for future reference.</p>
            </section>

            <section>
              <h3>THERE ARE OTHER TERMS THAT MAY APPLY TO YOU</h3>
              <p>This Acceptable Use Policy applies in addition to our <Link to="/privacy-policy">Privacy
                Policy</Link>, <Link to="/cookie-policy">Cookie Policy</Link>, <Link
                to="/general-terms-and-conditions">General Terms and Conditions</Link> and Trading Terms and
                Conditions.
              </p>
            </section>


            <section>
              <h3>WE MAY MAKE CHANGES TO THE TERMS OF THIS POLICY</h3>
              We amend these terms from time to time. Every time you wish to use our site, please check these terms to
              ensure you understand the terms that apply at that time. These terms were most recently updated on 10th
              January 2020.
            </section>


            <section>
              <h3>PROHIBITED USES</h3>
              <p>You may use our site only for lawful purposes. You may not use our site:</p>
              <ul>
                <li>In any way that breaches any applicable local, national or international law or regulation.</li>
                <li>In any way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect.</li>
                <li>For the purpose of harming or attempting to harm minors in any way.</li>
                <li>To send, knowingly receive, upload, download, use or re-use any material which is damaging or
                  potentially damaging to the goodwill of the Company or any individual or entity related to it.
                </li>
                <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional
                  material or any other form of similar solicitation (spam).
                </li>
                <li>To knowingly transmit any data, send or upload any material that contains viruses, Trojan horses,
                  worms, time-bombs, keystroke loggers, spyware, adware or any other harmful programs or similar
                  computer code designed to adversely affect the operation of any computer software or hardware.
                </li>
                <li>If you are under the age of 18 years old.</li>
              </ul>

              <p>You also agree:</p>
              <ul>
                <li>Not to reproduce, duplicate, copy or re-sell any part of our site in contravention of the provisions
                  of our General Terms and Conditions.
                </li>
                <li>Not to access without authority, interfere with, damage or disrupt:</li>
                <ul>
                  <li>any part of our site;</li>
                  <li>any equipment or network on which our site is stored;</li>
                  <li>any software used in the provision of our site; or</li>
                  <li>any equipment or network or software owned or used by any third party.</li>
                </ul>
              </ul>
            </section>

            <section>
              <h3>INTERACTIVE SERVICES</h3>
              <p>We may from time to time provide interactive services on or relating to our site, including, but not
                limited to:
              </p>
              <ul>
                <li>Chat rooms including ‘Tawk’ and/or live chat.</li>
                <li>Bulletin boards.</li>
                <li>Website enquiry form.</li>
                <li>Blogs and the commenting features thereof.</li>
                <li>Know your client (KYC) forms for businesses (corporates), individuals or any other client due
                  diligence.
                </li>
                <li>Email and other instant and non-instant messaging services.</li>
                <li>Telephone assistance.</li>
              </ul>
              <p><strong>(interactive services.)</strong></p>
              <p>Where applicable, we will do our best for users to be aware of any possible risks involved(and in
                particular for children, although this site is strictly not for anyone under the age of 18 years) from
                third parties when they use any interactive service provided on our site, and we will decide in each
                case whether it is appropriate to use moderation of the relevant service (including what kind of
                moderation to use) in the light of any risks. However, we are under no obligation to oversee, monitor or
                moderate any interactive service we provide on our site, and we expressly exclude our liability for any
                loss or damage arising from the use of any interactive service by a user whether the service is
                moderated or not.
              </p>
            </section>

            <section>
              <h3>CONTENT STANDARDS</h3>
              <p>These content standards apply to any and all material which you contribute to our site
                (<strong>Contribution</strong>), and to any interactive services associated with it.
              </p>
              <p>The Content Standards must be complied with in spirit as well as to the letter. The standards apply to
                each part of any Contribution as well as to its whole.
              </p>
              <p>LondonLink OTC Limited will determine, in its sole discretion, whether a Contribution breaches the
                Content Standards.
              </p>
              <p>A Contribution must:</p>
              <ul>
                <li>Be accurate (where it states facts).</li>
                <li>Be genuinely held (where it states opinions).</li>
                <li>Comply with the law applicable in England and Wales and in any country from which it is posted.</li>
              </ul>
              <p>A Contribution must not:</p>
              <ul>
                <li>Be defamatory of any person or entity.</li>
                <li>Be obscene, offensive, hateful or inflammatory.</li>
                <li>Promote sexually explicit material.</li>
                <li>Promote violence.</li>
                <li>Promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or
                  age.
                </li>
                <li>Infringe any copyright, database right or trademark of any other person or entity.</li>
                <li>Be likely to deceive any person or entity.</li>
                <li>Breach any legal duty owed to a third party, such as a contractual duty or a duty of confidence.
                </li>
                <li>Promote any illegal activity.</li>
                <li>Be in contempt of court.</li>
                <li>Be threatening, abuse or invade an entity or a person’s privacy, or cause annoyance, inconvenience
                  or needless anxiety to that entity or person.
                </li>
                <li>Be likely to harass, upset, embarrass, alarm or annoy any other person or entity.</li>
                <li>Impersonate any person or entity, or misrepresent your identity or affiliation with any person or
                  entity.
                </li>
                <li>Give the impression that the Contribution emanates from LondonLink OTC Limited or any person
                  representing it, if this is not the case.
                </li>
                <li>Advocate, promote, incite any party to commit, or assist any unlawful or criminal act such as (by
                  way of example only) copyright infringement or computer misuse.
                </li>
                <li>Contain a statement which you know or believe, or have reasonable grounds for believing, that
                  members of the public to whom the statement is, or is to be, published are likely to understand as a
                  direct or indirect encouragement or other inducement to the commission, preparation or instigation of
                  acts of terrorism.
                </li>
                <li>Contain any advertising or promote any services or web links to other sites.</li>
              </ul>
            </section>

            <section>
              <h3>BREACH OF THIS POLICY</h3>
              <p>When we consider that a breach of this acceptable use policy has occurred, we may take such action as
                we deem appropriate
              </p>
              <p>Failure to comply with this acceptable use policy constitutes a material breach of the <Link
                to="/general-terms-and-conditions">General Terms and Conditions</Link> upon which you are
                permitted to use our site, and may result in our taking any one or unlimited combination of the
                following actions:
              </p>
              <ul>
                <li>Immediate, temporary or permanent withdrawal of your right to use our site.</li>
                <li>Immediate, temporary or permanent removal of any Contribution uploaded by you to our site.</li>
                <li>Issue of a warning to you.</li>
                <li>Legal proceedings against you for reimbursement of all costs on an indemnity basis (including, but
                  not limited to, reasonable administrative and legal costs) resulting from the breach.
                </li>
                <li>Further legal action against you.</li>
                <li>Disclosure of such information to law enforcement authorities as we reasonably feel is necessary or
                  as required by law.
                </li>
              </ul>
              <p>We exclude our liability for all actions we may take in response to breaches of this acceptable use
                policy. The actions we may take are not limited to those described above, and we may take any other
                action we reasonably deem appropriate.
              </p>
            </section>

            <section>
              <h3>WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES?</h3>
              <p>If you are a consumer, please note that the terms of this policy, its subject matter and its formation
                are governed by English law. You and we both agree that the courts of England and Wales will have
                exclusive jurisdiction.
              </p>
              <p>If you are a business, the terms of this policy, its subject matter and its formation (and any
                non-contractual disputes or claims) are governed by English law. We both agree to the exclusive
                jurisdiction of the courts of England and Wales.
              </p>
            </section>

            <LegalDisclaimer version="1.2" name="Acceptable Use Policy" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  return <NotFoundPage />;
};
