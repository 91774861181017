import React from "react";
import { PublicPageTemplate, ResetPasswordForm } from "components";
import { Helmet } from "react-helmet";
import { Box, Container, Heading } from "@chakra-ui/react";

export const ResetPasswordPage = () => {
  return (
    <PublicPageTemplate>
      <Container>
        <Helmet>
          <title>Reset your password</title>
        </Helmet>

        <Box maxW="450px">
          <Heading size="lg">Reset your password</Heading>
          <ResetPasswordForm />
        </Box>
      </Container>
    </PublicPageTemplate>
  );
};
