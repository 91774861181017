import React from "react";
import { Helmet } from "react-helmet";
import { useLocation, Link } from "react-router-dom";
import { Alert, AlertIcon, Box, Container, Heading, Stack, Text } from "@chakra-ui/react";
import { LoginForm } from "../LoginForm/LoginForm";
import { PublicPageTemplate } from "../../PublicPageTemplate/PublicPageTemplate";

interface RouterState {
  sessionExpired?: boolean;
}

export const LoginPage = () => {
  const { search } = useLocation<RouterState>();
  const urlParams = new URLSearchParams(search);

  return (
    <PublicPageTemplate>
      <Container>
        <Helmet>
          <title>Login</title>
        </Helmet>

        <Box maxW="600px">
          <Heading size="lg" mb={5}>Sign in to your account</Heading>

          {urlParams.get('sessionExpired') === '1' && (
            <Alert status="info" mb={5}>
              <AlertIcon />
              Your session has expired, please log in again.
            </Alert>
          )}

          <LoginForm />

          <Stack color="gray.600">
            <Text><Link to="/request-password-reset">Forgot password?</Link></Text>
            <Text>Don&apos;t have an account? <Link to="/register">Sign up</Link></Text>
          </Stack>
        </Box>
      </Container>

    </PublicPageTemplate>
  );
};

