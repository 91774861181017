import React, { Fragment, useEffect } from "react";
import { Field, FieldProps, Form, Formik } from "formik";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  Grid,
  Stack
} from "@chakra-ui/react";
import * as yup from "yup";
import { FormDisclaimer, SpinnerToTickIcon } from "components";
import { useCustomerForms, useCustomerVerification } from "hooks";
import { ComplianceActionStages } from "../../../../../../../shared/src/types/compliance";
import { DataStatuses } from "../../../../../../../shared/src/types/common";

export interface CertificationsFormValues {
  politicalOrganisation: boolean,
  businessNonIndividual: boolean,
  restrictedCountry: boolean,
  circumstances: boolean
}

const validationSchema = yup.object().shape({
  politicalOrganisation: yup.bool().oneOf([true], 'Field must be checked'),
  businessNonIndividual: yup.bool().oneOf([true], 'Field must be checked'),
  restrictedCountry: yup.bool().oneOf([true], 'Field must be checked'),
  circumstances: yup.bool().oneOf([true], 'Field must be checked')
});

const initialValues = {
  selectAll: false,
  politicalOrganisation: false,
  businessNonIndividual: false,
  restrictedCountry: false,
  circumstances: false
};

export const SelfCertificationForm = () => {
  const { submitForm, formsState, resetFormsState } = useCustomerForms();
  const { customerStageSelect } = useCustomerVerification();

  const isLoading = formsState.formSubmitRequest.dataStatus === DataStatuses.Loading;
  const isSuccess = formsState.formSubmitRequest.dataStatus === DataStatuses.Ok;

  useEffect(() => {
    // Move to next stage after success
    if (isSuccess) {
      // Add a small delay before moving onto the next step to allow the SpinnerToTickIcon component animation to finish
      setTimeout(() => {
        resetFormsState();
        customerStageSelect(ComplianceActionStages.t0Form);
      }, 1250);
    }
  }, [isSuccess]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: CertificationsFormValues) => submitForm({
        notTradingBehalfPoliticalIndividualOrOrganisation: values.politicalOrganisation,
        notTradingBehalfBusinessOrNonIndividual: values.businessNonIndividual,
        notCitizenOfRestrictedCountries: values.restrictedCountry,
        acknowledgeObligatedInformCircumstanceChanges: values.circumstances
      })}>
      {({ setFieldValue, values, setFieldTouched }) => {
        return (
          <Form id="certifications-form">
            <Stack spacing="3">
              <Field name="selectAll">
                {({ field }: FieldProps) => (
                  <FormControl>
                    <Checkbox
                      spacing={3}
                      alignItems="baseline"
                      {...field}
                      onChange={(event) => {
                        const value = event.currentTarget.checked;
                        setFieldValue('politicalOrganisation', value);
                        setFieldValue('businessNonIndividual', value);
                        setFieldValue('restrictedCountry', value);
                        setFieldValue('circumstances', value);

                        // Need this to sync up validation
                        // https://github.com/formium/formik/issues/2059
                        setFieldTouched('politicalOrganisation', !value);
                        setFieldTouched('businessNonIndividual', !value);
                        setFieldTouched('restrictedCountry', !value);
                        setFieldTouched('circumstances', !value);
                      }}
                      id="selectAll">
                      Confirm all below
                    </Checkbox>
                  </FormControl>
                )}
              </Field>

              <Divider />

              <Field name="politicalOrganisation">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.politicalOrganisation && form.touched.politicalOrganisation);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <Checkbox
                        size="md"
                        spacing={3}
                        isInvalid={isInvalid}
                        isChecked={values.politicalOrganisation}
                        {...field}
                        id="politicalOrganisation">
                        Click here to confirm you are not trading for or on behalf
                        of a political individual or organisation
                      </Checkbox>
                      <FormErrorMessage>{form.errors.politicalOrganisation}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Field name="businessNonIndividual">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.businessNonIndividual && form.touched.businessNonIndividual);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <Checkbox
                        size="md"
                        spacing={3}
                        isInvalid={isInvalid}
                        isChecked={values.businessNonIndividual}
                        {...field}
                        id="businessNonIndividual">
                        Click here to confirm you are not trading for or on behalf
                        of a business or non-individual entity
                      </Checkbox>
                      <FormErrorMessage>{form.errors.businessNonIndividual}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Field name="restrictedCountry">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.restrictedCountry && form.touched.restrictedCountry);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <Checkbox
                        size="md"
                        spacing={3}
                        isInvalid={isInvalid}
                        isChecked={values.restrictedCountry}
                        {...field}
                        id="restrictedCountry">
                        Click here to confirm you are not a citizen of the USA, Canada or Japan
                      </Checkbox>
                      <FormErrorMessage>{form.errors.restrictedCountry}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Field name="circumstances">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.circumstances && form.touched.circumstances);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <Checkbox
                        size="md"
                        spacing={3}
                        isInvalid={isInvalid}
                        isChecked={values.circumstances}
                        {...field}
                        id="circumstances">
                        Click here that you acknowledge that you are obliged
                        to inform us if your circumstances change
                      </Checkbox>
                      <FormErrorMessage>{form.errors.circumstances}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>
            </Stack>

            <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
              <FormDisclaimer name="Level 1: Self certification" />
            </Grid>

            {/* Handle Error */}
            {formsState.formSubmitRequest.dataStatus === "error" && (
              <Alert status="error" mt={5}>
                <AlertIcon />
                {formsState.formSubmitRequest.dataError?.message}
              </Alert>
            )}

            <Box textAlign="left" mb={5}>
              <Button
                type="submit"
                transition="all 0.5s linear"
                colorScheme={isSuccess ? "green" : "brand"}
                leftIcon={<Fragment />}
                spinnerPlacement="end"
                rightIcon={isSuccess ? <SpinnerToTickIcon size="sm" /> : <Fragment />}
                isLoading={isLoading}
                isDisabled={isLoading}
                loadingText="Submitting"
                id="submit">
                {isSuccess ? "Submitting" : "Submit"}
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
