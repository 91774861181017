import { useEffect, useState } from "react";
import { CustomerConfigModel, CustomerConfigModelState } from "../../../shared/src/models/customer/CustomerConfigModel";

const configModel = new CustomerConfigModel();

export function useCustomerConfig() {
  const [config, setConfig] = useState<CustomerConfigModelState>(configModel.getConfigState());

  // This fires when model state changes
  function onListen() {
    setConfig(configModel.getConfigState());
  }

  useEffect(() => {
    configModel.addListener(onListen);

    return function cleanup() {
      configModel.removeListener(onListen);
    };
  }, []);

  function fetchConfig() {
    return configModel.fetchConfig();
  }

  return {
    config,
    fetchConfig
  };
}
