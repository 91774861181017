import React from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import { CookiesProvider } from 'react-cookie';
import { ChakraProvider } from '@chakra-ui/react';
import { EnvCheck } from 'components';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { envars } from './config/envars';
import { theme } from './theme';

// Fonts
import '@fontsource/open-sans/300.css';
import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/open-sans/700.css';
import '@fontsource/open-sans/800.css';

const isReactSnap = navigator && navigator.userAgent === 'ReactSnap';

// Declare globals here
// testPayload used in testing
window.testPayload = {
  sumsub: {
    applicantId: undefined
  }
};

declare global {
  interface Window {
    testPayload: any;
    freshReload: boolean;
  }
}

const tagManagerArgs = {
  gtmId: envars.GTM.ID || '',
  auth: envars.GTM.AUTH,
  preview: envars.GTM.PREVIEW
};

if (envars.GTM.ID && !isReactSnap) {
  TagManager.initialize(tagManagerArgs);
}

const rootElement = document.getElementById('root');

const RootApp = () => {
  return (
    <React.StrictMode>
      <EnvCheck>
        <CookiesProvider>
          <ChakraProvider theme={theme}>
            <App />
          </ChakraProvider>
        </CookiesProvider>
      </EnvCheck>
    </React.StrictMode>
  );
};

render(<RootApp />, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
