import React from "react";
import { NonIndividualForm, PublicPageTemplate } from "components";
import { Container, Divider, Heading, Text } from "@chakra-ui/react";
import { Helmet } from "react-helmet";

export const NonIndividualCustomerPage = () => {
  return (
    <PublicPageTemplate>
      <Helmet>
        <title>Non-Individuals/ BUSINESSES/ CORPORATES</title>
        <meta name="description" content="" />
      </Helmet>

      <Container>
        <Heading mb={4} fontSize="26px" as="h1">
          Non-Individuals/ BUSINESSES/ CORPORATES
        </Heading>
        <Text fontSize="sm"><Text color="#e60000" as="span">*</Text> indicates required fields</Text>

        <Divider py={2} />

        <NonIndividualForm />
      </Container>
    </PublicPageTemplate>
  );
};
