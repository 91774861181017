interface Envars {
  BUSINESS_ENTITY: "gib" | "uk" | undefined;
  ENVIRONMENT: "local" | "development" | "staging" | "production" | undefined;
  API_URL: string | undefined;
  WS_URL: string | undefined;
  DISABLE_TAWK: boolean;
  GTM: {
    ID: string | undefined;
    AUTH: string | undefined;
    PREVIEW: string | undefined;
  },
  BLOCK_SEARCH_INDEXING: boolean;
}

/**
 * Load in and parse environment variables
 * Ideally, these shouldn't be used directly in the application, but exposed through "constants.ts"
 */
const envars: Envars = {
  BUSINESS_ENTITY: process.env.REACT_APP_BUSINESS_ENTITY as Envars['BUSINESS_ENTITY'],
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT as Envars['ENVIRONMENT'],
  API_URL: process.env.REACT_APP_API_URL,
  WS_URL: process.env.REACT_APP_WS_URL,
  DISABLE_TAWK: JSON.parse(process.env.REACT_APP_DISABLE_TAWK || 'false'),
  GTM: {
    ID: process.env.REACT_APP_GTM_ID,
    AUTH: process.env.REACT_APP_GTM_AUTH,
    PREVIEW: process.env.REACT_APP_GTM_PREVIEW,
  },
  BLOCK_SEARCH_INDEXING: JSON.parse(process.env.REACT_APP_BLOCK_SEARCH_INDEXING || 'false'),
};

export { envars };
