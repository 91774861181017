import React, { useState } from "react";
import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup, Stack,
} from "@chakra-ui/react";
import { FieldProps } from "formik";

interface Props {
  field: FieldProps['field'];
  form: FieldProps['form'];
  label: string;
  isRequired?: boolean;
  type?: "text" | "textarea" | "radio"
  options?: { label: string, value: any }[];
  helpText?: string;
}

export const FormFieldRadio = ({ field, form, label, isRequired, helpText, options }: Props) => {
  const [value, setValue] = useState<string>("");
  const hasError = form.errors.hasOwnProperty(field.name);

  const handleChange = (newValue: string) => {
    setValue(newValue);
    form.setFieldValue(field.name, (newValue.toLowerCase() === 'true'));
  };

  return (
    <FormControl isInvalid={hasError} isRequired={isRequired}>
      <FormLabel fontSize="sm" >
        {label}
      </FormLabel>

      <FormHelperText mb={2}>{helpText}</FormHelperText>

      <RadioGroup {...field} onChange={(e) => handleChange(e)} value={value}>
        <Stack direction="row">
          {options?.length && options.map((option) => (
            <Radio key={option.value} value={option.value}>{option.label}</Radio>
          ))}
        </Stack>
      </RadioGroup>

      <FormErrorMessage fontSize="xs">{form.errors[field.name]}</FormErrorMessage>
    </FormControl>
  );
};
