import React, { Fragment, ReactNode, useEffect, useState } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useCustomerAuth, useCustomerProfile } from "hooks";
import { FullScreenLoading } from "../FullScreenLoading/FullScreenLoading";
import { envars } from "../../config/envars";
import { useMigrationModalStore } from "../../store/MigrationModalStore";
import { DataStatuses } from "../../../../shared/src/types/common";

interface Props extends RouteProps {
  children: ReactNode;
}

export const PrivateRoute = ({ children, ...rest }: Props) => {
  const { authState } = useCustomerAuth();
  const { openModal } = useMigrationModalStore();

  /**
   * If customer is on UK version of the website, show migration popup instead of the page.
   */
  useEffect(() => {
    if (envars.BUSINESS_ENTITY === "uk") {
      openModal();
    }
  }, []);

  if (envars.BUSINESS_ENTITY === "uk") {
    return null;
  }

  interface RouteContentProps {
    routeProps: RouteProps;
  }

  const RouteContent = ({ routeProps }: RouteContentProps) => {
    const { fetchProfile, profile } = useCustomerProfile();
    const [isLoading, setIsLoading] = useState(profile.contactDetails === null);

    useEffect(() => {
      // If authenticated, fetch customer profile
      if (authState.authenticated) {
        if (profile.contactDetails === null) {
          fetchProfile();
        }
      } else {
        setIsLoading(false);
      }
    }, [authState.authenticated]);

    // Remove loading screen when profile data loaded
    useEffect(() => {
      if (profile.dataStatus === DataStatuses.Ok) {
        setIsLoading(false);
      }

      if (profile.dataStatus === DataStatuses.Error) {
        throw new Error("Failed to get profile data");
      }
    }, [profile.dataStatus]);

    if (isLoading) {
      return <FullScreenLoading />;
    }

    if (authState.authenticated) {
      return (
        <Fragment>
          {children}
        </Fragment>
      );
    } else {
      return (
        <Redirect
          to={{
            pathname: '/login/',
            search: "?sessionExpired=1",
            state: { from: routeProps.location }
          }}
        />
      );
    }
  };

  return (
    <Route
      {...rest}
      render={(routeProps: RouteProps) => <RouteContent routeProps={routeProps} />}
    />
  );
};
