import { useEffect, useState } from "react";
import { CustomerSumSubModel, CustomerSumSubModelState } from "../../../shared/src/models/customer/CustomerSumSubModel";

const sumsubModel = new CustomerSumSubModel();

export function useCustomerSumSub() {
  const [sumsubState, setSumsubState] = useState<CustomerSumSubModelState>(sumsubModel.getSumSubState());

  // This fires when model state changes
  function onListen() {
    setSumsubState(sumsubModel.getSumSubState());
  }

  useEffect(() => {
    sumsubModel.addListener(onListen);

    return function cleanup() {
      sumsubModel.removeListener(onListen);
    };
  }, []);

  function getWebSDKToken() {
    sumsubModel.getWebSDKToken();
  }

  return {
    sumsubState,
    getWebSDKToken
  };
}
