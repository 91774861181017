import { useCustomerVerification } from "./useCustomerVerification";
import { Compliance } from "../../../shared/src/types/compliance";

export function useComplianceState() {
  const { verificationState } = useCustomerVerification();
  const { complianceState } = verificationState;
  const complianceStateDefined = complianceState as Compliance;

  // States
  const sumsubFinalFailed = complianceStateDefined.actions.kycSumsub.state === 'failedFinal';
  const level1 = complianceStateDefined.state.level1;
  const level2 = complianceStateDefined.state.level2;
  const tradingState = complianceStateDefined.state.trading;
  const dnt = tradingState === "DNT";

  // The customer must agree to terms when registering, so if there are new terms released flag this
  const newTermsNotAcceptedSinceRegistering = complianceStateDefined.actions.termsAccepted.status === "todo";

  // Level 1
  const level1Required = level1 === 'todo';
  const level1Completed = level1 === 'completed';

  // Level 2
  const level2Required = level2 === 'todo';
  const level2Completed = level2 === 'completed';
  const level2Optional = level2 === 'optional';
  const level2Failed = level2 === 'failed';
  const level2Pending = level2 === 'pending';

  const actions = complianceStateDefined.actions;

  return {
    sumsubFinalFailed,
    level1,
    level2,
    tradingState,
    dnt,
    level1Required,
    level1Completed,
    level2Required,
    level2Completed,
    level2Optional,
    level2Failed,
    level2Pending,
    actions,
    newTermsNotAcceptedSinceRegistering
  };
}
