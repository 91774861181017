import React from "react";
import { LegalPageTemplate, LegalDisclaimer, NotFoundPage } from "components";
import { Container } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { constants } from "../../../config/constants";

// This includes both a GIB and UK version
export const GDPRFAQPage = () => {
  /**
   * GIB version
   */
  if (constants.businessEntity === "gib") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>GDPR Frequently Asked Questions</title>
        </Helmet>

        <Container>
          <article>
            <h1>GDPR Frequently Asked Questions</h1>

            <section>
              <h2>What is GDPR?</h2>
              <p>
                The General Data Protection Regulation (“GDPR”) is a legislative regime that seeks to protect, and remove obstacles to the flow of, personal data, while imposing obligations on organisations that process such data. The EU GDPR came into effect on 25 May 2018 and applied in Gibraltar from that date up to and including 31 December 2020.
              </p>
              <p>On 1 January 2021, the EU GDPR was superseded in the UK by the UK GDPR and in Gibraltar by the Gibraltar GDPR after the United Kingdom’s, and consequently Gibraltar’s, exit from the EU and the end of the Brexit transition period (although the EU GDPR may continue to apply to UK and Gibraltar controllers and processors who have an establishment in the EU or who offer goods or services to data subjects in the EU or who monitor their behaviour in the EU).</p>
              <p>The UK GDPR remains in force in the UK and should be read in conjunction with the Data Protection Act 2018 “UK DPA”). The Gibraltar GDPR remains in force in Gibraltar and should be read in conjunction with Gibraltar’s Data Protection Act 2004 (“Gibraltar DPA”).</p>
              <p>Each jurisdiction’s respective GDPR and DPA legislation governs how organisations process information about individuals, including but not limited to the collection, recording, structuring, storage, use and disclosure or transfer of personal data to third parties. Further, they protect individuals with regard to the processing of data, in particular by:</p>
              <ol>
                <li>requiring personal data to be processed lawfully, fairly and transparently, on the basis of the data subject’s consent or another specified basis;
                </li>
                <li>conferring rights on the data subject to obtain information about the processing of personal data and to require inaccurate personal data to be rectified; and
                </li>
                <li> conferring responsibility to monitor and enforce the UK/Gibraltar GDPR and the UK/Gibraltar DPA on the relevant Commissioner in each jurisdiction (the Information Commissioner’s Office (“ICO”) in the UK and the Gibraltar Regulatory Authority (“GRA”) in Gibraltar).
                </li>
              </ol>
            </section>

            <section>
              <h2>What do the different terms mean?</h2>
              <p>The Gibraltar GDPR sets out a number of definitions, including the following:</p>
              <ul>
                <li>“personal data” is “any information relating to an identified or identifiable natural person (‘data subject’); an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or <em>to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person”</em>;
                </li>
                <li>“processing” as <em>“any operation or set of operation which is performed on personal data or on sets of personal data, whether or no by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction”</em>;</li>
                <li>“controller” as <em>“the natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of personal data”</em>; and</li>
                <li>“processor” as <em>“a natural or legal person, public authority, agency or other body which processes personal data on behalf of the controller.</em></li>
              </ul>
            </section>

            <section>
              <h2>Who does the Gibraltar GDPR apply to?</h2>
              <p>The Gibraltar GDPR applies to the processing of personal data:</p>
              <ul>
                <li>in the context of the activities of an establishment of a controller or a processor in Gibraltar, regardless of whether the processing takes place in Gibraltar or elsewhere;</li>
                <li>of data subjects who are in Gibraltar by a controller or processor not established in Gibraltar, where the processing activities relate to (a) offering goods or services to those data subjects in Gibraltar or (b) monitoring data subjects’ behaviour taking place in Gibraltar; and</li>
                <li>by a controller not established in Gibraltar but where Gibraltar law applies by virtue of public international law.</li>
              </ul>
              <p>LondonLink (GI) Limited (“LondonLink GI”) is a company incorporated in Gibraltar and is subject to the Gibraltar GDPR and the DPA.</p>
            </section>

            <section>
              <h2>What responsibilities do companies have under the Gibraltar GDPR?</h2>
              <p>LondonLink GI and other companies must ensure that a data subject’s consent to the processing of personal data is clear, affirmative, and in plain language, and must make it easy for data subjects to withdraw consent at any time if they wish to do so.</p>
              <p>Article 13 of the Gibraltar GDPR provides that at the time that personal data is obtained, controllers must provide data subjects with certain information, namely:</p>
              <ul>
                <li>the identity and the contact details of the controller (and, where applicable, the controller’s representative);</li>
                <li>the contact details of the data protection officer, where applicable;</li>
                <li>the purposes of the processing for which the personal data are intended as well as the legal basis for the processing;</li>
                <li>the legitimate interests pursued by the controller or by a third party (where the processing is necessary and permitted for the purposes of those interests);</li>
                <li>the recipients or categories of recipients of the personal data, if any;</li>
                <li>where applicable, the fact that the controller intends to transfer personal data to a third country or international organisation and related information;</li>
                <li>the period for which the personal data will be stored (or the criteria used to determine that period);</li>
                <li>the existence of the right to request from the controller access to and rectification or erasure of personal data or restriction of processing concerning the data subject or to object to processing as well as the right to data portability;</li>
                <li>the existence of the right to withdraw consent at any time;</li>
                <li>the existence of the right to lodge a complaint with the Commissioner (i.e. the GRA);</li>
                <li>whether the provision of personal data is a statutory or contractual requirement, or a requirement necessary to enter into a contract, as well as whether the data subject is obliged to provide the personal data and of the possible consequences of failure to provide such data; and</li>
                <li>the existence of automated decision-making, including profiling and meaningful information about the logic involved, as well as the significance and the envisaged consequences of such processing for the data subject.</li>
              </ul>
            </section>

            <section>
              <h2>Are there any specific rules businesses should be following in order to ensure compliance?</h2>
              <p>Article 5 of the Gibraltar GDPR provides that personal data must be:</p>
              <ul>
                <li>processed lawfully, fairly and in a transparent manner;</li>
                <li>collected only for specified, explicit and legitimate purposes;</li>
                <li>adequate, relevant and limited to what is necessary;</li>
                <li>accurate and, where necessary, kept up to date;</li>
                <li>held only for the time necessary and no longer; and</li>
                <li>processed in a manner that ensures appropriate security of the personal data.</li>
              </ul>
            </section>

            <section>
              <h2>What are the penalties for failing to comply with the Gibraltar GDPR?</h2>
              <p>The Gibraltar GDPR has introduced a tiered approach to fines, meaning that the severity of the breach will determine the fine imposed.</p>
              <p>The maximum fine a company can face is 4% of their annual global turnover or £17.5 million, whichever is higher.</p>
              <p>Less serious violations, such as having improper records, or failing to notify of any breaches, can be fined a maximum of 2% of their annual global turnover or £8.7 million, whichever is higher.</p>
            </section>

            <section>
              <h2>Do all organisations now have to appoint a Data Protection Officer (“DPO”)?</h2>
              <p>Article 37 of  the Gibraltar GDPR provides that the controller and the processor shall appoint a DPO where:</p>
              <ul>
                <li>the processing is carried out by a public authority or body (with the exception of courts acting in their judicial capacity);</li>
                <li>the core activities of the controller or the processor consist of processing operations which require regular and systematic monitoring of data subjects on a large scale; or</li>
                <li>the core activities of the controller or the processor consist of processing on a large scale special categories of data or personal data relating to criminal convictions and offences.</li>
              </ul>
              <p>Where none of the above criteria apply, the relevant organisation is not required to appoint a DPO (although it may still wish to do so) but it must nevertheless ensure that it has sufficient staff and skills in place to be able to carry out its obligations under the Gibraltar GDPR.</p>
            </section>

            <section>
              <h2>What rights do individuals have under the Gibraltar GDPR?</h2>
              <p>The key rights of data subjects under the Gibraltar GDPR are:</p>
              <ul>
                <li>the right to be informed - organisations must be completely transparent in how they are using personal data;</li>
                <li>the right of access - i.e. to know exactly what information is held about them and how it is processed;</li>
                <li>the right of rectification - i.e. to have personal data rectified if it is inaccurate or incomplete;</li>
                <li>the right to erasure (or &apos;the right to be forgotten&apos;) - to have their personal data deleted or removed without the need for a specific reason as to why they wish to discontinue;</li>
                <li>the right to restrict processing - i.e. to block or suppress processing of their personal data;</li>
                <li>the right to data portability - i.e. to retain and reuse their personal data for their own purpose;</li>
                <li>the right to object - i.e. in certain circumstances, to object to their personal data being used. This includes, if a company uses personal data for the purpose of direct marketing or for scientific and historical research;</li>
                <li>the right to automated decision making and profiling - i.e. not to be subject to a decision based solely on automated processing, including profiling, which produces legal effects concerning, or significantly affecting, them.  This is intended to protect data subjects against the risk that a potentially damaging decision is made without human intervention; and</li>
                <li>the right to complain to the Commissioner (i.e. the GRA) if they consider that the processing of their personal data infringes the Gibraltar GDPR.</li>
              </ul>
            </section>

            <LegalDisclaimer version="2.0" name="GDPR FAQ" dateLastUpdated="January 2022" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  /**
   * UK version
   */
  if (constants.businessEntity === "uk") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>GDPR Frequently Asked Questions</title>
        </Helmet>

        <Container>
          <article>
            <h1>GDPR Frequently Asked Questions</h1>

            <section>
              <h2>What is GDPR?</h2>
              <p>
                GDPR stands for General Data Protection Regulation and is the new European Union Regulation set to
                replace the Data Protection Directive (DPD) and The UK Data Protection Act 1998. After many years
                of debate it was approved by the EU Parliament on April 14th 2016 and involves the protection of
                personal data and the rights of individuals. Its aim is to ease the flow of personal data across
                the 28 EU member states.
              </p>
            </section>

            <section>
              <h2>When will the GDPR come into effect?</h2>
              <p>
                The Regulation will come into effect on the 25th May 2018 and will bring in significant changes to
                current data protection laws as we know them. Any company deemed non-compliant will face hefty fines.
              </p>
            </section>

            <section>
              <h2>Who does GDPR apply to?</h2>
              <p>
                Any organisation which processes and holds the personal data of data subjects residing in the EU will
                be obliged to abide by the laws set out by GDPR. This applies to every organisation, regardless of
                whether or not they themselves reside in one of the 28 EU member states.
              </p>
            </section>

            <section>
              <h2>What responsibilities will companies have under this new regulation?</h2>
              <p>Rules for obtaining valid consent to use personal information will become much tougher when the GDPR
                comes into force. Therefore, companies must ensure that consent is clear, affirmative, and in plain
                language. Companies must also make it easy for data subjects to withdraw consent if they wish to do so.
              </p>
              <p>According to the Information Commissioner&apos;s Office (ICO), organisations are expected to:</p>
              <p>
                <em>&quot;..... put into place comprehensive but proportionate governance measures. Good practice tools
                  that the ICO has championed for a long time such as privacy impact assessments and privacy by design
                  are
                  now legally required in certain circumstances. Ultimately, these measures should minimise the risk of
                  breaches and uphold the protection of personal data. Practically, this is likely to mean more policies
                  and procedures for organisations, although many organisations will already have good governance
                  measures
                  in place.&quot;</em>
              </p>
            </section>

            <section>
              <h2>What kind of information does the GDPR apply to?</h2>
              <p>Much like the Data Protection Act 1998, GDPR applies to personal data. The current Data Protection
                Directive defines personal data as; <em>&quot;any information relating to an identified or identifiable
                  natural person (&quot;data subject&quot;); an identifiable person is one who can be identified,
                  directly
                  or indirectly, in particular by reference to an identification number or to one or more factors
                  specific
                  to his physical, physiological, mental, economic, cultural or social identity.&quot;</em></p>

              <p>However, although this definition will mostly remain unchanged, it will be slightly more detailed in
                that
                it will make clear that online identifiers, such as an IP address, will also be classed as personal
                data.
              </p>

              <p>Sensitive personal data - The GDPR refers to sensitive personal data as <em>&quot;special categories of
                personal data which uniquely identify a person.&quot;</em> This will include genetic data and biometric
                data.
              </p>
            </section>

            <section>
              <h2>Are there any specific rules businesses should be following in order to ensure
                compliance?</h2>
              <p>Article 5 of the EU GDPR states that personal data must be:</p>
              <ul>
                <li>Processed lawfully, fairly and in a transparent manner</li>
                <li>Collected only for specified, explicit and legitimate purposes</li>
                <li>Adequate, relevant and limited to what is necessary</li>
                <li>Accurate and kept up to date</li>
                <li>Held only for the absolute time necessary and no longer</li>
                <li>Processed in a manner that ensures appropriate security of the personal data</li>
              </ul>
            </section>

            <section>
              <h2>What will the penalties be for failing to comply with GDPR?</h2>
              <p>The GDPR have introduced a tiered approach to fines, meaning that the severity of the breach will
                determine the fine imposed.
              </p>
              <p>The maximum fine a company can face is 4% of their annual global turnover, or €20 million, whichever is
                the highest.
              </p>
              <p>Less serious violations, such as having improper records, or failing to notify of any breaches, can be
                fined a maximum of 2% of their annual global turnover, or €10 million.
              </p>
            </section>

            <section>
              <h2>What effect, if any, does Brexit have on GDPR?</h2>
              <p>Even though UK Prime Minister, Theresa May, has now announced a definitive date (29th March 2017) to
                begin the process of leaving the European Union, Brexit is still expected to take at least two years to
                take full effect, therefore, UK businesses still need to become GDPR ready by 25th May 2018.
              </p>
            </section>

            <section>
              <h2>Do all organisations now have to appoint a Data Protection Officer (DPO)?</h2>
              <p>It is not necessarily compulsory for all organisations to appoint a DPO as this will be dependent upon
                a
                number of factors. According to the ICO, a company should appoint a DPO if they:
              </p>
              <ul>
                <li>are a public authority (with the exception of courts acting in their judicial capacity)</li>
                <li>carry out large scale systematic monitoring of individuals, such as, online behaviour tracking; or
                </li>
                <li>carry out large scale processing of special categories of data or data relating to criminal
                  convictions and offences
                </li>
              </ul>
              <p>Any organisation is able to appoint a DPO if they wish to do so. However, even if a company chooses not
                {/* noinspection SpellCheckingInspection */}
                to appoint a DPO because the above doesn&apos;t apply to them, they must still ensure that they have
                sufficient staff and skills in place to be able to carry out their obligations under the GDPR.
              </p>
            </section>

            <section>
              <h2>What rights will individuals have under GDPR?</h2>
              <p>There are 8 fundamental rights of individuals under GDPR. These are:</p>
              <ul>
                <li>The right to be informed - Organisations must be completely transparent in how they are using
                  personal
                  data.
                </li>
                <li>The right of access - Individuals will have the right to know exactly what information is held about
                  them and how it is processed.
                </li>
                <li>The right of rectification - Individuals will be entitled to have personal data rectified if it is
                  inaccurate or incomplete.
                </li>
                <li>The right to erasure - Also known as &apos;the right to be forgotten&apos;, this refers to an
                  individual&apos;s right to having their personal data deleted or removed without the need for a
                  specific
                  reason as to why they wish to discontinue.
                </li>
                <li>The right to restrict processing - Refers to an individual&apos;s right to block or suppress
                  processing of their personal data.
                </li>
                <li>The right to data portability - This allows individuals to retain and reuse their personal data for
                  their own purpose.
                </li>
                <li>The right to object - In certain circumstances, individuals are entitled to object to their personal
                  data being used. This includes, if a company uses personal data for the purpose of direct marketing,
                  scientific and historical research, or for the performance of a task in the public interest.
                </li>
                <li>Rights of automated decision making and profiling - The GDPR has put in place safeguards to protect
                  individuals against the risk that a potentially damaging decision is made without human intervention.
                  For example, individuals can choose not to be the subject of a decision where the consequence has a
                  legal bearing on them, or is based on automated processing.
                </li>
              </ul>
            </section>

            <LegalDisclaimer version="1.0" name="GDPR FAQ" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  return <NotFoundPage />;
};
