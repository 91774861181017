import React from "react";
import { LegalPageTemplate, LegalDisclaimer, NotFoundPage } from "components";
import { Container } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { constants } from "../../../config/constants";

export const CookiePolicyPage = () => {
  /**
   * GIB version
   */
  if (constants.businessEntity === "gib") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Cookie Policy</title>
        </Helmet>

        <Container>
          <article>
            <h1>Cookie Policy</h1>
            <p><strong>INFORMATION ABOUT OUR USE OF COOKIES</strong></p>
            <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you with a good experience when you browse our website and also allows us to improve our site. By continuing to browse the site, you are agreeing to our use of cookies.</p>
            <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.</p>
            <p>We use the following cookies:</p>
            <ul>
              <li><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website, use a shopping cart or make use of e-billing services.
              </li>
              <li><strong>Analytical/performance cookies.</strong> These allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.
              </li>
              <li><strong>Functionality cookies.</strong> These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).
              </li>
              <li><strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have accessed and the links you have followed. We will use this information to make our website and any advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.
              </li>
            </ul>
            <p>Please note that third parties (including, for example, advertising networks and providers of external services like web traffic analysis services) may also use cookies, over which we have no control.</p>
            <p>You block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies.</p>
            <p><strong>However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our site.</strong></p>
            <LegalDisclaimer version="2.0" name="Cookie Policy" dateLastUpdated="January 2022" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  /**
   * UK version
   */
  if (constants.businessEntity === "uk") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Cookie Policy</title>
        </Helmet>

        <Container maxWidth="container.xl">
          <article>
            <h1>Cookie Policy</h1>
            <p><strong>INFORMATION ABOUT OUR USE OF COOKIES</strong></p>
            <p>Our website uses cookies to distinguish you from other users of our website. This helps us to provide you
              with a good experience when you browse our website and also allows us to improve our site. By continuing
              to browse the site, you are agreeing to our use of cookies.
            </p>
            <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your
              computer if you agree. Cookies contain information that is transferred to your computer’s hard drive.
            </p>
            <p>We use the following cookies:</p>
            <ul>
              <li><strong>Strictly necessary cookies.</strong> These are cookies that are required for the operation of
                our website. They include, for example, cookies that enable you to log into secure areas of our website,
                use a shopping cart or make use of e-billing services.
              </li>
              <li><strong>Analytical/performance cookies.</strong> They allow us to recognise and count the number of
                visitors and to see how visitors move around our website when they are using it. This helps us to
                improve the way our website works, for example, by ensuring that users are finding what they are looking
                for easily.
              </li>
              <li><strong>Functionality cookies.</strong> These are used to recognise you when you return to our
                website. This enables us to personalise our content for you, greet you by name and remember your
                preferences (for example, your choice of language or region).
              </li>
              <li><strong>Targeting cookies.</strong> These cookies record your visit to our website, the pages you have
                visited and the links you have followed. We will use this information to make our website and the
                advertising displayed on it more relevant to your interests. We may also share this information with
                third parties for this purpose.
              </li>
            </ul>
            <p>Please note that third parties (including, for example, advertising networks and providers of external
              services like web traffic analysis services) may also use cookies, over which we have no control.
            </p>
            <p>You block cookies by activating the setting on your browser that allows you to refuse the setting of all
              or some cookies.
            </p>
            <p><strong>However, if you use your browser settings to block all cookies (including essential cookies) you
              may not be able to access all or parts of our site.</strong></p>

            <LegalDisclaimer version="1.0" name="Cookie Policy" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  return <NotFoundPage />;
};
