import { ComplianceTradingStates } from "./common";

export type ComplianceItemStatus = 'unknown' | 'todo' | 'completed' | 'optional' | 'pending' | 'failed';
export type ComplianceItemState =
  | 'unknown'
  | 'todo'
  | 'completed'
  | 'failedFinal'
  | 'waitingForSumsub'
  | 'approved'
  | 'declined';
export type ComplianceTradingState = 'unknown' | 'active' | 'unverified' | 'DNT' | 'inactive';

export interface ComplianceItem {
  status: ComplianceItemStatus;
  state?: ComplianceItemState;
}

export interface ComplianceState {
  level1: string;
  level2: string;
  trading: ComplianceTradingStates;
}

export enum ComplianceActionStages {
  // Level 1
  termsAccepted = "termsAccepted",
  emailVerification = "emailVerification",
  selfCertification = "selfCertification",
  t0Form = "t0Form",
  kycSumsub = "kycSumsub",

  // Level 2
  t1Form = "t1Form",
  level2AdditionalVerification = "level2AdditionalVerification",
}

export interface ComplianceActions {
  termsAccepted: ComplianceItem;
  emailVerification: ComplianceItem;
  kycSumsub: ComplianceItem;
  selfCertification: ComplianceItem;
  t0Form: ComplianceItem;
  t1Form: ComplianceItem;
  level2AdditionalVerification: ComplianceItem;
}

export interface Compliance {
  actions: ComplianceActions;
  state: ComplianceState;
}

