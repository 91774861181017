import { BaseWebSocketClientApplication } from "./BaseWebSocketClientApplication";
import { RemoteEventsWatchersManager } from "./RemoteEventsWatchersManager";
import { AxiosInstance } from "axios";

export type LondonLinkApiClientAdapterAbstractOptions = {
}

export interface LondonLinkApiClientXhrAdapterAbstract {
  axiosInstance: AxiosInstance;
  get(url: string): Promise<any>;
  post(
    url: string,
    data?: Record<string, any>,
    options?: LondonLinkApiClientAdapterAbstractOptions
  ): Promise<any>;
}

export class LondonLinkApiClient {
  private xhrAdapter: LondonLinkApiClientXhrAdapterAbstract | undefined;
  private websocketApp: BaseWebSocketClientApplication | undefined;
  private websocketRemoteEventWatcher: RemoteEventsWatchersManager | undefined;

  public init(xhrAdapter: LondonLinkApiClientXhrAdapterAbstract, websocketApp: BaseWebSocketClientApplication) {
    this.xhrAdapter = xhrAdapter;
    this.websocketApp = websocketApp;
  }

  public getXhrClient() {
    if (!this.xhrAdapter) {
      throw new Error("xhrAdapter must be defined.");
    }

    return this.xhrAdapter;
  }

  public getWebsocketClient() {
    if (!this.websocketApp) {
      throw new Error("websocketApp must be defined.");
    }

    return this.websocketApp;
  }

  public getWebsocketRemoteEventWatcher() {
    if (!this.websocketApp) {
      throw new Error("websocketRemoteEventWatcher must be defined.");
    }

    if (!this.websocketRemoteEventWatcher) {
      this.websocketRemoteEventWatcher = new RemoteEventsWatchersManager({
        websocketClientApp: this.websocketApp
      });
    }

    return this.websocketRemoteEventWatcher;
  }
}
