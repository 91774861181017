import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container } from '@chakra-ui/react';
import { LegalDisclaimer, LegalPageTemplate, NotFoundPage } from "components";
import { constants } from "../../../config/constants";

// This includes both a GIB and UK version
export const PrivacyPolicyPage = () => {
  /**
   * GIB version
   */
  if (constants.businessEntity === "gib") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>

        <Container>
          <article>
            <h1>Privacy Policy</h1>
            <section>
              <p><strong>This is LondonLink GI’s (“the Company” or “LondonLink”) privacy policy.</strong></p>
              <p>LondonLink respects your privacy and is committed to protecting your personal data. This privacy policy will inform you about how the Company looks after your personal data when you visit our website or otherwise use our services. It also informs you about your privacy rights and how the law protects you.</p>
              <p>This privacy policy is provided in an easy-to-read, sectioned format. Each section covers a specific area, each of which is listed below. Please also use the Glossary to understand the meaning of some of the less common terms used in this privacy policy.</p>
              <ol>
                <li>
                  <a href="#important-information-and-who-we-are">IMPORTANT INFORMATION AND WHO WE ARE</a>
                </li>
                <li>
                  <a href="#the-data-we-collect-about-you">THE DATA WE COLLECT ABOUT YOU</a>
                </li>
                <li>
                  <a href="#how-is-your-personal-data-collected">HOW YOUR PERSONAL DATA IS COLLECTED</a>
                </li>
                <li>
                  <a href="#how-we-use-your-personal-data">HOW WE USE YOUR PERSONAL DATA</a>
                </li>
                <li>
                  <a href="#disclosures-of-your-personal-data">DISCLOSURES OF YOUR PERSONAL DATA</a>
                </li>
                <li>
                  <a href="#international-transfers">INTERNATIONAL TRANSFERS</a>
                </li>
                <li>
                  <a href="#data-security">DATA SECURITY</a>
                </li>
                <li>
                  <a href="#data-retention">DATA RETENTION</a>
                </li>
                <li>
                  <a href="#your-legal-rights">YOUR LEGAL RIGHTS</a>
                </li>
                <li>
                  <a href="#glossary">GLOSSARY</a>
                </li>
              </ol>

              <hr />
            </section>

            <section id="important-information-and-who-we-are">
              <h2>1. Important information and who we are</h2>
              <h3>Purpose of this privacy policy</h3>
              <p>This privacy policy aims to give you information on how the Company collects and processes your personal data through your use of its services and/or its website <a href="https://londonlink.io">www.londonlink.io.</a></p>
              <p>This website is not intended for anyone under 18 years of age and we do not knowingly collect any data relating to anyone under that age.</p>
              <p>It is important that you read this privacy policy together with any other privacy policies or fair processing notices we may provide on specific occasions when we are collecting or processing personal data about you so that you are fully aware of how and why we are using your data. This privacy policy supplements any other policies or notices and is not intended to override them.</p>

              <h3>Controller</h3>
              <p>The Company (also referred to as “we”, “us” or “our” in this privacy policy) is a data “controller” for the purposes of the General Data Protection Regulation (“GDPR”), and is responsible for determining the purposes and means of processing your personal data.</p>

              <h3>Contact</h3>
              <p>If you have any questions or concerns about this privacy policy or our privacy practices, please contact our data privacy manager by email at <a href="mailto:datarequest@londonlink.io">datarequest@londonlink.io</a>.</p>
              <p>You have the right to make a complaint at any time to the supervisory authority for data protection in Gibraltar, the Gibraltar Regulatory Authority (“GRA”, whose website is www.gra.gi) if you consider that our use of your personal data infringes the GDPR. We would appreciate the opportunity to consider and seek to resolve any complaint you may have before you approach the GRA so please contact us in the first instance.</p>

              <h3>Changes to the privacy policy and your duty to inform us of changes</h3>
              <p>This version was last updated on 20th of January 2022.</p>
              <p>It is important that the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes during your relationship with us.</p>

              <h3>Third-party links</h3>
              <p>This website may include links to third-party websites, plug-ins and applications. Clicking on those links or enabling those connections may allow third parties to collect or share data about you. We do not control these third-party websites and are not responsible for their privacy statements. When you leave our website, we encourage you to read the privacy policy of every website you visit.</p>
            </section>

            <section id="the-data-we-collect-about-you">
              <h2>2. The data we collect about you</h2>
              <p>Personal data, or personal information, means any information about an individual from which that person can be identified. It does not include data where the identity has been removed (anonymous data).</p>
              <p>We may collect, use, store and transfer different kinds of personal data about you which we have grouped together as follows:</p>
              <ul>
                <li><strong>Identity Data</strong> includes scans or photos of ID documentation, first name, maiden name, last name, username or similar identifier, marital status, title, date of birth and gender.</li>
                <li><strong>Contact Data</strong> includes billing address, delivery address, email address and telephone numbers.</li>
                <li><strong>Financial Data</strong> includes bank account, blockchain and payment card details.</li>
                <li><strong>Transaction Data</strong> includes details about payments to and from you and other details of products and services you have purchased from us including wallet addresses. This definition also includes any screenshots/photos provided to us for transaction verification purposes.</li>
                <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform and other technology on the devices you use to access this website.</li>
                <li><strong>Profile Data</strong> includes your online usernames/aliases, purchases or orders made by you, your interests, preferences and feedback.</li>
                <li><strong>Usage Data</strong> includes information about how you use our website, products and services.</li>
                <li><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us and third parties and your communication preferences.</li>
              </ul>

              <p>We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic data for any purpose. Aggregated Data may be derived from your personal data but is not considered personal data in law as it does not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific service. However, if we combine or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we treat the combined data as personal data which will be used in accordance with this privacy policy.</p>
              <p>We do not collect any <strong>Special Categories of Personal Data</strong> about you (this includes details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, information about your health and genetic and biometric data). Nor do we collect from you any information about criminal convictions and offences.</p>

              <h3>If you fail to provide personal data</h3>
              <p>Where we need to collect personal data by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract/provide the service we have or are trying to enter into with, or provide for, you. In this case, we may have to cancel a product or service you have with us. We will notify you if this is the case at the time.</p>
            </section>

            <section id="how-is-your-personal-data-collected">
              <h2>3. How your personal data is collected</h2>
              <p>We use different methods to collect data from and about you, including through:</p>
              <ul>
                <li><strong>Direct interactions.</strong> You may give us your Identity, Contact and Financial Data by responding to our requests to do so for verification and/or operational reasons by corresponding with us through electronic means including (but not limited to) email, WhatsApp and other instant messaging applications in addition to by post, over the phone, in person or other direct interactions. This includes (but is not limited to) personal data you provide when you:</li>
                <ul>
                  <li>open a trade or otherwise request our services (by conduct, in writing or verbally) using a third-party platform (including online peer to peer trading websites);</li>
                  <li>are onboarded as an Over The Counter (OTC) customer;</li>
                  <li>create an account or upload documentation on our website;</li>
                  <li>subscribe to our service or publications;</li>
                  <li>request marketing material to be sent to you;</li>
                  <li>engage us or open dialogue about how our services could benefit you; or</li>
                  <li>give us some feedback.</li>
                </ul>
                <li><strong>Automated technologies or interactions.</strong> As you interact with our website, we may automatically collect Technical Data about your equipment, browsing actions, IP address information and patterns. We collect this personal data by using cookies, server logs and other similar technologies. For further information, please see our <a href="/cookie-policy">Cookie Policy.</a></li>
                <li><strong>Third parties or publicly available sources.</strong> We may receive personal data about you from various third parties and public sources including, but not limited to:</li>
                <ul>
                  <li>Technical Data from analytics providers such as Google;</li>
                  <li>Contact, Financial and Transaction Data from providers of technical, payment and delivery services; and</li>
                  <li>Identity and Contact Data from publicly available sources.</li>
                  <li>Compliance related data from providers of blockchain analysis, watchlist screening providers and other proprietary data sets.</li>
                </ul>
              </ul>
            </section>

            <section id="how-we-use-your-personal-data">
              <h2>4. How we use your personal data</h2>
              <p>We will only use your personal data when the law permits us to do so. Most commonly, we will use your personal data in the following circumstances:</p>
              <ul>
                <li>where we need to perform the contract we are about to enter into or have entered into with you;</li>
                <li>when it is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests; and</li>
                <li>when we need to comply with a legal or regulatory obligation.</li>
              </ul>

              <h3>Purposes for which we will use your personal data</h3>
              <p>We have set out below, in table format, a description of all the ways we plan to use your personal data, and which of the legal bases we rely on to do so. We have also identified what our legitimate interests are, as/where appropriate.</p>
              <p>Note that we may use your personal data for more than one lawful ground depending on the specific purpose for doing so. Please contact us if you require details about the specific legal ground that we are relying on to use your personal data, where more than one ground has been set out in the table below.</p>

              <div className="table-container">
                <table cellSpacing="0">
                  <thead>
                  <tr>
                    <th>Purpose/Activity</th>
                    <th>Type of data</th>
                    <th>Lawful basis for using your personal data, including the basis of legitimate interest</th>
                  </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>To discuss our services with you and register you as a new customer</td>
                      <td>
                        (a) Identity<br />
                        (b) Contact
                      </td>
                      <td>
                        Performance of a contract with you
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To process and deliver services including:<br />
                        (a) Manage transfers of fiat or cryptoassets, fees and charges<br />
                        (b) Collect and recover fiat or cryptoassets owed to us<br />
                        (c) Conduct initial and ongoing due diligence
                      </td>
                      <td>
                        (a) Identity<br />
                        (b) Contact<br />
                        (c) Financial<br />
                        (d) Transaction<br />
                        (e) Marketing and Communications
                      </td>
                      <td>
                        (a) Performance of a contract for services with you<br />
                        (b) Necessary for our legitimate interests (to recover debts due to us)<br />
                        (c) Necessary to comply with regulatory obligations
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To manage our relationship with you which will include:<br />
                        (a) Notifying you about changes to our terms or privacy policy<br />
                        (b) Asking you to leave a review or take a survey
                      </td>
                      <td>
                        (a) Identity<br />
                        (b) Contact<br />
                        (c) Profile<br />
                        (d) Marketing and Communications
                      </td>
                      <td>
                        (a) Performance of a contract with you<br />
                        (b) Necessary to comply with a legal obligation<br />
                        (c) Necessary for our legitimate interests (to keep our records updated and to study how customers use our products/services)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To enable you to partake in a prize draw, competition or complete a survey
                      </td>
                      <td>
                        (a) Identity<br />
                        (b) Contact<br />
                        (c) Profile<br />
                        (d) Usage<br />
                        (e) Marketing and Communications
                      </td>
                      <td>
                        (a) Performance of a contract with you<br />
                        (b) Necessary for our legitimate interests (to study how customers use our products/services, to develop them and grow our business)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To administer and protect our business and this website (including troubleshooting, data analysis, testing, system maintenance, support, reporting and hosting of data)
                      </td>
                      <td>
                        (a) Identity<br />
                        (b) Contact<br />
                        (c) Technical
                      </td>
                      <td>
                        (a) Necessary for our legitimate interests (for running our business, provision of administration and IT services, network security, to prevent fraud and in the context of a business reorganisation or group restructuring exercise)<br />
                        (b) Necessary to comply with a legal obligation
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>To deliver relevant website content and advertisements to you and measure or understand the effectiveness of the advertising we serve to you</p>
                      </td>
                      <td>
                        (a) Identity<br />
                        (b) Contact<br />
                        (c) Profile<br />
                        (d) Usage<br />
                        (e) Marketing and Communications<br />
                        (f) Technical
                      </td>
                      <td>
                        <p>Necessary for our legitimate interests (to study how customers use our products/services, to develop them, to grow our business and to inform our marketing strategy)</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>To use data analytics to improve our website, products/services, marketing, customer relationships and experiences</p>
                      </td>
                      <td>
                        (a) Identity<br />
                        (b) Contact<br />
                        (c) Profile<br />
                        (d) Usage<br />
                        (e) Marketing and Communications<br />
                        (f) Technical
                      </td>
                      <td>
                        <p>Necessary for our legitimate interests (to define types of customers for our products and services, to keep our website updated and relevant, to develop our business and to inform our marketing strategy)</p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p>To make suggestions and recommendations to you about goods or services that may be of interest to you</p>
                      </td>
                      <td>
                        (a) Identity<br />
                        (b) Contact<br />
                        (c) Technical<br />
                        (d) Usage<br />
                        (e) Profile
                      </td>
                      <td>
                        Necessary for our legitimate interests (to develop our products/services and grow our business)
                      </td>
                    </tr>
                    <tr>
                      <td>
                        To process a complaint you make
                      </td>
                      <td>
                        (a) Identity<br />
                        (b) Contact<br />
                        (c) Financial<br />
                        (d) Transaction<br />
                        (e) Technical
                      </td>
                      <td>
                        Necessary for our legitimate interests (to consider and seek to resolve your complaint)
                      </td>
                    </tr>
                  </tbody>
                </table>

                <h3>Promotional offers and marketing from us (not third parties)</h3>
                <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services and offers may be relevant for you (we call this marketing).</p>
                <p>You may receive marketing communications from us if you have requested information from us or received services from us or if you provided us with your details when you entered a competition or registered for a promotion.</p>

                <h3>Change of use of your data for marketing purposes</h3>
                <p>We strive to provide you with choices regarding certain personal data uses, particularly around marketing and advertising. If you have consented to receive marketing communications and would like to request a change to how we use your data for marketing purposes, please make a request by email to <a href="mailto:unsubscribe@londonlink.io">unsubscribe@londonlink.io</a>.</p>

                <h3>Opting out of our marketing messages</h3>
                <p>You can also ask us to stop sending you marketing messages at any time in writing. Where you opt out of receiving these marketing messages, this will not apply to personal data provided to us as a result of a product/service purchase, warranty registration, product/service experience or other transactions.</p>
                <p>If you would like to opt out of marketing please email us at <a href="mailto:unsubscribe@londonlink.io">unsubscribe@londonlink.io</a>.</p>
              </div>

              <h3>Third-party marketing</h3>
              <p>We will always request your express opt-in consent before we share your personal data outside the Company for third-party marketing purposes.</p>

              <h3>Opting out of third-party marketing</h3>
              <p>Once you have opted-in to third-party marketing, you will need to contact that third party directly if you wish to opt-out with them.</p>

              <h3>Cookies</h3>
              <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or access cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. For more information about the cookies we use, please see our <Link to="/cookie-policy">Cookie Policy</Link>.</p>

              <h3>Change of purpose</h3>
              <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably consider that we need to use it for another reason and that reason is compatible with the original purpose. If you would like an explanation as to how the processing for the new purpose is compatible with the original purpose, please email <a href="mailto:datarequest@londonlink.io">datarequest@londonlink.io</a>.</p>
              <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain the legal basis which allows us to do so.</p>
              <p>Please note that we may use your personal data without your knowledge or consent, in compliance with the above rules, where this is required or permitted by law.</p>
            </section>

            <section id="disclosures-of-your-personal-data">
              <h2>5. Disclosures of your personal data</h2>
              <p>We may have to share your personal data with the parties set out below for the purposes set out in the table in paragraph 4 above:</p>
              <ul>
                <li>Internal Third Parties as defined in the <em>Glossary</em>.</li>
                <li>External Third Parties as defined in the <em>Glossary</em>.</li>
                <li>Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business, then the new owners may use your personal data in the same way as set out in this privacy policy.</li>
              </ul>
              <p>We require all third parties to respect the security of your personal data and to treat it in accordance with the law. We do not allow our third-party service providers to use your personal data for their own purposes, and only permit them to process your personal data for specified purposes and in accordance with our instructions.</p>
            </section>

            <section id="international-transfers">
              <h2>6. International transfers</h2>
              <p>We may share your personal data within the Company and within the wider LondonLink group of companies (“LondonLink Group”) for one or more of the purposes explained in paragraph 4 above. At the time of writing, LondonLink Group consists of the Company and LondonLink OTC Limited, a company incorporated in the United Kingdom. </p>
              <p>We ensure your personal data is protected by requiring the Company and all companies in the LondonLink Group to enter into data processing agreements that contain strict data protection obligations.</p>
              <p>We may also share your personal data with third parties, once again for one or more of the purposes explained in paragraph 4 above.  Where those third parties are based in the European Union, the strict provisions of the EU GDPR will apply.  Where those third parties are based outside of the European Union, we will ensure a similar degree of protection is afforded to your personal data by ensuring at least one of the following safeguards is implemented:</p>
              <ul>
                <li>We will only transfer your personal data to countries that have been deemed to provide an adequate level of protection for personal data by the European Commission. For further details, see European Commission: Adequacy of the protection of personal data in non-EU countries.</li>
                <li>Where we use certain service providers, we may use specific contracts approved by the European Commission which give personal data the same protection it has in Europe. For further details, see European Commission: Model contracts for the transfer of personal data to third countries.</li>
                <li>Where we use providers based in the US, we may transfer data to them if they are part of the Privacy Shield which requires them to provide similar protection to personal data shared between the Europe and the US. For further details, see European Commission: EU-US Privacy Shield.</li>
              </ul>
              <p>Please <a href="/contact">contact us</a> if you would like further information on the specific mechanism used by us when transferring your personal data out of Gibraltar.</p>
            </section>

            <section id="data-security">
              <h2>7. Data security</h2>
              <p>We have put in place appropriate security measures to prevent your personal data from being accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data to those employees, agents, contractors and other third parties who have a business need to know. They will only process your personal data on our instructions and they are subject to a duty of confidentiality.</p>
              <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and any applicable regulator of a breach, where we are legally required to do so.</p>
              <p>We also have various internal good practice policies and guidelines, do&apos;s and don&apos;ts and similar helpful information for contractors and employees.</p>
            </section>

            <section id="data-retention">
              <h2>8. Data retention</h2>
              <h3>How long we will use your personal data</h3>
              <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements.</p>
              <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the purposes for which we process your personal data and whether we can achieve those purposes through other means, and the applicable legal requirements.</p>
              <p>By law we have to keep basic information about our customers (including Contact, Identity, Financial and Transaction Data) for six years after they cease being customers.</p>
              <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for research or statistical purposes in which case we may use this information indefinitely without further notice to you.</p>
            </section>

            <section id="your-legal-rights">
              <h2>9. Your legal rights</h2>
              <p>You have the right to:</p>
              <ul>
                <li><strong>Request access</strong> to your personal data (commonly known as a &quot;data subject access request&quot;). This enables you to receive a copy of the personal data we hold about you and to check that we are lawfully using it.</li>
                <li><strong>Request correction</strong> of the personal data that we hold about you. This enables you to have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the accuracy of the new data you provide to us.</li>
                <li><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove personal data where there is no good reason for us continuing to use it. You also have the right to ask us to delete or remove your personal data where you have successfully exercised your right to object to processing (see below), where we may have processed your information unlawfully or where we are required to erase your personal data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</li>
                <li><strong>Object to processing</strong> of your personal data where we are relying on a legitimate interest (or those of a third party) and there is something about your particular situation which makes you want to object to processing on this ground as you feel it impacts on your fundamental rights and freedoms. You also have the right to object where we are processing your personal data for direct marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to process your information which override your rights and freedoms.</li>
                <li><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios: (a) if you want us to establish the data&apos;s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it; (c) where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</li>
                <li><strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</li>
                <li><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</li>
              </ul>
              <p>For more information about these rights, please visit the GRA website.</p>
              <p>If you wish to exercise any of these rights, raise an issue or ask a data protection related question, please send an email to: <a href="mailto:datarequest@londonlink.io">datarequest@londonlink.io</a>.</p>
              <p>As above, you also have a right to lodge a complaint with the GRA if you are in any way dissatisfied about how we process your personal data.</p>

              <h3>No fee usually required</h3>
              <p>You will not normally have to pay a fee to access your personal data (or to exercise any of the other rights). However, we may charge a reasonable fee if your request is unfounded, repetitive or excessive. Alternatively, we may refuse to comply with your request in these circumstances.</p>

              <h3>What we may need from you</h3>
              <p>We may need to request specific information from you to help us confirm your identity and ensure your right to access your personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further information in relation to your request to speed up our response.</p>

              <h3>Time limit to respond</h3>
              <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if your request is particularly complex or you have made a number of requests. In this case, we will notify you and keep you updated.</p>
            </section>

            <section id="glossary">
              <h2>10. Glossary</h2>
              <h3>LAWFUL BASIS</h3>
              <p><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our business to enable us to give you the best service/product and the best and most secure experience. We make sure we consider and balance any potential impact on you (both positive and negative) and your rights before we use your personal data for our legitimate interests. We do not use your personal data for activities where our interests are overridden by the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can obtain further information about how we assess our legitimate interests against any potential impact on you in respect of specific activities by contacting us.</p>
              <p><strong>Performance of Contract</strong> means using your data where it is necessary for the performance of a contract to which you are a party or to take steps at your request before entering into such a contract.</p>
              <p><strong>Comply with a legal or regulatory obligation</strong> means using your personal data where it is necessary for compliance with a legal or regulatory obligation to which we are subject.</p>

              <h3>THIRD PARTIES</h3>
              <h4>Internal Third Parties</h4>
              <p>Other LondonLink Group companies providing services for or on behalf of the Company.</p>

              <h4>External Third Parties</h4>
              <ul>
                <li>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors and insurers based in Gibraltar or elsewhere who provide consultancy, banking, legal, insurance and accounting services.</li>
                <li>Gibraltar’s Income Tax Office, regulators and other authorities acting as processors or joint controllers based in Gibraltar or elsewhere who may require reporting of processing activities in certain circumstances.</li>
                <li>Any companies asked by the Company to undertake tasks for the purposes of customer onboarding, initial or ongoing verifications or due diligence, acting as processors or joint controllers based in Gibraltar, the United Kingdom or elsewhere on behalf of the Company.</li>
              </ul>
            </section>

            <LegalDisclaimer version="2.0" name="Privacy Policy" dateLastUpdated="January 2022" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  /**
   * UK version
   */
  if (constants.businessEntity === "uk") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>Privacy Policy</title>
        </Helmet>

        <Container>
          <article>
            <h1>Privacy Policy</h1>
            <p>This is London Link OTC’s (“the Company” or “LondonLink”) privacy policy.</p>

            <section>
              <p>The Company respects your privacy and is committed to protecting your personal data. This privacy
                policy
                will inform you as to how the Company looks after your personal data when you visit our website or
                otherwise use our services. It also informs you about your privacy rights and how the law protects you.
              </p>
              <p>This privacy policy is provided in an easy to read, sectioned format. Each section covers a specific
                area, each of which is listed below. Please also use the Glossary to understand the meaning of some of
                the
                less common terms used in this privacy policy.
              </p>
              <ol>
                <li>
                  <a href="#important-information-and-who-we-are">IMPORTANT INFORMATION AND WHO WE ARE</a>
                </li>
                <li>
                  <a href="#the-data-we-collect-about-you">THE DATA WE COLLECT ABOUT YOU</a>
                </li>
                <li>
                  <a href="#how-is-your-personal-data-collected">HOW IS YOUR PERSONAL DATA COLLECTED</a>
                </li>
                <li>
                  <a href="#how-we-use-your-personal-data">HOW WE USE YOUR PERSONAL DATA</a>
                </li>
                <li>
                  <a href="#disclosures-of-your-personal-data">DISCLOSURES OF YOUR PERSONAL DATA</a>
                </li>
                <li>
                  <a href="#international-transfers">INTERNATIONAL TRANSFERS</a>
                </li>
                <li>
                  <a href="#data-security">DATA SECURITY</a>
                </li>
                <li>
                  <a href="#data-retention">DATA RETENTION</a>
                </li>
                <li>
                  <a href="#your-legal-rights">YOUR LEGAL RIGHTS</a>
                </li>
                <li>
                  <a href="#glossary">GLOSSARY</a>
                </li>
              </ol>

              <hr />
            </section>

            <section id="important-information-and-who-we-are">
              <h2>1. Important information and who we are</h2>

              <h3>Purpose of this privacy policy</h3>
              <p>This privacy policy aims to give you information on how the Company collects and processes your
                personal data through your use of its services and/or this website.
              </p>
              <p>This website is not intended for anyone under 18 years of age and we do not knowingly collect any data
                relating to anyone under 18 years of age.
              </p>
              <p>It is important that you read this privacy policy together with any other privacy policies or fair
                processing notices we may provide on specific occasions when we are collecting or processing personal
                data about you so that you are fully aware of how and why we are using your data. This privacy policy
                supplements any other policies or notices and is not intended to override them.
              </p>

              <h3>Controller</h3>
              <p>The Company (also referred to as “we”, “us” or “our” in this privacy policy) is a data controller and
                data processor responsible for the storage and processing of your personal data.
              </p>

              <h3>Contact</h3>
              <p>If you have any questions about this privacy policy or our privacy practices, please contact our data
                privacy manager by email at <a
                  href="mailto:datarequest@londonlinkotc.co.uk">datarequest@londonlinkotc.co.uk</a>.
              </p>
              <p>You have the right to make a complaint at any time to the Information Commissioner’s Office (ICO), the
                UK supervisory authority for data protection issues (www.ico.org.uk). We would appreciate the
                opportunity to bring your concerns to us to manage and resolve to the best of our abilities before you
                approach the ICO therefore, please contact us in the first instance.
              </p>

              <h3>Changes to the privacy policy and your duty to inform us of changes</h3>
              <p>This version was last updated on 9th January 2020.</p>
              <p>It is important that the personal data we hold about you is accurate and current. Please keep us
                informed if your personal data changes during your relationship with us.
              </p>

              <h3>Third-party links</h3>
              <p>This website may include links to third-party websites, plug-ins and applications. Clicking on those
                links or enabling those connections may allow third parties to collect or share data about you. We do
                not control these third-party websites and are not responsible for their privacy statements. When you
                leave our website, we encourage you to read the privacy policy of every website you visit.
              </p>
            </section>

            <section id="the-data-we-collect-about-you">
              <h2>2. The data we collect about you</h2>
              <p>Personal data, or personal information, means any information about an individual from which that
                person can be identified. It does not include data where the identity has been removed (anonymous data).
              </p>
              <p>We may collect, use, store and transfer different kinds of personal data about you which we have
                grouped together follows:
              </p>
              <ul>
                <li><strong>Identity Data</strong> includes scans or photos of ID documentation, first name, maiden
                  name, last name, username or similar identifier, marital status, title, date of birth and gender.
                </li>
                <li><strong>Contact Data</strong> includes billing address, delivery address, email address and
                  telephone numbers.
                </li>
                <li><strong>Financial Data</strong> includes bank account and payment card details.</li>
                <li><strong>Transaction Data</strong> includes details about payments to and from you and other details
                  of products and services you have purchased from us including wallet addresses. This definition also
                  includes any screenshots/photos provided to us for transaction verification purposes.
                </li>
                <li><strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser
                  type and version, time zone setting and location, browser plug-in types and versions, operating system
                  and platform and other technology on the devices you use to access this website.
                </li>
                <li><strong>Profile Data</strong> includes your online usernames/aliases, purchases or orders made by
                  you, your interests, preferences and feedback.
                </li>
                <li><strong>Usage Data</strong> includes information about how you use our website, products and
                  services.
                </li>
                <li><strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing
                  from us and our third parties and your communication preferences.
                </li>
              </ul>
              <p>We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic data
                for any purpose. Aggregated Data may be derived from your personal data but is not considered personal
                data in law as this data does not directly or indirectly reveal your identity. For example, we may
                aggregate your Usage Data to calculate the percentage of users accessing a specific service. However, if
                we combine or connect Aggregated Data with your personal data so that it can directly or indirectly
                identify you, we treat the combined data as personal data which will be used in accordance with this
                privacy policy.
              </p>
              <p>We do not collect any <strong>Special Categories of Personal Data</strong> about you (this includes
                details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
                political opinions, trade union membership, information about your health and genetic and biometric
                data). Nor do we collect any information about criminal convictions and offences.
              </p>
              <h3>If you fail to provide personal data</h3>
              <p>Where we need to collect personal data by law, or under the terms of a contract we have with you and
                you fail to provide that data when requested, we may not be able to perform the contract/ provide the
                service we have or are trying to enter into/ provide with/ for you. In this case, we may have to cancel
                a product or service you have with us but we will notify you if this is the case at the time.
              </p>
            </section>

            <section id="how-is-your-personal-data-collected">
              <h2>3. How is your personal data collected?</h2>
              <p>We use different methods to collect data from and about you including through:</p>
              <ul>
                <li><strong>Direct interactions.</strong> You may give us your Identity, Contact and Financial Data by
                  responding to our requests to do so for verification and/or operational reasons by corresponding with
                  us through electronic means including (but not limited to) email, WhatsApp and other instant messaging
                  applications in addition to by post, over the phone, in person or other direct interactions. This
                  includes (but not limited to) personal data you provide when you:
                  <ul>
                    <li>open a trade or otherwise request our services (by conduct, in writing or verbally) using a 3rd
                      party platform (including online peer to peer trading websites);
                    </li>
                    <li>are onboarded as an Over The Counter (OTC) client;</li>
                    <li>create an account on our website;</li>
                    <li>subscribe to our service or publications;</li>
                    <li>request marketing to be sent to you;</li>
                    <li>engage us or open dialogue about how our services could benefit you; or</li>
                    <li>give us some feedback.</li>
                  </ul>
                </li>
                <li><strong>Automated technologies or interactions.</strong> As you interact with our website, we may
                  automatically collect Technical Data about your equipment, browsing actions and patterns. We collect
                  this personal data by using cookies, server logs and other similar technologies. For further
                  information, please see our <Link to="/cookie-policy">Cookie Policy</Link>.
                </li>
                <li>
                  <strong>Third parties or publicly available sources.</strong>
                  <ul>
                    <li>We may receive personal data about you from various third parties and public sources including
                      Technical Data from analytics providers such as Google;
                    </li>
                    <li>Contact, Financial and Transaction Data from providers of technical, payment and delivery
                      services; and
                    </li>
                    <li>Identity and Contact Data from publicly available sources.</li>
                  </ul>
                </li>
              </ul>
            </section>

            <section id="how-we-use-your-personal-data">
              <h2>4. How we use your personal data</h2>
              <p>We will only use your personal data when the law allows us to. Most commonly, we will use your personal
                data in the following circumstances:
              </p>
              <ul>
                <li>Where we need to perform the contract we are about to enter into or have entered into with you.</li>
                <li>Where it is necessary for our legitimate interests (or those of a third party) and your interests
                  and fundamental rights do not override those interests.
                </li>
                <li>Where we need to comply with a legal or regulatory obligation.</li>
              </ul>
              <h3>Purposes for which we will use your personal data</h3>
              <p>We have set out below, in a table format, a description of all the ways we plan to use your personal
                data, and which of the legal bases we rely on to do so. We have also identified what our legitimate
                interests are, as/where appropriate.
              </p>
              <p>Note that we may process your personal data for more than one lawful ground depending on the specific
                purpose for which we are using your data. Please contact us if you need details about the specific legal
                ground that we are relying on to process your personal data, where more than one ground has been set out
                in the table below.
              </p>

              <div className="table-container">
                <table cellSpacing="0">
                  <thead>
                  <tr>
                    <th>Purpose/Activity</th>
                    <th>Type of data</th>
                    <th>Lawful basis for processing including basis of legitimate interest</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>To discuss our services with you and register you as a new customer</td>
                    <td>
                      (a) Identity
                      <br />
                      (b) Contact
                    </td>
                    <td>Performance of a contract with you</td>
                  </tr>

                  <tr>
                    <td>
                      To process and deliver services including:
                      <br />
                      (a) Manage transfers of fiat or cryptoassets, fees and charges
                      <br />
                      (b) Collect and recover fiat or cryptoassets owed to us
                      <br />
                      (c) Conduct initial and ongoing due diligence
                    </td>
                    <td>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Financial
                      <br />
                      (d) Transaction
                      <br />
                      (e) Marketing and Communications
                    </td>
                    <td>
                      (a) Performance of a contract for services with you
                      <br />
                      (b) Necessary for our legitimate interests (to recover debts due to us)
                    </td>
                  </tr>

                  <tr>
                    <td>
                      To manage our relationship with you which will include:
                      <br />
                      (a) Notifying you about changes to our terms or privacy policy
                      <br />
                      (b) Asking you to leave a review or take a survey
                    </td>
                    <td>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Profile
                      <br />
                      (d) Marketing and Communications
                    </td>
                    <td>
                      (a) Performance of a contract with you
                      <br />
                      (b) Necessary to comply with a legal obligation
                      <br />
                      (c) Necessary for our legitimate interests (to keep our records updated and to study how customers
                      use our products/services)
                    </td>
                  </tr>

                  <tr>
                    <td>
                      To enable you to partake in a prize draw, competition or complete a survey
                    </td>
                    <td>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Profile
                      <br />
                      (d) Usage
                      <br />
                      (e) Marketing and Communications
                    </td>
                    <td>
                      (a) Performance of a contract with you
                      <br />
                      (b) Necessary for our legitimate interests (to study how customers use our products/services, to
                      develop them and grow our business)
                    </td>
                  </tr>

                  <tr>
                    <td>
                      To administer and protect our business and this website (including troubleshooting, data analysis,
                      testing, system maintenance, support, reporting and hosting of data)
                    </td>
                    <td>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Technical
                    </td>
                    <td>
                      (a) Necessary for our legitimate interests (for running our business, provision of administration
                      and IT services, network security, to prevent fraud and in the context of a business
                      reorganisation or group restructuring exercise)
                      <br />
                      (b) Necessary to comply with a legal obligation
                    </td>
                  </tr>

                  <tr>
                    <td>
                      To deliver relevant website content and advertisements to you and measure or understand the
                      effectiveness of the advertising we serve to you
                    </td>
                    <td>
                      (a) Identity
                      <br />
                      (b) Contact
                      <br />
                      (c) Profile
                      <br />
                      (d) Usage
                      <br />
                      (e) Marketing and Communications
                      <br />
                      (f) Technical
                    </td>
                    <td>
                      Necessary for our legitimate interests (to study how customers use our products/services, to
                      develop them, to grow our business and to inform our marketing strategy)
                    </td>
                  </tr>

                  <tr>
                    <td>
                      To use data analytics to improve our website, products/services, marketing, customer relationships
                      and experiences
                    </td>
                    <td>
                      (a) Technical <br />
                      (b) Usage
                    </td>
                    <td>
                      Necessary for our legitimate interests (to define types of customers for our products and
                      services, to keep our website updated and relevant, to develop our business and to inform our
                      marketing strategy)
                    </td>
                  </tr>

                  <tr>
                    <td>
                      To make suggestions and recommendations to you about goods or services that may be of interest to
                      you
                    </td>
                    <td>
                      (a) Identity <br />
                      (b) Contact <br />
                      (c) Technical <br />
                      (d) Usage <br />
                      (e) Profile <br />
                    </td>
                    <td>
                      Necessary for our legitimate interests (to develop our products/services and grow our business)
                    </td>
                  </tr>

                  </tbody>
                </table>
              </div>

              <h3>Marketing</h3>
              <p>We strive to provide you with choices regarding certain personal data uses, particularly around
                marketing and advertising. If you have consented to receive marketing communications and would like to
                request a change to how we use your data for marketing purposes, please make a request by email to <a
                  href="mailto:unsubscribe@londonlinkotc.co.uk">unsubscribe@londonlinkotc.co.uk</a>.
              </p>
              <h3>Promotional offers and marketing from us (not third parties)</h3>
              <p>We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think
                you may want or need, or what may be of interest to you. This is how we decide which products, services
                and offers may be relevant for you (we call this marketing).
              </p>
              <p>You may receive marketing communications from us, if you have requested information from us or received
                services from us or, if you provided us with your details when you entered a competition or registered
                for a promotion.
              </p>

              <h3>Third-party marketing</h3>
              <p>We will get your express opt-in consent before we share your personal data outside the Company for
                marketing purposes.
              </p>

              <h3>Opting out</h3>
              <p>You can ask us to stop sending you marketing messages at any time in writing. Where you opt out of
                receiving these marketing messages, this will not apply to personal data provided to us as a result of a
                product/service purchase, warranty registration, product/service experience or other transactions.
              </p>
              <p>If you would like to opt out of marking please email us at <a
                href="mailto:unsubscribe@londonlinkotc.co.uk">unsubscribe@londonlinkotc.co.uk</a></p>

              <h3>Opting out of third-party marketing.</h3>
              <p>Once you have opted-in to third party marketing, you will need to contact that third party directly if
                you wish to opt-out with them.
              </p>

              <h3>Cookies</h3>
              <p>You can set your browser to refuse all or some browser cookies, or to alert you when websites set or
                access cookies. If you disable or refuse cookies, please note that some parts of this website may become
                inaccessible or not function properly. For more information about the cookies we use, please see
                our <Link to="/cookie-policy">Cookie Policy</Link>.
              </p>

              <h3>Change of purpose </h3>
              <p>We will only use your personal data for the purposes for which we collected it, unless we reasonably
                consider that we need to use it for another reason and that reason is compatible with the original
                purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible
                with the original purpose, please email <a
                  href="mailto:datarequest@londonlinkotc.co.uk">datarequest@londonlinkotc.co.uk</a>.
              </p>
              <p>If we need to use your personal data for an unrelated purpose, we will notify you and we will explain
                the legal basis which allows us to do so.
              </p>
              <p>Please note that we may process your personal data without your knowledge or consent, in compliance
                with the above rules, where this is required or permitted by law.
              </p>
            </section>

            <section id="disclosures-of-your-personal-data">
              <h2>5. Disclosures of your personal data</h2>
              <p>We may have to share your personal data with the parties set out below for the purposes set out in the
                table in paragraph 4 above.
              </p>
              <ul>
                <li>Internal Third Parties as set out in the <em>Glossary</em>.</li>
                <li>External Third Parties as set out in the <em>Glossary</em>.</li>
                <li>
                  Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets.
                  Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our
                  business, then the new owners may use your personal data in the same way as set out in this privacy
                  policy.
                </li>
              </ul>
              <p>We require all third parties to respect the security of your personal data and to treat it in
                accordance with the law. We do not allow our third-party service providers to use your personal data for
                their own purposes and only permit them to process your personal data for specified purposes and in
                accordance with our instructions.
              </p>
            </section>

            <section id="international-transfers">
              <h2>6. International transfers</h2>
              <p>We share your personal data within the Company. We may in the future transfer your data outside the
                European Economic Area (EEA) but still keep it within the Company.
              </p>
              <p>We ensure your personal data is protected by requiring the Company to follow rules when processing your
                personal data. These rules are called &quot;binding corporate rules&quot;. For further details,
                see European Commission: Binding corporate rules.
              </p>
              <p>If we ever transfer your personal data out of the EEA, we will ensure a similar degree of protection is
                afforded to it by ensuring at least one of the following safeguards is implemented:
              </p>
              <ul>
                <li>We will only transfer your personal data to countries that have been deemed to provide an adequate
                  level of protection for personal data by the European Commission. For further details, see European
                  Commission: Adequacy of the protection of personal data in non-EU countries.
                </li>
                <li>Where we use certain service providers, we may use specific contracts approved by the European
                  Commission which give personal data the same protection it has in Europe. For further details, see
                  European Commission: Model contracts for the transfer of personal data to third countries.
                </li>
                <li>Where we use providers based in the US, we may transfer data to them if they are part of the Privacy
                  Shield which requires them to provide similar protection to personal data shared between the Europe
                  and the US. For further details, see European Commission: EU-US Privacy Shield.
                </li>
              </ul>
              <p>Please <Link to="/contact">contact us</Link> if you want further information on the
                specific mechanism used by us when transferring your personal data out of the EEA.
              </p>
            </section>

            <section id="data-security">
              <h2>7. Data security</h2>
              <p>We have put in place appropriate security measures to prevent your personal data from being
                accidentally lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit
                access to your personal data to those employees, agents, contractors and other third parties who have a
                business need to know. They will only process your personal data on our instructions and they are
                subject to a duty of confidentiality.
              </p>
              <p>We have put in place procedures to deal with any suspected personal data breach and will notify you and
                any applicable regulator of a breach, where we are legally required to do so.
              </p>
              <p>We also have various internal good practice policies and guidelines, do&apos;s and don&apos;ts and
                similar helpful information for contractors and employees.
              </p>
            </section>

            <section id="data-retention">
              <h2>8. Data retention</h2>
              <h3>How long will you use my personal data for?</h3>
              <p>We will only retain your personal data for as long as necessary to fulfil the purposes we collected it
                for, including for the purposes of satisfying any legal, accounting, or reporting requirements.
              </p>
              <p>To determine the appropriate retention period for personal data, we consider the amount, nature, and
                sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
                personal data, the purposes for which we process your personal data and whether we can achieve those
                purposes through other means, and the applicable legal requirements.
              </p>
              <p>By law we have to keep basic information about our customers (including Contact, Identity, Financial
                and Transaction Data) for six years after they cease being customers for tax purposes.
              </p>
              <p>In some circumstances you can ask us to delete your data; see the Information Commissioner’s website
                for more information.
              </p>
              <p>In some circumstances we may anonymise your personal data (so that it can no longer be associated with
                you) for research or statistical purposes in which case we may use this information indefinitely without
                further notice to you.
              </p>
            </section>

            <section id="your-legal-rights">
              <h2>9. Your legal rights</h2>
              <p>Under certain circumstances, you have rights under data protection laws in relation to your personal
                data. Please see the information commissioner’s website to find out more about your legal rights - <a
                  href="https://ico.org.uk/">https://ico.org.uk/</a>.
              </p>
              <p>If you wish to exercise any of your rights, raise an issue or ask a data protection related question,
                please do not hesitate to contact us by email: <a
                  href="mailto:datarequest@londonlinkotc.co.uk">datarequest@londonlinkotc.co.uk</a></p>
              <p>If, however, you are in any way dissatisfied about how we process your personal information, you have a
                right to lodge a complaint with the Information Commissioner&apos;s Office (“ICO”). This can be done
                at <a href="https://www.ico.org.uk/concerns">www.ico.org.uk/concerns</a>. Our ICO registration number is
                ZA481978.
              </p>
              <h3>No fee usually required</h3>
              <p>You will not have to pay a fee to access your personal data (or to exercise any of the other rights).
                However, we may charge a reasonable fee if your request is clearly unfounded, repetitive, excessive or
                if we feel necessary in the circumstances. Alternatively, we may refuse to comply with your request in
                these circumstances.
              </p>
              <h3>What we may need from you</h3>
              <p>We may need to request specific information from you to help us confirm your identity and ensure your
                right to access your personal data (or to exercise any of your other rights). This is a security measure
                to ensure that personal data is not disclosed to any person who has no right to receive it. We may also
                contact you to ask you for further information in relation to your request to speed up our response.
              </p>
              <h3>Time limit to respond</h3>
              <p>We try to respond to all legitimate requests within one month. Occasionally it may take us longer than
                a month if your request is particularly complex or you have made a number of requests. In this case, we
                will notify you and keep you updated.
              </p>
            </section>

            <section id="glossary">
              <h2>10. Glossary</h2>

              <h3>LAWFUL BASIS</h3>
              <p><strong>Legitimate Interest</strong> means the interest of our business in conducting and managing our
                business to enable us to give you the best service/product and the best and most secure experience. We
                make sure we consider and balance any potential impact on you (both positive and negative) and your
                rights before we process your personal data for our legitimate interests. We do not use your personal
                data for activities where our interests are overridden by the impact on you (unless we have your consent
                or are otherwise required or permitted to by law). You can obtain further information about how we
                assess our legitimate interests against any potential impact on you in respect of specific activities by
                contacting us
              </p>
              <p><strong>Performance of Contract</strong> means processing your data where it is necessary for the
                performance of a contract to which you are a party or to take steps at your request before entering into
                such a contract.
              </p>
              <p><strong>Comply with a legal or regulatory obligation</strong> means processing your personal data where
                it is necessary for compliance with a legal or regulatory obligation that we are subject to.
              </p>

              <h3>THIRD PARTIES</h3>
              <p><strong>Internal Third Parties</strong></p>
              <p>Associated companies of the Company contracted to provide services for or on behalf of the Company.</p>
              <p><strong>External Third Parties</strong></p>
              <ul>
                <li>Professional advisers acting as processors or joint controllers including lawyers, bankers, auditors
                  and insurers based in the United Kingdom who provide consultancy, banking, legal, insurance and
                  accounting services.
                </li>
                <li>HM Revenue & Customs, regulators and other authorities acting as processors or joint controllers
                  based in the United Kingdom who may require reporting of processing activities in certain
                  circumstances.
                </li>
                <li>Any companies asked by the Company to undertake tasks for the purposes of customer onboarding,
                  initial or ongoing verifications or due diligence, acting as processors or joint controllers based in
                  the United Kingdom or elsewhere on behalf of the Company.
                </li>
              </ul>

              <h3>YOUR LEGAL RIGHTS</h3>
              <p>You have the right to:</p>
              <p><strong>Request access</strong> to your personal data (commonly known as a &quot;data subject access
                request&quot;). This enables you to receive a copy of the personal data we hold about you and to check
                that we are lawfully processing it.
              </p>
              <p><strong>Request correction</strong> of the personal data that we hold about you. This enables you to
                have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the
                accuracy of the new data you provide to us.
              </p>
              <p><strong>Request erasure</strong> of your personal data. This enables you to ask us to delete or remove
                personal data where there is no good reason for us continuing to process it. You also have the right to
                ask us to delete or remove your personal data where you have successfully exercised your right to object
                to processing (see below), where we may have processed your information unlawfully or where we are
                required to erase your personal data to comply with local law. Note, however, that we may not always be
                able to comply with your request of erasure for specific legal reasons which will be notified to you, if
                applicable, at the time of your request.
              </p>
              <p><strong>Object to processing</strong> of your personal data where we are relying on a legitimate
                interest (or those of a third party) and there is something about your particular situation which makes
                you want to object to processing on this ground as you feel it impacts on your fundamental rights and
                freedoms. You also have the right to object where we are processing your personal data for direct
                marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to
                process your information which override your rights and freedoms.
              </p>
              <p><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to
                suspend the processing of your personal data in the following scenarios: (a) if you want us to establish
                the data&apos;s accuracy; (b) where our use of the data is unlawful but you do not want us to erase it;
                (c) where you need us to hold the data even if we no longer require it as you need it to establish,
                exercise or defend legal claims; or (d) you have objected to our use of your data but we need to verify
                whether we have overriding legitimate grounds to use it.
              </p>
              <p><strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide
                to you, or a third party you have chosen, your personal data in a structured, commonly used,
                machine-readable format. Note that this right only applies to automated information which you initially
                provided consent for us to use or where we used the information to perform a contract with you.
              </p>
              <p><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal
                data. However, this will not affect the lawfulness of any processing carried out before you withdraw
                your consent. If you withdraw your consent, we may not be able to provide certain products or services
                to you. We will advise you if this is the case at the time you withdraw your consent.
              </p>
              <p>If you wish to exercise your legal rights in relation to any of the above, please send an email to <a
                href="mailto:datarequest@londonlinkotc.co.uk">datarequest@londonlinkotc.co.uk</a> with your request
                which will be responded to within the legal timeframe.
              </p>
            </section>

            <LegalDisclaimer version="1.2.1" name="Privacy Policy" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  return <NotFoundPage />;
};

