import React, { Fragment, useEffect } from "react";
import * as yup from "yup";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Stack,
  useBoolean
} from "@chakra-ui/react";
import { FieldProps, Form, Formik, Field } from "formik";
import { Link, useHistory } from "react-router-dom";
import { PasswordRequirements } from "components";
import { useCustomerAuth } from "hooks";

export interface RegisterFormValues {
  email: string;
  password: string;
  terms: boolean;
  tradingTerms: boolean;
  disclaimers: boolean;
  marketing: boolean;
}

const validationSchema = yup.object().shape({
  email: yup.string().email('Invalid email').required('Field is required'),
  password: yup.string()
    .required('Field is required')
    .min(8, 'Must contain at least 8 characters')
    .matches(/[a-zA-Z]/, 'Must contain at least one letter')
    .matches(/\d/, 'Must contain at least one number')
    .test({
      name: 'passwordCannotBeEmail',
      message: 'Your password cannot be your email address',
      test(value) {
        return this.parent.email !== value;
      },
    }),
  terms: yup.bool().oneOf([true], 'Field must be checked'),
  tradingTerms: yup.bool().oneOf([true], 'Field must be checked'),
  disclaimers: yup.bool().oneOf([true], 'Field must be checked')
});

const initialValues = {
  email: '',
  password: '',
  selectAll: false,
  terms: false,
  tradingTerms: false,
  disclaimers: false,
  marketing: false
};


export const RegisterForm = () => {
  const history = useHistory();
  const { register, authState, resetRequestState } = useCustomerAuth();
  const [showPassword, setShowPassword] = useBoolean(false);

  function handleSubmit(values: RegisterFormValues) {
    register({
      email: values.email,
      password: values.password,
      formData: {
        generalTermsAndConditionsAgreementGIB: values.terms,
        tradingTermsAndConditionsAgreementGIB: values.tradingTerms,
        acceptableUsePolicyAgreementGIB: values.disclaimers,
        privacyPolicyAgreementGIB: values.disclaimers,
        riskDisclaimerAgreementGIB: values.disclaimers,
        marketingMessagesAgreementGIB: values.marketing,
      }
    });
  }

  // Reset request state when component is unmounted
  useEffect(() => {
    return function cleanup() {
      resetRequestState("registerRequest");
    };
  }, []);

  useEffect(() => {
    if (authState.authenticated) {
      history.push({ pathname: "/customer" });
    }
  }, [authState.authenticated]);

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validationSchema={validationSchema}
      onSubmit={(values: RegisterFormValues) => handleSubmit(values)}>
      {({ setFieldValue, values, setFieldTouched }) => {
        return (
          <Form id="register-form">
            <Stack spacing="6" maxWidth={450}>
              <Field name="email">
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={!!(form.errors.email && form.touched.email)}>
                    <FormLabel htmlFor="email">Email</FormLabel>
                    <Input {...field} type="email" id="email" autoFocus />
                    <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Field name="password">
                {({ field, form }: FieldProps) => (
                  <FormControl isInvalid={!!(form.errors.password && form.touched.password)}>
                    <FormLabel htmlFor="password">Password</FormLabel>
                    <InputGroup size="md">
                      <Input {...field} type={showPassword ? 'text' : 'password'} id="password" />
                      <InputRightElement width="4.5rem">
                        <Button type="button" h="1.75rem" size="sm" onClick={setShowPassword.toggle}>
                          {showPassword ? 'Hide' : 'Show'}
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <PasswordRequirements password={values.password} />

              <Heading as="h2" size="sm">
                Agreements
              </Heading>

              <Stack spacing="3">
                <Field name="selectAll">
                  {({ field }: FieldProps) => (
                    <FormControl>
                      <Checkbox
                        size="small-text"
                        spacing={3}
                        alignItems="baseline"
                        {...field}
                        onChange={(event) => {
                          const value = event.currentTarget.checked;
                          setFieldValue('terms', value);
                          setFieldValue('tradingTerms', value);
                          setFieldValue('disclaimers', value);
                          setFieldValue('marketing', value);

                          // Need this to sync up validation
                          // https://github.com/formium/formik/issues/2059
                          setFieldTouched('terms', !value);
                          setFieldTouched('tradingTerms', !value);
                          setFieldTouched('disclaimers', !value);
                          setFieldTouched('marketing', !value);
                        }}
                        id="selectAll">
                        Agree and select all below
                      </Checkbox>
                    </FormControl>
                  )}
                </Field>

                <Divider />

                <Field name="terms">
                  {({ field, form }: any) => {
                    const isInvalid = (form.errors.terms && form.touched.terms);
                    return (
                      <FormControl isInvalid={isInvalid}>
                        <Checkbox
                          size="small-text"
                          spacing={3}
                          isChecked={values.terms}
                          {...field}
                          isInvalid={isInvalid}
                          id="terms">
                          <Fragment>
                            I agree to the{' '}
                            <Link to="/general-terms-and-conditions" target="_blank" rel="noopener noreferrer">
                              General Terms & Conditions
                            </Link>
                          </Fragment>
                        </Checkbox>
                        <FormErrorMessage>{form.errors.terms}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>

                <Field name="tradingTerms">
                  {({ field, form }: any) => {
                    const isInvalid = (form.errors.tradingTerms && form.touched.tradingTerms);
                    return (
                      <FormControl isInvalid={isInvalid}>
                        <Checkbox
                          size="small-text"
                          spacing={3}
                          isChecked={values.tradingTerms}
                          {...field}
                          isInvalid={isInvalid}
                          id="tradingTerms">
                          <Fragment>
                            I agree to the{' '}
                            <Link to="/trading-terms-and-conditions" target="_blank" rel="noopener noreferrer">
                              Trading Terms & Conditions
                            </Link>
                          </Fragment>
                        </Checkbox>
                        <FormErrorMessage>{form.errors.tradingTerms}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>

                <Field name="disclaimers">
                  {({ field, form }: any) => {
                    const isInvalid = (form.errors.disclaimers && form.touched.disclaimers);
                    return (
                      <FormControl isInvalid={isInvalid}>
                        <Checkbox
                          size="small-text"
                          spacing={3}
                          isChecked={values.disclaimers}
                          {...field}
                          isInvalid={isInvalid}
                          id="disclaimers">
                          <Fragment>
                            I have read and understand the{' '}
                            <Link to="/risk-disclaimer" target="_blank" rel="noopener noreferrer">
                              Risk Disclaimer
                            </Link>,{' '}
                            <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                              Privacy Policy
                            </Link> and{' '}
                            <Link to="/acceptable-use-policy" target="_blank" rel="noopener noreferrer">
                              Acceptable Use Policy
                            </Link>
                          </Fragment>
                        </Checkbox>
                        <FormErrorMessage>{form.errors.disclaimers}</FormErrorMessage>
                      </FormControl>
                    );
                  }}
                </Field>

                <Field name="marketing">
                  {({ field, form }: any) => (
                    <FormControl isInvalid={(form.errors.marketing && form.touched.marketing)}>
                      <Checkbox
                        size="small-text"
                        spacing={3}
                        alignItems="baseline"
                        isChecked={values.marketing}
                        {...field}
                        id="marketing">
                        <Fragment>
                          Send me updates from LondonLink with information about our products,
                          services, events, news and offers.
                        </Fragment>
                      </Checkbox>
                      <FormErrorMessage>{form.errors.marketing}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Stack>
            </Stack>

            {/* Handle Error */}
            {authState.registerRequest.dataStatus === "error" && (
              <Alert status="error" mt={5}>
                <AlertIcon />
                {authState.registerRequest.dataError?.message}
              </Alert>
            )}

            <Box textAlign="left" mb={5}>
              <Button
                mt={5}
                isDisabled={authState.registerRequest.dataStatus === "loading"}
                isLoading={authState.registerRequest.dataStatus === "loading"}
                loadingText="Submitting"
                colorScheme="brand"
                size="md"
                type="submit"
                id="submit">
                Create account
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};
