import React from "react";
import { motion } from "framer-motion";
import { Image, Text } from "@chakra-ui/react";
import css from "./FullScreenLoading.module.css";
import logo from "../../assets/images/LondonLink_Icon.png";

export const FullScreenLoading = () => {
  return (
    <motion.div
      className={css.fullscreenLoadingOverlay}
      transition={{ opacity: { duration: 1 } }}
      animate={{ opacity: [0, 1] }}>
      <div className={css.pageContent}>
        <motion.div
          transition={{
            y: {
              duration: 0.5,
              repeat: Infinity,
              repeatType: "mirror",
              ease: "easeInOut",
            },
          }}
          animate={{
            y: ["6px", "0px"],
          }}>
          <Image src={logo} ml="-4px" alt="LondonLink Logo" width="60px" />
        </motion.div>
        <Text fontSize="sm" mt={4} color="grey">Loading</Text>
      </div>
    </motion.div>
  );
};
