import React, { Fragment, useEffect } from "react";
import { Form, Formik, Field, FieldProps } from "formik";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  Grid,
  Stack,
} from "@chakra-ui/react";
import * as yup from "yup";
import { ContinueNextStep, FormDisclaimer, SpinnerToTickIcon } from "components";
import { useCustomerForms, useCustomerProfile, useCustomerVerification } from "hooks";
import { Link } from "react-router-dom";
import { ComplianceActionStages } from "../../../../../../../shared/src/types/compliance";
import { DataStatuses } from "../../../../../../../shared/src/types/common";

export interface TermsOfServiceFormValues {
  terms: boolean;
  tradingTerms: boolean;
  disclaimers: boolean;
  marketing: undefined | boolean;
}

const validationSchema = yup.object().shape({
  terms: yup.bool().oneOf([true], 'Field must be checked'),
  tradingTerms: yup.bool().oneOf([true], 'Field must be checked'),
  disclaimers: yup.bool().oneOf([true], 'Field must be checked'),
});

interface Props {
  allTermsAgreed: boolean;
}

export const TermsOfServiceForm = ({ allTermsAgreed }: Props) => {
  const { profile } = useCustomerProfile();
  const { submitForm, formsState, resetFormsState } = useCustomerForms();
  const { customerStageSelect } = useCustomerVerification();

  const isLoading = formsState.formSubmitRequest.dataStatus === DataStatuses.Loading;
  const isSuccess = formsState.formSubmitRequest.dataStatus === DataStatuses.Ok;

  useEffect(() => {
    // Move to next stage after success
    if (isSuccess) {
      // Add a small delay before moving onto the next step to allow the SpinnerToTickIcon component animation to finish
      setTimeout(() => {
        resetFormsState();
        customerStageSelect(ComplianceActionStages.emailVerification);
      }, 1250);
    }
  }, [isSuccess]);

  const initialValues = {
    terms: allTermsAgreed,
    tradingTerms: allTermsAgreed,
    disclaimers: allTermsAgreed,
    marketing: profile.agreements.marketingMessagesAgreementGIB
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values: TermsOfServiceFormValues) => submitForm({
        generalTermsAndConditionsAgreementGIB: values.terms,
        tradingTermsAndConditionsAgreementGIB: values.tradingTerms,
        acceptableUsePolicyAgreementGIB: values.disclaimers,
        privacyPolicyAgreementGIB: values.disclaimers,
        riskDisclaimerAgreementGIB: values.disclaimers,
        marketingMessagesAgreementGIB: values.marketing
      })}>
      {({ setFieldValue, values, setFieldTouched }) => {
        return (
          <Form id="terms-of-service-form">
            <Stack spacing="3">
              {!allTermsAgreed && (
                <Field name="selectAll">
                  {({ field }: FieldProps) => (
                    <FormControl>
                      <Checkbox
                        size="small-text"
                        spacing={3}
                        alignItems="baseline"
                        isDisabled={allTermsAgreed}
                        {...field}
                        onChange={(event) => {
                          const value = event.currentTarget.checked;
                          setFieldValue('terms', value);
                          setFieldValue('tradingTerms', value);
                          setFieldValue('disclaimers', value);
                          setFieldValue('marketing', value);

                          // Need this to sync up validation
                          // https://github.com/formium/formik/issues/2059
                          setFieldTouched('terms', !value);
                          setFieldTouched('tradingTerms', !value);
                          setFieldTouched('disclaimers', !value);
                          setFieldTouched('marketing', !value);
                        }}
                        id="selectAll">
                        Agree and select all below
                      </Checkbox>
                    </FormControl>
                  )}
                </Field>
              )}

              <Field name="terms">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.terms && form.touched.terms);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <Checkbox
                        size="small-text"
                        spacing={3}
                        isChecked={values.terms}
                        isDisabled={allTermsAgreed}
                        {...field}
                        isInvalid={isInvalid}
                        id="terms">
                        <Fragment>
                          I agree to the{' '}
                          <Link to="/general-terms-and-conditions" target="_blank" rel="noopener noreferrer">
                            General Terms & Conditions
                          </Link>
                        </Fragment>
                      </Checkbox>
                      <FormErrorMessage>{form.errors.terms}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Field name="tradingTerms">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.tradingTerms && form.touched.tradingTerms);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <Checkbox
                        size="small-text"
                        spacing={3}
                        isChecked={values.tradingTerms}
                        isDisabled={allTermsAgreed}
                        {...field}
                        isInvalid={isInvalid}
                        id="tradingTerms">
                        <Fragment>
                          I agree to the{' '}
                          <Link to="/trading-terms-and-conditions" target="_blank" rel="noopener noreferrer">
                            Trading Terms & Conditions
                          </Link>
                        </Fragment>
                      </Checkbox>
                      <FormErrorMessage>{form.errors.tradingTerms}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              <Field name="disclaimers">
                {({ field, form }: any) => {
                  const isInvalid = (form.errors.disclaimers && form.touched.disclaimers);
                  return (
                    <FormControl isInvalid={isInvalid}>
                      <Checkbox
                        size="small-text"
                        spacing={3}
                        isChecked={values.disclaimers}
                        isDisabled={allTermsAgreed}
                        {...field}
                        isInvalid={isInvalid}
                        id="disclaimers">
                        <Fragment>
                          I have read and understand the{' '}
                          <Link to="/risk-disclaimer" target="_blank" rel="noopener noreferrer">
                            Risk Disclaimer
                          </Link>,{' '}
                          <Link to="/privacy-policy" target="_blank" rel="noopener noreferrer">
                            Privacy Policy
                          </Link> and{' '}
                          <Link to="/acceptable-use-policy" target="_blank" rel="noopener noreferrer">
                            Acceptable Use Policy
                          </Link>
                        </Fragment>
                      </Checkbox>
                      <FormErrorMessage>{form.errors.disclaimers}</FormErrorMessage>
                    </FormControl>
                  );
                }}
              </Field>

              {!allTermsAgreed && (
                <Field name="marketing">
                  {({ field, form }: any) => (
                    <FormControl isInvalid={(form.errors.marketing && form.touched.marketing)}>
                      <Checkbox
                        size="small-text"
                        spacing={3}
                        alignItems="baseline"
                        isChecked={values.marketing}
                        {...field}
                        id="marketing">
                        <Fragment>
                          Send me updates from LondonLink with information about our products,
                          services, events, news and offers.
                        </Fragment>
                      </Checkbox>
                      <FormErrorMessage>{form.errors.marketing}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              )}
            </Stack>

            {!allTermsAgreed && (
              <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
                <FormDisclaimer name="Level 1: Terms of service" />
              </Grid>
            )}

            {/* Handle Error */}
            {formsState.formSubmitRequest.dataStatus === "error" && (
              <Alert status="error" mt={5}>
                <AlertIcon />
                {formsState.formSubmitRequest.dataError?.message}
              </Alert>
            )}

            <Box textAlign="left" my={5}>
              {allTermsAgreed ? (
                <ContinueNextStep />
              ) : (
                <Button
                  type="submit"
                  transition="all 0.5s linear"
                  colorScheme={isSuccess ? "green" : "brand"}
                  leftIcon={<Fragment />}
                  spinnerPlacement="end"
                  rightIcon={isSuccess ? <SpinnerToTickIcon size="sm" /> : <Fragment />}
                  isLoading={isLoading}
                  isDisabled={isLoading}
                  loadingText="Submitting"
                  id="submit">
                  {isSuccess ? "Submitting" : "Submit"}
                </Button>
              )}
            </Box>

          </Form>
        );
      }}
    </Formik>
  );
};
