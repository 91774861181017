import React, { Fragment } from 'react';
import { Box, Stack } from '@chakra-ui/react';
import { MainNavItem } from "./MainNavItem/MainNavItem";
import { constants } from "../../../config/constants";

interface Props {
  closeMenu: () => void;
}

export const MainNav = ({ closeMenu }: Props) => {
  return (
    <Box as="nav">
      <Stack
        textAlign="center"
        listStyleType="none"
        as="ul"
        spacing={{ base: 1, md: 6 }}
        direction={{ base: 'column', md: 'row' }}
        my={{
          base: 5, md: 4, lg: 3, xl: 0
        }}>
        {constants.businessEntity === "gib" && (
          <Fragment>
            <MainNavItem closeMenu={closeMenu} link="/why-londonlink">Why LondonLink?</MainNavItem>
            <MainNavItem closeMenu={closeMenu} link="/about">About us</MainNavItem>
          </Fragment>
        )}
        <MainNavItem closeMenu={closeMenu} link="/contact">Contact us</MainNavItem>
      </Stack>
    </Box>
  );
};
