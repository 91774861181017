import React, { ReactElement, useEffect, Fragment } from "react";
import { Box, Container, Flex, IconButton, VisuallyHidden, Image, useBoolean, useMediaQuery } from "@chakra-ui/react";
import { FaBars } from "react-icons/all";
import { Link } from "react-router-dom";
import { useCustomerAuth } from "hooks";
import logo from '../../assets/images/LondonLink_Logo.svg';
import { MainNav } from "./MainNav/MainNav";
import { CustomerProfile } from "./CustomerProfile/CustomerProfile";
import { RegisterButtons } from "./RegisterButtons/RegisterButtons";
import { constants } from "../../config/constants";

interface MenuItemsProps {
  condition: boolean;
  wrap: (children: ReactElement) => ReactElement;
  children: ReactElement;
}

export const Header = () => {
  const [isLargerThan767] = useMediaQuery('(min-width: 768px)');
  const [showMenu, setShowMenu] = useBoolean(isLargerThan767);
  const { authState } = useCustomerAuth();

  const isLoggedIn = authState.authenticated;
  const isReactSnap = navigator && navigator.userAgent === 'ReactSnap';

  const closeMenu = () => {
    if (!isLargerThan767) {
      setShowMenu.off();
    }
  };

  useEffect(() => {
    // Hide the menu on mobile page changes
    if (!isLargerThan767) {
      setShowMenu.off();
    } else {
      // Resizing the window hows menu again on desktop if started at mobile
      setShowMenu.on();
    }
  }, [isLargerThan767]);

  const MenuItems = ({ condition, wrap, children }: MenuItemsProps) => (
    (condition && !isReactSnap) ? wrap(children) : children
  );

  return (
    <Box
      as="header"
      padding="20px 0"
      boxShadow="0 2px 5px rgba(0,0,0, 0.4)">
      <Container>
        <Box
          display="flex"
          alignItems="center"
          flexDirection={{ base: 'column', lg: 'row' }}
          justifyContent="space-between">
          <Flex justifyContent="space-between" width={{ base: '100%', md: 'auto' }}>
            <Link to="/">
              <Image
                src={logo}
                alt="LondonLink Logo"
                width={{ base: 150, md: 200 }}
              />
            </Link>

            {(!isLargerThan767 && !isReactSnap) && (
              <IconButton
                bg="white"
                onClick={setShowMenu.toggle}
                aria-label="Toggle menu"
                icon={<FaBars color="#222155" size={28} />}
              />
            )}
          </Flex>

          {/* For screen readers, avoid display none */}
          <MenuItems condition={!showMenu} wrap={(children) => (
            <VisuallyHidden>
              {children}
            </VisuallyHidden>)}>
            <Fragment>
              <MainNav closeMenu={closeMenu} />
              {constants.businessEntity === "gib" && (
                isLoggedIn ? <CustomerProfile /> : <RegisterButtons closeMenu={closeMenu} />
              )}
            </Fragment>
          </MenuItems>
        </Box>
      </Container>
    </Box>
  );
};
