import React, { ChangeEvent } from "react";
import {
  Box, Flex,
  FormControl, FormErrorMessage, FormHelperText,
  FormLabel,
  Grid,
  ListItem,
  Select, Switch,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import { Field } from "formik";
import { SourceOfFundsFormValues } from "../SourceOfFundsForm";

interface Props {
  values: SourceOfFundsFormValues;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const SOFStep3 = ({ values, setFieldValue }: Props) => {
  return (
    <Grid templateColumns={{ base: '1fr', lg: 'repeat(1, 1fr)' }} gap={5} my={5}>
      <Field name="individualOneOffIncomeSwitch">
        {({ field, form }: any) => {
          const isInvalid = (form.errors.individualOneOffIncomeSwitch && form.touched.individualOneOffIncomeSwitch);
          return (
            <FormControl isInvalid={isInvalid}>
              <FormLabel as="legend">
                Have you received any one-off income/ payments in the past 3 months?
                <Box as="span" color="gray.500" fontSize="xs" ml={1}>(optional)</Box>
              </FormLabel>
              <Flex mt={3}>
                <Switch
                  {...field}
                  isChecked={values.individualOneOffIncomeSwitch}
                  size="lg"
                  colorScheme="teal"
                  id="individualOneOffIncomeSwitch"
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setFieldValue('individualOneOffIncomeSwitch', event.target.checked);
                  }}
                />
                <Text as="samp" fontSize="sm" ml={3} alignSelf="center">
                  {values.individualOneOffIncomeSwitch ? 'Yes' : 'No'}
                </Text>
              </Flex>
              <FormErrorMessage>{form.errors.individualOneOffIncomeSwitch}</FormErrorMessage>
            </FormControl>
          );
        }}
      </Field>

      {values.individualOneOffIncomeSwitch && (
        <Field name="individualOneOffIncome">
          {({ field, form }: any) => {
            const isInvalid = (form.errors.individualOneOffIncome && form.touched.individualOneOffIncome);
            return (
              <FormControl isInvalid={isInvalid}>
                <FormLabel as="legend">What is the value of your one-off income/ payments in the past 3 months?</FormLabel>
                <FormHelperText fontSize="xs" mb={2}>
                  <Box fontSize="sm" mb={4} color="gray.500">
                    <Text>One-off payments could include:</Text>
                    <UnorderedList mt={1} mb={1} spacing={1}>
                      <ListItem>
                        Funds from an asset disposal such as a <Text as="u">property sale</Text> or
                        {' '}<Text as="u">vehicle sale</Text>
                      </ListItem>
                      <ListItem>
                        Funds from the sale of another investment such as
                        {' '}<Text as="u">stocks, cryptos, bonds</Text> etc
                      </ListItem>
                      <ListItem>
                        Funds withdrawn from your business via
                        {' '}<Text as="u">dividend, directors loan, liquidation</Text> etc
                      </ListItem>
                      <ListItem>
                        Funds received via <Text as="u">inheritance</Text>
                      </ListItem>
                      <ListItem>
                        Funds obtained by way of <Text as="u">credit or loan</Text>
                      </ListItem>
                      <ListItem>
                        Funds <Text as="u">gifted</Text> to you by somebody else
                      </ListItem>
                      <ListItem>
                        Funds withdrawn as a lump sum from your <Text as="u">pension</Text>
                      </ListItem>
                      <ListItem>
                        Funds received via a <Text as="u">windfall</Text> such as a
                        {' '}<Text as="u">lottery or other gambling activity</Text>
                      </ListItem>
                    </UnorderedList>
                  </Box>
                </FormHelperText>
                <Select
                  {...field}
                  maxWidth={485}
                  placeholder="Select option"
                  name="individualOneOffIncome"
                  id="individualOneOffIncome"
                  size="sm">
                  <option value={`{ "min": 0, "max": 12000 }`}>£0.00 - £12,000</option>
                  <option value={`{ "min": 12001, "max": 25000 }`}>£12,001 - £25,000</option>
                  <option value={`{ "min": 25001, "max": 40000 }`}>£25,001 - £40,000</option>
                  <option value={`{ "min": 40001, "max": 70000 }`}>£40,001 - £70,000</option>
                  <option value={`{ "min": 70001, "max": 100000 }`}>£70,001 - £100,000</option>
                  <option value={`{ "min": 100001, "max": 150000 }`}>£100,001 - £150,000</option>
                  <option value={`{ "min": 150001, "max": 250000 }`}>£150,001 - £250,000</option>
                  <option value={`{ "min": 250001, "max": 500000 }`}>£250,001 - £500,000</option>
                  <option value={`{ "min": 500001, "max": 1000000 }`}>£500,001 - £1,000,000</option>
                  <option value={`{ "min": 1000001, "max": 9999999 }`}>£1,000,001 - or above</option>
                </Select>
                <FormErrorMessage>{form.errors.individualOneOffIncome}</FormErrorMessage>
              </FormControl>
            );
          }}
        </Field>
      )}
    </Grid>
  );
};
