import React, { Fragment, ReactNode } from "react";
import { MainBodyContent } from "components";
import { Footer } from "../Footer/Footer";
import { Header } from "../Header/Header";

interface Props {
  children: ReactNode;
}

export const PublicPageTemplate = ({ children }: Props) => {
  return (
    <Fragment>
      <Header />

      <MainBodyContent>
        {children}
      </MainBodyContent>

      <Footer />
    </Fragment>
  );
};
