import React, { ReactNode } from 'react';
import { Box } from '@chakra-ui/react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { BiUser, BiCheckShield } from 'react-icons/bi';
import { RiCoinsLine } from 'react-icons/ri';

interface Props {
  closeMobileDrawer?: () => void;
}

interface MenuItemProps {
  children: ReactNode;
  to: string;
  icon: ReactNode;
}

export const CustomerAccountSidebar = ({ closeMobileDrawer }: Props) => {
  const iconProps = {
    color: '#a8b2bd',
    size: 20
  };

  const MenuItem = ({ children, to, icon }: MenuItemProps) => {
    const { pathname } = useLocation();
    const isActive = pathname === to;

    return (
      <Box
        as={RouterLink}
        to={to}
        onClick={closeMobileDrawer}
        fontSize="15px"
        padding={2}
        color="gray.800"
        bg="gray.100"
        borderRight="4px solid"
        borderRightColor={isActive ? '#2ab893' : 'gray.300'}
        borderBottom="1px solid"
        borderBottomColor="gray.300"
        alignItems="center"
        display="flex">
        <Box mr={3}>{icon}</Box>
        {children}
      </Box>
    );
  };

  return (
    <Box as="aside" width={250}>
      <MenuItem to="/customer/trade" icon={<RiCoinsLine {...iconProps} />}>
        Trade
      </MenuItem>
      <MenuItem to="/customer/verification" icon={<BiCheckShield {...iconProps} />}>
        Verification
      </MenuItem>
      <MenuItem to="/customer/account" icon={<BiUser {...iconProps} />}>
        Account
      </MenuItem>
    </Box>
  );
};
