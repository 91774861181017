export const radii = {
  full: '9999px',
  '2xl': '1.25rem',
  '3xl': '1rem',
  xl: '0.5rem',
  lg: '0.375rem',
  md: '0.2rem',
  base: '0.15rem',
  sm: '0.075rem',
  none: '0',
};
