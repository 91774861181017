import React, { ReactChild } from 'react';
import {
  Heading, Box
} from '@chakra-ui/react';

interface Props {
  image: ReactChild;
  title: string;
  children: ReactChild;
  align?: 'left' | 'center' | 'right'
}

export const USP = ({
  image, title, children, align = 'center'
}: Props) => {
  return (
    <Box my={{ base: 25, md: 5 }} display="flex" flexDirection={align === 'center' ? 'column' : 'row'}>
      <Box textAlign={align}>
        {image}
      </Box>
      <Box flex="1" pl={align === 'center' ? 0 : 4} mt={align === 'center' ? 4 : 0}>
        <Heading as="h3" fontSize="lg" textAlign={align} mb={3}>{title}</Heading>
        <Box textAlign={align}>
          {children}
        </Box>
      </Box>
    </Box>
  );
};
