import React from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Link, useLocation } from "react-router-dom";
import { useMigrationModalStore } from "../../store/MigrationModalStore";
import { constants } from "../../config/constants";

export const MigrationModal = () => {
  const { isModalOpen, closeModal } = useMigrationModalStore();
  const location = useLocation();

  return (
    <Modal isOpen={isModalOpen} onClose={closeModal} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>We have moved</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          LondonLink OTC has moved trading to our Gibraltar based subsidiary. This allows us to offer you a wider variety of features and services.
        </ModalBody>

        <ModalFooter>
          <HStack spacing={2}>
            {location.pathname !== "/why-gibraltar/" && (
              <Button as="a" href={`${constants.sites.gib}/why-gibraltar/`} variant="ghost" colorScheme="blue">Learn more</Button>
            )}
            <Button as="a" href={constants.sites.gib} colorScheme="blue">Visit new website</Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
