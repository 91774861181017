import React, { Fragment, ReactNode } from "react";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Container,
  Divider,
  Heading,
  HStack, useMediaQuery,
} from "@chakra-ui/react";
import {
  IneligibleToTradeBanner,
  LoggedInHeader,
  MainBodyContent,
  Footer,
  CustomerAccountSidebar,
  TermsNotAcceptedBanner
} from "components";
import { useComplianceState, useCustomerProfile } from "hooks";
import { Helmet } from "react-helmet";
import { Link as RouterLink } from "react-router-dom";
import { HiOutlineChevronRight } from "react-icons/all";
import { Customer } from "../../../../shared/src/types/customer";

interface Props {
  children: ReactNode;
  pageName: string;
  breadcrumb?: { title: string, current: boolean, to: string }[];
}

export const LoggedInDefaultTemplate = ({ children, pageName, breadcrumb }: Props) => {
  const { profile } = useCustomerProfile();
  const [isLargerThan767] = useMediaQuery('(min-width: 768px)');
  const complianceState = useComplianceState();

  return (
    <Fragment>
      <Helmet>
        <title>{pageName}</title>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>

      <LoggedInHeader />

      <MainBodyContent>
        <Container>
          {breadcrumb?.length && (
            <Breadcrumb
              mb={5}
              spacing="8px"
              color="#6f6f6f"
              fontSize="sm"
              separator={<HiOutlineChevronRight color="#cacaca" size={14} />}>
              {breadcrumb.map((b) => {
                return (
                  <BreadcrumbItem key={b.title} isCurrentPage={b.current}>
                    <BreadcrumbLink as={RouterLink} to={b.to}>
                      {b.title}
                    </BreadcrumbLink>
                  </BreadcrumbItem>
                );
              })}
            </Breadcrumb>
          )}

          {complianceState.dnt && <IneligibleToTradeBanner />}
          {complianceState.newTermsNotAcceptedSinceRegistering && <TermsNotAcceptedBanner />}

          <HStack spacing={10} d="flex" alignItems="flex-start" flexDirection={{ base: 'column', md: 'row' }}>
            {isLargerThan767 && <CustomerAccountSidebar />}

            <Box flex={1} width="100%">
              <Heading as="h1" size="lg" fontWeight={500} mb={5}>
                {pageName}
              </Heading>
              <Divider mb={5} />
              {children}
            </Box>
          </HStack>
        </Container>
      </MainBodyContent>

      <Footer />
    </Fragment>
  );
};
