import { AbstractActionModel } from "../abstract/AbstractActionModel";
import { DataStatuses, ErrorResponse } from "../../types/common";
import { customerApiClient } from "../../../../fe-main/src/libs/CustomerLondonLinkApiClient";

export interface CustomerSumSubModelState {
  webSDKToken: string | null;
  getWebSDKTokenRequest: {
    dataStatus: DataStatuses;
    dataError: ErrorResponse | null;
  },
}

const defaultState: CustomerSumSubModelState = {
  webSDKToken: null,
  getWebSDKTokenRequest: { dataStatus: DataStatuses.Uninitialized, dataError: null }
}

const xhrClient = customerApiClient.getXhrClient();

export class CustomerSumSubModel extends AbstractActionModel {
  private state = defaultState;

  private updateState(newState: Partial<CustomerSumSubModelState>) {
    this.state = { ...this.state, ...newState };
    this.notify();
  }

  /*
    Public methods
  */
  // WebSDK Token
  public async getWebSDKToken() {
    this.updateState({ getWebSDKTokenRequest: { dataError: null } as CustomerSumSubModelState['getWebSDKTokenRequest'] }); // Reset error on new fetch

    const genericErrorMessage = "There was an unexpected error fetching SumSub SDK Token. Please try again.";

    if (this.state.getWebSDKTokenRequest.dataStatus !== DataStatuses.Loading) {
      try {
        this.updateState({ getWebSDKTokenRequest: { dataStatus: DataStatuses.Loading, dataError: null } });

        const res = await xhrClient.post("/sumsub/getWebSDKAccessToken", {
          levelName: "Tier 0 Individual"
        });

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({
            webSDKToken: res.data.token,
            getWebSDKTokenRequest: {
              dataStatus: DataStatuses.Ok,
              dataError: null
            }
          });
        } else {
          this.updateState({ getWebSDKTokenRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
        }
      } catch (error: any) {
        let errorMessage = genericErrorMessage;
        this.updateState({ getWebSDKTokenRequest: { dataStatus: DataStatuses.Error, dataError: { stack: error, message: errorMessage } } });
      }
    }
  }

  public getSumSubState() {
    return this.state;
  }
}
