import { AbstractActionModel } from "../abstract/AbstractActionModel";
import { DataStatuses, ErrorResponse } from "../../types/common";
import { customerApiClient } from "../../../../fe-main/src/libs/CustomerLondonLinkApiClient";

export interface CustomerFormsModelState {
  formSubmitRequest: {
    dataStatus: DataStatuses;
    dataError: ErrorResponse | null;
  },
  corpoFormSubmitRequest: {
    dataStatus: DataStatuses;
    dataError: ErrorResponse | null;
  }
}

const defaultState: CustomerFormsModelState = {
  formSubmitRequest: { dataStatus: DataStatuses.Uninitialized, dataError: null },
  corpoFormSubmitRequest: { dataStatus: DataStatuses.Uninitialized, dataError: null },
}

const customerXhrClient = customerApiClient.getXhrClient();

export class CustomerFormsModel extends AbstractActionModel {
  private state = defaultState;

  private updateState(newState: Partial<CustomerFormsModelState>) {
    this.state = { ...this.state, ...newState };
    this.notify();
  }

  /*
    Public methods
  */
  public async submitForm(params: Record<string, any>) {
    const genericErrorMessage = "There was an unexpected error.\n Please try again, if the issue persists please contact us.";

    if (this.state.formSubmitRequest.dataStatus !== "loading") {
      try {
        this.updateState({ formSubmitRequest: { dataStatus: DataStatuses.Loading, dataError: null } });
        const res = await customerXhrClient.post("/form/submit", {
          formData: params
        });

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({ formSubmitRequest: { dataStatus: DataStatuses.Ok, dataError: null } });
        } else {
          this.updateState({ formSubmitRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
        }
      } catch (error) {
        this.updateState({ formSubmitRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
      }
    }
  }

  public async submitCorpoForm(params: Record<string, any>) {
    const genericErrorMessage = "There was an unexpected error.\n Please try again, if the issue persists please contact us.";

    if (this.state.corpoFormSubmitRequest.dataStatus !== DataStatuses.Loading) {
      try {
        this.updateState({ corpoFormSubmitRequest: { dataStatus: DataStatuses.Loading, dataError: null } });
        const res = await customerXhrClient.post("/form/submitCorpo", {
          formData: params
        });

        if (res.data && res.data.status.toLowerCase() === "ok") {
          this.updateState({ corpoFormSubmitRequest: { dataStatus: DataStatuses.Ok, dataError: null } });
        } else {
          this.updateState({ corpoFormSubmitRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
        }
      } catch (error) {
        this.updateState({ corpoFormSubmitRequest: { dataStatus: DataStatuses.Error, dataError: { message: genericErrorMessage } } });
      }
    }
  }

  public getFormsState() {
    return this.state;
  }

  public resetFormsState() {
    this.updateState(defaultState);
  }
}
