import React from "react";
import {
  RiCheckboxBlankCircleLine,
  RiCheckboxCircleFill,
  RiCloseCircleLine,
} from "react-icons/all";
import { Box, Spinner, Tooltip } from "@chakra-ui/react";
import { ComplianceItemStatus } from "../../../../../shared/src/types/compliance";

interface Props {
  status: ComplianceItemStatus;
}

export const VerificationLevelStatusIndicator = ({ status }: Props) => {
  const Icon = () => {
    switch (status) {
      case "completed":
        return <RiCheckboxCircleFill color="#38a169" size={18} />;
      case "failed":
        return <RiCloseCircleLine color="#d12c2c" size={18} />;
      case "optional":
      case "todo":
        return <RiCheckboxBlankCircleLine color="#b9b9b9" size={18} />;
      case "pending":
        return (
          <Spinner
            ml="2px"
            mr="1px"
            width="15px"
            height="15px"
            thickness="1px"
            speed="0.65s"
            emptyColor="gray.200"
            color="orange.500"
          />
        );
      default:
        return null;
    }
  };

  return (
    <Tooltip fontSize="xs" hasArrow label={status} textTransform="capitalize">
      <Box>
        <Icon />
      </Box>
    </Tooltip>
  );
};
