import React, { useEffect } from "react";
import { useToast } from "@chakra-ui/react";
import { useOnlineStatus } from "hooks";

export const ConnectionStatus = () => {
  const isOnline = useOnlineStatus();
  const toast = useToast();
  const toastIdRef = React.useRef<any>(null);

  useEffect(() => {
    if (!isOnline) {
      toastIdRef.current = toast({
        title: 'No internet connection available',
        description: 'Your device lost connection to the internet.',
        status: 'error',
        duration: null,
        isClosable: false,
      });
    } else if (toastIdRef && toastIdRef.current) {
      toast.update(toastIdRef.current, {
        title: 'Internet connection restored',
        description: 'You are back online.',
        duration: 1000,
        status: 'success'
      });
    }
  }, [isOnline]);

  return null;
};
