import React, { ReactNode } from 'react';
import { Alert, AlertProps } from '@chakra-ui/react';

interface Props extends AlertProps {
  children: ReactNode;
}

export const VerificationStatusResult = ({ children, ...rest }: Props) => {
  return (
    <Alert
      padding={8}
      marginTop={5}
      variant="subtle"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      {...rest}>
      {children}
    </Alert>
  );
};
