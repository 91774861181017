import React, { ReactNode, useEffect } from "react";
import { Route, RouteProps } from "react-router-dom";
import { envars } from "../../config/envars";
import { useMigrationModalStore } from "../../store/MigrationModalStore";

interface Props extends RouteProps {
  children: ReactNode;
}

export const MigratedToGibRoute = ({ children, ...rest }: Props) => {
  const { openModal } = useMigrationModalStore();

  /**
   * If customer is on UK version of the website, show migration popup instead of the page.
   */
  useEffect(() => {
    if (envars.BUSINESS_ENTITY === "uk") {
      openModal();
    }
  }, []);

  if (envars.BUSINESS_ENTITY === "uk") {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(routeProps: RouteProps) => children}
    />
  );
};
