import React, { ReactNode, AllHTMLAttributes } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button } from '@chakra-ui/react';

interface Props {
  children: ReactNode;
  link: string;
  target?: AllHTMLAttributes<HTMLLinkElement>['target'];
  closeMenu?: () => void;
}

export const MainNavItem = ({
  children, link, target = '_self', closeMenu
}: Props) => {
  return (
    <li>
      <Button
        variant="ghost"
        as={RouterLink}
        to={{ pathname: link }}
        onClick={closeMenu}
        target={target}>
        {children}
      </Button>
    </li>
  );
};
