import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { envars } from "../../config/envars";
import { constants } from "../../config/constants";

export const SEO = () => {
  const { pathname } = useLocation();

  // SEO
  const title = 'Bitcoin brokerage';
  const description = 'Buy Bitcoin with confidence with London’s most established bitcoin brokerage.';
  const url = `${constants.site() + pathname}`;

  // This is used in canonical tag so search engines know GIB is the primary site that should be indexed
  const primaryUrl = `${constants.sites.gib + pathname}`;

  return (
    <Helmet
      titleTemplate="%s | LondonLink"
      defaultTitle={title}>
      <html lang="en" />

      {/* Stop bots crawling site if envar is true */}
      {envars.BLOCK_SEARCH_INDEXING && (
        <meta name="robots" content="noindex, nofollow" />
      )}

      <meta name="description" content={description} />
      <link rel="canonical" href={primaryUrl} />


      {/* Open Graph */}
      <meta property="og:title" content={title} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content="en_GB" />
      <meta property="og:image" content={`${constants.site()}/logo512.png`} />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${constants.site()}/logo512.png`} />
    </Helmet>
  );
};
