import React, { useEffect } from "react";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  PinInput,
  PinInputField,
  Stack,
  useBoolean
} from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import { useCustomerAuth } from "hooks";
import { Link as RouterLink, useLocation } from "react-router-dom";
import * as yup from "yup";
import { PasswordRequirements } from "components";
import { DataStatuses } from "../../../../../shared/src/types/common";

export interface ResetFormValues {
  email: string;
  code: string;
  password: string;
}

const validationSchema = yup.object().shape({
  code: yup.string().required('Field is required'),
  password: yup.string()
    .required('Field is required')
    .min(8, 'Must contain at least 8 characters')
    .matches(/[a-zA-Z]/, 'Must contain at least one letter')
    .matches(/\d/, 'Must contain at least one number')
    .test({
      name: 'passwordCannotBeEmail',
      message: 'Your password cannot be your email address',
      test(value) {
        return this.parent.email !== value;
      },
    }),
});

export const ResetPasswordForm = () => {
  const { authState, resetPassword, resetRequestState } = useCustomerAuth();

  // Parse URL params
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const paramEmail = urlParams.get('email');
  const paramCode = urlParams.get('code');
  const paramOriginalTab = urlParams.get('originalTab');

  const [showPassword, setShowPassword] = useBoolean(false);

  const initialValues = {
    email: paramEmail || '',
    code: paramCode || '',
    password: ''
  };
  
  function handleSubmit(values: ResetFormValues) {
    resetPassword({ 
      email: values.email,
      newPassword: values.password,
      code: values.code
    });
  }

  // Reset request state when component is unmounted
  useEffect(() => {
    return function cleanup() {
      resetRequestState("resetPassword");
    };
  }, []);

  const isSuccess = authState.resetPassword.dataStatus === DataStatuses.Ok;
  const isError = authState.resetPassword.dataStatus === DataStatuses.Error;
  const isLoading = authState.resetPassword.dataStatus === DataStatuses.Loading;

  if (isSuccess) {
    return (
      <Alert status="success" mt={4}>
        <AlertIcon />
        <Box flex="1">
          <AlertTitle>Your password has been successfully reset</AlertTitle>
          <AlertDescription display="block">
            You can now <RouterLink to="/login">sign in</RouterLink> with your new password.
          </AlertDescription>
        </Box>
      </Alert>
    );
  }

  return (
    <Box as="section">
      {paramOriginalTab === '1' && (
        <Box my={5}>
          <Alert status="success">
            <AlertIcon />
            <Box flex="1">
              <AlertTitle>Password reset instructions sent</AlertTitle>
              <AlertDescription display="block">
                If you have an account registered with us, you will receive an email with
                password reset instructions.
              </AlertDescription>
            </Box>
          </Alert>
        </Box>
      )}

      <Formik
        initialValues={initialValues}
        validateOnBlur={false}
        validationSchema={validationSchema}
        onSubmit={(values: ResetFormValues) => handleSubmit(values)}>
        {({ setFieldValue, values }) => {
          return (
            <Form id="password-reset-form">
              <Stack spacing="6" mt={2}>
                <Field name="code">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isInvalid={!!(form.errors.code && form.touched.code)}
                      mt={5}
                      hidden={paramCode !== null}>
                      <FormLabel htmlFor="code">Verification code</FormLabel>
                      <FormHelperText mb={3}>
                        Enter the verification code we sent to your email address
                      </FormHelperText>
                      <HStack>
                        <PinInput
                          {...field}
                          variant="flushed"
                          type="alphanumeric"
                          onChange={(value) => setFieldValue('code', value)}>
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required autoFocus />
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required />
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required />
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required style={{ marginRight: 15 }} />

                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required style={{ marginLeft: 15 }} />
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required />
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required />
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required style={{ marginRight: 15 }} />

                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required style={{ marginLeft: 15 }} />
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required />
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required />
                          <PinInputField type={paramCode !== null ? 'hidden' : 'text'} required />
                        </PinInput>
                      </HStack>
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="email">
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={!!(form.errors.email && form.touched.email)}>
                      <FormLabel htmlFor="email">Email</FormLabel>
                      <Input {...field} type="email" id="email" autoFocus />
                      <FormErrorMessage>{form.errors.email}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field name="password">
                  {({ field, form }: FieldProps) => (
                    <FormControl isInvalid={!!(form.errors.password && form.touched.password)}>
                      <FormLabel htmlFor="password">New Password</FormLabel>
                      <InputGroup size="md" maxWidth={450}>
                        <Input {...field} type={showPassword ? 'text' : 'password'} id="password" />
                        <InputRightElement width="4.5rem">
                          <Button type="button" h="1.75rem" size="sm" onClick={setShowPassword.toggle}>
                            {showPassword ? 'Hide' : 'Show'}
                          </Button>
                        </InputRightElement>
                      </InputGroup>
                      <FormErrorMessage>{form.errors.password}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <PasswordRequirements password={values.password} />

                {/* Handle Error */}
                {isError && (
                  <Alert status="error" mt={5}>
                    <AlertIcon />
                    {authState.resetPassword.dataError?.message}
                  </Alert>
                )}

                <Button
                  width={{ base: '100%', md: 'auto' }}
                  isDisabled={isLoading}
                  isLoading={isLoading}
                  loadingText="Sending..."
                  colorScheme="brand"
                  size="md"
                  type="submit"
                  id="submit">
                  Reset Password
                </Button>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
