import React from 'react';
import { Box, Divider, Text, Code } from '@chakra-ui/react';
import { constants } from "../../../config/constants";

interface Props {
  version?: string;
  name: string;
  dateLastUpdated?: string;
}

export const LegalDisclaimer = ({ version, name, dateLastUpdated }: Props) => {
  return (
    <Box>
      <Divider />
      <Text color="gray.500" mb={2}>
        This document and content is copyright of {constants.businessEntityFullName()}{" "}
        - &copy; LondonLink {(new Date()).getFullYear()}. All rights are strictly reserved. Theft of intellectual
        property is a serious matter and LondonLink will pursue claims against any perpetrators.
      </Text>
      {version && <div className="body-small body-secondary">
        <pre><Code>v{version}</Code> {name}{dateLastUpdated && `, ${dateLastUpdated}`}.</pre>
      </div>}
    </Box>
  );
};
