import React from "react";
import { Badge } from "@chakra-ui/react";
import { ComplianceState } from "../../../../shared/src/types/compliance";

interface Props {
  complianceState: ComplianceState;
}

export const ComplianceLevelBadge = ({ complianceState }: Props) => {
  const isLevel2 = complianceState.level2 === 'completed';
  const isLevel1 = complianceState.level1 === 'completed';

  const dnt = complianceState.trading === "DNT";

  if (dnt) {
    return null;
  }

  if (isLevel2) {
    return (
      <Badge mt={3} colorScheme="green">
        Level 2 Verified
      </Badge>
    );
  }

  if (isLevel1) {
    return (
      <Badge mt={3} colorScheme="green">
        Level 1 Verified
      </Badge>
    );
  }

  return (
    <Badge mt={3} colorScheme="red">
      Unverified
    </Badge>
  );
};
