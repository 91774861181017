import React, { FormEvent, useState } from "react";
import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Stack, Input, Textarea } from "@chakra-ui/react";
import { FieldProps } from "formik";
import { NonIndividualFormValues } from "../NonIndividualForm";

interface Props {
  field: FieldProps['field'];
  form: FieldProps['form'];
  label: string;
  isRequired?: boolean;
  helpText?: string;
}

export const FormFieldStartUpCapital = ({ field, form, label, isRequired, helpText }: Props) => {
  const [values, setValues] = useState<NonIndividualFormValues['startUpCompanyCapital']>({
    directorsLoan: "",
    companyLoan: "",
    equityRaise: "",
    other: ""
  });

  const handleChange = (event: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const formField = event.currentTarget.name;
    const value = event.currentTarget.value;
    const newValue = { [formField]: value };
    setValues({ ...values, ...newValue });
  };

  const handleBlur = () => {
    form.setFieldValue(field.name, values);
  };

  const hasError = form.errors.hasOwnProperty(field.name);
  const atLeast1Value = !!(values.other || values.directorsLoan || values.companyLoan || values.equityRaise);

  return (
    <FormControl isInvalid={hasError} isRequired={isRequired}>
      <FormLabel fontSize="sm" >
        {label}
      </FormLabel>

      <FormHelperText mb={2}>{helpText}</FormHelperText>

      <Stack direction={{ base: "column", lg: "row" }} spacing={4} mt={4}>
        <FormControl isInvalid={hasError} as="fieldset" isRequired={!atLeast1Value}>
          <FormLabel fontSize="sm" as="legend">Directors Loan</FormLabel>
          <Input size="sm" type="text" name="directorsLoan" onChange={handleChange} onBlur={handleBlur} />
        </FormControl>

        <FormControl isInvalid={hasError} as="fieldset" isRequired={!atLeast1Value}>
          <FormLabel fontSize="sm" as="legend">Company Loan</FormLabel>
          <Input size="sm" type="text" name="companyLoan" onChange={handleChange} onBlur={handleBlur} />
        </FormControl>

        <FormControl isInvalid={hasError} as="fieldset" isRequired={!atLeast1Value}>
          <FormLabel fontSize="sm" as="legend">Equity Raise</FormLabel>
          <Input size="sm" type="text" name="equityRaise" onChange={handleChange} onBlur={handleBlur} />
        </FormControl>

        <FormControl isInvalid={hasError} as="fieldset" isRequired={!atLeast1Value}>
          <FormLabel fontSize="sm" as="legend">Other (specify)</FormLabel>
          <Textarea size="sm" type="text" name="other" onChange={handleChange} onBlur={handleBlur} />
        </FormControl>
      </Stack>

      <Input {...field} value={JSON.stringify(values)} size="sm" type="hidden" />

      <FormErrorMessage fontSize="xs">{form.errors[field.name]}</FormErrorMessage>
    </FormControl>
  );
};
