import React from "react";
import { LegalDisclaimer, LegalPageTemplate, NotFoundPage } from "components";
import { Container, Link } from "@chakra-ui/react";
import { Helmet } from "react-helmet";
import { constants } from "../../../config/constants";

export const TermsAndConditionsPage = () => {
  /**
   * GIB version
   */
  if (constants.businessEntity === "gib") {
    return (
      <LegalPageTemplate>
        <Helmet>
          <title>General Terms & Conditions</title>
        </Helmet>

        <Container>
          <article>
            <h1>General Terms & Conditions</h1>
            <p><strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OUR SITE OR OUR SERVICES</strong>
            </p>

            <section>
              <h2>WHAT&apos;S IN THESE TERMS?</h2>
              <p>These terms tell you the rules, terms and conditions for using our website https://www.londonlink.io (our site) and our services generally.</p>
              <p>Click on the links below to go straight to more information on each area:</p>
              <ul>
                <li>
                  <a href="#risk-disclaimer">Risk Disclaimer</a>
                </li>
                <li>
                  <a href="#who-we-are">Who we are and how to contact us</a>
                </li>
                <li>
                  <a href="#accept-terms">By using our site you accept these terms</a>
                </li>
                <li>
                  <a href="#other-terms">There are other terms that may apply to you</a>
                </li>
                <li>
                  <a href="#changes-terms">We may make changes to these terms</a>
                </li>
                <li>
                  <a href="#changes-site">We may make changes to our site</a>
                </li>
                <li>
                  <a href="#withdraw-site">We may suspend or withdraw our site</a>
                </li>
                <li>
                  <a href="#account-details">You must keep your account details safe</a>
                </li>
                <li>
                  <a href="#intellectual-property">How you may use material on our site</a>
                </li>
                <li>
                  <a href="#information">Do not rely on information on our site</a>
                </li>
                <li>
                  <a href="#websites-we-link-to">We are not responsible for websites we link to</a>
                </li>
                <li>
                  <a href="#user-generated-content">User-generated content is not approved by us</a>
                </li>
                <li>
                  <a href="#loss-or-damage">Our responsibility for loss or damage suffered by you</a>
                </li>
                <li>
                  <a href="#personal-information">How we may use your personal information</a>
                </li>
                <li>
                  <a href="#uploading-content">Uploading content to our site</a>
                </li>
                <li>
                  <a href="#viruses">We are not responsible for viruses and you must not introduce them</a>
                </li>
                <li>
                  <a href="#rules">Rules about linking to our site</a>
                </li>
                <li>
                  <a href="#anti-money-laundering">Anti money laundering</a>
                </li>
                <li>
                  <a href="#no-cash">Cash as a means/method of payment</a>
                </li>
                <li>
                  <a href="#cryptoassets">Any storage of Cryptoassets</a>
                </li>
                <li>
                  <a href="#force-majeure">Force Majeure</a>
                </li>
                <li>
                  <a href="#severability">Severability</a>
                </li>
                <li>
                  <a href="#rights-third-parties">Rights of third parties</a>
                </li>
                <li>
                  <a href="#country-laws">Which country’s laws apply to any disputes?</a>
                </li>
              </ul>
            </section>

            <section id="risk-disclaimer">
              <h3>RISK DISCLAIMER</h3>
              <p>Cryptoassets are not backed or guaranteed by any government or financial institution.</p>
              <p>When purchasing Cryptoassets from LondonLink, you must satisfy yourself that you understand all the associated investment risks in light of the above, and generally. Before you decide to invest, do not invest in anything you cannot afford to lose.
              </p>
              <p>You must acknowledge when buying and/or selling Cryptoassets from LondonLink that 100% of your capital invested is at risk. We cannot, and do not make any guarantee about the future value of your Cryptoassets and we will not be held liable for any losses you incur. Equally, we will not request a benefit from any gains you might make.
              </p>
              <p>Furthermore, we are not qualified or authorised to give any advice. We very strongly suggest you obtain financial advice from your professional advisor on any investments you make in Cryptoassets.
              </p>
              <p>For the avoidance of doubt, LondonLink are a brokerage offering an ‘execution only’ service at this time. It is solely your responsibility to store any Cryptoassets received in a safe manner. This includes but is not limited to providing LondonLink with the correct and appropriate wallet address to receive Cryptoassets purchased through LondonLink.
              </p>
              <p>All Cryptoasset transactions are immutable. This means that they cannot be reversed should you lose access to your Cryptoasset wallet. If you decide to store or invest your Cryptoassets with a third-party you should do your own due-diligence on that third party before sending your Cryptoassets to them. LondonLink will not be held responsible for loss incurred as a result of the acts or omissions of third parties.</p>
              <p>You should be particularly cautious with any third parties that promise guaranteed or unrealistic returns.</p>
            </section>

            <section id="who-we-are">
              <h3>WHO WE ARE AND HOW TO CONTACT US</h3>
              <p>https://www.londonlink.io is a site operated by LondonLink (GI) Limited (”We”).</p>
              <p>We are registered in Gibraltar under company number 121194 and have our registered office at Madison Building, Midtown, Queensway, Gibraltar, GX11 1AA.</p>
              <p>We are a limited company.</p>
              <p>To contact us, please email <a href="mailto:hello@londonlink.io">hello@londonlink.io</a>.</p>
            </section>

            <section id="accept-terms">
              <h3>BY USING OUR SITE YOU ACCEPT THESE TERMS</h3>
              <p>By using our site and/or our Services (as defined in our Trading Terms and Conditions as amended from time to time), you confirm that you accept these terms of use and that you agree to comply with them.</p>
              <p>If you do not agree to these terms, you must not use our site or our Services.</p>
              <p>We recommend that you print a copy of these terms for future reference.</p>
            </section>

            <section id="other-terms">
              <h3>THERE ARE OTHER TERMS THAT MAY APPLY TO YOU</h3>
              <p>These terms refer to the following additional terms, which also apply to your use of our site:</p>
              <ul>
                <li>Our <Link to="/privacy-policy">Privacy Policy</Link></li>
                <li>Our <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>, which sets out the
                  permitted uses and prohibited uses of our site. When using our site, you must comply with this
                  Acceptable Use Policy.
                </li>
                <li>Our <Link to="/cookie-policy">Cookie Policy</Link>, which sets out information about the
                  cookies on our site.
                </li>
              </ul>
            </section>

            <section id="changes-terms">
              <h3>WE MAY MAKE CHANGES TO THESE TERMS</h3>
              <p>We amend these terms from time to time. Every time you wish to use our site, please check these terms to ensure you understand the terms that apply at that time. These terms were most recently updated on 20th January 2022.</p>
            </section>

            <section id="changes-site">
              <h3>WE MAY MAKE CHANGES TO OUR SITE</h3>
              <p>We may update and change our site from time to time. We will try to give you reasonable notice of any major changes.</p>
            </section>

            <section id="withdraw-site">
              <h3>WE MAY SUSPEND OR WITHDRAW OUR SITE</h3>
              <p>Our site is made available free of charge.</p>
              <p>We do not guarantee that our site, or any content on it, will always be available or be uninterrupted. We may suspend or withdraw or restrict the availability of all or any part of our site for business and operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.</p>
              <p>You are also responsible for ensuring that all persons who access our site through your internet connection are aware of these terms of use and other applicable terms and conditions, and that they comply with them.</p>
            </section>

            <section id="account-details">
              <h3>YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</h3>
              <p>If you choose, or you are provided with, a user identification ID (or similar), password or any other piece of information as part of our security procedures, you must treat such information as confidential. You must not disclose it to any third party.</p>
              <p>We have the right to disable any user identification ID (or similar) or password, whether chosen by you or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the provisions of these terms of use.</p>
              <p>If you know or suspect that anyone other than you knows your user identification code and/or password, you must promptly notify us immediately using your preferred method.</p>
            </section>

            <section id="intellectual-property">
              <h3>HOW YOU MAY USE MATERIAL ON OUR SITE</h3>
              <p>We are the owner or the licensee of all intellectual property rights related to our site, and in the material published on it. Those works are protected by copyright laws and treaties around the world. All such rights are reserved.</p>
              <p>You may print off one copy, and may download extracts, of any page(s) from our site for your personal use and you may draw the attention of others within your organisation (if any) to content posted on our site.</p>
              <p>You must not modify the paper or digital copies of any materials you have printed off or downloaded in any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics separately from any accompanying text.</p>
              <p>Our status (and that of any identified contributors) as the authors of content on our site must always be acknowledged.</p>
              <p>You must not use any part of the content on our site for commercial purposes without obtaining a licence to do so from us or our licensors.</p>
              <p>If you print off, copy or download any part of our site in breach of these terms of use, your right to use our site will cease immediately and you must, at our option, return or destroy any copies of the materials you have made.</p>
            </section>

            <section id="information">
              <h3>DO NOT RELY ON INFORMATION ON THIS SITE</h3>
              <p>The content on our site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on our site.</p>
              <p>Although we make reasonable efforts to update the information on our site, we make no representations, warranties or guarantees, whether express or implied, that the content on our site is accurate, complete or up-to-date.</p>
            </section>

            <section id="websites-we-link-to">
              <h3>WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO</h3>
              <p>Where our site contains links to other sites and resources provided by third parties, these links are provided for your information only. Such links should not be interpreted as approval by us of those linked websites or information you may obtain from them.</p>
              <p>We have no control over the contents of those sites or resources.</p>
            </section>

            <section id="user-generated-content">
              <h3>USER-GENERATED CONTENT IS NOT APPROVED BY US</h3>
              <p>This website may include information and materials uploaded by other users of the site, including but not limited to bulletin boards, blog entries and chat rooms. This information and these materials have not been verified or approved by us. The views expressed by other users on our site do not represent our views or values.</p>
              <p>If you wish to complain about information and materials uploaded by other users please contact us using your preferred method.</p>
            </section>

            <section id="loss-or-damage">
              <h3>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</h3>
              <p><strong>Whether you are a consumer or a business user:</strong></p>
              <ul>
                <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This includes liability for death or personal injury caused by our negligence or the negligence of our employees, agents or subcontractors, or for fraud or fraudulent misrepresentation.</li>
                <li>Different limitations and exclusions of liability may apply to liability arising as a result of the supply of our Services, which will be set out in supplementary documents which shall be supplied to you in due course, if applicable.</li>
              </ul>
              <p><strong>If you are a business user:</strong></p>
              <ul>
                <li>We exclude all implied conditions, warranties, representations or other terms that may apply to our site or any content on it.</li>
                <li>We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with:</li>
                <ul>
                  <li>use of, or inability to use, our site; or</li>
                  <li>use of or reliance on any content displayed on our site.</li>
                </ul>
                <li>In particular, we will not be liable for:</li>
                <ul>
                  <li>loss of profits, sales, business, or revenue;</li>
                  <li>business interruption;</li>
                  <li>loss of anticipated savings;</li>
                  <li>loss of business opportunity, goodwill or reputation; or</li>
                  <li>any indirect or consequential loss or damage.</li>
                </ul>
              </ul>
              <p><strong>If you are a consumer user:</strong></p>
              <ul>
                <li>Please note that we only provide our site for private use. You agree not to use our site for any commercial or business purposes, and we have no liability to you for any loss of profit, loss of business, business interruption, or loss of business opportunity.</li>
              </ul>
            </section>

            <section id="personal-information">
              <h3>HOW WE MAY USE YOUR PERSONAL INFORMATION</h3>
              <p>We will only use your personal information as set out in our <Link to="/privacy-policy">Privacy
                Policy</Link>.</p>
            </section>

            <section id="uploading-content">
              <h3>UPLOADING CONTENT TO OUR SITE</h3>
              <p>Whenever you make use of a feature that allows you to upload content to our site, or to make contact with other users of our site, you must comply with the content standards set out in our <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>.</p>
              <p>You warrant that any such contribution does comply with those standards, and you will be liable to us and indemnify us for any breach of that warranty. This means you will be responsible for any loss or damage we suffer as a result of your breach of warranty.</p>
              <p>Any content you upload to our site will be considered non-confidential and non-proprietary. You retain all of your ownership rights in your content, but you are required to grant us and other users of our site a limited licence to use, store and copy that content and to distribute and make it available to third parties.</p>
              <p>We also have the right to disclose your identity to any third party who is claiming that any content posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or of their right to privacy.</p>
              <p>We have the right, but are not forced, to remove any posting you make on our site if, in our opinion, your post does not comply with the content standards set out in our Acceptable Use Policy or if in our reasonable opinion is distasteful or untrue.</p>
              <p>You are solely responsible for securing and backing up your content.</p>
            </section>

            <section id="viruses">
              <h3>WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM</h3>
              <p>We do not guarantee that our site will be secure or free from bugs or viruses.</p>
              <p>You are responsible for configuring your information technology, computer programmes and platform to access our site. You should use your own virus protection software.</p>
              <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other material that is malicious or technologically harmful. You must not attempt to gain unauthorised access to our site, the server on which our site is stored or any server, computer or database connected to our site. You must not attack our site via a denial-of-service attack or a distributed denial-of service attack. By breaching this provision, you would commit a criminal offence under Part 15 of the Crimes Act 2011. We will report any such breach to the relevant law enforcement authorities and we will cooperate with those authorities by disclosing your identity to them. In the event of such a breach, your right to use our site will cease immediately.</p>
            </section>

            <section id="rules">
              <h3>RULES ABOUT LINKING TO OUR SITE</h3>
              <p>You may link to our home page, provided you do so in a way that is fair and legal and does not damage our reputation or take advantage of it.</p>
              <p>You must not establish a link in such a way as to suggest any form of association, approval or endorsement on our part where none exists.</p>
              <p>You must not establish a link to our site in any website that is not owned by you.</p>
              <p>Our site must not be framed on any other site, nor may you create a link to any part of our site other than the home page.</p>
              <p>We reserve the right to withdraw linking permission without notice.</p>
              <p>The website in which you are linking must comply in all respects with the content standards set out in
                our <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>.
              </p>
              <p>If you wish to link to or make any use of content on our site other than that set out above, please
                contact <a href="mailto:hello@londonlink.io">hello@londonlink.io</a>.
              </p>
            </section>

            <section id="anti-money-laundering">
              <h3>ANTI-MONEY LAUNDERING</h3>
              <p>It is absolutely prohibited for you to try to use LondonLink or the Services it provides for the purpose of cleaning money, hiding money, in any way attempting to obscure the source of funds, or attempting to circumvent financial regulations or restrictions.</p>
              <p>If we suspect you have attempted to deceive in this way or engage in money laundering activities, terrorist financing or any fraud, LondonLink will cease to make any Services available to you, and any associated individuals or entities we think may be involved or related. You and they will also be blacklisted from the use of LondonLink’s Services.</p>
              <p>In all circumstances, we will take a zero-tolerance approach to any illegal activity you are, or may become, involved in. We are fully committed to our responsibilities in this regard and reserve the right to report any activity we consider suspicious to the appropriate authorities, including the Police and/ or the Gibraltar Financial Intelligence Unit.</p>
            </section>


            <section id="no-cash">
              <h3>CASH AS A MEANS/METHOD OF PAYMENT</h3>
              <p>Under no circumstances will LondonLink provide Services or otherwise, buy and/or sell any Cryptoassets from you using cash as a method of payment.</p>
            </section>

            <section id="cryptoassets">
              <h3>ANY STORAGE OF CRYPTOASSETS</h3>
              <p>LondonLink does not store any Cryptoassets on behalf of any customers.</p>
            </section>

            <section id="force-majeure">
              <h3>FORCE MAJEURE</h3>
              <p>If we are prevented, hindered or delayed in or from performing any of our obligations under the Trading Terms and Conditions by a Force Majeure Event, we shall not be in breach of this agreement (the General Terms and Conditions) or otherwise liable for any such failure or delay in the performance of such obligations. The time for performance of such obligations shall be extended accordingly.</p>
              <p>Force Majeure Event is defined as any circumstance not within a party’s reasonable control including, without limitation: acts of God, flood, drought, earthquake or other natural disaster; epidemic or pandemic; terrorist attack, civil war, civil commotion or riots, war, threat of or preparation for war, armed conflict, imposition of sanctions, embargo, or breaking off of diplomatic relations; nuclear, chemical or biological contamination or sonic boom; strike or other industrial dispute; any law or any action taken by a government or public authority, including without limitation imposing an export or import restriction, quota or prohibition; collapse of buildings, fire, explosion or accident; non-performance by suppliers or subcontractors; interruption or failure of electricity, telecommunications or internet services (including, but not limited to, relevant blockchain or Cryptoasset instability) or network provider services; or failure of equipment and/or software.</p>
            </section>

            <section id="severability">
              <h3>SEVERABILITY</h3>
              <p>If any court or relevant authority decides that any paragraph (separated by a blank line and not by clause number) is unlawful, all remaining paragraphs will remain in full force and effect.</p>
            </section>

            <section id="rights-third-parties">
              <h3>RIGHTS OF THIRD PARTIES</h3>
              <p>This contract is between you and LondonLink. No other third-party person or third-party entity shall have any rights to enforce any of its terms.</p>
            </section>

            <section id="country-laws">
              <h3>WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES?</h3>
              <p>If you are a consumer, please note that these terms of use, their subject matter and their formation, are governed by Gibraltar law and the courts of Gibraltar. You and we both agree that the courts of Gibraltar will have exclusive jurisdiction.</p>
              <p>If you are a business, these terms of use, their subject matter and their formation (and any non-contractual disputes or claims) are governed by Gibraltar law. We both agree to the exclusive jurisdiction of the courts of Gibraltar.</p>
              <p>This document and content is copyright of LondonLink (GI) Limited. All rights are strictly reserved. Theft of intellectual property is a serious matter and LondonLink will pursue claims against any perpetrators.</p>
            </section>

            <LegalDisclaimer version="2.0" name="General Terms and Conditions" dateLastUpdated="January 2022" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  /**
   * UK version
   */
  if (constants.businessEntity === "uk") {
    return (
      <LegalPageTemplate>
        <Container>
          <Helmet>
            <title>General Terms & Conditions</title>
          </Helmet>

          <article>
            <h1>General Terms &amp; Conditions</h1>
            <p><strong>PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING OUR SITE OR OUR SERVICES</strong>
            </p>

            <section>
              <h2>WHAT&apos;S IN THESE TERMS?</h2>
              <p>These terms tell you the rules, terms and conditions for using our website
                https://www.londonlinkotc.co.uk (<strong>our site</strong>) and our services generally.
              </p>
              <p>Click on the links below to go straight to more information on each area:</p>
              <ul>
                <li>
                  <a href="#risk-disclaimer">Risk Disclaimer</a>
                </li>
                <li>
                  <a href="#who-we-are">Who we are and how to contact us</a>
                </li>
                <li>
                  <a href="#accept-terms">By using our site you accept these terms</a>
                </li>
                <li>
                  <a href="#other-terms">There are other terms that may apply to you</a>
                </li>
                <li>
                  <a href="#changes-terms">We may make changes to these terms</a>
                </li>
                <li>
                  <a href="#changes-site">We may make changes to our site</a>
                </li>
                <li>
                  <a href="#withdraw-site">We may suspend or withdraw our site</a>
                </li>
                <li>
                  <a href="#account-details">You must keep your account details safe</a>
                </li>
                <li>
                  <a href="#intellectual-property">How you may use material on our site</a>
                </li>
                <li>
                  <a href="#information">Do not rely on information on our site</a>
                </li>
                <li>
                  <a href="#websites-we-link-to">We are not responsible for websites we link to</a>
                </li>
                <li>
                  <a href="#user-generated-content">User-generated content is not approved by us</a>
                </li>
                <li>
                  <a href="#loss-or-damage">Our responsibility for loss or damage suffered by you</a>
                </li>
                <li>
                  <a href="#personal-information">How we may use your personal information</a>
                </li>
                <li>
                  <a href="#uploading-content">Uploading content to our site</a>
                </li>
                <li>
                  <a href="#viruses">We are not responsible for viruses and you must not introduce them</a>
                </li>
                <li>
                  <a href="#rules">Rules about linking to our site</a>
                </li>
                <li>
                  <a href="#anti-money-laundering">Anti money laundering</a>
                </li>
                <li>
                  <a href="#no-cash">No cash</a>
                </li>
                <li>
                  <a href="#cryptoassets">Any storage of Cryptoassets</a>
                </li>
                <li>
                  <a href="#force-majeure">Force Majeure</a>
                </li>
                <li>
                  <a href="#severability">Severability</a>
                </li>
                <li>
                  <a href="#rights-third-parties">Rights of third parties</a>
                </li>
                <li>
                  <a href="#country-laws">Which country’s laws apply to any disputes?</a>
                </li>
              </ul>
            </section>

            <section id="risk-disclaimer">
              <h3>RISK DISCLAIMER</h3>
              <p>Cryptoassets are not backed or guaranteed by any government or financial institution.</p>
              <p>When purchasing Cryptoassets from LondonLink, you must satisfy yourself that you understand all the
                associated investment risks in light of the above, and generally. Before you decide to invest, do not
                invest in anything you cannot afford to lose.
              </p>
              <p>You must acknowledge when buying and/or selling Cryptoassets from LondonLink that 100% of your capital
                invested is at risk. We cannot, and do not make any guarantee about the future value and we will not be
                held liable for any losses you incur, or request a benefit from any gains you make.
              </p>
              <p>Furthermore, we are not qualified or authorised to give any advice. We very strongly suggest obtaining
                financial advice from your professional advisor on any investments you make in Cryptoassets.
              </p>
              <p>For the avoidance of doubt, LondonLink are a brokerage offering an ‘execution only’ service at this
                time. It is solely your responsibility to store any Cryptoasset received in a safe manner. This includes
                but is not limited to providing LondonLink with the correct and appropriate wallet address to receive
                Cryptoassets purchased through LondonLink.
              </p>
            </section>

            <section id="who-we-are">
              <h3>WHO WE ARE AND HOW TO CONTACT US</h3>
              <p>https://www.londonlinkotc.co.uk/ is a site operated by London Link OTC Limited (”We”). We are
                registered in England and Wales under company number 11668544 and have our registered office at 71-75
                Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ.
              </p>
              <p>We are a limited company.</p>
              <p>To contact us, please email <a href="mailto:hello@londonlinkotc.co.uk">hello@londonlinkotc.co.uk</a> or
                telephone our customer service line on 0207 971 1013.
              </p>
            </section>

            <section id="accept-terms">
              <h3>BY USING OUR SITE YOU ACCEPT THESE TERMS</h3>
              <p>By using our site, you confirm that you accept these terms of use and that you agree to comply with
                them.
              </p>
              <p>If you do not agree to these terms, you must not use our site.</p>
              <p>We recommend that you print a copy of these terms for future reference.</p>
            </section>

            <section id="other-terms">
              <h3>THERE ARE OTHER TERMS THAT MAY APPLY TO YOU</h3>
              <p>These terms of use refer to the following additional terms, which also apply to your use of our site:
              </p>
              <ul>
                <li>Our <Link to="/privacy-policy">Privacy Policy</Link></li>
                <li>Our <Link to="/acceptable-use-policy">Acceptable Use Policy</Link> which sets out the
                  permitted uses and prohibited uses of our site. When using our site, you must comply with this
                  Acceptable Use Policy.
                </li>
                <li>Our <Link to="/cookie-policy">Cookie Policy</Link>, which sets out information about the
                  cookies on our site.
                </li>
              </ul>
              <p>In addition to the above, which apply at all times when accessing our site, If you also use any of our
                company’s services, our ‘Trading Terms and Conditions’ (<strong>Trading Terms</strong>) will apply in
                addition to these general terms and conditions and the policies listed above.
              </p>
              <p>A copy of the Trading Terms will be made available when you make an account on our website or at least
                before we trade with you if we are trading on another platform other than our site. Once the Trading
                Terms are made available to you, positively confirming that you agree with them or by continuing to
                trade with us will constitute your full and irrevocable acceptance thereof.
              </p>
            </section>

            <section id="changes-terms">
              <h3>WE MAY MAKE CHANGES TO THESE TERMS</h3>
              <p>We amend these terms from time to time. Every time you wish to use our site, please check these terms
                to ensure you understand the terms that apply at that time. These terms were most recently updated on
                9th January 2020.
              </p>
            </section>

            <section id="changes-site">
              <h3>WE MAY MAKE CHANGES TO OUR SITE</h3>
              <p>We may update and change our site from time to time. We will try to give you reasonable notice of any
                major changes.
              </p>
            </section>

            <section id="withdraw-site">
              <h3>WE MAY SUSPEND OR WITHDRAW OUR SITE</h3>
              <p>Our site is made available free of charge.</p>
              <p>We do not guarantee that our site, or any content on it, will always be available or be uninterrupted.
                We may suspend or withdraw or restrict the availability of all or any part of our site for business and
                operational reasons. We will try to give you reasonable notice of any suspension or withdrawal.
              </p>
              <p>You are also responsible for ensuring that all persons who access our site through your internet
                connection are aware of these terms of use and other applicable terms and conditions, and that they
                comply with them.
              </p>
            </section>

            <section id="account-details">
              <h3>YOU MUST KEEP YOUR ACCOUNT DETAILS SAFE</h3>
              <p>If you choose, or you are provided with, a user identification ID (or similar), password or any other
                piece of information as part of our security procedures, you must treat such information as
                confidential. You must not disclose it to any third party.
              </p>
              <p>We have the right to disable any user identification ID (or similar) or password, whether chosen by you
                or allocated by us, at any time, if in our reasonable opinion you have failed to comply with any of the
                provisions of these terms of use.
              </p>
              <p>If you know or suspect that anyone other than you knows your user identification code or password, you
                must promptly notify us using your preferred method.
              </p>
            </section>

            <section id="intellectual-property">
              <h3>HOW YOU MAY USE MATERIAL ON OUR SITE</h3>
              <p>We are the owner or the licensee of all intellectual property rights in our site, and in the material
                published on it. Those works are protected by copyright laws and treaties around the world. All such
                rights are reserved.
              </p>
              <p>You may print off one copy, and may download extracts, of any page(s) from our site for your personal
                use and you may draw the attention of others within your organisation to content posted on our site.
              </p>
              <p>You must not modify the paper or digital copies of any materials you have printed off or downloaded in
                any way, and you must not use any illustrations, photographs, video or audio sequences or any graphics
                separately from any accompanying text.
              </p>
              <p>Our status (and that of any identified contributors) as the authors of content on our site must always
                be acknowledged.
              </p>
              <p>You must not use any part of the content on our site for commercial purposes without obtaining a
                licence to do so from us or our licensors.
              </p>
              <p>If you print off, copy or download any part of our site in breach of these terms of use, your right to
                use our site will cease immediately and you must, at our option, return or destroy any copies of the
                materials you have made.
              </p>
            </section>

            <section id="information">
              <h3>DO NOT RELY ON INFORMATION ON THIS SITE</h3>
              <p>The content on our site is provided for general information only. It is not intended to amount to
                advice on which you should rely. You must obtain professional or specialist advice before taking, or
                refraining from, any action on the basis of the content on our site.
              </p>
              <p>Although we make reasonable efforts to update the information on our site, we make no representations,
                warranties or guarantees, whether express or implied, that the content on our site is accurate, complete
                or up to date.
              </p>
            </section>

            <section id="websites-we-link-to">
              <h3>WE ARE NOT RESPONSIBLE FOR WEBSITES WE LINK TO</h3>
              <p>Where our site contains links to other sites and resources provided by third parties, these links are
                provided for your information only. Such links should not be interpreted as approval by us of those
                linked websites or information you may obtain from them.
              </p>
              <p>We have no control over the contents of those sites or resources.</p>
            </section>

            <section id="user-generated-content">
              <h3>USER-GENERATED CONTENT IS NOT APPROVED BY US</h3>
              <p>This website may include information and materials uploaded by other users of the site, including but
                not limited to bulletin boards, blog entries and chat rooms. This information and these materials have
                not been verified or approved by us. The views expressed by other users on our site do not represent our
                views or values.
              </p>
              <p>If you wish to complain about information and materials uploaded by other users please contact us using
                your preferred method.
              </p>
            </section>

            <section id="loss-or-damage">
              <h3>OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU</h3>
              <p><strong>Whether you are a consumer or a business user:</strong></p>
              <ul>
                <li>We do not exclude or limit in any way our liability to you where it would be unlawful to do so. This
                  includes liability for death or personal injury caused by our negligence or the negligence of our
                  employees, agents or subcontractors and for fraud or fraudulent misrepresentation.
                </li>
                <li>Different limitations and exclusions of liability will apply to liability arising as a result of the
                  supply of our services, which will be set out in our Trading Terms as referred to above.
                </li>
              </ul>

              <p><strong>If you are a business user:</strong></p>
              <ul>
                <li>We exclude all implied conditions, warranties, representations or other terms that may apply to our
                  site or any content on it.
                </li>
                <li>We will not be liable to you for any loss or damage, whether in contract, tort (including
                  negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in
                  connection with:
                </li>
                <ul>
                  <li>use of, or inability to use, our site; or</li>
                  <li>use of or reliance on any content displayed on our site.</li>
                </ul>
                <li>In particular, we will not be liable for:</li>
                <ul>
                  <li>loss of profits, sales, business, or revenue;</li>
                  <li>business interruption;</li>
                  <li>loss of anticipated savings;</li>
                  <li>loss of business opportunity, goodwill or reputation; or</li>
                  <li>any indirect or consequential loss or damage.</li>
                </ul>
              </ul>

              <p><strong>If you are a consumer user:</strong></p>
              <ul>
                <li>Please note that we only provide our site for private use. You agree not to use our site for any
                  commercial or business purposes, and we have no liability to you for any loss of profit, loss of
                  business, business interruption, or loss of business opportunity.
                </li>
              </ul>
            </section>

            <section id="personal-information">
              <h3>HOW WE MAY USE YOUR PERSONAL INFORMATION</h3>
              <p>We will only use your personal information as set out in our <Link to="/privacy-policy">Privacy
                Policy</Link>.
              </p>
            </section>

            <section id="uploading-content">
              <h3>UPLOADING CONTENT TO OUR SITE</h3>
              <p>Whenever you make use of a feature that allows you to upload content to our site, or to make contact
                with other users of our site, you must comply with the content standards set out in our <Link
                  to="/acceptable-use-policy">Acceptable Use Policy</Link>.
              </p>
              <p>You warrant that any such contribution does comply with those standards, and you will be liable to us
                and indemnify us for any breach of that warranty. This means you will be responsible for any loss or
                damage we suffer as a result of your breach of warranty.
              </p>
              <p>Any content you upload to our site will be considered non-confidential and non-proprietary. You retain
                all of your ownership rights in your content, but you are required to grant us and other users of our
                site a limited licence to use, store and copy that content and to distribute and make it available to
                third parties.
              </p>
              <p>We also have the right to disclose your identity to any third party who is claiming that any content
                posted or uploaded by you to our site constitutes a violation of their intellectual property rights, or
                of their right to privacy.
              </p>
              <p>We have the right, but are not forced, to remove any posting you make on our site if, in our opinion,
                your post does not comply with the content standards set out in our Acceptable Use Policy or if in our
                reasonable opinion is distasteful or untrue.
              </p>
              <p>You are solely responsible for securing and backing up your content.</p>
            </section>

            <section id="viruses">
              <h3>WE ARE NOT RESPONSIBLE FOR VIRUSES AND YOU MUST NOT INTRODUCE THEM</h3>
              <p>We do not guarantee that our site will be secure or free from bugs or viruses.</p>
              <p>You are responsible for configuring your information technology, computer programmes and platform to
                access our site. You should use your own virus protection software.
              </p>
              <p>You must not misuse our site by knowingly introducing viruses, trojans, worms, logic bombs or other
                material that is malicious or technologically harmful. You must not attempt to gain unauthorised access
                to our site, the server on which our site is stored or any server, computer or database connected to our
                site. You must not attack our site via a denial-of-service attack or a distributed denial-of service
                attack. By breaching this provision, you would commit a criminal offence under the Computer Misuse Act
                1990. We will report any such breach to the relevant law enforcement authorities and we will co-operate
                with those authorities by disclosing your identity to them. In the event of such a breach, your right to
                use our site will cease immediately.
              </p>
            </section>

            <section id="rules">
              <h3>RULES ABOUT LINKING TO OUR SITE</h3>
              <p>You may link to our home page, provided you do so in a way that is fair and legal and does not damage
                our reputation or take advantage of it.
              </p>
              <p>You must not establish a link in such a way as to suggest any form of association, approval or
                endorsement on our part where none exists.
              </p>
              <p>You must not establish a link to our site in any website that is not owned by you.</p>
              <p>Our site must not be framed on any other site, nor may you create a link to any part of our site other
                than the home page.
              </p>
              <p>We reserve the right to withdraw linking permission without notice.</p>
              <p>The website in which you are linking must comply in all respects with the content standards set out in
                our <Link to="/acceptable-use-policy">Acceptable Use Policy</Link>.
              </p>
              <p>If you wish to link to or make any use of content on our site other than that set out above, please
                contact <a href="mailto:hello@londonlinkotc.co.uk">hello@londonlinkotc.co.uk</a>.
              </p>
            </section>

            <section id="anti-money-laundering">
              <h3>ANTI-MONEY LAUNDERING</h3>
              <p>It is absolutely prohibited for you to try and use the Company or the services it provides for the
                purpose of; cleaning money, hiding money, in any way attempting to obscure the source of funds, or
                attempting to circumvent financial regulations or restrictions.
              </p>
              <p>If we suspect you have attempted to deceive in this way or engage in money laundering activities,
                terrorist financing or any fraud the Company will cease to make any services available to you, and any
                associated individual(s), incorporated or unincorporated business(es) or Trust(s) we think may be
                involved or related. You and they will also be blacklisted from the use of the Company’s services.
              </p>
              <p>We will take a zero tolerance approach in all circumstances to any illegal activity you are, or may
                become, involved in. We are fully committed to our responsibilities in this regard and reserve the right
                to report any activity we consider suspicious.
              </p>
            </section>

            <section id="no-cash">
              <h3>CASH AS A MEANS/METHOD OF PAYMENT</h3>
              <p>Under no circumstances will LondonLink provide services or otherwise, buy and/or sell any Cryptoassets
                from you using cash as a method of payment.
              </p>
            </section>

            <section id="cryptoassets">
              <h3>ANY STORAGE OF CRYPTOASSETS</h3>
              <p>LondonLink does not store any Cryptoassets on behalf of any customers.</p>
            </section>

            <section id="force-majeure">
              <h3>FORCE MAJEURE</h3>
              <p>If we are unable to perform the Services due to factors beyond our control including but not limited to
                Force Majeure, change of law or change in sanctions policy we will not have any liability to you with
                respect to the Services provided under the Trading Terms and for a period of time coincident with the
                event.
              </p>
              <p>Force Majeure is defined as any cause or condition beyond reasonable control, including but not limited
                to, any delay or failure due to any act of god, act of civil or military authorities, act of terrorists,
                civil disturbance, war, strike or other industrial dispute, fire, interruption in telecommunications or
                internet services (including, but not limited to, relevant blockchain or Cryptoasset instability) or
                network provider services, failure of equipment and/or software, other catastrophe or any other
                occurrence which is beyond our reasonable control.
              </p>
            </section>

            <section id="severability">
              <h3>SEVERABILITY</h3>
              <p>If any court or relevant authority decides that any paragraph (separated by a blank line and not by
                clause number) is unlawful, all remaining paragraphs will remain in full force and effect.
              </p>
            </section>

            <section id="rights-third-parties">
              <h3>RIGHTS OF THIRD PARTIES</h3>
              <p>This contract is between you and LondonLink. No other third-party person or third-party entity shall
                have any rights to enforce any of its terms subject to the Novation clause below.
              </p>
            </section>

            <section id="country-laws">
              <h3>WHICH COUNTRY’S LAWS APPLY TO ANY DISPUTES?</h3>
              <p>If you are a consumer, please note that these terms of use, their subject matter and their formation,
                are governed by English law and the courts of England and Wales. You and we both agree that the courts
                of England and Wales will have exclusive jurisdiction except that if you are a resident of Northern
                Ireland you may also bring proceedings in Northern Ireland, and if you are resident of Scotland, you may
                also bring proceedings in Scotland.
              </p>
              <p>If you are a business, these terms of use, their subject matter and their formation (and any
                non-contractual disputes or claims) are governed by English law. We both agree to the exclusive
                jurisdiction of the courts of England and Wales.
              </p>
            </section>

            <LegalDisclaimer version="1.2.1" name="General Terms and Conditions" />
          </article>
        </Container>
      </LegalPageTemplate>
    );
  }

  return <NotFoundPage />;
};
