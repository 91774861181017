import axios, { AxiosInstance } from "axios";
import { LondonLinkApiClient } from "../../../shared/src/libs/LondonLinkApiClient";
import { LondonLinkApiClientAxiosAdapter } from "../../../shared/src/libs/LondonLinkApiClientAxiosAdapter";
import { BaseWebSocketClientApplication } from "../../../shared/src/libs/BaseWebSocketClientApplication";
import { envars } from "../config/envars";

const axiosInstance: AxiosInstance = axios.create({
  baseURL: envars.API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

const websocketClientApplicationInstance = new BaseWebSocketClientApplication({
  websocketUrl: envars.WS_URL,
  WebsocketClass: WebSocket
});

const xhrAdapter = new LondonLinkApiClientAxiosAdapter(axiosInstance);
const websocketClientApplication = websocketClientApplicationInstance;

export const customerApiClient = new LondonLinkApiClient();
customerApiClient.init(xhrAdapter, websocketClientApplication);
