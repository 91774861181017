import { useEffect, useState } from "react";
import { ComplianceActionStages } from "../../../shared/src/types/compliance";
import { customerProfileModel } from "./useCustomerProfile";
import { CustomerVerificationModelState, CustomerVerificationModel, CustomerVerificationRequestNames, VerifyEmailRequest } from "../../../shared/src/models/customer/CustomerVerficationModel";

const verificationModel = new CustomerVerificationModel(customerProfileModel);

export function useCustomerVerification() {
  const [verificationState, setVerificationState] = useState<CustomerVerificationModelState>(verificationModel.getVerificationState());

  // This fires when model state changes
  function onListen() {
    setVerificationState(verificationModel.getVerificationState());
  }

  useEffect(() => {
    verificationModel.addListener(onListen);

    return function cleanup() {
      verificationModel.removeListener(onListen);
    };
  }, []);

  function customerStageSelect(stage: ComplianceActionStages) {
    verificationModel.customerStageSelect(stage);
  }

  function autoSelectCustomerStage() {
    verificationModel.autoSelectCustomerStage();
  }

  function verifyEmail(params: VerifyEmailRequest) {
    verificationModel.verifyEmail(params);
  }

  function resendEmailVerification() {
    verificationModel.resendEmailVerification();
  }

  function resetCustomerVerificationState() {
    verificationModel.resetCustomerVerificationState();
  }

  function resetRequestState(request: CustomerVerificationRequestNames) {
    verificationModel.resetRequestState(request);
  }

  return {
    verificationState,
    customerStageSelect,
    resetCustomerVerificationState,
    resetRequestState,
    verifyEmail,
    resendEmailVerification,
    autoSelectCustomerStage
  };
}
