const { KTimer } = require('./KTimer');

exports.RemoteEventsWatchersManager = class RemoteEventsWatchersManager {
  // --------------------------------------------------------------------------
  //                              Initialize code
  // --------------------------------------------------------------------------

  constructor(config) {
    this.remoteState         = {};
    this.localState          = {};
    this.timer               = new KTimer(this);
    this.watchRequestPending = false;

    // check config
    if (!config) {
      throw new Error('RemoteEventsWatchersManager - config must be present');
    }
    if (!config.websocketClientApp) {
      throw new Error('RemoteEventsWatchersManager - config.websocketClientApp must be present');
    }

    // Listen on events from websocketClientApp
    this.websocketClientApp = config.websocketClientApp;
    this.websocketClientApp.registerChannel('EventServer', this);
    this.websocketClientApp.addConnectionStatusChangedListener(() => {
      this.onConnectionStatusChanged();
    });

  }

  // --------------------------------------------------------------------------
  //                          Internal API (private)
  // --------------------------------------------------------------------------

  _localStateChanged() {
    this.timer.callOnce(0, 500);
  }

  _updateRemoteState(arrayOfUidsChanged) {
    for (let item of arrayOfUidsChanged) {
      // Make sure there is slot for requested uid in remoteState map.
      if (!this.remoteState[item.uid]) {
        this.remoteState[item.uid] = {};
      }

      // Compare stamp just received from server with last known one.
      if (this.remoteState[item.uid].stamp != item.serverStamp) {
        // Stamp changed for this uid. Update remoteState map and propagate
        // it to listeners.
        // console.log('Stamp changed for uid [', item.uid, '] from [', this.remoteState[item.uid].stamp, '] to [', item.serverStamp, ']');

        this.remoteState[item.uid].stamp = item.serverStamp;

        // Compare stamp just received from server with stamp known to
        // local listeners.
        //
        // Condition below is to first check if there is anybody locally listening to given uid.
        // There is a small window of time, when everyone locally unregistered from given uid, but the server
        // doesn't know about it yet, so it sent the update anyway.
        if (this.localState[item.uid]) {
          for (let listener of this.localState[item.uid].listeners) {
            if (item.serverStamp != listener.stamp) {
              // One of listeneres stores out of dated stamp. Notify it about latest one.
              listener.listener.onRemoteEventStampReceived(item.uid, item.serverStamp);
            }
          }
        }
      }
    }
  }

  _setListenerStatus(uid, listener, newStatus) {
    const oldStatus = listener.status;

    listener.status = newStatus;

    if ((oldStatus.status != newStatus.status) ||
        (oldStatus.state  != newStatus.state)) {
      listener.listener.onRemoteEventStatusReceived(uid, newStatus);
    }
  }

  _checkIfRemoteRequestNeeded() {
    if (this.watchRequestPending) {
      // We're still waiting for responde for last watch request. Ignore call.
      // We will try again just after pending request finished.
    } else {
      //
      // Compare local and remote states.
      //

      let arrayOfWatchedItems        = [];
      let arrayOfWatchedItemsChanged = false;

      for (let localUid in this.localState) {
        let localStamp = null;

        if (!this.remoteState[localUid]) {
          // Uid is registered locally, but not passed to server yet.
          // Get local stamp from first listener.
          localStamp = this.localState[localUid].listeners[0].stamp;
          arrayOfWatchedItemsChanged = true;

          // Broadcast 'connecting' status.
          for (let item of this.localState[localUid].listeners) {
            if (item.state != 'watching') {
              this._setListenerStatus(localUid, item, {status: 'ok', state: 'connecting'});
            }
          }
        } else {
          // Uid is registered locally and server already knows that we want
          // to watch it. Get last known server stamp.
          if (this.remoteState[localUid].stamp) {
            localStamp = this.remoteState[localUid].stamp;
          } else {
            localStamp = this.localState[localUid].listeners[0].stamp;
          }
        }

        arrayOfWatchedItems.push({
          uid: localUid,
          stamp: localStamp
        });
      }

      // Check for uids, that are still watched on server, but we don't need
      // to watch them anymore.
      for (let remoteUid in this.remoteState) {
        if (!this.localState[remoteUid]) {
          // Uid is watched on server, but is not watched locally anymore.
          arrayOfWatchedItemsChanged = true;
          delete this.remoteState[remoteUid];
        }
      }

      // Send list of watched uid to server if changed.
      if (arrayOfWatchedItemsChanged) {
        this.watchRequestPending = true;

        const actions = [
          {
            actionName: 'watch',
            items: arrayOfWatchedItems
          }
        ];

        if (false) {
          console.log('Going to send new watch list to server', arrayOfWatchedItems);
        }

        this.websocketClientApp.sendActions(actions, (err, result) => {
          let retryNeeded = false;

          if (err) {
            // Error - can't send watch action to server.
            // Try again in 5 seconds.
            console.log('ERROR! Cannot to send watch action. Error is:', err);
            retryNeeded = true;

            // Broadcast error to listeners.
            for (let item of arrayOfWatchedItems) {
              for (let listener of this.localState[item.uid].listeners) {
                this._setListenerStatus(item.uid, listener, {
                  status: 'error',
                  state: 'connecting',
                  err: {errorCode: 'connectionError', ext: {err: err}}
                });
              }
            }
          } else if (result[0].status != 'ok') {
            // Watch action sent, but failed on server side.
            // Try again in 5 seconds.
            console.log('ERROR! Watch action failed on server side. Server response is:', result[0]);
            retryNeeded = true;

            // Broadcast error to listeners.
            for (let item of result[0].items) {
              for (let listener of this.localState[item.uid].listeners) {
                this._setListenerStatus(item.uid, listener, {
                  status: 'error',
                  state: 'connecting',
                  err: {errorCode: 'remoteError', ext: {serverResponse: item}}
                });
              }
            }
          } else {
            // We got result from watch action.
            // Propagate it to remoteState[] array.
            this._updateRemoteState(result[0].items);

            // Update listener statuses.
            for (let item of result[0].items) {
              if (this.localState[item.uid]) {
                if (item.status == 'watching') {
                  if (item.sync == 'unknown') {
                    // Uid is watched, but remote event manager doesn't know
                    // current stamp yet. We need to get it in another way.
                    for (let listener of this.localState[item.uid].listeners) {
                      this._setListenerStatus(item.uid, listener, {
                        status: 'error',
                        state: 'watching',
                        err: {errorCode: 'unknownStamp'}
                      });
                    }
                  } else {
                    // Success - uid is watched and we got current remote stamp.
                    for (let listener of this.localState[item.uid].listeners) {
                      this._setListenerStatus(item.uid, listener, {
                        status: 'ok',
                        state: 'watching'
                      });
                    }
                  }
                } else {
                  // Unexpected status - treat as remote error.
                  for (let listener of this.localState[item.uid].listeners) {
                    this._setListenerStatus(item.uid, listener, {
                      status: 'error',
                      state: 'off',
                      err: {errorCode: 'remoteError', ext: {serverResponse: item}}
                    });
                  }
                }
              }
            }
          }

          // Don't lock watch api anymore.
          this.watchRequestPending = false;

          if (retryNeeded) {
            // Retry request in next 5 seconds if error.
            console.log('Retrying watch request in 5 seconds...');

            this.timer.callOnce(0, 5000);
          } else {
            // Request processed succesfully, but local state might changed
            // while action was in pending state. Let's check it now.
            this._checkIfRemoteRequestNeeded();
          }
        });
      }
    }
  }

  onTimer() {
    this._checkIfRemoteRequestNeeded();
  }

  onDataOnChannel(event) {
    this._updateRemoteState([event]);
  }

  onConnectionStatusChanged() {
    if (this.websocketClientApp.isSocketConnected()) {
      // Connection started up or restored after error.
      // Update remoteState map from scratch.
      this.remoteState = {};
      this._checkIfRemoteRequestNeeded();
    }
  }

  // --------------------------------------------------------------------------
  //                                 Public API
  // --------------------------------------------------------------------------

  watchRemoteEvents(watcher, uid, stamp = null) {
    // Check parmaters.
    if (!watcher) throw new Error('missing param watcher');
    if (!uid) throw new Error('missing param uid');
    if (stamp === null) throw new Error('missing param stamp');

    // Make sure there is an entry for requested uid in localState map.
    if (!this.localState[uid]) {
      this.localState[uid] = {listeners: []};
    }

    // Check if watcher is already registered?
    let watcherAlreadyRegistered = false;

    for (let item of this.localState[uid].listeners) {
      if (item.listener == watcher) {
        // Watcher is already registered for this uid.
        // Update the stamp currently stored for this watcher.
        item.listener.stamp = stamp;
        watcherAlreadyRegistered = true;
        break;
      }
    }

    if (!watcherAlreadyRegistered) {
      // Watcher seen first time for this uid.
      // Create new watcher slot from scratch.
      this.localState[uid].listeners.push({
        listener: watcher,
        stamp: stamp,
        status: {state: 'unknown'}
      });

      this._localStateChanged();
    }
  }

  unwatchRemoteEvents(watcher, uid) {
    // Check parameters.
    if (!watcher) throw new Error('missing param watcher');
    if (!uid) throw new Error('missing param uid');

    // Find watcher in listeners list for requested uid.
    for (let idx in this.localState[uid].listeners) {
      if (this.localState[uid].listeners[idx].listener == watcher) {
        // Watcher found. Remove it from listeners list.
        this.localState[uid].listeners.splice(idx, 1);

        // Remove uid from local watch list if last listener removed.
        if (this.localState[uid].listeners.length == 0) {
          delete this.localState[uid];
        }

        break;
      }
    }

    this._localStateChanged();
  }
};
