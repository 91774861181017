import { AxiosInstance, AxiosResponse } from "axios";
import {
  LondonLinkApiClientAdapterAbstractOptions,
  LondonLinkApiClientXhrAdapterAbstract
} from "./LondonLinkApiClient";

export class LondonLinkApiClientAxiosAdapter implements LondonLinkApiClientXhrAdapterAbstract {
  public axiosInstance: AxiosInstance;

  constructor(axiosInstance: AxiosInstance) {
    this.axiosInstance = axiosInstance;
  }

  public async post(url: string, data: Record<string, any>, options: LondonLinkApiClientAdapterAbstractOptions = {}): Promise<AxiosResponse> {
    return this.axiosInstance.post(url, data, options);
  }

  public async get(url: string): Promise<AxiosResponse> {
    return this.axiosInstance.get(url)
  }
}
