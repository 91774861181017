import React, { ReactChild } from 'react';
import { Container, Box } from '@chakra-ui/react';

interface Props {
  backgroundImage?: string;
  children: ReactChild;
}

export const CoverBanner = ({ backgroundImage, children }: Props) => {
  return (
    <Box
      color="#fff"
      marginTop={{ base: '-16px', md: '-32px' }}
      backgroundSize="cover"
      backgroundPosition="center"
      padding={{ base: '50px 0', md: '140px 0' }}
      backgroundImage={backgroundImage ? `url('${backgroundImage}')` : 'none'}>
      <Container maxW="container.xl" textAlign="center">
        {children}
      </Container>
    </Box>
  );
};
