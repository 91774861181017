import React, { Fragment } from "react";
import { Alert, AlertIcon, Button, Heading } from "@chakra-ui/react";
import { useComplianceState, useCustomerVerification } from "hooks";
import { ContinueNextStep, SumSubIframe } from "components";
import { ComplianceActionStages } from "../../../../../../shared/src/types/compliance";

export const KYC = () => {
  const complianceState = useComplianceState();
  const { customerStageSelect } = useCustomerVerification();

  return (
    <Fragment>
      <Heading size="md" as="h2" mb={3}>
        KYC (Know Your Customer) verification
      </Heading>

      {complianceState.actions.kycSumsub.status === 'completed' ? (
        <ContinueNextStep successMessage="Your details have been successfully submitted." />
      ) : (
        <Fragment>
          {complianceState.actions.emailVerification.status === "completed" ? <SumSubIframe /> : (
            <Alert status="info">
              <AlertIcon />
              You need to
              <Button
                onClick={() => customerStageSelect(ComplianceActionStages.emailVerification)}
                variant="link"
                colorScheme="blue"
                mx={2}>
                confirm your email address
              </Button>
              before you can complete KYC verification
            </Alert>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};
