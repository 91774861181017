import React, { useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { Spinner } from "@chakra-ui/react";

interface Props {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const SpinnerToTickIcon = ({ size = "md" }: Props) => {
  const [showCheck, setShowCheck] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShowCheck(true), 500);
    return () => clearTimeout(timer);
  }, []);

  return showCheck ? <FaCheck /> : <Spinner size={size} />;
};
