import { useEffect, useState } from "react";
import {
  CustomerContactMessagesModel,
  CustomerContactMessagesModelState, SendContactMessageRequest
} from "../../../shared/src/models/customer/CustomerContactMessagesModel";
import { constants } from "../config/constants";

const contactMessagesModel = new CustomerContactMessagesModel(constants.domain());

export function useCustomerContactMessages() {
  const [message, setMessage] = useState<CustomerContactMessagesModelState>(contactMessagesModel.getMessagesState());

  // This fires when model state changes
  function onListen() {
    setMessage(contactMessagesModel.getMessagesState());
  }

  useEffect(() => {
    contactMessagesModel.addListener(onListen);

    return function cleanup() {
      contactMessagesModel.removeListener(onListen);
    };
  }, []);

  function sendMessage(params: SendContactMessageRequest) {
    return contactMessagesModel.sendMessage(params);
  }

  return {
    message,
    sendMessage,
  };
}
