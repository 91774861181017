import React from "react";
import { Button, HStack, VStack } from "@chakra-ui/react";
import { useCustomerVerification } from "hooks";
import { VerificationLevelStatusIndicator } from "../VerificationLevelStatusIndicator/VerificationLevelStatusIndicator";
import { ComplianceActionStages } from "../../../../../shared/src/types/compliance";
import { VerificationTemplateProps } from "../VerificationTemplate/VerificationTemplate";

interface Props {
  items: VerificationTemplateProps['verificationStages']
}

export const VerificationStatusSidebar = ({ items }: Props) => {
  const { customerStageSelect, verificationState } = useCustomerVerification();

  function handleClick(stage: ComplianceActionStages) {
    customerStageSelect(stage);
  }

  return (
    <VStack spacing={{ base: -3, md: 2 }} alignItems="flex-start" mb={{ base: 4, md: 0 }}>
      {items.map((item) => {
        return (
          <HStack spacing={1} key={item.key}>
            <VerificationLevelStatusIndicator status={item.status} />
            <Button
              disabled={item.disabled}
              onClick={() => handleClick(item.item)}
              colorScheme={item.item === verificationState.currentStage ? "black" : "blue"}
              variant="ghost">
              {item.title}
            </Button>
          </HStack>
        );
      })}
    </VStack>
  );
};
