export type Nullable<T> = { [K in keyof T]: T[K] | null };
export type DeepNullable<T> = {
  [K in keyof T]: DeepNullable<T[K]> | null;
};

export type Listener = () => void;

export enum DataStatuses {
  Uninitialized = "uninitialized",
  Loading = "loading",
  Error = "error",
  Ok = "ok"
}

export type ErrorResponse = {
  stack?: Error | unknown | undefined;
  message: string | undefined;
}

export type ComplianceTradingStates = 'unknown' | 'active' | 'unverified' | 'DNT' | 'inactive';
