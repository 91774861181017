import React from "react";
import { Alert, AlertDescription, Button, HStack } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useMigrationModalStore } from "../../store/MigrationModalStore";
import { constants } from "../../config/constants";

/**
 * This banner shows when the user is on the UK website
 * it explains that the company has moved to GIB
 */
export const MigrationBanner = () => {
  const { closeModal } = useMigrationModalStore();
  const isReactSnap = navigator && navigator.userAgent === 'ReactSnap';

  if (constants.businessEntity === "uk") {
    return (
      <Alert colorScheme="orange" justifyContent="center">
        <HStack spacing={2} flexDirection="column">
          <AlertDescription mb={2} fontSize="sm" display="block">
            LondonLink OTC has moved trading to our Gibraltar based subsidiary. This allows us to offer you a wider variety of features and services.
          </AlertDescription>
          <HStack spacing={2}>
            <Button size="xs" as="a" href={`${constants.sites.gib}/why-gibraltar/`} variant="ghost" colorScheme="blue">Learn more</Button>
            <Button size="xs" as="a" href={constants.sites.gib} colorScheme="blue">Visit new website</Button>
          </HStack>
        </HStack>
      </Alert>
    );
  }

  return null;
};
