import React, { Fragment, useEffect } from "react";
import { SOFStep1, SOFStep2, SOFStep3, SOFStep4, SpinnerToTickIcon, StepsProps } from "components";
import { Form, Formik } from "formik";
import { Alert, AlertIcon, Box, Button, Grid } from "@chakra-ui/react";
import * as yup from "yup";
import { useCustomerForms, useCustomerVerification } from "hooks";
import { ComplianceActionStages } from "../../../../../../../shared/src/types/compliance";
import { DataStatuses } from "../../../../../../../shared/src/types/common";

interface Props {
  steps: StepsProps;
}

export interface SourceOfFundsFormValues {
  individualRecurringIncome: string;
  individualSavings: string;
  individualOneOffIncomeSwitch: boolean;
  individualOneOffIncome?: string;
  individualNetWorthSwitch: boolean;
  individualNetWorth?: string;
}

const initialValues: SourceOfFundsFormValues = {
  individualRecurringIncome: '',
  individualSavings: '',
  individualOneOffIncomeSwitch: false,
  individualOneOffIncome: '',
  individualNetWorthSwitch: false,
  individualNetWorth: '',
};

export const SourceOfFundsForm = ({ steps }: Props) => {
  const { submitForm, formsState, resetFormsState } = useCustomerForms();
  const { customerStageSelect } = useCustomerVerification();
  const { nextStep, prevStep, activeStep } = steps;

  const isLoading = formsState.formSubmitRequest.dataStatus === DataStatuses.Loading;
  const isSuccess = formsState.formSubmitRequest.dataStatus === DataStatuses.Ok;

  const isFirstStep = activeStep === 0;
  const isFinalStep = activeStep === 3;

  useEffect(() => {
    // Move to next stage after success
    if (isSuccess) {
      // Add a small delay before moving onto the next step to allow the SpinnerToTickIcon component animation to finish
      setTimeout(() => {
        resetFormsState();
        customerStageSelect(ComplianceActionStages.level2AdditionalVerification);
      }, 1250);
    }
  }, [isSuccess]);

  const validationSchema = [
    yup.object().shape({
      individualRecurringIncome: yup.string().required('Field is required'),
    }),
    yup.object().shape({
      individualSavings: yup.string().required('Field is required'),
    }),
    yup.object().shape({
      individualOneOffIncome: yup.string().when('individualOneOffIncomeSwitch', {
        is: (val: boolean) => val,
        then: yup.string().required('Field is required'),
        otherwise: yup.string().nullable()
      }),
    }),
    yup.object().shape({
      individualNetWorth: yup.string().when('individualNetWorthSwitch', {
        is: (val: boolean) => val,
        then: yup.string().required('Field is required'),
        otherwise: yup.string().nullable()
      }),
    }),
  ];

  return (
    <Formik
      validateOnMount
      initialValues={initialValues}
      validationSchema={validationSchema[activeStep]}
      onSubmit={(values: SourceOfFundsFormValues) => {
        const formData = {
          individualRecurringIncome: JSON.parse(values.individualRecurringIncome),
          individualSavings: JSON.parse(values.individualSavings)
        };

        // Add optional fields
        if (values.individualOneOffIncome) {
          Object.assign(formData, { individualOneOffIncome: JSON.parse(values.individualOneOffIncome) });
        }

        if (values.individualNetWorth) {
          Object.assign(formData, { individualNetWorth: JSON.parse(values.individualNetWorth) });
        }

        submitForm(formData);
      }}>
      {({ validateForm, setTouched, isValid, values, setFieldValue }) => {
        return (
          <Form id="t1" style={{ width: '100%' }}>
            {/* Steps */}
            {activeStep === 0 && <SOFStep1 />}
            {activeStep === 1 && <SOFStep2 />}
            {activeStep === 2 && <SOFStep3 values={values} setFieldValue={setFieldValue} />}
            {activeStep === 3 && <SOFStep4 values={values} setFieldValue={setFieldValue} />}

            {/* Handle Error */}
            {formsState.formSubmitRequest.dataStatus === "error" && (
              <Alert status="error" mt={5}>
                <AlertIcon />
                {formsState.formSubmitRequest.dataError?.message}
              </Alert>
            )}

            {/* Buttons */}
            <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
              <Box mt={8} textAlign="right" d="flex" justifyContent={isFirstStep ? 'flex-end' : 'space-between'}>
                {!isFirstStep && (
                  <Button
                    onClick={prevStep}
                    size="md"
                    type="button">
                    Back
                  </Button>
                )}

                {!isFinalStep && (
                  <Button
                    id="next-page"
                    colorScheme="brand"
                    size="md"
                    type="button"
                    onClick={() => {
                      validateForm().then((errors) => {
                        if (isValid && Object.keys(errors).length === 0) {
                          nextStep();
                        } else {
                          // Touch all fields that have errors to display error messages
                          const errorsObject: { [key: string]: boolean } = {};
                          for (const [key] of Object.entries(errors)) {
                            errorsObject[key] = true;
                          }

                          setTouched(errorsObject);
                        }
                      });
                    }}>
                    Continue
                  </Button>
                )}

                {isFinalStep && (
                  <Button
                    type="submit"
                    transition="all 0.5s linear"
                    colorScheme={isSuccess ? "green" : "brand"}
                    leftIcon={<Fragment />}
                    spinnerPlacement="end"
                    rightIcon={isSuccess ? <SpinnerToTickIcon size="sm" /> : <Fragment />}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                    loadingText="Submitting"
                    id="submit">
                    {isSuccess ? "Submitting" : "Submit"}
                  </Button>
                )}
              </Box>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
