import React from "react";
import {
  Box,
  FormControl, FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  ListItem,
  Select,
  Text,
  UnorderedList
} from "@chakra-ui/react";
import { Field } from "formik";

export const SOFStep2 = () => {
  return (
    <Grid templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }} gap={5} my={5}>
      <Field name="individualSavings">
        {({ field, form }: any) => {
          const isInvalid = (form.errors.individualSavings && form.touched.individualSavings);
          return (
            <FormControl isInvalid={isInvalid}>
              <FormLabel as="legend">How much accumulated savings do you hold currently?</FormLabel>
              <FormHelperText fontSize="xs" mb={2}>
                <Box fontSize="sm" mb={4} color="gray.500">
                  <Text>
                    Funds are considered savings when they have not moved between accounts in 3+ months.
                    Examples include:
                  </Text>
                  <UnorderedList mt={1} mb={1} spacing={1}>
                    <ListItem>Funds accumulated in your <Text as="u">bank/ savings accounts</Text></ListItem>
                    <ListItem>Funds accumulated in <Text as="u">NS&I</Text></ListItem>
                    <ListItem>Funds in your <Text as="u">ISA accounts</Text></ListItem>
                  </UnorderedList>
                </Box>
              </FormHelperText>
              <Select
                {...field}
                maxWidth={485}
                placeholder="Select option"
                name="individualSavings"
                id="individualSavings"
                size="sm">
                <option value={`{ "min": 0, "max": 12000 }`}>£0.00 - £12,000</option>
                <option value={`{ "min": 12001, "max": 25000 }`}>£12,001 - £25,000</option>
                <option value={`{ "min": 25001, "max": 40000 }`}>£25,001 - £40,000</option>
                <option value={`{ "min": 40001, "max": 70000 }`}>£40,001 - £70,000</option>
                <option value={`{ "min": 70001, "max": 100000 }`}>£70,001 - £100,000</option>
                <option value={`{ "min": 100001, "max": 150000 }`}>£100,001 - £150,000</option>
                <option value={`{ "min": 150001, "max": 250000 }`}>£150,001 - £250,000</option>
                <option value={`{ "min": 250001, "max": 500000 }`}>£250,001 - £500,000</option>
                <option value={`{ "min": 500001, "max": 1000000 }`}>£500,001 - £1,000,000</option>
                <option value={`{ "min": 1000001, "max": 2500000 }`}>£1,000,001 - £2,500,000</option>
                <option value={`{ "min": 2500001, "max": 5000000 }`}>£2,500,001 - £5,000,000</option>
                <option value={`{ "min": 5001000, "max": 9999999 }`}>£5,001,000 - or above</option>
              </Select>
              <FormErrorMessage>{form.errors.individualSavings}</FormErrorMessage>
            </FormControl>
          );
        }}
      </Field>
    </Grid>
  );
};
