import React from "react";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Link } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";

export const TermsNotAcceptedBanner = () => {
  const location = useLocation();

  return (
    <Alert status="warning" mb={5} mt={1}>
      <AlertIcon />
      <AlertTitle mr={2}>
        There are new terms you have not reviewed which must be accepted before you are able to trade
      </AlertTitle>
      <AlertDescription>
        <Link color="teal.600" as={RouterLink} to="/customer/verification/level-1/">See terms</Link>
      </AlertDescription>
    </Alert>
  );
};
