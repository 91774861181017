import { useEffect, useState } from "react";
import {
  CustomerAuthModel,
  CustomerAuthModelState, CustomerAuthRequestNames,
  LoginRequest, NewPasswordRequest,
  RegisterRequest, ResetPasswordRequest
} from "../../../shared/src/models/customer/CustomerAuthModel";

const customerAuthModel = new CustomerAuthModel();

export function useCustomerAuth() {
  const [authState, setAuthState] = useState<CustomerAuthModelState>(customerAuthModel.getCustomerAuthState());

  // This fires when model state changes
  function onListen() {
    setAuthState(customerAuthModel.getCustomerAuthState());
  }

  useEffect(() => {
    customerAuthModel.addListener(onListen);

    return function cleanup() {
      customerAuthModel.removeListener(onListen);
    };
  }, []);

  function sendLogin(params: LoginRequest) {
    return customerAuthModel.sendLogin(params);
  }

  function register(params: RegisterRequest) {
    return customerAuthModel.register(params);
  }

  function requestNewPassword(params: NewPasswordRequest) {
    return customerAuthModel.requestNewPassword(params);
  }

  function resetRequestState(request: CustomerAuthRequestNames) {
    customerAuthModel.resetRequestState(request);
  }


  function resetPassword(request: ResetPasswordRequest) {
    customerAuthModel.resetPassword(request);
  }


  function logout() {
    return customerAuthModel.logout();
  }

  return {
    authState,
    sendLogin,
    register,
    requestNewPassword,
    resetPassword,
    resetRequestState,
    logout
  };
}
