import React from "react";
import { Alert, AlertDescription, AlertIcon, AlertTitle, Link } from "@chakra-ui/react";
import { Link as RouterLink, useLocation } from "react-router-dom";

export const IneligibleToTradeBanner = () => {
  const location = useLocation();

  if (location.pathname.includes("/customer/trade")) {
    // Trade page already has a message showing trading ineligibility
    return null;
  }

  return (
    <Alert status="error" mb={5} mt={1}>
      <AlertIcon />
      <AlertTitle mr={2}>
        You are not eligible to trade
      </AlertTitle>
      <AlertDescription>
        <Link color="teal.600" as={RouterLink} to="/customer/trade">Learn more</Link>
      </AlertDescription>
    </Alert>
  );
};
