import React, { Fragment } from "react";
import { Heading } from "@chakra-ui/react";
import { useComplianceState } from "hooks";
import { AccountPurposeForm, ContinueNextStep } from "components";

export const AccountPurpose = () => {
  const complianceState = useComplianceState();

  return (
    <Fragment>
      <Heading size="md" as="h2" mb={3}>
        Account purpose
      </Heading>

      {complianceState.actions.t0Form.status === 'completed' ? (
        <ContinueNextStep successMessage="Your details have been successfully submitted." />
      ) : (
        <AccountPurposeForm />
      )}
    </Fragment>
  );
};
